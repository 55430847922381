
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'

import {
  SegmentResource
} from 'be-structures/typescript-generator/assembly'

import { formatDateToDMYShortDot, formatDateToHHmm } from 'common/filters/formattedDates'

import { getImgUrl } from 'common/getImgUrl'

import TlIcons from 'ui-modules/icons/icon'
import Checkbox from 'ui-modules/overflow/string-item.package.vue'
import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'

import WaitingFlightsTimingTooltip from 'ui-modules/tooltips/waiting-flights-timing-tooltip/waiting-flights-timing-tooltip.vue'

@Component({
  components: {
    TlIcons,
    Checkbox,
    CustomTooltip,
    WaitingFlightsTimingTooltip
  },
  filters: {
    formatDateToHHmm,
    formatDateToDMYShortDot
  }
})
export default class FlightsTable extends Vue {
  @Prop(Array) segments!: SegmentResource[]
  @Prop(String) selectedSegmentId!: string

  getImgUrl = getImgUrl

  get segmentsSortedByDate() {
    if (!this.segments) {
      return []
    }
    return this.segments.sort((a ,b) => moment(a.departure.time).isBefore(moment(b.departure.time)) ? -1 : 1)
  }

  isSelectedSegment(segmentId: string) {
    return this.selectedSegmentId === segmentId
  }

  selectSegment(segmentId: string, segment: SegmentResource) {
    if (!this.isSelectedSegment(segmentId)) {
      this.$emit('select-flight-segment', { segment })
    }
  }
}
