
import { Component, Prop, Watch } from 'vue-property-decorator';
import CustomTooltip from "ui-modules/tooltips/custom-tooltip.vue";
import Calendar from 'ui-modules/svg-icon/calendar.vue'
import CalendarSmall from 'ui-modules/svg-icon/calendar-small.vue'
import TooltipWrapper from 'ui-modules/modals/modal-content/tooltip-wrapper.vue'
import Datepicker from 'ui-modules/datepicker/datepicker.vue'
import PriceDatepicker from 'ui-modules/datepicker/price-datepicker.vue'
import RadioButtonSquare from 'ui-modules/elements/radio-button-square/radio-button-square.vue';
import Tooltip from 'ui-modules/elements/tooltip/tooltip.vue'
import DatepickersDialog from './ui/datepickers-dialog.vue';
import DatepickerWithBarCompactImpl from './datepicker-with-bar'
import { IPackageCompareQueryClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types';
import eventBus from 'applications/desktop/eventBus'

@Component({
  components: {
    TooltipWrapper,
    Calendar,
    CalendarSmall,
    CustomTooltip,
    Datepicker,
    DatepickersDialog,
    PriceDatepicker,
    RadioButtonSquare,
    Tooltip,
  },
})
export default class DateSelector extends DatepickerWithBarCompactImpl {
  @Prop(Object) searchQuery!: IPackageCompareQueryClass
  @Prop(Boolean) isShowDatepicker: boolean
  @Prop(Boolean) exactDateValue: boolean

  isDialogVisible = false
  isExactDate = false
  maxWidthTooltip = '400px'

  @Watch('exactDateValue')
  onExactDatesChange(newValue = false) {
    this.isExactDate = newValue
  }

  @Watch('isShowDatepicker')
  onisShowDatepickerChange(newValue: boolean) {
    this.isDialogVisible = newValue
  }

  get dateInterval() {
    return this.searchQuery.dateInterval
  }

  created() {
    this.isExactDate = this.exactDateValue
    this.isDialogVisible = this.isShowDatepicker
    eventBus.$on('update-destinations-apply', () => {
      this.isDialogVisible = false
    })

    eventBus.$on('show-calendar', () => {
      this.isDialogVisible = true
    })
  }

  onConfirmDates() {
    this.isDialogVisible = false
    this.onConfirm()
  }

  onClose() {
    this.isDialogVisible = false
    this.$emit('close-date-picker')
    this.onCancel()
  }

  onDateTypeChange(isExact: boolean) {
    this.$emit('date-type-change', isExact)
  }
}
