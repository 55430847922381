import { required, numeric } from 'vuelidate/lib/validators'

import {
  alphaSentence, 
} from 'common/validation/validation'

import {
  TPersonInfoFormValidation
} from '../custom-forms.types'

import moment from 'moment'

export default ({ 
  requiredForm,
  paxType,
  minDateLimit,
  maxDateLimit,
  minDate
}: {
  requiredForm: boolean,
  paxType: PaxTypeOld,
  minDateLimit: string,
  maxDateLimit: string,
  minDate: string
}): TPersonInfoFormValidation => {
  const vRules = {
    person: {
      firstName: {
        alphaSentence
      },
      lastName: {
        alphaSentence
      },
      birthday: {
        [`dateLimitFor${paxType}`]: v => {
          return (!minDateLimit || moment(v).isSameOrAfter(moment(minDateLimit)))
              && (!maxDateLimit || moment(v).isSameOrBefore(moment(maxDateLimit)))
        }
      },
      gender: {},
      sitizenshipCountry: {},
      passportNumber: {
        numeric
      },
      passportExpiryDate: {
        minDateLimit: v => {
          return (!v || moment(v).isSameOrAfter(moment(minDate)))
        }
      }
    }
  }

  if (requiredForm) {
    return {
      person: {
        firstName: {
          ...vRules.person.firstName,
          required
        },
        lastName: {
          ...vRules.person.lastName,
          required
        },
        birthday: {
          ...vRules.person.birthday,
          required,
        },
        gender: {
          ...vRules.person.gender,
          required
        },
        sitizenshipCountry: {
          ...vRules.person.sitizenshipCountry,
          required
        },
        passportNumber: {
          ...vRules.person.passportNumber,
          required
        },
        passportExpiryDate: {
          ...vRules.person.passportExpiryDate,
          required
        },
      }
    }
  } else {
    return vRules
  }
}
