
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import PaxUniversalSelector from 'ui-modules/selectors/pax-universal-selector.vue'
import { IPackageComparePaxes } from 'applications/desktop/package-compare-app/types/package-compare-app.types'

@Component({
  components: {
    PaxUniversalSelector
  }
})
export default class PassengerSelector extends Vue {
  @Prop(Object) paxes: IPackageComparePaxes

  config: any = {
    ADULT: { val: [1, 9] },
    CHILD: { val: [0, 9] },
    INFANT: { val: [0, 0] }
  }
}
