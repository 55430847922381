
import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";

import PriceBlock from "applications/desktop/package-compare-app/components/search-results/ui/price-block.vue";
import ProductBlock from "applications/desktop/package-compare-app/components/search-results/ui/product-block.vue";
import HotelStars from "ui-modules/cards/hotel/stars.vue";
import OrderButton from "ui-modules/buttons/order-btn.vue";
import SomethingWentWrong from "ui-modules/something-went-wrong/something-went-wrong.vue"
import mathCeil from "common/filters/mathCeil";
import CustomTooltip from "ui-modules/tooltips/custom-tooltip.vue";
import TlIcon from "ui-modules/icons/icon";
import HotelImage from 'ui-modules/cards/hotel/v2/hotel-image.vue'

import {
  IPackageClass,
  ISearchResultCardPost
} from "applications/desktop/package-compare-app/components/search-results/types/search-results.types";

import { formatDateToDMDot } from 'common/filters/formattedDates'
import {PRODUCT_RESULT_MODULE} from "../../../store/store";

@Component({
  components: {
    HotelStars,
    OrderButton,
    PriceBlock,
    ProductBlock,
    CustomTooltip,
    TlIcon,
    SomethingWentWrong,
    HotelImage
  },
  filters: {
    mathCeil
  },
})
export default class SearchResultCard extends Vue implements ISearchResultCardPost {
  @Prop(Object) destinationPackage!: IPackageClass
  @Prop(Boolean) isFavoritePackage!: boolean
  @Prop(Boolean) isComparablePackage!: boolean
  @Prop(Boolean) isReplacedPackage!: boolean
  @Prop(Boolean) isPackageSubstitute!: boolean
  @Prop(Boolean) isPackageSelectionMode!: boolean
  @Prop(Boolean) getProductsDetailsPending!: boolean
  @Prop(Boolean) alwaysShowTooltipOnActiveComparison!: boolean;
  @Prop({ type: Boolean, default: false }) hideCityName!: boolean;
  @Prop(Number) quantityComparablePackages!: number;
  @Prop(Number) quantityFavoritePackages!: number;
  @Prop(Boolean) exactDates!: boolean;
  @Prop(Boolean) isBookingErrorOccured!: boolean;
  @Prop(Boolean) isPackageUnavailable!: boolean;
  @Prop(Number) index!: number

  destroyed() {
    this.$emit('drop-index-to-scroll')
  }

  mounted() {
    if (!this.destinationPackage.isSoldOut) {
      this.$emit('scroll-to-card', this.index)
    }
  }
  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }
  get getSessionReady() {
    return this.resultStore.isSessionReady
  }

  get productResult() {
    return PRODUCT_RESULT_MODULE
  }

  nextStepTraining() {
    this.$emit('next-step-training')
  }
  setModalPost() {
    const modalPost = {
      component: 'session-expired',
      preventDefaultClose: true
    }
    this.productResult.setModalPost({modalPost})
  }

  changeComparisonStatus() {
    if(this.getSessionReady) {
      this.$emit('change-comparison-status')
    } else {
      this.setModalPost()
      this.resultStore.setIsShowModal({value: true})
    }
  }
  changeFavoriteStatus() {
    if(this.getSessionReady) {
      this.$emit('change-favorite-status')
    } else {
      this.setModalPost()
      this.resultStore.setIsShowModal({value: true})
    }
  }
  get isFastOk() {
    return this.destinationPackage?.selectedProduct.isFastOk();
  }

  get dealInterval(): string {
    return `
      ${formatDateToDMDot(this.destinationPackage.selectedProduct.segmentGroups[0].segments[0].departure.time)}
      -
      ${formatDateToDMDot(this.destinationPackage.selectedProduct.segmentGroups[1].segments[0].departure.time)}
    `;
  }

  get disableComparisonTooltip() {
    return this.alwaysShowTooltipOnActiveComparison ?
      this.quantityComparablePackages > 0 && !this.isActiveComparisonBtn :
      this.isPackageSelectionMode ? !(this.isComparablePackage && !this.isPackageSubstitute) : this.quantityComparablePackages > 0
  }

  get isActiveComparisonBtn(): boolean {
    return this.isComparablePackage || this.isPackageSubstitute || this.isReplacedPackage
  }

  get isEnabledComparisonBtnTooltip(): boolean {
    if (this.alwaysShowTooltipOnActiveComparison) {
      return this.quantityComparablePackages === 0 || this.isActiveComparisonBtn
    } else {
      return this.quantityComparablePackages === 0 ||
        (this.isActiveComparisonBtn && this.isPackageSelectionMode)
    }
  }

  get hotel() {
    return this.destinationPackage.hotel;
  }

  get segmentGroups() {
    return this.destinationPackage.selectedProduct?.segmentGroups;
  }

  get packageWithSingleAvailableNight() {
    return this.destinationPackage.withSingleAvailableNight()
  }

  showFlightChangeModal(): void {
    this.$emit("show-flight-change-modal");
  }
}
