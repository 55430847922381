
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon'
import TransitionSlide from 'ui-modules/transition/transition-slide.vue'
import dateTime from 'common/filters/datetime'

import { IPackageHotelClass } from 'applications/desktop/package-compare-app/components/create-search/types/create-search.types'
import { IOrderSegmentGroup } from 'modules/product-order/data/product-order.types'

@Component({
  components: {
    TlIcon,
    TransitionSlide
  },
  filters: {
    dateTime
  }
})
export default class HotelFlySegments extends Vue {
  @Prop(Object)
  product!: any
  @Prop(Boolean)
  moreAvailable!: Boolean
  @Prop(Boolean)
  pending: boolean
  @Prop(Boolean)
  reverseSlide: boolean

  get segmentGroups(): IOrderSegmentGroup[] {
    return this.product?.segmentGroups
  }
}
