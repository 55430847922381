
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcon
  }
})
export default class Stars extends Vue {
  @Prop(Number) countStars!: number
  @Prop(Boolean) small!: boolean
  @Prop({ type: Boolean, default: true }) greyStars!: boolean

  get getCountStarts(): number {
    return  Number(this.countStars)
  }
  get count(): number {
    return this.greyStars ? 5 : this.countStars
  }
}
