
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import FilterCondition from "ui-modules/products-filter/filter.condition.vue"
import FilterConditionItemsList from 'ui-modules/products-filter/filter.condition-items-list.vue'

import {
  IFilterConditionGroup,
  IFilterItemsListCondition,
  IResultPost
} from "ui-modules/products-filter/types/products-filter.types"

@Component({
  components: {
    FilterCondition,
    FilterConditionItemsList
  }
})
export default class FilterAirlines extends Vue {
  @Prop(Object)
  post!: IResultPost

  get airlinesPost(): IFilterItemsListCondition {
    return {
      items: this.post.packageInitialFilter.airlines,
      selectedItems: this.post.packageFilter.airlines,
      selectedItemsCodes: this.post.packageFilter.airlinesCodes,
      type: 'airlines'
    }
  }

  get conditionGroupPost(): IFilterConditionGroup {
    return {
      groupTitle: 'filter.airlines',
      useAccordionTitleInFilter: this.post.useAccordionTitleInFilter
    }
  }
}
