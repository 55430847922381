
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component({})
export default class ActionPanel extends Vue {
  @Prop(String)
  submitClass: string
  @Prop({ type: String, default: 'Apply' })
  submitText: string
  @Prop(String)
  resetClass: string
  @Prop({ type: String, default: 'Cancel' })
  resetText: string
  @Prop(String)
  wrapperClass: string
  @Prop(Boolean)
  disabled: boolean
  @Prop(Boolean) noSubmitButton: boolean
}
