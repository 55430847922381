import { required, numeric, email } from 'vuelidate/lib/validators'

import {
  alphaSentence, anyAlphaSentence,
  phoneNumber,
  phoneNumberMustStartWithPlus
} from 'common/validation/validation'

import {
  TPersonInfoFormValidation
} from '../custom-forms.types'

export default ({ requiredForm }: { requiredForm: boolean }): TPersonInfoFormValidation => {
  const vRules = {
    person: {
      firstName: {
        alphaSentence,
        required
      },
      lastName: {
        alphaSentence,
        required
      },
      phone: {
        phoneNumber,
        phoneNumberMustStartWithPlus,
        required
      },
      email: {
        email,
        required
      },
    }
  }

  if (requiredForm) {
    return {
      person: {
        firstName: {
          ...vRules.person.firstName
        },
        lastName: {
          ...vRules.person.lastName
        },
        phone: {
          ...vRules.person.phone
        },
        email: {
          ...vRules.person.email
        },
      }
    }
  } else {
    return vRules
  }
}
