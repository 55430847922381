
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import ActionPanel from 'ui-modules/buttons/action-panel.vue'
import CountrySelector from 'ui-modules/selectors/country-selector.vue'

import { IDestinationModalPost } from 'ui-modules/modals/modal-content/modal-select-country/modal-select-country.types'
import { IQueryLocation } from 'modules/product-search.v2/data/product-search.types'
import BoxLoader from "../../../marker-loader/marker-loader.vue";


@Component({
  components: {
    BoxLoader,
    ModalWindow,
    ActionPanel,
    CountrySelector,
  },
})
export default class ModalSelectCountry extends Vue {
  @Prop(Object) post: IDestinationModalPost

  currentSelectedDestination: Partial<IQueryLocation> = {}

  onCountrySelect(location: IQueryLocation) {
    this.currentSelectedDestination = location
    this.$emit('select-destination', location)
  }

  get updatedDestination() {
    return Object.entries(this.currentSelectedDestination).length ? this.currentSelectedDestination : this.post.value
  }
}
