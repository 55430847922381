import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'

import {
  PRODUCT_ORDER_MODULE,
  PRODUCT_ORDER_VIEW_MODULE
} from 'applications/desktop/package-compare-app/store/store'

@Component({})
export default class OrderCommon extends Vue {
  get orderStore() {

    return PRODUCT_ORDER_MODULE
  }

  get orderStoreView() {
    return PRODUCT_ORDER_VIEW_MODULE

  }

  get order() {
    return this.orderStore.order
  }

  get owner() {
    return this.orderStoreView.owner
  }
}
