import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.min.css'
import Vuetify from 'vuetify/lib'

import colors from 'common/vuetify/light-theme.basic.scss'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: false,
  theme: {
    themes: {
      light: {
        primary: colors.primary,
        accent: colors.accent,
        error: colors.error,
        success: colors.success,
        alpha: colors.alpha,
        beta: colors.beta,
        'beta-60': colors['beta-60'],
        gamma: colors.gamma,
        'gamma-a15': colors['gamma-a15'],
        delta: colors.delta,
        epsilon: colors.epsilon,
        zeta: colors.zeta,
        eta: colors.eta,
        theta: colors.theta,
        iota: colors.iota,
        lambda: colors.lambda,
        mu: colors.mu
      }
    },
    options: {
      customProperties: true,
      variations: false
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})