import Component, { mixins } from 'vue-class-component'
import { Ref } from 'vue-property-decorator'

import {
  AppMode,
  HOTEL_SCORE_TYPES,
  IPackageHotelClass,
  HotelFacilityGroupsTypes,
  IPackageClass,
  IPackageHotelFacility
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

import SearchResultsCommon from 'applications/desktop/package-compare-app/components/search-results/search-results.common'
import ComparisonTable from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table.vue'
import PackageFilter from 'applications/desktop/package-compare-app/components/compare-products/ui/filter.vue'
import PackageScore from 'ui-modules/package-score/package-score.vue'

import TlIcon from 'ui-modules/icons/icon'
import {PRODUCT_SEARCH_MODULE} from "applications/desktop/package-compare-app/store/store";

@Component({
  components: {
    PackageScore,
    TlIcon,
    PackageFilter,
    ComparisonTable
  }
})
export default class CompareProductsComp extends mixins(SearchResultsCommon) {
  @Ref("comparable-products-content") readonly comparisonTableContent!: HTMLFormElement;
  hotelFacilityGroupsTypes = HotelFacilityGroupsTypes
  hotelScoreTypes = HOTEL_SCORE_TYPES
  created() {
    this.checkToPageReloaded()
  }
  get searchStore() {
    return PRODUCT_SEARCH_MODULE
  }

  get searchQuery() {
    return this.searchStore.searchQuery
  }

  checkToPageReloaded() {
    //TODO: write this
  }


  get facilitiesGroupsNames(): string[] {
    return Object.values(HotelFacilityGroupsTypes)
  }


  onPreparePackageForAdditionToComparison(
    {
      fromFavorites = false,
      currentPackage,
      index
    }: {
      fromFavorites?: boolean,
      currentPackage: IPackageClass,
      index: number
    }): void {

    this.packageCompareAppStore.setReplacedPackage(currentPackage)
    this.packageCompareAppStore.setIndexForSelectedComparisonColumn({ index })

    if (fromFavorites) {
      this.goToFavoritePackages(AppMode.PACKAGE_SELECTION)
    } else {
      this.goToResultPage(AppMode.PACKAGE_SELECTION)
    }
  }

  onDisableFacility(facility: IPackageHotelFacility): void {
    this.packageCompareAppStore.disableFacility({ facility })
  }

  onEnableFacility(facility: IPackageHotelFacility): void {
    this.packageCompareAppStore.enableFacility({ facility })
  }

  showHotelInfo(hotel: IPackageHotelClass) {
    const modalPost = {
      component: 'session-expired',
      preventDefaultClose: true
    }
    this.setModalPost({modalPost: modalPost})
    this.openHotelCardModal(hotel)
  }
}
