import cloneDeep from 'lodash/cloneDeep'

import type {
  MediaResource
} from 'be-structures/typescript-generator/assembly'

import {
  IMediaResource,
  MEDIA_BLANK
} from 'modules/common/common.types'

export const convertMediaResourceToIMediaResource = (({ media }: { media?: MediaResource }): IMediaResource => {
  const convertedMedia = cloneDeep(MEDIA_BLANK)

  if (media) {
    for (const item of Object.keys(MEDIA_BLANK)) {
      convertedMedia[item] = media[item]
    }
  }
  
  return convertedMedia
})