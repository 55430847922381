
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import ActionPanel from 'ui-modules/buttons/action-panel.vue'
import Datepicker from 'ui-modules/datepicker/datepicker.vue'

import { IStartEnd } from "modules/common/common.types";
import { PRODUCT_SEARCH_MODULE } from "applications/desktop/package-compare-app/store/store";
import Switcher from 'ui-modules/switcher/switcher.vue'

@Component({
  components: {
    ModalWindow,
    ActionPanel,
    Switcher,
    Datepicker
  }
})
export default class ModalDatepicker extends Vue {
  @Prop(Object) post: any

  get datepicker() {
    return this.$refs.datepicker
  }
  get searchStore() {
    return PRODUCT_SEARCH_MODULE
  }

  get searchQuery() {
    return this.searchStore.searchQuery
  }

  get exactDates() {
    return  this.searchQuery.exactDates
  }

  changeSearchType(exactDates) {
    this.searchQuery.exactDates = exactDates
  }

  setQueryDateInterval(interval: IStartEnd<string>) {
    this.searchQuery.dateInterval = interval
    this.updateDestinationsAfterChangingQuery()
  }
}
