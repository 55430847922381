
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import CustomTooltip from 'ui-modules/elements/tooltip/tooltip.vue'
import dynamicFacade from 'common/filters/dynamicFacade'

@Component({
  components: {
    CustomTooltip
  }
})
export default class CustomTextField extends Vue {
  @Prop({ type: String, default: null }) tooltip!: string
  @Prop({ type: String, default: null }) label!: string
  @Prop({ type: String, default: null }) placeholder!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: [String, Number, Date], default: null }) value!: string | number | Date
  @Prop({ type: String, default: null }) appendIcon!: string
  @Prop({ type: String, default: null }) errorMessages!: string
  @Prop({ type: [Array, String], default: null }) mask!: string[] | string
  @Prop({ type: String, default: null }) dynamicMask!: string
  @Prop({ type: String, default: null }) persistentHint!: string
  @Prop({ type: Boolean, default: false }) isWarningHint!: boolean
  @Prop({ type: Boolean, default: false }) hideDetails!: boolean
  @Prop({ type: Boolean, default: false }) forbidSelect: boolean

  model: string | number | Date = null

  @Watch('value')
  initModel(value) {
    this.model = value
  }

  created() {
    this.model = this.value
  }

  get dynamicMaskValue(): string {
    return dynamicFacade({ s: this.model, facade: this.dynamicMask })
  }

  focus() {
    this.$refs.input.focus()
  }

  selectAll() {
    if (!this.forbidSelect) {
      (this.$refs.input as any).$el.querySelector('input').select()
    }
  }
}
