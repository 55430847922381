
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import { DealFeature } from 'be-structures/typescript-generator/assembly'

import { getImgUrl, isImgUrl } from 'common/getImgUrl'
import Mark from "ui-modules/mark/mark.vue"
import OrderSummaryItem
  from "../../applications/desktop/package-compare-app/components/order/ui/order-summary/order-summary-item.vue";
import Stars from "../cards/hotel/stars.vue";

@Component({
  components: {
    Stars, OrderSummaryItem,
    Mark,
  }
})

export default class ProductFeaturesBox extends Vue {
  @Prop(Array) dealFeatures!: DealFeature[]
  @Prop({ type: Boolean, default: false, required: false }) notAvailable!: Boolean
	@Prop({ type: Boolean, default: true, required: false }) withDash!: boolean
  @Prop({ type: Boolean }) hideText: Boolean
	@Prop({ type: String }) title: string

	getImgUrl = getImgUrl
	isImgUrl = isImgUrl

  get classFeatureItem() {
    return this.hideText ? 'order-features-box__item--no-text' : ''
  }

  get classFeatureText() {
    return !this.withDash ? 'order-features-box__text--noDash' : ''
  }

  isNotAvailable(feature: DealFeature): boolean {
    return this.notAvailable && isImgUrl(feature.toLowerCase(), 'svg')
  }
}
