import {
  IPaxes,
  TArrayOfVacationTypes
} from 'modules/common/common.types'

const MAX_TOTAL_PAX: number = 9

const PAX: IPaxes = {
  ADULT: [1, 9],
  CHILD: [0, 9],
  INFANT: [0, 9],
  SENIOR: [0, 9]
}

const VACATION_GROUPS: TArrayOfVacationTypes[] = [
    ['BEACH', 'CITY'],
  ['SKI',  'FAMILY', 'SPA', 'GUIDED_TOUR', 'CIVIL_MARRIAGE','FLY_AND_DRIVE', 'YOUNG_VACATION'],
]

enum QueryTypes {
  'comparePackage' = 'COMPARE_PACKAGE',
  'package' = 'PACKAGE',
  'flight' = 'FLIGHT'
}

enum SegmentIndexes {
  'firstSegment',
  'secondSegment'
}

enum SearchPendingsNames {
  'getCountries',
  'getCountriesForLocale',
  'getAndSetCountries',
  'getCities',
  'getAndSetCities',
  'bestDeals',
  'getDepartureDatesByCountry',
  'getAndSetDepartureDatesByCountry',
  'getReturnDatesByCountry',
  'getAndSetReturnDatesByCountry',
  'getCountry',
  'getCountryAll',
  'getCountryDigest',
}

enum SearchResultTypes {
  SEARCH_HAS_BEGUN = 'SEARCH_HAS_BEGUN',
  DEALS_POST_PROCESSED = 'DEALS_POST_PROCESSED',
  SUPPLIERS_RESPONDED = 'SUPPLIERS_RESPONDED'
}

export {
  MAX_TOTAL_PAX,
  PAX,
  QueryTypes,
  SegmentIndexes,
  SearchResultTypes,
  VACATION_GROUPS,
  SearchPendingsNames
}
