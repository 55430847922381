
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { validationMixin, Vuelidate } from 'vuelidate'
import * as moment from 'moment'
import { TranslateResult } from 'vue-i18n'

import {
  IError,
  ICustomer,
  ICustomerInfo,
  IAddresses,
  IPassport,
  IMedia,
  ILocation,
  IPaxPrice,
  ICustomerPost,
  IPricePost
} from 'applications/desktop/flight-app/types/desktop-app.types'
import Selector from 'ui-modules/selectors/selector.vue'
import TlIcon from 'ui-modules/icons/icon'
import LocationFormSelector from 'ui-modules/location-form-selector/location-form-selector.vue'
import SpecialAssistances from './order-product.special-assistances.vue'
import ErrorTooltip from 'ui-modules/tooltips/err-tooltip.vue'
import {
  validations,
  ICustomerInput
} from '../validate/order-product.customer-fields.validate'

@Component({
  mixins: [validationMixin],
  components: {
    ErrorTooltip,
    Selector,
    LocationFormSelector,
    TlIcon,
    SpecialAssistances
  },
  validations
})
export default class OrderCustomerFields extends Vue {
  @Prop(Object)
  post!: ICustomerPost
  @Prop(Object)
  pricePost!: IPricePost
  @Prop(String)
  paxType!: string
  @Prop(Number)
  index: number
  @Prop(Object)
  error!: IError

  $v: Vuelidate<any>
  isAssistanceField: boolean = false
  customerData: ICustomerInput = {
    addresses: {
      country: null
    },
    customerInfo: {
      lastName: null,
      firstName: null,
      middleName: null,
      gender: null,
      birthday: {
        year: null,
        month: null,
        day: null
      }
    },
    passport: {
      passportNumber: null,
      passportExpirationDate: {
        year: null,
        month: null,
        day: null
      }
    }
  }

  get currentCustomerCountry(): ILocation | string {
    return this.customerData.addresses.country
      ? this.customerData.addresses.country
      : ({} as ILocation)
  }

  get customerType(): string {
    return (
      this.paxType[0] +
      this.paxType.substring(1, this.paxType.length).toLowerCase()
    )
  }

  get paxTypeLabel(): TranslateResult {
    if (this.paxType === 'ADULT') return this.$t('common.paxes.adults')
    else if (this.paxType === 'CHILD') return this.$t('common.paxes.children')
    return this.$t('common.paxes.infants')
  }

  get formInvalidState(): boolean {
    return this.$v.customerData.$invalid
  }

  get monthsToSelector(): TranslateResult[] {
    let months: TranslateResult[] = []
    for (let i = 0; i < 12; i++) {
      const month: TranslateResult = this.$t(`common.months.${i}`)
      months.push(month)
    }
    return months
  }

  get daysToSelector(): string[] {
    const daysInterval: string[] = []
    const year: number = this.customerData.customerInfo.birthday.year
    const month: number = this.customerData.customerInfo.birthday.month
    const day: number = this.customerData.customerInfo.birthday.day

    if (year && month) {
      const monthNumber: string = moment()
        .month(month)
        .format('M')
      const currentYearAndMonth: moment.Moment = moment()
        .set('year', +year)
        .set('month', +monthNumber - 1)

      for (let i = 1; i <= currentYearAndMonth.daysInMonth(); i++) {
        daysInterval.push(i.toString())
      }
    }

    return daysInterval
  }

  get isPassportValidCurrentYear(): boolean {
    return (
      Number(this.customerData.passport.passportExpirationDate.year) ===
      Number(moment().format('YYYY'))
    )
  }

  get isPassportValidCurrentMonth(): boolean {
    return (
      this.isPassportValidCurrentYear &&
      String(this.customerData.passport.passportExpirationDate.month) ===
        String(moment().format('MMMM'))
    )
  }

  get currentCustomerGender(): string[] {
    return this.customerData.customerInfo.gender
      ? [this.customerData.customerInfo.gender]
      : ['Gender']
  }

  get monthsToPassportSelector(): TranslateResult[] {
    let months: TranslateResult[] = []
    for (let i = this.isPassportValidCurrentYear ? moment().month() : 0; i < 12; i++) {
      const month: TranslateResult = this.$t(`common.months.${i}`)
      months.push(month)
    }
    return months
  }

  get daysToPassportSelector(): string[] {
    const daysInterval: string[] = []
    if (
      this.customerData.passport.passportExpirationDate.month &&
      this.customerData.passport.passportExpirationDate.year
    ) {
      const monthNumber: string = moment()
        .month(this.customerData.passport.passportExpirationDate.month)
        .format('M')
      const currentYearAndMonth: moment.Moment = moment()
        .set('year', +this.customerData.passport.passportExpirationDate.year)
        .set('month', +monthNumber - 1)

      if (this.isPassportValidCurrentMonth) {
        const minDayNumber: number = Number(moment().format('DD'))
        for (
          let i = minDayNumber;
          i <= currentYearAndMonth.daysInMonth();
          i++
        ) {
          daysInterval.push(i.toString())
        }
      } else {
        for (let i = 1; i <= currentYearAndMonth.daysInMonth(); i++) {
          daysInterval.push(i.toString())
        }
      }
    }
    return daysInterval
  }

  getYearInterval(paxType: string): number[] {
    switch (paxType) {
      case 'CHILD':
        return [2, 17]
      case 'INFANT':
        return [0, 1]
      default:
        return [18, 65]
    }
  }

  getYearsToSelector(paxType: string, selectorType: string): string[] {
    const yearNow: number = moment().year()
    const yearInterval: string[] = []

    let minYear: number = null
    let maxYear: number = null

    if (selectorType === 'birth') {
      minYear = yearNow - this.getYearInterval(paxType)[1]
      maxYear = yearNow - this.getYearInterval(paxType)[0]
    } else {
      minYear = yearNow
      maxYear = yearNow + 20
    }

    for (let i = maxYear; i >= minYear; i--) {
      yearInterval.push(i.toString())
    }

    return selectorType === 'birth'
      ? yearInterval
      : yearInterval.sort((a, b) => +a - +b)
  }

  updateDate(
    type: string,
    date: { year: string; month: string; day: string; [type: string]: string },
    value: string
  ) {
    date[type] = value

    if (type === 'year') {
      date.month = null
    }

    date.day = null
  }

  formatData(): ICustomer {
    const birthYear: number = this.customerData.customerInfo.birthday.year
    const birthMonth: number = this.customerData.customerInfo.birthday.month
    const birthDay: number = this.customerData.customerInfo.birthday.day

    const passportYear: number = this.customerData.passport
      .passportExpirationDate.year
    const passportMonth: number = this.customerData.passport
      .passportExpirationDate.month
    const passportDay: number = this.customerData.passport
      .passportExpirationDate.day

    const birthday: moment.Moment | string = birthDay
      ? moment(`${birthYear}-${birthMonth}-${birthDay}`, 'YYYY-MMMM-DD').format(
          'YYYY-MM-DD'
        )
      : null

    const passportExpirationDate: moment.Moment | string = passportDay
      ? moment(
          `${passportYear}-${passportMonth}-${passportDay}`,
          'YYYY-MMMM-DD'
        ).format('YYYY-MM-DD')
      : null

    const formatedData = {
      type: this.customerType,
      customerInfo: {
        ...this.customerData.customerInfo,
        birthday
      },
      passport: {
        ...this.customerData.passport,
        passportExpirationDate
      },
      addresses: [
        {
          type: 'REGISTRATION',
          country: this.customerData.addresses.country
            ? (this.customerData.addresses.country as ILocation).code
            : this.customerData.addresses.country
        }
      ]
    } as ICustomer

    return formatedData
  }

  onInputCustomerData(): void {
    let customerData: ICustomer = this.formatData()

    this.$emit('input-customer', customerData)
  }

  showAssistancePopup(): void {
    this.isAssistanceField = !this.formInvalidState

    if (this.isAssistanceField) {
      this.$emit('customer-registration', this.index)
    }
  }

  onFocus(input: HTMLElement): void {
    const parentWrapper = input.closest('.input-wrapper')
    parentWrapper.classList.add('focus')
  }

  onBlur(input: HTMLElement): void {
    const parentWrapper = input.closest('.input-wrapper')
    parentWrapper.classList.remove('focus')
  }
}
