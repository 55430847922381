
import { Prop, Component, Mixins, Watch } from 'vue-property-decorator'
import { mixin as clickaway } from 'vue-clickaway'

import { IPackageProductClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types'
import Multiselect from 'vue-multiselect'
import ProductInfo from 'applications/desktop/package-compare-app/components/order/ui/product-selector/product-info.vue'
import SelectorCaret from 'ui-modules/svg-icon/selector-caret.vue'
import InfiniteLoading from 'vue-infinite-loading';
import InfoAboutOrderTooltip from "../../../../../../../ui-modules/tooltips/info-about-order-tooltip.vue";
import { PRODUCT_RESULT_MODULE } from 'applications/desktop/package-compare-app/store/store'

@Component({
  components: {
    InfoAboutOrderTooltip,
    Multiselect,
    ProductInfo,
    SelectorCaret,
    InfiniteLoading
  }
})
export default class ProductSelector extends Mixins(clickaway) {
  @Prop({type: Array, default: () => []}) products: IPackageProductClass[]
  @Prop(Object) selectedProduct: IPackageProductClass
  @Prop(Boolean) selectedUnavailable: boolean
  @Watch('products', {immediate: true})
  onProductsChange(products) {
    this.localProducts = [...products.slice(0,6)]
    this.firstProductIndexToLoad = 6
    this.infiniteLoaderId += 1
  }

  localProducts: IPackageProductClass[] = []
  firstProductIndexToLoad: number = 6
  selectorOpened: boolean = false
  clickedOnSelected: boolean = false
  isManualClose: boolean = false
  isOpened: boolean = false // This is a Boolean type!
  infiniteLoaderId: number = 0

  created() {
    this.localProducts = [...this.products.slice(0,6)]
  }

  get isShowEditIcon() {
    return !this.selectorOpened && this.products.length > 1
  }

  get productResultStore() {
    return PRODUCT_RESULT_MODULE
  }
  
  get tip(): string {
    if (this.products.length === 1) {
      return 'no-options'
    }
    return this.selectedUnavailable ? 'tip-for-unavailable' : 'tip'
  }

  get typeOfSelected(): string {
    if (this.selectorOpened) {
      return this.selectedUnavailable ? 'not-available' : 'selected'
    }
    return 'default'
  }

  onOverlayClick() {
    if (!this.selectorOpened) {
      return
    }
    // deactivate() will make multiselect fire @close, so onSelectorClose will be called again
    this.isManualClose = true
    this.$refs.multiselect.deactivate()
    this.$emit('close')
  }

  async onSelectorClose(val) {
    this.isOpened = false
    // waiting for selectedProduct to change on new value in case of selecting new option
    await this.$nextTick()
    if (this.selectedProduct.productId !== val.productId || this.isManualClose) {
      this.isManualClose = false
      this.selectorOpened = false
      return
    }
    this.open()
  }

  open() {
    if (this.$refs.multiselect) {
      this.$refs.multiselect.activate()
    }
  }

  loadMoreProducts(loadingState) {
    if (this.products.length >= this.firstProductIndexToLoad) {
      this.localProducts.push(...this.products.slice(this.firstProductIndexToLoad, this.firstProductIndexToLoad + 3))
      this.firstProductIndexToLoad += 3
      loadingState.loaded()
    } else {
      loadingState.complete()
    }
  }
}
