
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import HotelImage from 'ui-modules/cards/hotel/v2/hotel-image.vue'
import HotelStars from "ui-modules/cards/hotel/stars.vue";

import capitalizeDestination from "common/filters/capitalizeDestination";
import mathCeil from 'common/filters/mathCeil'
import { addCurrency } from 'common/filters/addCurrency'

import {
  IPackageClass,
  IPackageCompareResultClass,
  IPackageCompareResultState
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    HotelImage,
    HotelStars,
  },
  filters: {
    capitalizeDestination,
    mathCeil,
    addCurrency
  }
})
export default class HotelDigest extends Vue {
  @Prop(Object) currentPackage!: IPackageClass
}
