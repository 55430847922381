
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import TlIcons from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcons
  }
})
export default class GammaBtn extends Vue {
  @Prop(Boolean) disabled: boolean
  @Prop(String) title!: string
  @Prop({ type: Boolean, required: false, default: false }) isPending!: string

  onClick() {
    if (!this.isPending) {
      this.$emit('click')
    }
  }
}
