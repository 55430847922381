
import Component from 'vue-class-component'

import TlIcons from "ui-modules/icons/icon";
import {Prop, Vue} from "vue-property-decorator";
import {Accessibility} from "applications/desktop/accessibility/accessibility";
import {AppSections} from "../package-compare-app/components/root-app/types/root-app.conts";

@Component({
  name: "AccessBtn",
  components: {
    TlIcons,
  }
})
export default class AccessBtn extends Vue {
  @Prop({type: Accessibility, required: true}) startAccessibility: Accessibility;

  appSections = AppSections

  toggleAccessibility(status: boolean) {
    this.startAccessibility.toggleAccessibility(status);
  }
  get classAccessibilityBtn(): string {
    return 'access-btn-other-page'
    // return this.route !== this.appSections.SEARCH_PAGE ? 'access-btn' : 'access-btn-other-page'
  }

  get isAccessibility(): boolean {
    return this.startAccessibility.isAccessibility;
  }

  get isHideAccess() {
    return ["orderById", "errorPayment", "cancelPayment", "successPayment"].includes(this.routeName)
  }

  get routeName() {
    return this.$route.name;
  }

  get route(): string {
    switch (this.routeName) {
      case 'package-compare.prepare-search-data':
        return this.appSections.PREPARE_SEARCH_PAGE
      case 'order':
        return this.appSections.ORDER_PAGE
      default:
        return this.appSections.SEARCH_PAGE
    }
  }
};
