
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import {} from 'google-maps'

import gmapsInit from 'ui-modules/googleMaps/googleMaps.init'
import { TGoogleMapLocations } from 'ui-modules/googleMaps/googleMaps.types'

@Component
export default class GoogleMap extends Vue {
  @Prop(Array) locations!: TGoogleMapLocations
  @Prop({type: Boolean, default: false}) hideUI!: boolean // hide google maps ui

  @Watch('locations')
  initializeMap(value) {
    if (value.length > 0) {
      this.initialMarkers(value)
    }
  }

  map: google.maps.Map
  markers: google.maps.Marker[] = []
  addFullscreenListener() {
    if (!document.fullscreenElement) {
      document.addEventListener("fullscreenchange", this.handleFullscreenChange);
    }
  }

  handleFullscreenChange() {
    // this.map.panToBounds(this.bounds)
    if (document.fullscreenElement) {
      this.map.setOptions({minZoom: 4})
      // console.log('Entered fullscreen mode');
      this.map.fitBounds(this.bounds, -120)
    } else {
      this.map.setOptions({minZoom: 1})
      // console.log('Exited fullscreen mode');
      this.map.fitBounds(this.bounds)
      this.map.panToBounds(this.bounds)
      // this.map.setZoom(1); // масштаб при выходе из полноэкранного режима
    }
  }
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
  }

  async mounted() {
    this.addFullscreenListener();
    try {
      await gmapsInit()

      this.map = new google.maps.Map(this.$el, {
        disableDefaultUI: this.hideUI,
        controlSize: 22,
        minZoom:1
      })
      this.initialMarkers(this.locations)
    } catch (error) {
      console.error(error)
    }
  }

  bounds = null

  initialMarkers(locations: TGoogleMapLocations): void {
    if (!this.map) {
      console.warn('GoogleMap.initialMarkers map isn\'t ready')
      return
    }

    if (locations.length > 0) {
      const bounds = new google.maps.LatLngBounds()

      this.removeMarkers()

      locations.map(location => {
        if (location && location.lat && location.lng) {
          // TODO: TEMP
          location = { countryCode: location.countryCode, lat: +location.lat, lng: +location.lng }

          const marker = new google.maps.Marker({
            position: location,
            map: this.map
          })

          this.markers.push(marker);
          // marker.addListener('click', () => this.$emit('click-marker', { countryCode: location.countryCode }))
          bounds.extend(new google.maps.LatLng(location.lat, location.lng))
        }
      })

      if (bounds.getNorthEast()?.equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01)
        const extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01)
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
      }
      this.bounds =  bounds

      this.map.fitBounds(bounds)
      this.map.panToBounds(bounds)
    }
  }

  removeMarkers(): void {
    this.markers.map(marker => marker.setMap(null))
    this.markers = []
  }
}
