
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon'

@Component({
    components: { TlIcon }
})
export default class RoomTypeSelector extends Vue {
  @Prop(Array)
  list!: string[][]
  @Prop(Array)
  storeListValue!: string[][]

  @Watch('storeListValue')
  watchChanges(current: string[], prev: string[]) {
    if (prev[0] !== current[0]) {
      this.isChanged = true
    }
  }

  private _isOpen: boolean = false
  private _isChanged: boolean = false

  get isChanged(): boolean {
    return this.$data._isChanged
  }

  set isChanged(val: boolean) {
    this.$data._isChanged = val
  }

  get isOpen(): boolean {
    return this.$data._isOpen
  }

  set isOpen(val: boolean) {
    this.$data._isOpen = val
  }

  getPaxIcons(paxes: string[]) {
    return paxes.map(pax => {
      switch (pax) {
        case 'ADULT':
          return 'male'
        case 'CHILD':
          return 'child'
      }
    })
  }

  closeSelector(): void {
    if (this.isOpen) {
      document.removeEventListener('click', this.closeSelector)
    } else {
      this.$emit('open')
    }
    this.isOpen = !this.isOpen
  }

  openMultiply(): void {
    document.addEventListener('click', this.closeSelector)
  }

  onChangeListElement(listElement: number | string) {
    this.$emit('input', listElement)
    document.addEventListener('click', this.closeSelector)
  }
}
