
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import DealDetails from 'applications/desktop/package-compare-app/components/compare-products/ui/deal-details.vue'
import HotelInfo from 'applications/desktop/package-compare-app/components/compare-products/ui/hotel-info.vue'
import HotelDigest from 'applications/desktop/package-compare-app/components/compare-products/ui/hotel-digest.vue'
import HotelControlPanel from 'applications/desktop/package-compare-app/components/compare-products/ui/hotel-control-panel.vue'
import PackageScore from 'ui-modules/package-score/package-score.vue'
import OrderBtn from "ui-modules/buttons/order-btn.vue"
import SomethingWentWrong from "ui-modules/something-went-wrong/something-went-wrong.vue"

import {
  ComparisonItems,
  DEAL_DETAILS_COMPARISON_MAP,
  IPackageClass,
  IPackageCompareResultClass,
  IPackageCompareResultState,
  IPackageHotelFacility
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    DealDetails,
    HotelControlPanel,
    HotelInfo,
    PackageScore,
    OrderBtn,
    SomethingWentWrong,
    HotelDigest
  }
})
export default class ComparisonTableContent extends Vue {
  @Prop(String) comparisonItem!: string
  @Prop(Object) currentPackage!: IPackageClass
  @Prop(Boolean) noFavoritePackages!: boolean
  @Prop(Number) index!: number
  @Prop(Boolean) isBookingErrorOccured!: boolean;
  @Prop(Boolean) isPackageUnavailable!: boolean;

  @Prop(Object) post!: any
  @Prop(String) content!: string
  @Prop(Object) facility!: IPackageHotelFacility
  
  comparisonItems = ComparisonItems
  dealDetailsComparisonMap = DEAL_DETAILS_COMPARISON_MAP

  onAddPackageToComparison({ fromFavorites }: { fromFavorites?: boolean }) {
    this.$emit('add-to-comparison', {
      currentPackage: this.currentPackage || null,
      fromFavorites,
      index: this.index
    })
  }

  onRemovePackageFromComparison() {
    this.$emit('remove-from-comparison', {
      currentPackage: this.currentPackage,
      index: this.index
    })
  }

  checkFacilityById({ facilityId }: { facilityId: string }): boolean {
    return this.currentPackage.hotel.checkFacilityById({ facilityId })
  }
}
