
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { validationMixin, Vuelidate } from 'vuelidate'
import { required, minLength, between } from 'vuelidate/lib/validators'
import * as moment from 'moment'

import {
  ICustomer,
  IMedia
} from 'applications/desktop/flight-app/types/desktop-app.types'
import Selector from 'ui-modules/selectors/selector.vue'
import TlIcon from 'ui-modules/icons/icon'
import ErrorTooltip from 'ui-modules/tooltips/err-tooltip.vue'
import {
  validations,
  IOwnerInput
} from '../validate/order-product.owner-fields.validate'

@Component({
  mixins: [validationMixin],
  components: {
    Selector,
    TlIcon,
    ErrorTooltip
  },
  validations
})
export default class OrderOwnerFields extends Vue {
  @Prop(Array)
  owner!: ICustomer[]

  $v: Vuelidate<any>
  ownerData: IOwnerInput = {
    birthday: '1970-01-01',
    email: {
      mediaType: 'MAIL',
      value: null
    },
    mobile: {
      mediaType: 'MOBILE_PHONE',
      value: null
    },
    name: null,
    type: 'Adult'
  }
  mobilePreffix: string = null

  created() {
    if (this.owner && this.owner.length) {
      const owner = this.owner[0]
      let name = ''

      this.ownerData.email.value = owner.media.find(
        m => m.mediaType === 'MAIL'
      ).value
      this.ownerData.mobile.value = owner.media.find(
        m => m.mediaType === 'MOBILE_PHONE'
      ).value

      if (owner.customerInfo.firstName) {
        name = name + owner.customerInfo.firstName
      }
      if (owner.customerInfo.lastName) {
        name = name + ' ' + owner.customerInfo.lastName
      }

      this.ownerData.name = name
    }
  }

  get ownerName(): string {
    const nameWords: string[] = this.ownerData.name.split(' ')
    return nameWords[0]
  }

  get ownerSurname(): string {
    const nameWords: string[] = this.ownerData.name.split(' ')
    return nameWords[1]
  }

  onInputOwnerData(): void {
    const ownerObject: any = {}
    ownerObject.media = [this.ownerData.email, this.ownerData.mobile]
    ownerObject.type = this.ownerData.type
    ownerObject.customerInfo = {
      birthday: this.ownerData.birthday
    }

    if (this.ownerData.name) {
      ownerObject.customerInfo = {
        ...ownerObject.customerInfo,
        firstName: this.ownerName,
        lastName: this.ownerSurname
      }
    }
    this.$emit('input-owner', ownerObject)
  }

  onFocus(input: HTMLElement): void {
    const parentWrapper = input.closest('.input-wrapper')
    parentWrapper.classList.add('focus')
  }

  onBlur(input: HTMLElement): void {
    const parentWrapper = input.closest('.input-wrapper')
    parentWrapper.classList.remove('focus')
  }
}
