import CountriesLatLng from 'ui-modules/googleMaps/countriesLatLng.json'

import { IGoogleGeo } from 'applications/desktop/package-compare-app/types/package-compare-app.types'

// @ts-ignore
// type TCountriesLatLng = { [key: string]: any }

const getCountriesLatLng = ({ countryCode }: { countryCode: string }) => {
  return {
    countryCode,
    // @ts-ignore
    ...(CountriesLatLng)[countryCode.toLowerCase()]
  }
}

export {
  getCountriesLatLng
}