import PackageHotel from 'modules/product-result.v2/data/package-result/package-result.hotel/package-result.hotel.class'

export * from 'modules/product-result.v2/data/package-result/package-result.hotel/package-result.hotel.consts'

import {
    IMinMax
} from 'modules/common/common.types'

import {
    IMedia,
    IPartOfList,
    ICity
} from 'modules/product-result.v2/data/product-result.types'
import {TGoogleMapLocations} from "../../../../../ui-modules/googleMaps/googleMaps.types";

enum HotelFacilityGroupsTypes {
    ENTERTAINMENT = 'ENTERTAINMENT',
    COMFORT_ROOM = 'COMFORT_ROOM',
    COMFORT_HOTEL = 'COMFORT_HOTEL',
    SPORT = 'SPORT',
    FOOD_DRINKS = 'FOOD_DRINKS',
    BABY_CHILD_CARE = 'BABY_CHILD_CARE',
    HEALTH_BEAUTY = 'HEALTH_BEAUTY',
    SHOPPING = 'SHOPPING',
    BUSINESS_SERVICE = 'BUSINESS_SERVICE',
    TRANSPORTATION = 'TRANSPORTATION',
    FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
    COMMUNICATIONS = 'COMMUNICATIONS',
    BEACH_FRONT = 'BEACH_FRONT',
    ACTIVITIES = 'ACTIVITIES',
    ACCESSIBILITY = 'ACCESSIBILITY',
    ROOM_SERVICE = 'ROOM_SERVICE'
}

enum HotelFacilitiesTypes {
    'Hotel' = 'Hotel',
    'Room' = 'Room'
}

enum HotelRemarksTypes {
    'location' = 'location',
    'room_count' = 'room_count',
    'room_desc' = 'room_desc',
    'restaurant' = 'restaurant'
}

export type THotelRemarksTypes = keyof typeof HotelRemarksTypes
export type THotelFacilitiesTypes = keyof typeof HotelFacilitiesTypes

enum HotelFacilitiesCodes {
    'Parking' = 'Parking',
    'WIFI' = 'WIFI',
    'aircondition' = 'aircondition',
    'babySitting' = 'babySitting',
    'balcony' = 'balcony',
    'bar' = 'bar',
    'bathrobe' = 'bathrobe',
    'beach' = 'beach',
    'beautyParlour' = 'beautyParlour',
    'biliards' = 'biliards',
    'businessCtr' = 'businessCtr',
    'carRental' = 'carRental',
    'casino' = 'casino',
    'childrenActivities' = 'childrenActivities',
    'childrenClub' = 'childrenClub',
    'cinema' = 'cinema',
    'coffeeShop' = 'coffeeShop',
    'coffeeTeaMaker' = 'coffeeTeaMaker',
    'coffee_tea' = 'coffee_tea',
    'conventionCtr' = 'conventionCtr',
    'copyMachine' = 'copyMachine',
    'disco' = 'disco',
    'elevator' = 'elevator',
    'exchangeFac' = 'exchangeFac',
    'executiveFlr' = 'executiveFlr',
    'faxFacInroom' = 'faxFacInroom',
    'faxService' = 'faxService',
    'giftShop' = 'giftShop',
    'golf' = 'golf',
    'hairDriver' = 'hairDriver',
    'hairDryer' = 'hairDryer',
    'hairdresser' = 'hairdresser',
    'hamam' = 'hamam',
    'handicap' = 'handicap',
    'healthClub' = 'healthClub',
    'iceMachines' = 'iceMachines',
    'iron' = 'iron',
    'ironing' = 'ironing',
    'jacuzzi' = 'jacuzzi',
    'kitchen' = 'kitchen',
    'laundrySvc' = 'laundrySvc',
    'lounge' = 'lounge',
    'massage' = 'massage',
    'meetingRooms' = 'meetingRooms',
    'minibar' = 'minibar',
    'movieChannels' = 'movieChannels',
    'nonsmokingRms' = 'nonsmokingRms',
    'parking' = 'parking',
    'pcHookupInrm' = 'pcHookupInrm',
    'pharmacy' = 'pharmacy',
    'phoneDirDial' = 'phoneDirDial',
    'poolbar' = 'poolbar',
    'radio' = 'radio',
    'restaurants' = 'restaurants',
    'rommIron' = 'rommIron',
    'roomService' = 'roomService',
    'safeDepBox' = 'safeDepBox',
    'sauna' = 'sauna',
    'showerToilet' = 'showerToilet',
    'shuttle' = 'shuttle',
    'sightseeing' = 'sightseeing',
    'snowSkiing' = 'snowSkiing',
    'solarium' = 'solarium',
    'spa' = 'spa',
    'squash' = 'squash',
    'stores' = 'stores',
    'swimmingPool' = 'swimmingPool',
    'swimmingPoolindoor' = 'swimmingPoolindoor',
    'tabletennis' = 'tabletennis',
    'television' = 'television',
    'tennis' = 'tennis',
    'theater' = 'theater',
    'treatments' = 'treatments',
    'valetParking' = 'valetParking',
    'wake_up' = 'wake_up',
    'waterSports' = 'waterSports',
    'womensGstRms' = 'womensGstRms',
    'Cinema' = 'Cinema',
    'cinema' = 'cinema',
}

const HOTEL_FACILITIES: { [key in HotelFacilitiesCodes]: IFacilityGroup } = {
    handicap: {code: "handicap", groupName: "ACCESSIBILITY", priority: 0},
    swimmingPoolindoor: {code: "swimmingPoolindoor", groupName: "ACTIVITIES", priority: 1},
    sauna: {code: "sauna", groupName: "ACTIVITIES", priority: 2},
    sightseeing: {code: "sightseeing", groupName: "ACTIVITIES", priority: 3},
    childrenClub: {code: "childrenClub", groupName: "BABY_CHILD_CARE", priority: 4},
    childrenActivities: {code: "childrenActivities", groupName: "BABY_CHILD_CARE", priority: 5},
    babySitting: {code: "babySitting", groupName: "BABY_CHILD_CARE", priority: 6},
    beach: {code: "beach", groupName: "BEACH_FRONT", priority: 7},
    businessCtr: {code: "businessCtr", groupName: "BUSINESS_SERVICE", priority: 8},
    conventionCtr: {code: "conventionCtr", groupName: "BUSINESS_SERVICE", priority: 9},
    meetingRooms: {code: "meetingRooms", groupName: "BUSINESS_SERVICE", priority: 10},
    copyMachine: {code: "copyMachine", groupName: "BUSINESS_SERVICE", priority: 11},
    faxService: {code: "faxService", groupName: "BUSINESS_SERVICE", priority: 12},
    Parking: {code: "Parking", groupName: "COMFORT_HOTEL", priority: 13},
    laundrySvc: {code: "laundrySvc", groupName: "COMFORT_HOTEL", priority: 14},
    wake_up: {code: "wake_up", groupName: "COMFORT_HOTEL", priority: 15},
    executiveFlr: {code: "executiveFlr", groupName: "COMFORT_HOTEL", priority: 16},
    nonsmokingRms: {code: "nonsmokingRms", groupName: "COMFORT_HOTEL", priority: 17},
    jacuzzi: {code: "jacuzzi", groupName: "COMFORT_HOTEL", priority: 18},
    ironing: {code: "ironing", groupName: "COMFORT_HOTEL", priority: 19},
    hairdresser: {code: "hairdresser", groupName: "COMFORT_HOTEL", priority: 20},
    iceMachines: {code: "iceMachines", groupName: "COMFORT_HOTEL", priority: 21},
    womensGstRms: {code: "womensGstRms", groupName: "COMFORT_HOTEL", priority: 22},
    elevator: {code: "elevator", groupName: "COMFORT_HOTEL", priority: 23},
    lounge: {code: "lounge", groupName: "COMFORT_HOTEL", priority: 24},
    valetParking: {code: "valetParking", groupName: "COMFORT_HOTEL", priority: 25},
    WIFI: {code: "WIFI", groupName: "COMMUNICATIONS", priority: 26},
    pcHookupInrm: {code: "pcHookupInrm", groupName: "COMMUNICATIONS", priority: 27},
    Cinema: {code: "Cinema", groupName: "ENTERTAINMENT", priority: 28},
    cinema: {code: "cinema", groupName: "ENTERTAINMENT", priority: 28},
    theater: {code: "theater", groupName: "ENTERTAINMENT", priority: 29},
    casino: {code: "casino", groupName: "ENTERTAINMENT", priority: 30},
    disco: {code: "disco", groupName: "ENTERTAINMENT", priority: 31},
    biliards: {code: "biliards", groupName: "ENTERTAINMENT", priority: 32},
    exchangeFac: {code: "exchangeFac", groupName: "FINANCIAL_SERVICES", priority: 33},
    coffeeShop: {code: "coffeeShop", groupName: "FOOD_DRINKS", priority: 34},
    restaurants: {code: "restaurants", groupName: "FOOD_DRINKS", priority: 35},
    bar: {code: "bar", groupName: "FOOD_DRINKS", priority: 36},
    poolbar: {code: "poolbar", groupName: "FOOD_DRINKS", priority: 37},
    kitchen: {code: "kitchen", groupName: "FOOD_DRINKS", priority: 38},
    pharmacy: {code: "pharmacy", groupName: "FOOD_DRINKS", priority: 39},
    spa: {code: "spa", groupName: "HEALTH_BEAUTY", priority: 40},
    solarium: {code: "solarium", groupName: "HEALTH_BEAUTY", priority: 41},
    beautyParlour: {code: "beautyParlour", groupName: "HEALTH_BEAUTY", priority: 42},
    treatments: {code: "treatments", groupName: "HEALTH_BEAUTY", priority: 43},
    massage: {code: "massage", groupName: "HEALTH_BEAUTY", priority: 44},
    hamam: {code: "hamam", groupName: "HEALTH_BEAUTY", priority: 45},
    healthClub: {code: "healthClub", groupName: "HEALTH_BEAUTY", priority: 46},
    stores: {code: "stores", groupName: "SHOPPING", priority: 47},
    giftShop: {code: "giftShop", groupName: "SHOPPING", priority: 48},
    swimmingPool: {code: "swimmingPool", groupName: "SPORT", priority: 49},
    snowSkiing: {code: "snowSkiing", groupName: "SPORT", priority: 50},
    squash: {code: "squash", groupName: "SPORT", priority: 51},
    tabletennis: {code: "tabletennis", groupName: "SPORT", priority: 52},
    waterSports: {code: "waterSports", groupName: "SPORT", priority: 53},
    tennis: {code: "tennis", groupName: "SPORT", priority: 54},
    golf: {code: "golf", groupName: "SPORT", priority: 55},
    shuttle: {code: "shuttle", groupName: "TRANSPORTATION", priority: 56},
    carRental: {code: "carRental", groupName: "TRANSPORTATION", priority: 57},
    minibar: {code: "minibar", groupName: "FOOD_DRINKS", priority: 58},
    roomService: {code: "roomService", groupName: "FOOD_DRINKS", priority: 59},
    coffee_tea: {code: "coffee_tea", groupName: "FOOD_DRINKS", priority: 60},
    television: {code: "television", groupName: "COMFORT_ROOM", priority: 100},
    hairDryer: {code: "hairDryer", groupName: "COMFORT_ROOM", priority: 101},
    minibar: {code: "minibar", groupName: "COMFORT_ROOM", priority: 102},
    roomService: {code: "roomService", groupName: "COMFORT_ROOM", priority: 103},
    movieChannels: {code: "movieChannels", groupName: "COMFORT_ROOM", priority: 104},
    coffee_tea: {code: "coffee_tea", groupName: "COMFORT_ROOM", priority: 105},
    faxFacInroom: {code: "faxFacInroom", groupName: "COMFORT_ROOM", priority: 106},
    safeDepBox: {code: "safeDepBox", groupName: "COMFORT_ROOM", priority: 107},
    radio: {code: "radio", groupName: "COMFORT_ROOM", priority: 108},
    balcony: {code: "balcony", groupName: "COMFORT_ROOM", priority: 109},
    rommIron: {code: "rommIron", groupName: "COMFORT_ROOM", priority: 110},
    aircondition: {code: "aircondition", groupName: "COMFORT_ROOM", priority: 111},
    phoneDirDial: {code: "phoneDirDial", groupName: "COMFORT_ROOM", priority: 112},
    bathrobe: {code: "bathrobe", groupName: "COMFORT_ROOM", priority: 113},
    iron: {code: "iron", groupName: "COMFORT_ROOM", priority: 114},
    coffeeTeaMaker: {code: "coffeeTeaMaker", groupName: "COMFORT_ROOM", priority: 115},
    hairDriver: {code: "hairDriver", groupName: "COMFORT_ROOM", priority: 116},
    showerToilet: {code: "showerToilet", groupName: "COMFORT_ROOM", priority: 117}
}

const HOTEL_SCORE_TYPES: string[] = ['flight', 'hotel', 'room', 'food', 'kids']

const HOTEL_FACILITIES_GROUPED_BY_TYPES: { [key in THotelFacilitiesTypes]: Array<keyof typeof HotelFacilityGroupsTypes> } = {
    Hotel: [
        'ENTERTAINMENT',
        'COMFORT_HOTEL',
        'SPORT',
        'FOOD_DRINKS',
        'BABY_CHILD_CARE',
        'HEALTH_BEAUTY',
        'SHOPPING',
        'BUSINESS_SERVICE',
        'TRANSPORTATION',
        'FINANCIAL_SERVICES',
        'COMMUNICATIONS',
        'BEACH_FRONT',
        'ACTIVITIES',
        'ACCESSIBILITY',
        'ROOM_SERVICE'
    ],
    Room: ['COMFORT_ROOM']
}

type THotelFacility = IAdditional<THotelFacilitiesTypes, keyof typeof HotelFacilitiesCodes>

interface IPackageHotelFacility extends THotelFacility {
    groupName: IFacilityGroup['groupName']
    priority: IFacilityGroup['priority']
}

interface IFacilityGroup {
    groupName: keyof typeof HotelFacilityGroupsTypes
    code: THotelFacility['code']
    priority: number
}

interface IPackageHotel<T, K> {
    id: string
    supplier: string
    supplierId: string
    name: string
    description: string
    distanceToCityCenterMeters: number
    stars: number
    facilities: Array<IAdditional<T, K>>
    images: Array<IAdditional<TImageTypes, string>>
    remarks: IRemark[]
    city: ICity
    country: ICity['country']
    countryName: string
    geo: IGoogleGeo
    geoLocation: IGeolocation
    address: IAddress
    media: IMedia[]
    completion: string
    products: any[]
}

type TPackageHotel = IPackageHotel<THotelFacilitiesTypes, keyof typeof HotelFacilitiesCodes>
type TImageTypes = 'MAIN'

interface IPackageHotelConstructor {
    hotel: TPackageHotel
    minPrice?: string
    departures?: IMinMax<string>
    nights?: IPartOfList[]
}

export interface IAdditional<T, K> {
    id?: string
    type: T
    code?: K
    title: string
    url: string
    createTs?: string
    updateTs?: string
    urlAvailable?: boolean
}

interface IRemark {
    type: string
    text: string
}

interface IGeolocation {
    x: number
    y: number
    coordinates: number[]
    type: string
}

interface IGoogleGeo {
    lat: number
    lng: number
}

interface IAddress {
    city: string
    country?: string
    street: string
    house: string
    zipCode: string
}

interface IPackageHotelClass {
    readonly content: TPackageHotel
    readonly name: string
    readonly description: string
    readonly address: string
    readonly city: TPackageHotel['city']
    readonly country: TPackageHotel['country']
    readonly geo: IGoogleGeo
    readonly locations: TGoogleMapLocations
    readonly remarks: IRemark[]
    readonly hotelId: string
    readonly minProductsNight: number
    readonly distanceToCityCenterMeters: number
    readonly images: TPackageHotel['images']
    readonly media: TPackageHotel['media']

    stars: number
    minProductPriceForHotel: string
    departureIntervals: IMinMax<string>
    facilities: IPackageHotelFacility[]

    checkFacilities(): boolean

    getMainImage(): string

    getImages(): Array<{
        url: string
        title: string
    }>

    checkFacilityById({facilityId}: { facilityId: string }): boolean

    getFacilitiesByType(type: THotelFacilitiesTypes)

    getRemarkByType(type: THotelRemarksTypes)
}

export {
    // classes
    PackageHotel,
    // interfaces
    IPackageHotel,
    IRemark,
    IGeolocation,
    IGoogleGeo,
    IAddress,
    IPackageHotelClass,
    IFacilityGroup,
    IPackageHotelFacility,
    IPackageHotelConstructor,
    // types
    THotelFacility,
    TPackageHotel,
    // consts
    HOTEL_FACILITIES,
    HOTEL_SCORE_TYPES,
    HotelFacilitiesTypes,
    HotelFacilityGroupsTypes,
    HotelFacilitiesCodes,
    HOTEL_FACILITIES_GROUPED_BY_TYPES
}
