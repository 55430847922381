
import Vue from "vue";
import {Component} from "vue-property-decorator";

@Component({
  name: "clock-loader"
})
export default class ClockLoader extends Vue {

}
