
import {
  selectBaggageDetailsPost
} from "../../../../applications/desktop/package-compare-app/components/order/ui/select-baggage-details/select-baggage-details.vue";

interface IExtendModalPost {
  component: string
  preventDefaultClose: boolean
  post: selectBaggageDetailsPost
}
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import LuggageIcon from 'ui-modules/svg-icon/luggage.vue'
import ModalWindow from "../modal-window/modal-window.vue";

@Component({
  components: {ModalWindow, LuggageIcon}
})
export default class ModalSelectBaggageDetails extends Vue {
  @Prop(Object)
  post!: IExtendModalPost

  get getCitesCode() {
    return this.post.post.post.map(el => {
      return el.segments[0].departure.cityCode
    })
  }
  get getCountBaggage(): number {
    return this.post.post.baggageNum ?? 0
  }
  get getDefaultPriceBaggage(): number {
    return this.post.post.baggageNum !== 0 ? (Number(this.post.post.baggageData.price.amount) / 2) * this.post.post.baggageNum : 0
  }
  get getPriceBaggage(): number {
    return this.post.post.baggageNum !== 0 ? Number(this.post.post.baggageData.price.amount) * this.post.post.baggageNum : 0
  }
}
