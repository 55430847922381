
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'

import VueDatepickerWithBar from 'ui-modules/datepicker/datepicker-with-bar.vue'
import PassengerSelector from 'ui-modules/passenger-selector.vue'
import TypeRestFilter from 'ui-modules/typeRestFilter.vue'
import LocaleSelector from 'ui-modules/selectors/locale-selector.vue'
import Breadcrumbs from 'applications/desktop/package-compare-app/components/root-app/ui/breadcrumbs.vue'
import BtnWithCounter from 'ui-modules/buttons/btn-with-counter.vue'

import {
  AppSections,
  IHeaderPost,
  IQueryDestinations
} from 'applications/desktop/package-compare-app/components/root-app/types/root-app.types'
import ContactSelector from "../../../../../../ui-modules/selectors/contact-selector.vue";
import {PRODUCT_ORDER_MODULE, PRODUCT_RESULT_MODULE} from "../../../store/store";
import {Getter} from "vuex-class";
import i18n from "../../../../../../modules/i18n/i18n.name";
import Shepherd from "shepherd.js";
import {initSharpedConfig, userTrainingResultPage} from "../../../../../../common/userTrainingSteps";
import eventBus from "../../../../eventBus";
import DestinationBlockCompact from 'applications/desktop/package-compare-app/components/prepare-search-data/ui/destination-block-compact.vue'
import PrepareSearchDataComp from '../../prepare-search-data/prepare-search-data'
import DateSelector from 'ui-modules/datepicker/date-selector.vue'

@Component({
  components: {
    ContactSelector,
    Breadcrumbs,
    BtnWithCounter,
    DateSelector,
    DestinationBlockCompact,
    LocaleSelector,
    PassengerSelector,
    TypeRestFilter,
    VueDatepickerWithBar,
    // icons
  }
})
export default class SiteHeader extends PrepareSearchDataComp {
  @Prop(Object)
  post!: IHeaderPost
  @Prop(Boolean)
  showHotelModal: boolean
  @Prop(String)
  locale: string

  // @Getter(`${i18n}/currentLocale`)
  // currentLocale: string
  componentName = 'SiteHeader'
  appSections = AppSections
  isExactCountry = false
  isExactDate = false
  viewMode = 'header'
  locales: string[] = []

  tour: Tour = null;

  @Watch('searchQuery.exactDates')
  onExactDatesChange(newValue: boolean) {
    this.isExactDate = newValue
  }

  get productOrderModule() {
    return PRODUCT_ORDER_MODULE
  }

  mounted() {
    this.isExactDate = this.searchQuery.exactDates
    this.formattedReferralWelcomeText()
  }
  get agencyInfo() {
    return this.productOrderModule.agencyInfo
  }
  // get resultStore() {
  //   return PRODUCT_RESULT_MODULE
  // }
  get referralRule() {
    return this.resultStore.referralRule
  }
  get isReferral(): boolean {
    return this.referralRule?.condition?.childConditions.length > 0 && !!this.referralRule?.condition?.childConditions[0]?.welcomeMessage
  }
  get referralWelcomeText(): string {
    return this.referralRule?.condition?.childConditions[0]?.welcomeMessage[this.currentLocale.toLocaleUpperCase()]
  }
  get formattedWelcomeText() {
    return this.formattedReferralWelcomeText()
  }

  get getWelcomeText() {
    if(this.isReferral) {
      return this.formattedReferralWelcomeText()
    }
    else {
      return this.$t('header.welcome')
    }
  }
  formattedReferralWelcomeText() {
    if(!this.isReferral) return
    const regExp = /\$[0-9,]+|[0-9]/
    const price = this.referralWelcomeText?.match(regExp)
    if(price?.length) {
      const welcomeTextSplited = this.referralWelcomeText?.split(price[0])
      const separationFirstPartWelcomeText = welcomeTextSplited[0].split('!')
      const separationSecondPartWelcomeText = welcomeTextSplited[1].split('...')
      return `${separationFirstPartWelcomeText[0]}!<br />
        ${separationFirstPartWelcomeText[1]}
        <span style="
        font-family: 'Arial', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-weight: bold;
    margin: 0 10px;
    color: #04f504;">${price}</span>
        ${separationSecondPartWelcomeText[0]}... ${separationSecondPartWelcomeText[1]}`
    }
    else {
      return this.referralWelcomeText
    }
  }
  get mail():{
    value: string,
    contactType: string
  } {
    return {
      value: this.agencyInfo?.agency?.email,
      contactType: 'email'
    }
  }
  get phone(): {
    value: string,
    contactType: string
  } {
    return {
      value: this.agencyInfo?.agency?.phone,
      contactType: 'phone'
    }
  }
  get route(): string {
    switch (this.$route.name) {
      case 'package-compare.prepare-search-data':
        return this.appSections.PREPARE_SEARCH_PAGE
      default:
        return this.appSections.SEARCH_PAGE
    }
  }

  async onChangeQueryPrecision(isExactCountry: boolean) {
    this.isExactCountry = isExactCountry
    await this.setQueryDatePrecision(isExactCountry, this.searchQuery.exactDates)
    if (!isExactCountry) {
      eventBus.$emit('update-destinations-confirm', true) // to start new search
    }
  }

  async onCloseExactCountry() {
    this.isExactCountry = false
    const querySnapshot = this.searchStore.searchQuerySnapshot
    if(!querySnapshot) { // return to the default state
      const searchQuery = JSON.parse(JSON.stringify(this.searchQuery.content))
      searchQuery.exactDates = false
      searchQuery.isAnyCountry = true
      this.searchStore.initializeSearchQuery({ searchQuery })
    }
    else if (JSON.stringify(this.searchQuery.content) !== JSON.stringify(querySnapshot.content)) { // return to the previous state
      const searchQuery = querySnapshot.content
      this.searchStore.initializeSearchQuery({ searchQuery })
    }
  }

  onDateTypeChange(isExactDate: boolean) {
    this.isExactDate = isExactDate
    this.onUpdateDestinationConfirm()
  }

  async onSelectDestination(destinations: IQueryDestinations) {
    this.isExactCountry = true
    this.resultStore.initializeResultFilter({ digest: null, effectiveDigest: null })
    this.searchStore.setDestinations({ destinations: this.destinationsForCalendar }) // re-write destinations by calendar destinations
    this.setQueryDateInterval({ start: null, end: null })
    this.setQueryDestination(destinations)
  }

  async onUpdateDestinationConfirm() {
    await this.setQueryDatePrecision(this.isExactCountry, this.isExactDate)
    eventBus.$emit('update-destinations-confirm') // to start new search
  }

  onUpdateDestinationCancel() {
    this.isExactDate = !this.isExactDate
  }

  startUserTrainingOnResultPage() {
    // localStorage.removeItem('isShowUserTrainingCountryDetails')
    // this.tour = new Shepherd.Tour({
    //                     ...initSharpedConfig
    //                   });
    // this.tour.addSteps(userTrainingResultPage(this.tour));
    // localStorage.setItem('isShowUserTrainingResultPage', 'true')
    // this.tour.start()
    // console.log("startUserTrainingOnResultPage")
    // eventBus.$on('stop-training', () => {
    //   if (!this.tour)return;
    //   this.tour.complete()
    //   this.tour = null
    // })
    eventBus.$emit('open-introduction-dialog')
  }

}
