import Vue from 'vue'
import { PriceCalendarPeriodResource } from 'be-structures/typescript-generator/assembly'
import { Component, Prop, Watch } from 'vue-property-decorator';
import dateTime from 'common/filters/datetime'
import isEqual from 'lodash/isEqual'

@Component({
  components: {
  },
  filters: {
    dateTime
  }
})
export default class DatepickerWithBarImpl extends Vue {
  @Prop(String)
  format?: string
  @Prop(String)
  outputFormat?: string
  @Prop(String)
  dateSeparator?: string
  @Prop(Array)
  date: string[]
  @Prop(String)
  locale: string
  @Prop(String)
  viewMode: string
  @Prop(Boolean)
  exactDates!: boolean
  @Prop(Object)
  searchStore: any

  @Watch('locale')
  changeLocale(locale: string) {
    this.isOpen = false
  }

  @Watch('date')
  watching() {
    this.insideCurrentDate = this.inputDate;
  }

  defaultFormat: string = 'YYYY-MM-DD'
  defaultOutputFormat: string = 'D.MM.YY'
  defaultSeparator: string = '-'
  isOpen: boolean = true

  onlyOneDatePicked: boolean = false;
  insideCurrentDate: any = {};

  created() {
    this.insideCurrentDate = this.inputDate;
  }

  updateDates(date) {
    this.onlyOneDatePicked = false;
    this.insideCurrentDate = date;

    if (this.exactDates) {
      this.onConfirm()
    }
  }

  beginSelect() {
    if (!this.onlyOneDatePicked) {
        this.onlyOneDatePicked = true;
    }
  }

  onConfirm() {
    this.isOpen = false
    this.onlyOneDatePicked = false;
    if (!isEqual(this.inputDate, this.insideCurrentDate)) {
      this.$emit('select', this.outputDate(this.insideCurrentDate));
    }
  }

  onCancel() {
    this.isOpen = false
    this.onlyOneDatePicked = false;
    this.insideCurrentDate = this.inputDate;
  }

  onReset() {
    this.priceDatepicker.resetDatepicker()
  }

  onShow() {
    this.isOpen = true;
  }

  getReturnDates(dateObject: PriceCalendarPeriodResource) {
    this.searchStore.getAndSetDatesByCountry({
      countryCode: dateObject.countryCode,
      flightGroup: 'returns',
      // @ts-ignore
      departureDate: dateObject.departureDate
    })
  }

  get departureDates() {
    return this.searchStore.departureDatesByCountry
  }

  get dateRangeForShow(): string {
    return `${dateTime(this.date[0], 'DD.MM.YY')} - ${dateTime(this.date[1], 'DD.MM.YY')}`
  }

  get dateBegin(): string {
    return dateTime(this.date[0], 'DD.MM.YY')
  }

  get dateEnd(): string {
    return dateTime(this.date[1], 'DD.MM.YY')
  }

  get inputDate(): object {
    return {start: this.date[0], end: this.date[1]};
  }

  get priceDatepicker() {
    return this.$refs.priceDatepicker
  }

  outputDate(date: any): string[] {
    return [ date.start, date.end ];
  }
}
