
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon'
import FilterTemplate from 'ui-modules/products-filter/filter.template.vue'
import FilterBoardings from 'ui-modules/products-filter/conditions/filter.boardings.vue'
import FilterNights from 'ui-modules/products-filter/conditions/filter.nights.vue'
import FilterPrice from 'ui-modules/products-filter/conditions/filter.price.vue'
import FilterAirlines from 'ui-modules/products-filter/conditions/filter.airlines.vue'
import FilterFacilities from 'ui-modules/products-filter/conditions/filter.facilities.vue'
import FilterDepartureTime from 'ui-modules/products-filter/conditions/filter.departure-time.vue'
import FilterHotelStars from 'ui-modules/products-filter/conditions/filter.hotel-stars.vue'
import FilterProviders from 'ui-modules/products-filter/conditions/filter.providers.vue'

import FilterMap from 'applications/desktop/package-compare-app/components/search-results/ui/filter-map.vue'

import Gmap from 'ui-modules/googleMaps/googleMaps.vue'

import {
  IFilterPost,
  IPackageCompareProductFilterClass,
  IPartOfList,
  IProductFilter,
  IRegularResultPost
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

@Component({
  components: {
    FilterAirlines,
    FilterBoardings,
    FilterDepartureTime,
    FilterFacilities,
    FilterHotelStars,
    // FilterMap,
    FilterNights,
    FilterPrice,
    FilterProviders,
    FilterTemplate,
    // TlIcon

    Gmap
  }
})
export default class FavoritePackageFilter extends Vue {
  @Prop(Object)
  post!: IFilterPost
  @Prop(Array) locations!: Array<{ countryCode?: string, lat: number, lng: number}>

  get showResetPanel() {
    return false
  }
}
