import Component, { mixins } from 'vue-class-component'

import {
  PRODUCT_RESULT_VIEW_MODULE,
  PRODUCT_RESULT_MODULE, PRODUCT_SEARCH_MODULE
} from 'applications/desktop/package-compare-app/store/store'

import PrepareSearchDataCommon from 'applications/desktop/package-compare-app/components/prepare-search-data/prepare-search-data.common'
import RootAppCommon from 'applications/desktop/package-compare-app/components/root-app/root-app.common'
import OrderCommon from 'applications/desktop/package-compare-app/components/order/order.common'

import {
  IPackageHotelClass,
  IFilterPost,
  IPackageClass,
  IPackageProductClass,
  IPackagesListClass,
  IResultPost,
  IRequestPayload,
  IConvertedQuery,
  IConvertToPackageResult,
  ICountriesWithDigest,
  IStatPackageSearchDigestResource
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

import Accordion from 'ui-modules/accordion/accordion.vue'
import BoxLoader from 'ui-modules/marker-loader/marker-loader.vue'
import HotelCardModal from 'ui-modules/modals/hotel-card-modal/hotel-card-modal.vue'
import NotFoundPage from 'ui-modules/not-found-page/not-found-page.vue'

import ModalBase from 'ui-modules/modals/modal-base.vue'

import dateTime from 'common/filters/datetime'
import cloneDeep from 'lodash/cloneDeep'
import _ from "lodash";
import { IATACityResource } from 'be-structures/typescript-generator'
import timeout from 'common/timeout'

interface ICityData {
  city: IATACityResource,
  cityDigest: IStatPackageSearchDigestResource
}

@Component({
  components: {
    Accordion,
    BoxLoader,
    HotelCardModal,
    ModalBase,
    NotFoundPage
  },
  filters: {
    dateTime
  }
})
export default class SearchResultsCommon extends mixins(RootAppCommon, PrepareSearchDataCommon, OrderCommon) {
  componentKey: number = 0

  usedPackage: IPackageClass = null
  showHotelCardModal: boolean = false
  hotel: IPackageHotelClass = null
  interval: NodeJS.Timer = null

  created() {
    // this.checkToPageReloaded()
  }
  checkToPageReloaded() {
    const isClearRoute =  ["orderById", "errorPayment", "cancelPayment", "successPayment"].includes(this.$route.name)
    if(!!this.searchQuery || isClearRoute) {
      return; //continue
    } else {
      this.goToHomePage()
    }
  }

  get searchStore() {
    return PRODUCT_SEARCH_MODULE
  }

  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }

  get resultStoreView() {
    return PRODUCT_RESULT_VIEW_MODULE
  }

  get resultPost(): IResultPost {
    return {
      comparablePackages: this.resultStore.comparablePackages,
      favoritePackages: this.resultStore.favoritePackages,
      isFavoritePackage: ({ hotelId }: { hotelId: string }) => this.isFavoritePackage({ hotelId }),
      isComparablePackage: ({ hotelId }: { hotelId: string }) => this.isComparablePackage({ hotelId }),
      isReplacedPackage: ({ hotelId }: { hotelId: string }) => this.isReplacedPackage({ hotelId }),
      isPackageSubstitute: ({ hotelId }: { hotelId: string }) => this.isPackageSubstitute({ hotelId }),
      isPackageUnavailable: ({ hotelId }: { hotelId: string }) => this.isPackageUnavailable({ hotelId }),
      isPackageSelectionMode: this.isPackageSelectionMode,
      quantityComparablePackages: this.quantityComparablePackages,
      quantityFavoritePackages: this.quantityFavoritePackages,
      checkBookingErrorOccured: ({ hotelId }: { hotelId: string }) => this.checkBookingErrorOccured({ hotelId }),
      checkProductsDetailsPending: (currentPackage: IPackageClass) => this.checkProductsDetailsPending(currentPackage),
    }
  }

  get filterPost(): IFilterPost {
    return {
      searchStorePendings: this.searchStore.pendings,
      resultStoreViewPendings: this.resultStoreView.pendings,
      packageFilter: this.resultStore.filter,
      packageInitialFilter: this.resultStore.defaultFilter,
      useAccordionTitleInFilter: true,
      exactDates: this.searchQuery.exactDates
    }
  }

  get resultPayload(): IRequestPayload {
    const cityCode = this.resultStoreView.selectedCityCode
    const filter = this.filterInUse ? this.filter.convertFilterToPackageCompareResult({ defaultFilter: this.resultStore.defaultFilter }) : null
    if (filter && filter.dealFeatures) {
      delete filter.dealFeatures
    }
    return {
      filter,
      query: {
        countryCode: this.resultStoreView.selectedCountryCode || null,
        cityCodes: cityCode ? Array(cityCode) : null,
        vacationTerm: this.searchQuery.convertToRequest()
      }
    }
  }

  get destinationsPackages() {
    return this.resultStore.allDestinationsPackages
  }

  get filter() {
    return this.resultStore.filter
  }

  get defaultFilter() {
    return this.resultStore.defaultFilter
  }

  get selectedDestination() {
    return this.destinations?.[this.resultStoreView.selectedCountryCode] || null
  }

  get comparablePackages() {
    return this.resultStore.comparablePackages
  }

  get quantityFavoritePackages(): number {
    return this.resultStore.favoritePackages.content.length
  }

  get noFavoritePackages(): boolean {
    return this.quantityFavoritePackages === 0
  }

  get quantityComparablePackages(): number {
    return this.resultStore.comparablePackages.content.filter(p => p).length
  }

  get noComparablePackages(): boolean {
    return this.quantityComparablePackages === 0
  }

  get filterInUse(): boolean {
    const resultStore = this.resultStore

    if (resultStore.filter && resultStore.defaultFilter) {
      const newFilter = _.omit(this.filter.convertFilterToPackageCompareResult({}), ["airlines","boarding", "providers", "hotelFacilities"])
      const oldFilter = _.omit(this.defaultFilter.convertFilterToPackageCompareResult({}), ["airlines","boarding", "providers", "hotelFacilities"])
      const boardings = Boolean(this.filter.boarding.length)
      return (JSON.stringify(newFilter) !== JSON.stringify(oldFilter) || boardings)
    } else {
      return false
    }
  }

  async initializeResultPage({ initializeFilter = true }: { initializeFilter?: boolean }) {
    try {
      this.resultStoreView.setPending({ pendingName: 'initializeFilter', value: true })
      this.resultStore.initializeAllDestinationInfo()

      await this.waitForCountryPageDone() // to sync API calls
      await this.initializeVisibleDestinations({ms: 0})
      await this.createCountryList({ initializeFilter })
    } finally {
      this.resultStoreView.setPending({ pendingName: 'initializeFilter', value: false })
    }
  }

  async waitForCountryPageDone(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      setInterval(() => {
        if (this.searchStore.pendings.getAndSetCountries || this.searchStore.pendings.getCountriesForLocale[this.i18nStore.currentLocale]) {
          return
        }
        resolve(true)
      }, 100)
    })
  }

  async initializeVisibleDestinations({ ms = 0 }: { ms?: number }) {
    this.resultStoreView.initialVisibleDestination()

    if (this.searchQuery.isAnyCountry) {
      for (const countryCode of Object.keys(this.destinations)) {
        if (countryCode !== this.resultStoreView.selectedCountryCode) {
          if (ms > 0) {
            await timeout(ms)
          }

          this.resultStoreView.setVisibleDestination({
            destination: this.destinations[countryCode]
          })
        }
      }
    }
  }

  async createCountryList({ initializeFilter = true }: { initializeFilter?: boolean }) {
    this.getCountriesDataAccurate(initializeFilter)
    await this.getCitiesDataPrimary()
    await this.getCitiesDataAccurate()

    clearInterval(this.interval)
  }

  async getCountriesDataAccurate(initializeFilter?: boolean) {
    if (!initializeFilter) {
      return
    }

    const query: IConvertedQuery = {
      countryCode: null
    }
    if (!this.searchQuery.isAnyCountry) {
        query.countryCode = this.resultStoreView.selectedCountryCode;
    }

    const newFilterDigest = await this.searchStore.getCountryDigest({
      ...this.resultPayload,
      query
    })
    this.resultStore.initializeResultFilter(newFilterDigest)
  }

  async getCitiesDataPrimary() {

    return new Promise((resolve, reject) => {
      Object.keys(this.destinations).forEach(async (countryCode) => {
        const { cities } = await this.searchStore.getAndSetDestinationCitiesWithDigest({ // FIXME: rename the action
          ...this.resultPayload,
          query: {
            ...this.resultPayload.query,
            countryCode
          }
        })
        this.resultStoreView.setPending({ pendingName: 'gettingPrimaryData', value: false })

        if (countryCode !== this.resultStoreView.selectedCountryCode) {
          if (!!this.resultStoreView && !!this.resultStoreView.visibleDestinations) {
            this.resultStoreView.setVisibleDestination({
              destination: {
                ...this.resultStoreView.visibleDestinations[countryCode],
                cities
              }
            })
          }
        }

        if (this.searchQuery.isAnyCountry) {
          this.resultStore.setAllDestinationsInfo({
            countryCode,
            info: {
              minPrice: Number.MAX_VALUE,
              quantityOfPackages: 1
            }
          });
        }
      })

      this.interval = setInterval(() => {
        if (this.searchStore.pendings.getCountryAll) {
          return
        }
        resolve(true)
      }, 100)
    })
  }

  async getCitiesDataAccurate() {
    Object.keys(this.destinations).map(async (countryCode) => {

      const cityDigests = this.destinations[countryCode].cities.map(async (city) => {
        const cityDigest = await this.searchStore.getCityDigest({
          ...this.resultPayload,
          query: {
            ...this.resultPayload.query,
            countryCode,
            cityCodes: [city.code]
          }
        });
        return { city, cityDigest }
      })
      const info = await this.getCountryInfo(cityDigests)
      const countryCities = info?.citiesData.map(cd => cd.city)

      this.searchStore.updateCoutryCities({countryCode, cities: countryCities})

      if (!!this.resultStoreView && !!this.resultStoreView.visibleDestinations) {
        this.resultStoreView.setVisibleDestination({
          destination: {
            ...this.resultStoreView.visibleDestinations[countryCode],
            cities: countryCities
          }
        })
      }

      this.resultStore.setAllDestinationsInfo({
        countryCode,
        info: {
          minPrice: info?.minPrice,
          quantityOfPackages: info?.quantityOfPackages
        }
      });
    });
  }

  async getCountryInfo(cityDigestPromises: Promise<ICityData>[]):
    Promise<{minPrice: number, quantityOfPackages: number, citiesData: ICityData[]}>
  {
    return await Promise.allSettled(cityDigestPromises)
      .then(results => {
        const citiesData: ICityData[] = []

        results.forEach(result => {
          if(result.status === 'rejected') {
            return
          }

          if(result.value.cityDigest.total) {
            citiesData.push(result.value)
          }
        })

        citiesData.sort((cityData1, cityData2) => cityData1.cityDigest.pricesPerOne.prices.min - cityData2.cityDigest.pricesPerOne.prices.min)
        return citiesData.length ?
          {
            citiesData,
            minPrice: citiesData[0].cityDigest.pricesPerOne.prices.min,
            quantityOfPackages: citiesData.reduce((acc, cityData) => {
              acc += cityData.cityDigest.total
              return acc
            }, 0)
          }:
          null
      })
  }

  async getCountriesDataPrimary({ filter, initializeFilter = true, isAnyCountry }: { filter?: IConvertToPackageResult, initializeFilter?: boolean, isAnyCountry?: boolean }) {
    this.resultStoreView.setPending({ pendingName: 'gettingPrimaryData', value: true })
    let data: ICountriesWithDigest = { countries: [], digest: null }

    if (!isAnyCountry) {
      const countries = await this.searchStore.getAndSetDestinationCountriesForCalendar()
      data = { ...data, countries }
    } else {
      const intervalId = this.resultStore.intervalId
      this.resultStore.deleteTimeout({intervalId})
      data = await this.searchStore.getAndSetDestinationCountriesWithDigest({ filter })
    }

    if (initializeFilter) {
      this.resultStore.initializeResultFilter({ digest: data.digest })
    }

    return Promise.resolve(data)
  }

  getDestinationPackages({ countryCode }: { countryCode: string }): IPackagesListClass {
    return this.resultStore.allDestinationsPackages[countryCode]
  }


  isFavoritePackage({ hotelId }: { hotelId: string }): boolean {
    return !!this.resultStore.favoritePackages?.getPackage({ hotelId })
  }

  isComparablePackage({ hotelId }: { hotelId: string }): boolean {
    return !!this.resultStore.comparablePackages.getPackage({ hotelId })
  }

  isReplacedPackage({ hotelId }: { hotelId: string }): boolean {
    return this.packageCompareAppStore.replacedPackage?.hotel.hotelId === hotelId
  }

  isPackageSubstitute({ hotelId }: { hotelId: string }): boolean {
    if (Number.isInteger(this.packageCompareAppStore.indexOfSelectedComparisonColumn)) {
      const selectedComparisonProduct = this.resultStore.comparablePackages.content[this.packageCompareAppStore.indexOfSelectedComparisonColumn]
      return !this.packageCompareAppStore.replacedPackage && selectedComparisonProduct?.hotel.hotelId === hotelId
    }
    return false
  }

  checkProductsDetailsPending(selectedPackage: IPackageClass) {
    return this.resultStore.pendings.getProductsDetails &&
           selectedPackage.hotel.hotelId === this.resultStore.selectedPackage?.hotel.hotelId
  }

  checkBookingErrorOccured({ hotelId }: { hotelId: string }): boolean {
    return !!this.resultStore.packagesWithBookingError?.getPackage({ hotelId })
  }

  isPackageUnavailable({ hotelId }: { hotelId: string }): boolean {
    return this.resultStore.packagesWithBookingError?.getPackage({ hotelId })?.packageIsUnavailable(this.resultStore.quantityOfPossibleBookingErrors)
  }

  onChangeFavoriteStatusOfPackage({ currentPackage }: { currentPackage: IPackageClass }): void {
    const isFavoritePackage = !!this.resultStore.favoritePackages?.getPackage({ hotelId: currentPackage.hotel.hotelId })

    if (isFavoritePackage) {
      this.resultStore.removePackageFromFavorite({ currentPackage })
    } else {
      this.resultStore.addPackageToFavorite({ currentPackage })
    }
  }

  onChangeComparisonStatusOfPackage({ currentPackage, index }: { currentPackage: IPackageClass, index?: number }): void {
    const isComparablePackage = !!this.resultStore.comparablePackages?.getPackage({ hotelId: currentPackage.hotel.hotelId })

    this.packageCompareAppStore.setReplacedPackage(null)

    if (isComparablePackage) {
      if (!this.isPackageSelectionMode) {
        this.resultStore.removePackageFromCompare({ currentPackage })
      }
    } else {
      if (this.quantityComparablePackages < this.resultStore.quentityOfComparablePackages || this.isPackageSelectionMode) {
        this.resultStore.addPackageToCompare({
          currentPackage,
          index: this.isPackageSelectionMode ? index : null
        })
        if (this.isPackageSelectionMode) {
          this.goToComparablePackages()
        }
      } else {
        this.showComparisonLimitModal()
      }
    }
  }

  showComparisonLimitModal() {
    const modalPost = {
      component: 'comparison-limit',
      preventDefaultClose: true
    }
    this.setModalPost({modalPost})
  }

  selectProductAndInitOrder({ product }: { product: IPackageProductClass }) {
    this.setModalPost({modalPost: null})
    this.usedPackage.selectedProduct = product
    this.resultStore.selectedPackage.selectedProduct = product

    this.initOrder({ selectedPackage: this.resultStore.selectedPackage })
  }

  openHotelCardModal(hotel: IPackageHotelClass) {
    this.hotel = hotel
    this.showHotelCardModal = true
  }

  openHotelInformation(hotel: IPackageHotelClass) {
    hotel.content.images.length
      ? this.showHotelGallery(hotel)
      : this.openHotelCardModal(hotel)
  }

  async showFlightChangeModal({ currentPackage }: { currentPackage: IPackageClass }) {
    this.usedPackage = currentPackage
    this.resultStore.setSelectedPackage(currentPackage)

    const modalPost = {
      component: 'product-selector',
      closeBtnInTitle: true,
      selectedProduct: currentPackage.selectedProduct,
      relevantProducts: currentPackage.products.content,
      resultPayload: this.resultPayload,
      returnTill: this.searchStore.searchQuery.dateInterval.end,
      hotelInfo: this.usedPackage.content.hotel,
      getAndSetPackageProducts: this.resultStore.pendings.getAndSetPackageProducts, // FIXME: change the variable name, e.g. getAndSetPackageProductsPending
      isPackageUnavailable: this.isPackageUnavailable({ hotelId: currentPackage.hotel.hotelId }),
      checkBookingErrorOccured: this.checkBookingErrorOccured({ hotelId: currentPackage.hotel.hotelId }),
      checkProductsDetailsPending: this.checkProductsDetailsPending(currentPackage)
    }
    this.setModalPost({modalPost})
  }

  showHotelGallery(hotel: IPackageHotelClass) {
    const modalPost = { component: 'hotel-gallery', hotel }
    this.setModalPost({modalPost})
  }

  setCurrentPackage(selectedPackage: IPackageClass) {
    this.resultStore.setSelectedPackage(selectedPackage)
  }

  selectProductFromSearch(product: IPackageProductClass) {
    this.setModalPost({modalPost: null})
    this.resultStore.selectedPackage.selectedProduct = product
  }

  async changeHotelProductByNights(currentPackage: IPackageClass, direction: string) {
    this.setCurrentPackage(currentPackage)

    let currentPackageProducts = cloneDeep(currentPackage.products.content)
    currentPackageProducts.sort((a, b) => (a.nights) - (b.nights));
    let nightsArray: number[] = []
    const currentPackageFilteredProducts = currentPackageProducts.filter(item => {
      if (!nightsArray.includes(item.nights)) {
        nightsArray.push(item.nights)
        return item
      }
    })

    const currentProductIndex = currentPackageFilteredProducts.findIndex(item => {
      return item.nights === this.resultStore.selectedPackage.selectedProduct.nights
    })

    if (direction === 'decrease') {
      if (currentProductIndex === 0) {
        this.selectProductFromSearch(currentPackageFilteredProducts[currentPackageFilteredProducts.length - 1])
      } else {
        this.selectProductFromSearch(currentPackageFilteredProducts[currentProductIndex - 1])
      }
    } else if (direction === 'increase') {
      if (currentProductIndex === currentPackageFilteredProducts.length - 1) {
        this.selectProductFromSearch(currentPackageFilteredProducts[0])
      } else {
        this.selectProductFromSearch(currentPackageFilteredProducts[currentProductIndex + 1])
      }
    }
  }

  async initOrder({ selectedPackage }: { selectedPackage: IPackageClass }) {
    gtag('event', 'select_hotel', {
      event_category: 'User Actions',
      event_label: 'Select Hotel',
      value: 1
    });
    try {
      const modalPost = {
        component: 'loading-booking-wizard',
        preventDefaultClose: true
      }
      this.setModalPost({modalPost})
      this.resultStore.setPending({ pendingName: 'getProductsDetails', value: true })
      this.resultStore.setSelectedPackage(selectedPackage)

      const agencyId = await this.orderStore.getAndSetDefaultWebsite()

      await this.resultStore.getAndSetDetailsForPackageProduct({
        product: selectedPackage.selectedProduct,
        agencyId
      })

      if (this.checkBookingErrorOccured({ hotelId: selectedPackage.hotel.hotelId })) {
        selectedPackage.quantityOfBookingErrors = 0
        this.resultStore.removePackageFromPackagesWithBookingError({ currentPackage: selectedPackage })
      }

      this.$router.push({
        name: 'order',
        params: { orderId: 'new' }
      })
    } catch {
      selectedPackage.bookingErrorOccured(this.resultStore.quantityOfPossibleBookingErrors)

      if (!this.checkBookingErrorOccured({ hotelId: selectedPackage.hotel.hotelId })) {
        this.resultStore.addPackageToPackagesWithBookingError({ currentPackage: selectedPackage })
      }
    } finally {
      this.resultStore.setPending({ pendingName: 'getProductsDetails', value: false })
      const modalPost = {
        component: 'session-expired',
        preventDefaultClose: true
      }
      this.setModalPost({modalPost: modalPost})
    }
  }
}
