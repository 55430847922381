
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import FilterCondition from "ui-modules/products-filter/filter.condition.vue"
import FilterConditionSlider from 'ui-modules/products-filter/filter.condition-slider.vue'

import {
  IFilterConditionGroup,
  IFilterSliderCondition
} from "ui-modules/products-filter/types/products-filter.types"
import {
  IRegularFilterPost
} from "applications/desktop/package-compare-app/components/search-results/types/search-results.types";
import FilterGroup from "ui-modules/products-filter/filter.group.vue";
import { addCurrency } from "common/filters/addCurrency"

@Component({
  components: {
    FilterCondition,
    FilterGroup,
    FilterConditionSlider
  },
  filters: {
    addCurrency,
  }
})
export default class FilterPrice extends Vue {
  @Prop(Object)
  post!: IRegularFilterPost

  minValue: number = 0
  maxValue: number = 0

  mounted() {
    this.minValue = this.filterPrice[0]
    this.maxValue = this.filterPrice[1]
  }

  @Watch('post')
  onPostChange() {
    this.minValue = this.filterPrice[0]
    this.maxValue = this.filterPrice[1]
  }

  get filterPrice(): number[] {
    return [
      Math.floor(this.post.packageFilter.price[0]),
      Math.floor(this.post.packageFilter.price[1])
    ]
  }

   get filterInitialPrice(): number[] {
    return [
      Math.floor(this.post.packageInitialFilter.price[0]),
      Math.floor(this.post.packageInitialFilter.price[1])
    ]
  }

  get hasNaNValues(): boolean {
    return isNaN(this.minValue) || isNaN(this.maxValue)
  }

  get pricePost(): IFilterSliderCondition {
    return {
      formatValue: '$',
      value: this.filterPrice,
      valueLimits: this.filterInitialPrice,
      type: 'price',
      direction: this.post.direction
    }
  }

  get conditionGroupPost(): IFilterConditionGroup {
    return {
      groupTitle: 'filter.price',
      useAccordionTitleInFilter: this.post.useAccordionTitleInFilter
    }
  }

  onChangeSlider(partOfFilter) {
    this.$emit('set-filter', partOfFilter);
  }

  onSetVisibleValue(value : [number, number]) {
    const [minValue, maxValue] = value
    this.minValue = minValue
    this.maxValue = maxValue
  }
}
