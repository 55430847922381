
import { Vue, Component } from "vue-property-decorator";
import CallSvg from './call.svg.vue'
import CloseSvg from './close.svg.vue'
import EmailSvg from './email.svg.vue'
import LocaleSelector from "ui-modules/selectors/locale-selector-2.vue";
import clickOutside from 'click-outside'

import {
  i18n_MODULE,
} from 'applications/desktop/package-compare-app/store/store'
import { TLocaleType } from "applications/desktop/package-compare-app/components/root-app/types/root-app.types";

@Component({
  name: "lang-and-contacts-button",
  components: {
    CallSvg,
    CloseSvg,
    EmailSvg,
    LocaleSelector,
  }
})
export default class LangAndContactsDialog extends Vue {

  unbind = null
  mounted() {
    setTimeout(() => {
      this.unbind = clickOutside(this.$refs.langAndContactsDialog, () => {
        this.$emit('close')
      })
    }, 0)
  }

  beforeDestroy() {
    this.unbind()
  }

  get i18nStore() {
    return i18n_MODULE
  }

  get localeList(): string[] {
    return Object.keys(this.i18nStore.locales)
  }

  get currentLocale(): TLocaleType {
    return this.i18nStore.currentLocale
  }
}
