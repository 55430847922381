
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
export default class FilterSkeletonList extends Vue {
  @Prop({ type: Number, default: 2 }) listItemNum: number

  get type(): string {
    return `text@${this.listItemNum}`
  }
}
