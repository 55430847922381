
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import ModalBase from 'ui-modules/modals/modal-base.vue'

import { formatDateToDMYDot } from 'common/filters/formattedDates'

import {
  IStartEnd,
  IPriceCalendarPeriod,
  IPackageCompareQueryClass, IQueryLocation
} from "applications/desktop/package-compare-app/types/package-compare-app.types";

@Component({
  components: {
    ModalBase
  }
})
export default class DateSelectBLock extends Vue {
  @Prop(Object) selectedDestination? :IQueryLocation
  @Prop(Object) departureDates!: IPriceCalendarPeriod
  @Prop(Object) returnDates!: IPriceCalendarPeriod
  @Prop(Object) searchStorePendings!: any
  @Prop(Object) searchQuery!: IPackageCompareQueryClass
  @Prop(String) locale!: string

  showDatesModal = false

  get dateInterval() {
    return this.searchQuery.dateInterval
  }

  get dateIntervalExist() {
    return this.searchQuery.dateIntervalExist()
  }

  get dateRangeForShow(): string {
    return `${formatDateToDMYDot(this.dateInterval.start)} - ${formatDateToDMYDot(this.dateInterval.end)}`
  }

  openDatesModal() {
    this.showDatesModal = true
  }

  closeDatesModal() {
    this.showDatesModal = false
  }

  selectDates(dates: IStartEnd<string>) {
    this.closeDatesModal()
    this.$emit('select-dates', dates)
  }
}
