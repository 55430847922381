
import Vue from "vue"
import {Component, Prop} from "vue-property-decorator"
import * as moment from 'moment'

import dateTime from "common/filters/datetime"
import DashTextPlaceholder
  from 'applications/desktop/package-compare-app/components/search-results/ui/dash-text-placeholder.vue'
import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'
import WaitingFlightsTimingTooltip
  from 'ui-modules/tooltips/waiting-flights-timing-tooltip/waiting-flights-timing-tooltip.vue'

import {
  SegmentResource
} from 'be-structures/typescript-generator/assembly'
import {
  IFlightDetailsPost
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'
import TooltipFlightDetailsDiagram from "./tooltip-flight-details-diagram/tooltip-flight-details-diagram.vue";
import {ItineraryPointResource} from "be-structures/typescript-generator";
import TlIcon from "../../../../../../ui-modules/icons/icon";
import Stars from "../../../../../../ui-modules/cards/hotel/stars.vue";

@Component({
             filters: {
               dateTime
             },
             components: {
               Stars,
               TlIcon,
               TooltipFlightDetailsDiagram,
               DashTextPlaceholder,
               CustomTooltip,
               WaitingFlightsTimingTooltip
             }
           })

export default class FlightDetails extends Vue implements IFlightDetailsPost {
  @Prop(Array) segments!: SegmentResource[]
  @Prop(Boolean) isDeparture: boolean
  @Prop(String) provider: string
  isShowTooltipFLightDetailsDiagram = false

  get isHiveStops() {
    return this.segments?.length > 1
  }

  get stopAirportCode() {
    return this.segments[0]?.arrival?.airport
  }

  get stopAirportName() {
    return this.segments[0]?.arrival?.airportName
  }

  get stopTime() {
    const departureTime = moment(this.segments[1]?.departure.time)
    const arrivalTime = moment(this.segments[0]?.arrival?.time)
    return departureTime.diff(arrivalTime, 'hours')
  }

  get firstSegment() {
    return this.segments[0];
  }

  get lastSegment() {
    return this.segments.at(-1);
  }

  get mainDeparture(): ItineraryPointResource {
    return this.firstSegment.departure
  }

  get mainArrival(): ItineraryPointResource {
    return this.lastSegment.arrival
  }

  get departureTime(): string {
    return moment(this.mainDeparture?.time).format('HH:mm')
  }

  get arrivalTime(): string {
    return moment(this.mainArrival?.time).format('HH:mm')
  }

  get isExtraDay() {
    const departureDayPlusOne = moment(this.mainDeparture?.time).add(1, 'days').format('DD.MM.YYYY')
    const arrivalDay = moment(this.mainDeparture?.time).format('DD.MM.YYYY')
    return departureDayPlusOne === arrivalDay
  }

  get departurePoint() {
    return `${this.mainDeparture?.cityName} - ${this.mainDeparture?.airport} ${this.mainDeparture?.airportName}`
  }

  get arrivalPoint() {
    return `${this.mainArrival?.cityName} - ${this.mainArrival?.airport} ${this.mainArrival?.airportName}`
  }

  get cityCode() {
    return this.mainDeparture?.cityCode
  }

  get airlineCode() {
    return this.firstSegment.airline?.code
  }

  get flightNumber() {
    return this.firstSegment?.flightNumber
  }

  get timeIsKnownDeparture() {
    return this.mainDeparture?.timeIsKnown
  }

  get timeIsKnownArrival() {
    return this.mainArrival?.timeIsKnown
  }
}
