import Package from 'modules/product-result.v2/data/package-result/package-result.package/package-result.package.class'

import {
  IPackageHotel,
  IPackageHotelClass,
  IPackageProduct,
  IPackageProductClass,
  IPackageProductsListClass,
  // IPackageProductPrice,
  PackageHotel,
  TPackageHotel,
  IMinMax
} from 'modules/product-result.v2/data/product-result.types'

interface IPackage {
  hotel: TPackageHotel
  products?: IPackageProduct[]
  minPrice: string
}

interface IPackageClass {
  readonly content: IPackage
  readonly minPrice: string
  readonly selectedProductsIds: string[][]
  readonly isSoldOut: boolean
  selectedProduct: IPackageProductClass
  hotel: IPackageHotelClass
  // // productsIds: Array<IPackageProduct['productId']>
  products: IPackageProductsListClass
  nightsInterval: IMinMax<number>
  quantityOfBookingErrors: number
  // selectedProviderCode: string
  // selectedProduct: IPackageProductClass
  convertToStorage(): any
  setProducts(value: IPackageProduct[]): void
  getScore(): any
  setSelectedProductsIds({ segmentGroupIndex, value }: { segmentGroupIndex: number, value: string[] }): void
  getProductsWithoutSelectedProduct(): IPackageProductClass[]
  bookingErrorOccured(quantityOfPossibleBookingErrors: number): void
  packageIsUnavailable(quantityOfPossibleBookingErrors: number): boolean
  withSingleAvailableNight() : boolean
  // getNextProvider(): void
  // getPrevProvider(): void
  // getNextProduct(): void
  // getPrevProduct(): void
}

export {
  // classes
  Package,
  // interfaces
  IPackageClass,
  IPackage
  // IPackageProductPrice
}
