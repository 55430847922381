
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import PaxIconsList from 'ui-modules/icons/pax-icons/pax-icons-list.vue'
import RoomPaxInfo from "ui-modules/room-pax-info/room-pax-info.vue"
import LuggageIcon from 'ui-modules/svg-icon/luggage.vue'
import LuggageChildIcon from 'ui-modules/svg-icon/luggage-child.vue'
import SkiingIcon from 'ui-modules/svg-icon/skiing.vue'

import capitalizeDestination from "common/filters/capitalizeDestination";
import convertToRoman from "common/filters/convertToRoman";

import { IProductDetailsPaxComplectClass } from "applications/desktop/package-app/components/order-product/types/order-product.types";
import { ICustomerClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types'
import { IRoomCustomer, TCustomersAdditions } from 'applications/desktop/package-compare-app/components/order/types/order.types'

import {
  convertICustomerResourceToCustomerInfoForm
} from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/customer-info/customer-info-form.converter'

@Component({
  components: {
    PaxIconsList,
    RoomPaxInfo,
    LuggageIcon,
    LuggageChildIcon,
    SkiingIcon
  },
  filters: {
    capitalizeDestination,
    convertToRoman,
  }
})

export default class PassengersAndAccomodation extends Vue {
  @Prop(Number) roomIndex!: number;
  @Prop(Array) customers!: IRoomCustomer[]
  @Prop(Object) paxComplect!: IProductDetailsPaxComplectClass;
  @Prop(Object) additions!: TCustomersAdditions

  showLuggageBlock(roomCustomer: IRoomCustomer) {
    return this.showBaggageInfo(roomCustomer) || this.showSkiInfo(roomCustomer)
  }

  showBaggageInfo(roomCustomer: IRoomCustomer) {
    return !!(
      this.getServiceDataForPax(roomCustomer, 'BAGGAGE') && this.getServiceDataForPax(roomCustomer, 'BAGGAGE').quantity > 0
    )
  }
  showSkiInfo(roomCustomer: IRoomCustomer) {
    return !!(
      this.getServiceDataForPax(roomCustomer, 'SKI')  && this.getServiceDataForPax(roomCustomer, 'SKI').quantity > 0
    )
  }

  getCustomerInfo(customer: ICustomerClass) {
    return convertICustomerResourceToCustomerInfoForm({ data: customer?.content })
  }

  getServiceDataForPax(roomCustomer: IRoomCustomer, serviceType: string) {
    const services = [...Object.values(this.additions)[this.roomIndex][roomCustomer.customer.customerKey]?.additionalPayments]
    if (services.length) {
      const index = services.findIndex(item => {
        return item.paxType === roomCustomer.type && item.additionalPaymentType === serviceType
      })
      return index === -1 ? null : services[index]
    }
    return null
  }
}
