
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import ModalBase from 'ui-modules/modals/modal-base.vue'

import { TPaxes } from 'applications/desktop/package-compare-app/types/package-compare-app.types'

@Component({
  components: {
    ModalBase
  }
})
export default class PassengersSelectBlock extends Vue {
  @Prop(Object) paxes: TPaxes
  @Prop(Number) quantityOfPassengers: number

  showPassengersModal = false

  openPassengersModal() {
    this.showPassengersModal = true
  }
  closePassengersModal() {
    this.showPassengersModal = false
  }
  onApplyPassengers(pax: TPaxes) {
    this.closePassengersModal()
    this.$emit('apply', pax)
  }
}
