
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})

export default class DeltaBtn extends Vue {
  @Prop(String) text!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  click() {
    if (!this.disabled) this.$emit('click')
  }
}
