
import Vue from 'vue'
import TlIcon from 'ui-modules/icons/icon'
import { Component, Prop } from 'vue-property-decorator'

import {
  IPackageCompareResultClass
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

@Component({
  components: {
    TlIcon
  }
})
export default class PackageScore extends Vue {
  @Prop(Object)
  packageResult: IPackageCompareResultClass
  @Prop(Boolean)
  pending: boolean

  readonly keyHotelFeatures = ['flight', 'hotel', 'room', 'food', 'kids']

  get score(): any {
    return (this.packageResult.hotel && this.packageResult.getScore()) || {}
  }

  get scoresInPercents() {
    if (!this.packageResult.hotel && this.packageResult.getScore()) return {}

    return this.packageResult.getScore()
  }

  get scoreKeys(): string[] {
    return Object.keys(this.scoresInPercents).filter(key => this.keyHotelFeatures.includes(key))
  }
}
