
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import LuggageIcon from 'ui-modules/svg-icon/luggage.vue'
import LuggageChildIcon from 'ui-modules/svg-icon/luggage-child.vue'
import SkiingIcon from 'ui-modules/svg-icon/skiing.vue'
import LuggageInfoTooltip from 'ui-modules/tooltips/luggage-info-tooltip.vue'

import prettyPrice from 'common/filters/prettyPrice'

import { IMoneyResource } from 'modules/common/price/price.types'
import {
  IPackageProductClass
} from "../../../../../../../modules/product-result.v2/data/package-result/package-result.product/package-result.product.types";
import SelectBaggageDetails from "../select-baggage-details/select-baggage-details.vue";

export interface IBaggageInfo {
  id: string,
  restriction: string,
  value: string,
  rule: string,
  price: IMoneyResource,
  paxType: string,
  additionalPaymentType: string
}

@Component({
  components: {
    SelectBaggageDetails,
    LuggageIcon,
    LuggageChildIcon,
    SkiingIcon,
    LuggageInfoTooltip
  },
  filters: {
    prettyPrice
  }
})
export default class ServicesSelect extends Vue {
  @Prop(Object) baggageData: IBaggageInfo
  @Prop(Object) skiData: IBaggageInfo
  @Prop({type: Number, default: 0}) baggageNum: number
  @Prop({type: Number, default: 0}) skiNum: number
  @Prop(Object) post: IPackageProductClass

  isOpenDetailsBaggage: boolean = false

  increaseService(service: string): void {
    this.$emit('increase', service)
  }

  decreaseService(service: string): void {
    if (this[service.toLowerCase() + 'Num'] > 0) {
      this.$emit('decrease', service)
    }
  }

  openDetailsBaggage(): void {
    this.isOpenDetailsBaggage = !this.isOpenDetailsBaggage
  }

  get baggagePrice(): string {
    return this.$options.filters.prettyPrice(this.baggageData.price, 0)
  }

  get isChild(): boolean {
    return this.baggageData?.paxType !== 'ADULT'
  }
  get selectBaggageDetailsPost(): {baggageData: IBaggageInfo, baggageNum: number, post: IPackageProductClass['segmentGroups']} {
    return {
      baggageData: this.baggageData,
      baggageNum: this.baggageNum,
      post: this.post.segmentGroups
    }
  }
}
