
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import RoomPaxInfo from "ui-modules/room-pax-info/room-pax-info.vue"

import convertToRoman from "common/filters/convertToRoman";
import prettyPrice from 'common/filters/prettyPrice'

import { IProductDetailsPaxComplectClass } from "applications/desktop/package-app/components/order-product/types/order-product.types";
import { IPackageProductPriceClass } from 'modules/product-result.v2/data/product-result.types'
@Component({
  components: { 
    RoomPaxInfo,
  },
  filters: {
    convertToRoman,
    prettyPrice,
  }
})

export default class ToBePaid extends Vue {
  @Prop(Array) paxComplects!: IProductDetailsPaxComplectClass[];
  @Prop(Object) orderTotalPrice!: object;

  getPaxTotalPrice(complect: IProductDetailsPaxComplectClass, index: number) {
    let price = index === 0 ? complect.price : complect.originalPrice
    if (!price) {
      price = complect.price
    }
    return {
      currency: price.currency,
      amount: price.total
    }
  }

}
