
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { Validations } from "vuelidate-property-decorators"
import { validationMixin } from "vuelidate"

import { IPersonInfoForm } from "../custom-forms.types"

import { checkValidationRules } from "common/validation/validation"
import validationRules from "./contact-person-info-form.validate"
import CustomTextField from "ui-modules/elements/text-field/text-field.vue"
import useLtrInput from 'common/directives/vuetify-form.use-rtl-input'

@Component({
  components: {
    CustomTextField,
  },
  directives: {
    useLtrInput
  },
  mixins: [validationMixin],
})
export default class ContactPersonInfoForm extends Vue {
  @Prop(Object) person!: any;
  @Prop({ type: Boolean, default: true, required: false }) requiredForm!: boolean;

  @Validations()
  initValidations() {
    return validationRules({ requiredForm: this.requiredForm });
  }

  get validForm() {
    return !this.$v.$invalid;
  }

  getErrorMessage({ validationPath, field }: { validationPath: string; field: string; }): string {
    const errorName = checkValidationRules({
      $v: this.$v,
      validationPath,
      field,
    });
    return errorName || null;
  }

  touchForm() {
    this.$v.$touch();
  }

  onInputCustomerInfo({
    validationPath,
    field,
    value,
  }: {
    validationPath: string;
    field: keyof IPersonInfoForm;
    value: string;
  }) {
    this.$v[validationPath][field].$touch();
    this.$emit("input-contact-person-info", {
      ...this.person,
      [field]: value,
    } as IPersonInfoForm);
  }
}
