import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import cloneDeep from 'lodash/cloneDeep'

import {
  IPackageClass,
  IProductDetailsPaxComplectClass,
  ICustomerClass,
  TRoomsCustomer,
  IPackageProductDetailsClass,
  PackageProductDetails,
  Customer,
  TRoomsAdditions,
  TOrderPendings,
  OrderPendingsNames
} from 'applications/desktop/package-compare-app/components/order/types/order.types';

import PRODUCT_ORDER_MODULE_VIEW from 'applications/desktop/package-compare-app/components/order/vuex/order.name'

@Module({ name: PRODUCT_ORDER_MODULE_VIEW, namespaced: true })
export default class extends VuexModule {
  private _orderStepNumber: number = 1
  private _boarding: string = null
  private _destinationPackage: IPackageClass = null
  private _productDetails: IPackageProductDetailsClass = new PackageProductDetails()
  private _owner: ICustomerClass = new Customer()
  private _customers: TRoomsCustomer = null
  private _additions: TRoomsAdditions = null
  private _formsIds: string[] = []
  private _keyOfCustomerAsOwner: string = null
  private _pendings: TOrderPendings = {
    startOrderReservation: false
  }


  get pendings() {
    return this._pendings
  }

  get orderStepNumber() {
    return this._orderStepNumber
  }

  get boarding() {
    return this._boarding
  }

  get destinationPackage() {
    return this._destinationPackage
  }

  get productDetails() {
    return this._productDetails
  }

  get owner() {
    return this._owner
  }

  get customers() {
    return this._customers
  }
  
  get additions() {
    return this._additions
  }

  get formsIds() {
    return this._formsIds
  }

  get keyOfCustomerAsOwner() {
    return this._keyOfCustomerAsOwner
  }


  @Mutation
  setPending({ pendingName, value }: { pendingName: keyof typeof OrderPendingsNames, value: boolean }) {
    this._pendings[pendingName] = value
  }

  @Mutation
  setOrderStep({ stepNumber }: { stepNumber: number }) {
    this._orderStepNumber = stepNumber
  }
  
  @Mutation
  selectBoarding({ boarding }: { boarding: string }) {
    this._boarding = boarding
  }

  @Mutation
  setDestinationPackage(destinationPackage: IPackageClass) {
    this._destinationPackage = cloneDeep(destinationPackage)
  }

  @Mutation
  setProductDetails(details: IPackageProductDetailsClass) {
    this._productDetails = cloneDeep(details)
  }

  @Mutation
  selectPaxComplects({ paxComplects }: { paxComplects: IProductDetailsPaxComplectClass[] }) {
    if(!!paxComplects) this._productDetails.paxComplects = cloneDeep(paxComplects)
  }

  @Mutation
  setOwner(owner: ICustomerClass) {
    this._owner = owner
  }

  @Mutation
  clearOwner() {
    this._owner = new Customer()
  }

  @Mutation
  setCustomers(customers: TRoomsCustomer) {
    this._customers = customers
  }

  @Mutation
  selectAdditions({ additions }: { additions: TRoomsAdditions }) {
    this._additions = additions
  }

  @Mutation
  setFormsIds({ formsIds }: { formsIds: string[] }) {
    this._formsIds = formsIds
  }
  
  @Mutation
  setKeyOfCustomerAsOwner(customerKey: string) {
    this._keyOfCustomerAsOwner = customerKey
  }
}
