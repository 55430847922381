/**
 * Return action names
 * @param name
 * @param options
 * @returns {{INITIAL: string, SUCCEEDED: string, FAILED: string}}
 */

function actionHelper<T extends string = string>(name: T): {
  FAILED: `${T}_FAILED`;
  SUCCEEDED: `${T}_SUCCEEDED`;
  INITIAL: T;
} {
  const succededString = "_SUCCEEDED";
  const failedString = "_FAILED";

  return {
    INITIAL: name,
    SUCCEEDED: `${name}${succededString}`,
    FAILED: `${name}${failedString}`
  };
}

export default actionHelper;
