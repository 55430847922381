
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IPackageProductClass
} from 'ui-modules/modals/modal.types'

import ActionPanel from 'ui-modules/buttons/action-panel.vue'
import ModalWindowV2 from 'ui-modules/modals/modal-content/modal-window-v2.vue'

import FlightInfo from 'ui-modules/flight-info/flight-info.vue'
import FlightSelector from 'ui-modules/flight-table/flight-selector.vue'

import SelectionFlightTip from 'ui-modules/modals/modal-content/modal-selection-flight-tip/modal-selection-flight-tip.vue'
import Shepherd from "shepherd.js";
import PRODUCT_RESULT_MODULE from 'modules/product-result.v2/product-result.name'
import { Action, Mutation, Getter } from 'vuex-class'
import {initSharpedConfig, userTrainingMoreDetails} from "../../../common/userTrainingSteps";
import MarkerLoader from "../../marker-loader/marker-loader.vue";

@Component({
  components: {
    MarkerLoader,
    ActionPanel,
    ModalWindowV2,
    FlightInfo,
    FlightSelector,
    SelectionFlightTip
  }
})
export default class ModalPackageSelector extends Vue {
  @Prop(Object) post: any

  @Getter(`${PRODUCT_RESULT_MODULE}/productsComposed`)
  productsComposed: []

  @Mutation(`${PRODUCT_RESULT_MODULE}/setProductsComposed`)
  setProductsComposed: ({}) => void

  @Action(`${PRODUCT_RESULT_MODULE}/getAndSetPackageProductsComposed`)
  getAndSetPackageProductsComposed: ({}) => void

  isLoading: boolean = true
  currentProduct: IPackageProductClass = null
  showFlightTipModal: boolean = false

  tour = new Shepherd.Tour({
    ...initSharpedConfig
  });
  
  mounted() {
    if(!localStorage.getItem('isShowUserTrainingMoreDetails')) {
      this.startUserTrainingInMoreDetails()
    }
  }
  initUserTrainingInMoreDetails() {
    this.tour.addSteps(userTrainingMoreDetails(this.tour));
  }

  async created() {
    this.currentProduct = this.post.selectedProduct
    this.initUserTrainingInMoreDetails()
    this.isLoading = true
    await this.getAndSetPackageProductsComposed({
      ...this.post.resultPayload,
      hotelId: this.post.hotelInfo.id,
    })
    this.isLoading = false
  }

  beforeDestroy() {
    this.setProductsComposed({})
  }

  onSelectProduct(product) {
    this.currentProduct = product
  }

  startUserTrainingInMoreDetails() {
    // this.tour.start()
    // console.log("startUserTrainingInMoreDetails")
    // localStorage.setItem('isShowUserTrainingMoreDetails', 'true')
  }

  selectAndBookProduct(): void {
    this.$emit('select-product-and-init-order', this.currentProduct)
  }

  openFlightTipModal() {
    this.showFlightTipModal = true
  }

  closeFlightTipModal() {
    this.showFlightTipModal = false
  }
}
