
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator"
import VueSlider from 'vue-slider-component'

import {
  IFilterSliderCondition
} from "ui-modules/products-filter/types/products-filter.types"

import { addCurrency } from 'common/filters/addCurrency'

@Component({
  components: {
    VueSlider
  }
})
export default class FilterConditionSlider extends Vue {
  @Prop(Object)
  post!: IFilterSliderCondition
  @Prop({type:String, default: 'always'})
  tooltip: string
  @Prop({type: Number, default: 15})
  mergeTooltipPercentageLimit: number
  addCurrency = addCurrency

  @Watch('value')
  getPercentWidth() {
    setTimeout(() => {
      this.sliderPercentData = this.$refs.slider.processArray[0]
    }, 100);
  }

  sliderPercentData = {
    start: 0,
    end: 100
  }

  currentValue = this.value

  get value(): [number, number] {
    return JSON.parse(JSON.stringify(this.post.value))
  }

  get sliderWithMarks(): boolean {
    return Boolean(this.post.marks && this.post.marks.interval && this.post.marks.range) || this.post.type === 'price'
  }
  get marks(): { [key: string]: string } {
    if (this.post.type === 'price') return this.getMarksForPrices()
    if (this.sliderWithMarks) {
      return this.post.marks.interval && this.post.marks.range ? this.getMarksWithTitle({ range: this.post.marks.range }) : null
    }
    return null
  }

  get showMergeTooltip() {
    const { start, end } = this.sliderPercentData

    if (this.tooltip !== 'none') {
      return end - start < this.mergeTooltipPercentageLimit
    } else {
      return false
    }
  }

  get mergedTooltip() {
    if (this.currentValue[0] !== this.currentValue[1]) {
      return this.getFormatter(String(this.currentValue[0])) + '-' + this.getFormatter(String(this.currentValue[1]))
    } else {
      return this.getFormatter(String(this.currentValue[0]))
    }
  }

  // temp
  getFormatter(value: string) {
    if (this.post.type === 'inDeparture' || this.post.type === 'outDeparture') {
      return this.post.filter.convertMinutesToHHmm(value)
    } else if (this.post.type === 'price') {
      return this.addCurrency(value)
    } else {
      return value + (this.post.formatValue || '')
    }
  }

  getMarks({ range, marks }: { range: [number, number], marks?: number[] }): number[] {
    if (range[1] > 0) {
      if (!marks) {
        return this.getMarks({ range, marks: [range[0]] })
      } else {
        const lrange = JSON.parse(JSON.stringify(range))
        const lmarks = JSON.parse(JSON.stringify(marks))

        lmarks.push(range[1] > this.post.marks.interval ? marks[marks.length - 1] + this.post.marks.interval : this.post.marks.range[1])
        lrange.splice(1, 1, range[1] - this.post.marks.interval)
        return this.getMarks({ range: lrange, marks: lmarks })
      }
    } else {
      return marks
    }
  }

  getMarksWithTitle({ range }: { range: [number, number] }): { [key: string]: string } {
    const marksList = this.getMarks({ range })
    return Object.assign({}, ...marksList.map((mark, index) => ({ [mark]: this.$t(`filter.time.${this.post.marks.titles[index]}`) || mark })))
  }

  changeSlider(value: [number, number]): void {
    this.$emit('change-slider', { type: this.post.type, value })
    this.currentValue = value
    this.sliderPercentData = this.$refs.slider.processArray[0]
  }

  dragSlider(value: [number, number]): void {
    this.$emit('set-visible-value',value);
    this.currentValue = value
    this.sliderPercentData = this.$refs.slider.processArray[0]
  }

  getMarksForPrices() {
    const marks = {
      [this.post.valueLimits[0]]: '',
      [this.post.valueLimits[1]]: '',
    }

    let i = 0
    while (i < this.post.valueLimits[1]) {
      if (i > this.post.valueLimits[0]) marks[i] = ''
      i += 100
    }
    return marks
  }
}
