import {
  ITravelProductPriceResource,
} from 'modules/product-result.v2/data/product-result.types';

import { PRICE_BLANK } from 'modules/common/common.types'
import {TravelProductPriceResource} from "be-structures/typescript-generator/assembly";

export const PRODUCT_PRICE_BLANK: TravelProductPriceResource = {
  baggagePrice: [],
  ...PRICE_BLANK,
  paxPrices: [],
  markup: null,
  commission: null,
  pricePerOne: null
}
