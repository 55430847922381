
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import HotelStars from "ui-modules/cards/hotel/stars.vue";
import TlIcon from 'ui-modules/icons/icon'
import Gmap from 'ui-modules/googleMaps/googleMaps.vue'

import {
  IAdditional,
  IPackageHotelClass,
  facilitiesList,
  IPackageHotelFacility
} from 'applications/desktop/package-compare-app/types/package-compare-app.types';
import { TGoogleMapLocations } from "ui-modules/googleMaps/googleMaps.types";
import {IMedia} from "../../../modules/product-order/data/product-order.types";

@Component({
  components: {
    HotelStars,
    TlIcon,
    Gmap,
  }
})
export default class HotelCardModal extends Vue {
  @Prop(Object)
  hotel!: IPackageHotelClass;

  get roomFacilities() {
    return this.hotel.getFacilitiesByType('Room')
  }

  get hotelFacilities() {
    return this.hotel.getFacilitiesByType('Hotel')
  }

  get hotelImages() {
    return this.hotel.getImages().slice(0, 4)
  }

  get locations(): TGoogleMapLocations {
    return this.hotel.locations
  }

  get locationDescription(): string {
    return this.hotel.getRemarkByType("location");
  }

  get roomCount(): string {
    return this.hotel.getRemarkByType("room_count");
  }

  get preparedHotelMedia(): IMedia[] {
    return this.hotel?.media?.filter(media => media.value && media.value.trim()) ?? [];
  }

  get noFacilities() {
    return !this.hotel.checkFacilities()
  }

  getFacilityImage() {
    return `url(${require(`images/png/no-facility-image.png`)})`
  }

  openHotelGallery() {
    this.$emit("show-hotel-gallery", this.hotel);
    this.$emit("close");
  }

  closeModal() {
    this.$emit("close");
  }
}
