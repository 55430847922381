import type {
  AddressType
} from 'be-structures/typescript-generator/assembly'

import {
  Address,
  IAddressesCollectionClass,
  TAddressesCollection,
  ADDRESSES_TYPES,
  IAddressResource,
  setClassName
} from 'modules/common/common.types'

export class AddressesCollection implements IAddressesCollectionClass {
  private _addressesCollection: TAddressesCollection = null

  constructor(addresses?: IAddressResource[]) {
    setClassName(AddressesCollection.name, this)

    this._addressesCollection = ADDRESSES_TYPES.reduce((collection, addressType): TAddressesCollection => {
      const existedAddress = addresses?.find(address => address.addressType === addressType)

      return {
        ...collection,
        [addressType]: new Address({ address: existedAddress, addressType })
      }
    }, {} as TAddressesCollection)
  }

  get content() {
    return this._addressesCollection
  }

  setAddress({ type, address }: { type: AddressType, address: IAddressResource }) {
    this._addressesCollection[type].content = address
  }
}