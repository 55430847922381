
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import CustomTooltip from "ui-modules/tooltips/custom-tooltip.vue";

@Component({
  components: {
    CustomTooltip,
  },
})
export default class HotelControlPanel extends Vue {}
