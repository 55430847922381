
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import SearchResultCard from 'applications/desktop/package-compare-app/components/search-results/ui/search-result.card.vue'

import {
  IFavoriteResultPost,
  IPackageHotelClass,
  IPackageClass,
  IPackageCompareProductFilterClass,
  IPackageCompareQueryClass,
  IPackageCompareResultState,
  IPartOfList,
  IProductFilter
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

@Component({
  components: {
    SearchResultCard
  }
})
export default class FavoriteResultsList extends Vue {
  @Prop(Object) post!: IFavoriteResultPost

  onShowFlightChangeModal({ currentPackage }: { currentPackage: IPackageClass }): void {
    this.$emit('show-flight-change-modal', { currentPackage })
  }

  changeFavoriteStatus({ currentPackage }: { currentPackage: IPackageClass }): void {
    this.$emit('change-favorite-status', { currentPackage })
  }

  changeComparisonStatus({ currentPackage }: { currentPackage: IPackageClass }): void {
    this.$emit('change-comparison-status', { currentPackage })
  }
}
