import Package from 'modules/product-result.v2/data/package-result/package-result.package/package-result.package.class'
import {
  HOTEL_SCORE_TYPES,
  IPackageCompare,
  IPackageCompareProductFilterClass,
  IPackageCompareResultClass,
  IPackageHotelClass,
  IPackageHotelConstructor,
  IPackageProductClass,
  IPackageProductsListClass,
  PackageCompareProductFilter,
  PackageHotel,
  setClassName
  } from 'modules/product-result.v2/data/product-result.types'

  // @ts-ignore
export default class PackageCompareResult extends Package
  implements IPackageCompareResultClass {
  private _defaultFilter: IPackageCompareProductFilterClass = null
  private _filter: IPackageCompareProductFilterClass = null
  private _hotels: IPackageHotelClass[] = null
  private _quantityOfHotels: number = 0

    // TODO: change products variable
    private _productGroupedByHotels: { [key: string]: IPackageProductsListClass } = {}
    private _selectedProductsIds: string[][] = [[],[]]

  constructor(packageCompare: IPackageCompare) {
    super(packageCompare)

    setClassName(PackageCompareResult.name, this)

    if (packageCompare.result) {
      // @ts-ignore
      this.setHotelsList({ products: packageCompare.result.content })
      this._filter = new PackageCompareProductFilter({
        filterData: packageCompare.result.page.query.digest
      })
      this._quantityOfHotels = packageCompare.result.page.totalElements
    }

    if (packageCompare.globalDefaultFilterData) {
      this._defaultFilter = new PackageCompareProductFilter({
        filterData: packageCompare.globalDefaultFilterData,
        isDefaultFilter: true
      })
    }
  }

  get hotels(): IPackageHotelClass[] {
    return this._hotels || []
  }
  get filter(): IPackageCompareProductFilterClass {
    return this._filter
  }
  get defaultFilter(): IPackageCompareProductFilterClass {
    return this._defaultFilter
  }
  get productsGroupedByHotels(): { [key: string]: IPackageProductsListClass } {
    return this._productGroupedByHotels
  }
  get selectedProductsIds(): string[][] {
    return this._selectedProductsIds
  }
  get quantityOfHotels(): number {
    return this._quantityOfHotels
  }

  set productsGroupedByHotels(value: { [key: string]: IPackageProductsListClass }) {
    this._productGroupedByHotels = value
  }
  set selectedProductsIds(value: string[][]) {
    this._selectedProductsIds = value
  }

  addToProductsGroupedByHotel({ hotelId, products}: { hotelId: string, products: IPackageProductsListClass }): void {
    this._productGroupedByHotels[hotelId] = products
  }


  getSegmentGroupsOfProductsGroupedByHotels({ hotelId }: { hotelId: string }): { [key: string]: IPackageProductClass['segmentGroups'] } {
    return this.productsGroupedByHotels[hotelId].content.reduce((productA, productB): { [key: string]: IPackageProductClass['segmentGroups'] } => {
      return {
        ...productA,
        [productB.content.productId]: productB.segmentGroups
      }
    }, {})
  }
  
  getScore(): any {
    if (!super.selectedProduct?.score) {
      return null
    }
    
    const baseScore = HOTEL_SCORE_TYPES.reduce((acc: any, type: string): any => ({
      ...acc,
      [type]: super.selectedProduct.score[type]
    }), {})
    const total = super.selectedProduct.score?.total
      
    return {
      baseScore,
      total
    }
  }

  setHotelsList({ products }: { products: IPackageHotelConstructor[] }): void {
    this._hotels = products.map(
      product =>
        new PackageHotel({ ...product })
    )
  }

  existSelectedHotelInHotelsList(): boolean {
    return Boolean (this._hotels?.find(hotel => super.hotel.hotelId === hotel.hotelId) || false)
  }
}
