
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import FilterGroup from "ui-modules/products-filter/filter.group.vue"
import FilterConditionSlider from 'ui-modules/products-filter/filter.condition-slider.vue'

import {
  IFilterSliderCondition,
  IResultPost
} from "ui-modules/products-filter/types/products-filter.types"
import {
  IRegularFilterPost
} from "applications/desktop/package-compare-app/components/search-results/types/search-results.types";

@Component({
  components: {
    FilterGroup,
    FilterConditionSlider
  }
})
export default class FilterHotelStars extends Vue {
  @Prop(Object)
  post!: IRegularFilterPost

  @Watch('post.packageFilter.hotelStars')
  onHotelStarsChange(newStars: number[]) {
    this.minValue = newStars[0]
    this.maxValue = newStars[1]  
  }

  get hasNaNValues(): boolean {
    return isNaN(this.minValue) || isNaN(this.maxValue)
  }

  get hotelStarsPost(): IFilterSliderCondition {
    return {
      formatValue: '⭐',
      value: this.post.packageFilter.hotelStars,
      valueLimits: this.post.packageInitialFilter.hotelStars,
      type: 'hotelStars',
      direction: this.post.direction
    }
  }



  minValue = this.hotelStarsPost.value[0]
  maxValue = this.hotelStarsPost.value[1]

  setVisibleValue(partOfFilter: [number, number]) {
    const [minValue, maxValue] = partOfFilter
    this.minValue = minValue
    this.maxValue = maxValue
  }

  setFilterValue(partOfFilter: {type: string, value: [number, number]}) {
    this.setVisibleValue(partOfFilter.value)
    this.$emit('set-filter', partOfFilter)
  }

}
