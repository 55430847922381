import { Route } from 'vue-router'

export default class ControlAppNavigation {
  constructor(private _route: Route) {}

  get breadcrumbs(): string[] {
    const breadcrumbs: string[] = []
    this._route.matched.forEach(route => {
      if (route.name) {
        breadcrumbs.push(route.name)
      }
    })
    if (!breadcrumbs.includes('Home')) {
      breadcrumbs.unshift('Home')
    }
    return breadcrumbs
  }
}
