
import Vue from "vue";
import { Prop, Component, Ref, Watch } from "vue-property-decorator";
import isEqual from "lodash/isEqual";
import CustomSelector from 'ui-modules/selectors/custom-selector/custom-selector.vue'
import PaxIconsList from 'ui-modules/icons/pax-icons/pax-icons-list.vue'

@Component({
  components: {
    PaxIconsList,
    CustomSelector
  },
})
export default class RoomTypePaxSelector extends Vue {
  @Prop(Array) paxTypeList!: Array<string[]> ;
  @Prop(Array) selectedRoomType!: string[];

  get uniqueRoomTypes() {
    return this.paxTypeList.filter((arr, index, self) => {
      return self.findIndex((el) => isEqual(arr, el)) === index;
    });
  }
}
