
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import FlightGeneralInfo from "ui-modules/flight-general-info/flight-general-info.vue"

import { IMoneyResource, ISegmentGroupResource } from 'modules/product-result.v2/data/product-result.types'
import {DealFeature} from "be-structures/typescript-generator";

interface IBaggageInfo {
  id: string,
  restriction: string,
  value: string,
  rule: string,
  price: IMoneyResource,
  paxType: string,
  additionalPaymentType: string
}

@Component({
  components: {
    FlightGeneralInfo
  }
})
export default class HotelInfo extends Vue {
  @Prop(Array) segmentGroups!: ISegmentGroupResource[]
  @Prop(Object) luggageInfo: IBaggageInfo
  @Prop(Object) cabinInfo: IBaggageInfo
  @Prop(Array) dealFeatures!: DealFeature[]


  get dealFeaturesFindBaggageInfo() {
    return this.dealFeatures.find(DealFeature => DealFeature === 'NOT_INCLUDING_BAGGAGE' || DealFeature === 'INCLUDING_BAGGAGE')
  }

  get cabinText() {
    return this.$t(`order-v2.luggage.cabin-${this.cabinInfo ? 'included' : 'only'}`)
  }

  get luggageText() {
    return this.$t(`order-v2.luggage.luggage-${this.dealFeaturesFindBaggageInfo === 'INCLUDING_BAGGAGE' ? 'included' : 'not-included'}`)
  }
}

