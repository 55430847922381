
// using is prevented, use formattedDates instead
export default function(value: string): string {
  const appLocale = localStorage.app_locale || 'en'
  if (value) {
    const hrmnLocale: any = {
      en: {
        h: 'h',
        m: 'm'
      },
      he: {
        h: 'ש',
        m: 'ד'
      },
      ru: {
        h: 'ч',
        m: 'м'
      }
    }
    const hrmn = value.match(/\d*[H|M]/g)
    // if the server responded, for example: PT50M, hrmn[0] will contain a match with 50M
    const hr =
      hrmn[0] && hrmn[0][hrmn[0].length - 1] === 'H'
        ? `${hrmn[0].match(/\d{1,2}/g)}${hrmnLocale[appLocale].h}`
        : ``
    const mn = hr
      ? hrmn[1]
        ? `${hrmn[1].match(/\d{1,2}/g)}${hrmnLocale[appLocale].m}`
        : ``
      : hrmn[0]
      ? `${hrmn[0].match(/\d{1,2}/g)}${hrmnLocale[appLocale].m}`
      : ``

    return `${hr} ${mn}`
  }
}
