
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Advice extends Vue {
  @Prop(String)
  icon: string

  dontShow: boolean = null
}
