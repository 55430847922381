
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
// @ts-ignore
import Multiselect from 'vue-multiselect'

import { ILocation } from 'applications/desktop/flight-app/types/desktop-app.types'
import LocationItem from './location-item.vue'

@Component({
  components: {
    Multiselect,
    LocationItem
  }
})
export default class LocationFormSelector extends Vue {
  @Prop(Boolean)
  isPending!: boolean
  @Prop({ type: String, default: 'Select...' })
  placeholder!: string
  @Prop(Array)
  location!: ILocation[]
  @Prop([Object, String])
  value!: ILocation | string
  @Prop(Boolean)
  isMandatory!: boolean

  @Emit('input')
  updateRequestBody(locationObject: ILocation) {}

  onSearchChange(locationObject: ILocation) {
    if (!locationObject) {
      return
    }
    this.$emit('search-change', locationObject)
  }
}
