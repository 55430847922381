import cloneDeep from 'lodash/cloneDeep'

import {
  TravelProductPriceResource
} from 'be-structures/typescript-generator/assembly'

import {
  ITravelProductPriceResource,
  PRODUCT_PRICE_BLANK
} from 'modules/product-result.v2/data/package-result/package-result.product-price/package-result.product-price.types'

export const convertTravelProductPriceResourceToITravelProductPriceResource = ({ productPrice }: { productPrice?: TravelProductPriceResource }): ITravelProductPriceResource => {
  const convertedProductPrice = cloneDeep(PRODUCT_PRICE_BLANK)
  
  if (productPrice) {
    for (const item of Object.keys(PRODUCT_PRICE_BLANK)) {
      convertedProductPrice[item] = productPrice[item]
    }
  }

  return convertedProductPrice
}