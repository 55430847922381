
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'

import DestinationCitiesCarousel from 'applications/desktop/package-compare-app/components/search-results/ui/destination-cities-carousel.vue'
import capitalizeDestination from 'common/filters/capitalizeDestination'

import {
  IRegularResultPost,
  IQueryLocation
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

@Component({
  components: {
    DestinationCitiesCarousel,
  },
  filters: {
    capitalizeDestination
  }
})
export default class DestinationItem extends Vue {
  @Prop(Object)
  post!: IRegularResultPost
  @Prop(Object)
  destination!: IQueryLocation
  @Prop(Boolean)
  isOpenedDestination!: boolean
  @Prop({ type: Boolean, default: false })
  hotelsRecieved: boolean
  @Prop(Number) index: number


  get country() {
    return this.destination['country']
  }

  get cities() {
    return this.destination['cities']
  }
  mounted() {
    this.$emit('show-user-training-on-result-page')
  }

  get countryFlagPath(): string {
    if (this.country) {
      return require(`images/flags/64/${this.country.code}.png`)
    }
    return null
  }

  get selectedCity() {
    if (this.cities) {
      return this.cities.find(city => this.post.isSelectedCity({ cityCode: city.code }))
    }
  }

  onToggle(destination: { cityCode?: string, countryCode?: string }): void {
    if (this.cities && this.hotelsRecieved) {
      const selectedCountry = this.isOpenedDestination ? this.country : null
      if (destination.countryCode !== selectedCountry?.code || destination.cityCode !== this.selectedCity?.code) {
        this.$emit('toggle-content', destination)
      }
    }
  }
}
