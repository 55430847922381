import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { getModule } from 'vuex-module-decorators'

import http from 'common/http/http'

import i18n_NAME from 'modules/i18n/i18n.name'
import i18n from 'modules/i18n/i18n.store'
import PACKAGE_COMPARE_DESKTOP_NAME from 'applications/desktop/package-compare-app/package-compare-app.name'
import PACKAGE_COMPARE_DESKTOP from 'applications/desktop/package-compare-app/package-compare-app.store'
import PRODUCT_SEARCH_NAME from 'modules/product-search.v2/product-search.name'
import PRODUCT_SEARCH from 'modules/product-search.v2/product-search.store'
import PRODUCT_RESULT_NAME from 'modules/product-result.v2/product-result.name'
import PRODUCT_RESULT from 'modules/product-result.v2/product-result.store'
import PRODUCT_RESULT_VIEW_NAME from 'applications/desktop/package-compare-app/components/search-results/vuex/search-result.name'
import PRODUCT_RESULT_VIEW from 'applications/desktop/package-compare-app/components/search-results/vuex/search-result.store'
import PRODUCT_ORDER_NAME from 'modules/product-order.v2/product-order.name'
import PRODUCT_ORDER from 'modules/product-order.v2/product-order.store'
import PRODUCT_ORDER_VIEW_NAME from 'applications/desktop/package-compare-app/components/order/vuex/order.name'
import PRODUCT_ORDER_VIEW from 'applications/desktop/package-compare-app/components/order/vuex/order.store'
import PaymentStore from "../../../../modules/payment/payment.store";
import paymentApi from "../../../../modules/payment/payment.api";


Vue.use(Vuex)

const vuexLocal = new VuexPersistence<any>({
  storage: window.localStorage,
  reducer: (state) => ({
    [PRODUCT_SEARCH_NAME]: {
      _searchQuery: state[PRODUCT_SEARCH_NAME]._searchQuery,
      _destinations: state[PRODUCT_SEARCH_NAME]._destinations
    },
    [PRODUCT_RESULT_NAME]: {
      _filter: state[PRODUCT_RESULT_NAME]._filter,
      _defaultFilter: state[PRODUCT_RESULT_NAME]._defaultFilter,
      _comparablePackages: state[PRODUCT_RESULT_NAME]._comparablePackages,
      _favoritePackages: state[PRODUCT_RESULT_NAME]._favoritePackages,
      _selectedPackage: state[PRODUCT_RESULT_NAME]._selectedPackage
    },
    [PRODUCT_RESULT_VIEW_NAME]: {
      _selectedCountryCode: state[PRODUCT_RESULT_VIEW_NAME]._selectedCountryCode,
      _selectedCityCode: state[PRODUCT_RESULT_VIEW_NAME]._selectedCityCode
    },
    [PRODUCT_ORDER_NAME]: {
      _agencyId: state[PRODUCT_ORDER_NAME]._agencyId
    }
  })
})

const storeConfig: StoreOptions<any> = {
  modules: {
    [i18n_NAME]: i18n,
    [PACKAGE_COMPARE_DESKTOP_NAME]: PACKAGE_COMPARE_DESKTOP,
    [PRODUCT_SEARCH_NAME]: PRODUCT_SEARCH,
    [PRODUCT_RESULT_NAME]: PRODUCT_RESULT,
    [PRODUCT_RESULT_VIEW_NAME]: PRODUCT_RESULT_VIEW,
    [PRODUCT_ORDER_NAME]: PRODUCT_ORDER,
    [PRODUCT_ORDER_VIEW_NAME]: PRODUCT_ORDER_VIEW,
    ["PAYMENT"]: PaymentStore({api: paymentApi({http})})
  },
  // plugins: [vuexLocal.plugin]
}
  export const store = new Vuex.Store<any>(storeConfig)
  export const i18n_MODULE = getModule(i18n, store)
  export const PACKAGE_COMPARE_DESKTOP_MODULE = getModule(PACKAGE_COMPARE_DESKTOP, store)
  export const PRODUCT_SEARCH_MODULE = getModule(PRODUCT_SEARCH, store)
  export const PRODUCT_RESULT_MODULE = getModule(PRODUCT_RESULT, store)
  export const PRODUCT_RESULT_VIEW_MODULE = getModule(PRODUCT_RESULT_VIEW, store)
  export const PRODUCT_ORDER_MODULE = getModule(PRODUCT_ORDER, store)
  export const PRODUCT_ORDER_VIEW_MODULE = getModule(PRODUCT_ORDER_VIEW, store)
