
import { Component } from 'vue-property-decorator';
import CustomTooltip from "ui-modules/tooltips/custom-tooltip.vue";
import Calendar from 'ui-modules/svg-icon/calendar.vue'
import CalendarSmall from 'ui-modules/svg-icon/calendar-small.vue'
import TooltipWrapper from 'ui-modules/modals/modal-content/tooltip-wrapper.vue'
import dateTime from 'common/filters/datetime'
import Datepicker from 'ui-modules/datepicker/datepicker.vue'
import PriceDatepicker from 'ui-modules/datepicker/price-datepicker.vue'
import DatepickerWithBarImpl from './datepicker-with-bar';

@Component({
  components: {
    TooltipWrapper,
    Calendar,
    CalendarSmall,
    CustomTooltip,
    Datepicker,
    PriceDatepicker,
  },
  filters: {
    dateTime
  }
})
export default class VueDatepickerWithBar extends DatepickerWithBarImpl {}
