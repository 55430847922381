import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import qs from 'qs'

import {
  i18n_MODULE
} from 'applications/desktop/package-compare-app/store/store'
const url = window.location.href
const parseUrl = url.split('#')[1]?.split('?')[1]?.split('&').reduce((previousValue, currentValue) => {
  const [name, value] = currentValue.split('=')
  return {
    ...previousValue,
    [name]: value
  }
}, {})

const instance: AxiosInstance = axios.create({
  // @ts-ignore
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    get: {
      'Content-Type': 'application/json'
    },
    post: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  timeout: 300000,
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

const pdfInstance: AxiosInstance = axios.create({
                                               // @ts-ignore
                                               baseURL: process.env.PRINT_BASE_URL,
                                               headers: {
                                                 Accept: 'application/json, text/plain, */*',
                                                 post: {
                                                   'Content-Type': 'application/json'
                                                 }
                                               },
                                               timeout: 300000,
                                               paramsSerializer: params => {
                                                 return qs.stringify(params, { arrayFormat: 'brackets' })
                                               }
                                             })

instance.interceptors.request.use(
  (request) => requestHandler(request)
)

instance.interceptors.response.use(
  (response: AxiosResponse) => responseHandler(response),
  (error: AxiosError) => errorHandler(error)
)

const requestHandler = (request: AxiosRequestConfig) => {
  request.headers = {
    ...request.headers,
    'Accept-Language': i18n_MODULE.currentFormattedLocale
  }

  return request
}

const responseHandler = (response: AxiosResponse) => {
  return response.data
}

const errorHandler = (error: AxiosError) => {
  return Promise.reject(error.response)
}

export default class Http {
  static post(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): AxiosPromise {
    return instance.post(url, {
      referralCode: parseUrl?.ref ?? '',
      ...data
    }, config)
  }

  static get(url: string, data: any, headers?: any): AxiosPromise {
    return instance.get(url, { params: {
        referralCode: parseUrl?.ref ?? '',
        ...data
      }, headers })
  }

  static put(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): AxiosPromise {
    return instance.put(url, {
      referralCode: parseUrl?.ref ?? '',
      ...data
    }, config)
  }
  static pdfPost(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise {
    console.log("pdfPost", pdfInstance)
        return pdfInstance.post(url, data, config)
  }
}
