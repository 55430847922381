
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SearchModalNoResult from "./search-modal.no-result.vue";
import EpsilonBtn from "ui-modules/buttons/epsilon-btn/epsilon-btn.vue";

@Component({
  components: {
    SearchModalNoResult,
    EpsilonBtn
  }
})
export default class NoResultResetFiltersModal extends Vue {
  @Prop(Boolean) isFilterResult: boolean
}
