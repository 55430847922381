import cloneDeep from 'lodash/cloneDeep'

import type {
  PriceResource
} from 'be-structures/typescript-generator/assembly'

import {
  PRICE_BLANK,
  IPriceResource
} from 'modules/common/price/price.types'

export const convertPriceResourceToIPriceResource = (({ price }: { price?: PriceResource }): IPriceResource => {
  const convertedPrice = cloneDeep(PRICE_BLANK)

  if (price) {
    for (const item of Object.keys(PRICE_BLANK)) {
      convertedPrice[item] = price[item]
    }
  }
  
  return convertedPrice
})