
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'

import TlIcon from 'ui-modules/icons/icon'
import DestinationCitiesCarousel from 'applications/desktop/package-compare-app/components/search-results/ui/destination-cities-carousel.vue'
import mathCeil from 'common/filters/mathCeil'
import {addCurrency} from 'common/filters/addCurrency'
import CustomTooltip from "ui-modules/tooltips/custom-tooltip.vue"

import {
  IATACityResource
} from 'be-structures/typescript-generator/assembly'

import {
  IPackagesListClass,
  IRegularResultPost,
  IQueryLocation,
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

@Component({
  components: {
    DestinationCitiesCarousel,
    TlIcon,
    CustomTooltip
  },
  filters: {
    mathCeil,
    addCurrency
  }
})
export default class DestinationItemContent extends Vue {
  @Prop(Object)
  post!: IRegularResultPost
  @Prop(Object)
  destinationPackages!: IPackagesListClass;
  @Prop(Object)
  destination!: IQueryLocation;
  @Prop(Boolean)
  isOpenedDestination!: boolean
  @Prop(Object)
  selectedCity!: IATACityResource
  @Prop(Array)
  cities!: IQueryLocation['cities']
  @Prop(Object)
  country!: IQueryLocation['country']

  get destinationInfo() {
    return this.post.allDestinationsInfo[this.country.code]
  }

  get isDestinationPriceReady() {
    return this.destinationInfo.minPrice !== Number.MAX_VALUE
  }
}
