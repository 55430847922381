import {
  IConvertToPackageResult,
  IConvertedQuery,
  IContentResponse,
  IVacationTerm
} from 'modules/common/common.types'

import {
  IATACountryResource,
  IATACityResource
} from 'be-structures/typescript-generator/assembly'
import {
  HotelsSort, SortValues
} from "modules/product-result.v2/data/package-compare/package-compare.filter/filter.consts";
import { TLocaleType } from 'modules/i18n/data/i18n.types';

export default (http: any) => {
  const getActiveCountriesForCalendar = (websiteKey: string): IATACountryResource[] => {
    return http.get('/customer/search/statpackage/calendar/countries', { websiteKey })
  }

  const getActiveCountries = ({
    query,
    filter,
    locale,
  }: {
    query: IVacationTerm,
    filter: IConvertToPackageResult
    locale: TLocaleType
  }): Promise<IContentResponse<IATACountryResource[]>> => {
    return http.put('customer/compare/statpackage/calendar/vacation/countries/page', {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 50,
        paged: true,
        sort: !!filter?.hotelsSort? filter.hotelsSort: [
          {
            [HotelsSort.NAME]: SortValues.ASC
          }
        ],
        unpaged: false
      },
      productFilter: filter || null,
      vacationTerm: query,
      locale: locale.toUpperCase(),
    })
  }

  const getActiveCities = ({
    query,
    filter
  }: {
    query: IConvertedQuery,
    filter: IConvertToPackageResult
  }): Promise<IContentResponse<IATACityResource[]>> => {
    return http.put(
      '/customer/compare/statpackage/calendar/vacation/cities/page',
      {
        pageable: {
          offset: 0,
          pageNumber: 0,
          pageSize: 50,
          paged: true,
          sort: !!filter?.hotelsSort? filter.hotelsSort : [
            {
              [HotelsSort.NAME]: SortValues.ASC
            }
          ],
          unpaged: false
        },
        productFilter: filter,
        ...query
      }
    )
  }

  const getDepartureDatesByCountry = (({ countryCode, websiteKey }: { countryCode: string, websiteKey: string }) => {
    return http.get(`customer/compare/statpackage/calendar/countries/${countryCode}/departures`, { websiteKey })
  })

  const getReturnDatesByCountry = (({ countryCode, departureDate, websiteKey }: { countryCode: string, departureDate: string, websiteKey: string }) => {
    return http.get(`customer/compare/statpackage/calendar/countries/${countryCode}/departures/${departureDate}/returns`, { websiteKey })
  })

  const getBestDeals = ({ query }: { query: IConvertedQuery }) => {
    const data = {
      vacationTerm: query.vacationTerm
    }
    return http.put(`customer/compare/statpackage/calendar/vacation/bests`, {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 14,
        paged: true,
        sort: [
          {
            [HotelsSort.NAME]: SortValues.ASC
          }
        ],
        unpaged: false
      },
      ...data
    })
  }

  const getCountryDigest = ({
    vacationTerm,
    filter,
    query
  }: {
    vacationTerm: IVacationTerm,
    filter: IConvertToPackageResult,
    query: IConvertedQuery,
  }): Promise<IContentResponse<IATACountryResource[]>> => {
    return http.put('customer/compare/statpackage/calendar/vacation/country/digests', {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 50,
        paged: true,
        sort: !!filter?.hotelsSort? filter.hotelsSort: [
          {
            [HotelsSort.NAME]: SortValues.ASC
          }
        ],
        unpaged: false
      },
      productFilter: filter || null,
      ...query,
      vacationTerm
    })
  }

  const getCityDigest = ({
    query,
    filter
  }: {
    query: IConvertedQuery,
    filter: IConvertToPackageResult
  }): Promise<IContentResponse<IATACountryResource[]>> => {
    return http.put('customer/compare/statpackage/calendar/vacation/city/digests', {
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 50,
        paged: true,
        sort: !!filter?.hotelsSort? filter.hotelsSort: [
          {
            [HotelsSort.NAME]: SortValues.ASC
          }
        ],
        unpaged: false
      },
      productFilter: filter || null,
      ...query
    })
  }

  return {
    getActiveCities,
    getActiveCountries,
    getDepartureDatesByCountry,
    getReturnDatesByCountry,
    getActiveCountriesForCalendar,
    getBestDeals,
    getCountryDigest,
    getCityDigest
  }
}
