
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator"

@Component({
  name: "filter-group"
})

export default class FilterContainer extends Vue {
  @Prop({
    type: String,
    default: 'bottom'
  })
  titlePosition: 'bottom' | 'top'
}
