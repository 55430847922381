
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class TransitionSlide extends Vue {
  @Prop(Boolean)
  reverse: boolean

  get transitionName() {
    return this.reverse ? 'paging-reverse' : 'paging'
  }
}
