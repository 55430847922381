import {offset} from "@floating-ui/dom";
import i18n from "../../applications/desktop/i18n";

const initSharpedConfig = {
    useModalOverlay: true,
    defaultStepOptions: {
        classes: 'sheperd-wrapper'
    }
}

const userTrainingSearchPage = (tour, locale: string) => {
    return [
        {
            modalOverlayOpeningRadius: 10,
            cancelIcon: {
                enabled: true
            },
            arrow: false,
            attachTo: {
                element: '.typeRestFilter',
                on: 'right-start'
            },
            text: i18n.t('common.userTraining.vacationTypeDescription', locale),
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ],
            floatingUIOptions: {
                middlewares: [offset(10)]
            }
        },
        {
            modalOverlayOpeningRadius: 10,
            cancelIcon: {
                enabled: true
            },
            arrow: false,
            attachTo: {
                element: '.destination-block',
                on: 'right-start'
            },
            text: i18n.t('common.userTraining.destinationDescription', locale),
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 10,
            cancelIcon: {
                enabled: true
            },
            arrow: false,
            attachTo: {
                element: '.date-select-block',
                on: 'right-start'
            },
            text: i18n.t('common.userTraining.dateSelectorDescription', locale),
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
    ]
}

const userTrainingResultPage = (tour) => {
    return [
        {
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.cities-carousel__location--info',
                on: i18n.locale === 'he' ? 'left-start' : 'right-start'
            },
            text: i18n.t('common.userTraining.cityInfoDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            name: "showMore",
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.destination-item-content__toggle-btn',
                on: i18n.locale === 'he' ? 'right-start' : 'left-start'
            },
            text: i18n.t('common.userTraining.showMoreDescription'),
            cancelIcon: {
                enabled: true
            }
        }
    ]
}

const userTrainingCountryDetails = tour => {
    return [

        {
            modalOverlayOpeningRadius: 7,
            modalOverlayOpeningPadding: 7,
            arrow: false,
            attachTo: {
                element: '.control-panel__comparison-btn',
                on: i18n.locale === 'he' ? 'right-start': 'left-start'
            },
            text: i18n.t('common.userTraining.controlPanelComparisonDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            arrow: false,
            attachTo: {
                element: '.site-header__comparison-btn',
                on: 'bottom-end'
            },
            text: i18n.t('common.userTraining.siteHeaderComparisonDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 7,
            modalOverlayOpeningPadding: 7,
            arrow: false,
            attachTo: {
                element: '.control-panel__favorite-btn',
                on: i18n.locale === 'he' ? 'right-start': 'left-start'
            },
            text: i18n.t('common.userTraining.controlPanelFavoriteDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            arrow: false,
            attachTo: {
                element: '.site-header__favorite-btn',
                on: 'bottom-end'
            },
            text: i18n.t('common.userTraining.siteHeaderFavoriteDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.selected-flight-info__change-flight-btn',
                on: 'left-start'
            },
            text: i18n.t('common.userTraining.moreDetailsDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.done')} `,
                    action: tour.next
                }
            ]
        },
    ]
}

const userTrainingMoreDetails = (tour) => {
    return [
        {
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.flight-selector__departure',
                on: i18n.locale === 'he' ? 'left-start' : 'right-start'
            },
            text: i18n.t('common.userTraining.flightDepartureDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.flight-selector__return',
                on: i18n.locale === 'he' ? 'left-start' : 'right-start'
            },
            text: i18n.t('common.userTraining.flightReturnDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.modal-window-v2-header__product-block',
                on: 'bottom-end'
            },
            text: i18n.t('common.userTraining.changeNightDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.flight-selector__boarding',
                on: i18n.locale === 'he' ? 'right-start' : 'left-start'
            },
            text: i18n.t('common.userTraining.boardingDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 10,
            arrow: false,
            attachTo: {
                element: '.flex-card__price',
                on: i18n.locale === 'he' ? 'right-start' : 'left-start'
            },
            text: i18n.t('common.userTraining.priceDescription'),
            cancelIcon: {
                enabled: true
            },
            buttons: [
                {
                    text: `${i18n.t('common.userTraining.next')} >`,
                    action: tour.next
                }
            ]
        },
    ]
}

export { userTrainingResultPage, userTrainingSearchPage, userTrainingMoreDetails, initSharpedConfig, userTrainingCountryDetails }
