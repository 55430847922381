import {
  CustomerResource,
  AsyncCallStatus,
  TravelOrderResource
} from 'be-structures/typescript-generator/assembly'

import {
  ICustomerResource
} from 'modules/product-order.v2/data/product-order.types'
import http from "../../common/http/http";

export default (http: any) => {
  const getDefaultWebsite = () => {
    return http.get(`/customer/compare/statpackage/calendar/vacation/website`)
  }

  const beginOrder = (agencyId: string): TravelOrderResource => {
    return http.post(`/customer/orders?agencyId=${agencyId}`)
  }

  const addProductToOrder = (
    {
      orderId,
      productId,
      complectIds
    }: {
      orderId: string,
      productId: string,
      complectIds: string[]
    }
  ) => {
    return http.post(
      `/customer/orders/statpackages/${orderId}?itemId=${productId}&${encodeURIComponent(
        'complectIds[]'
      )}=${complectIds}`,
      {}
    )
  }

  const registrationNewCustomer = ({ orderId, customer }: { orderId: string, customer: ICustomerResource }): Promise<CustomerResource> => {
    return http.post(`/customer/orders/${orderId}/customers`, customer)
  }

  const setContactPerson = ({ orderId, customerId }: { orderId: string, customerId: string }) => {
    return http.put(`/customer/orders/${orderId}/contact_person?customerId=${customerId}`)
  }

  const addCustomerToOrderItem = ({
    orderId,
    itemId,
    customerId
  }: {
    orderId: string,
    itemId: string,
    customerId: string
  }) => {
    return http.post(`/customer/orders/${orderId}/${itemId}/passengers?customerId=${customerId}`)
  }

  const addBaggageToCustomer = ({
    orderId,
    itemId,
    customerId,
    additionalPaymentId
  }: {
    orderId: string,
    itemId: string,
    customerId: string,
    additionalPaymentId: string
  }) => {
    return http.post(`customer/orders/statpackages/${orderId}/items/${itemId}/customers/${customerId}/additions?additionalPaymentId=${additionalPaymentId}`)
  }

  const startOrderPreReservation = ({ orderId }: { orderId: string }): Promise<{ status: AsyncCallStatus }> => {
    return http.get(`customer/orders/${orderId}/pre_reservation_status`)
  }

  const startOrderReservation = ({ orderId }: { orderId: string }) => {
    return http.post(`customer/orders/${orderId}/reservations`, {
      onlinePayment: true
    })
  }

  const getReservationStatus = ({ orderId }: { orderId: string }): Promise<{ status: AsyncCallStatus }> => {
    return http.get(`customer/orders/${orderId}/reservation_status`, {})
  }

  const getCountriesList = () => {
    return http.get('places/countries', {})
  }

  const getPaxType = ({ productId, paxComplectId }: {productId: string, paxComplectId: string }) => {
    const headers= {
      'Accept': 'application/hal+json'
    }
    return http.get(
        `/customer/orders/statpackages/paxTypeByProduct/${productId}/${paxComplectId}`,
        // `/customer/orders/statpackages/paxType/${itemId}?birthday=${birthday}`,
        {},
        headers
    )
  }

  const sendPdfEmail = ({
    locale,
    orderId,
    Customer_name,
    Agency_name,
    Agent_phone,
    Agent_mail,
    mailsTo
  }: {
    Customer_name: string,
    Agency_name: string,
    Agent_phone: string,
    Agent_mail: string,
    locale: string
    orderId: string
    mailsTo: string[]
  }) =>{
    console.log("api start")
    return http.pdfPost(`/pdf/documents/toptoursResult/`, {
      isMail: true,
      Customer_name,
      Agency_name,
      Agent_phone,
      Agent_mail,
      locale,
      orderId,
      mailsTo
    })
  }

  return {
    sendPdfEmail,
    beginOrder,
    getDefaultWebsite,
    registrationNewCustomer,
    setContactPerson,
    addCustomerToOrderItem,
    startOrderPreReservation,
    startOrderReservation,
    getReservationStatus,
    addProductToOrder,
    addBaggageToCustomer,
    getCountriesList,
    getPaxType
  }
}
