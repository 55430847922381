
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ModalWindow from "ui-modules/modals/modal-content/modal-window/modal-window.vue";

@Component({
  components: {
    ModalWindow,
  }
})
export default class SearchModalNoResult extends Vue {
  @Prop(Boolean) isHideCloseBtn: boolean
  @Prop(Boolean) hideHRLine: boolean
}
