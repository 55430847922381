
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IPackageClass,
  IProductDetailsPaxComplectClass,
  IPackageProductDetails
} from 'applications/desktop/package-app/components/order-product/types/order-product.types'

@Component({})
export default class OrderCommonDetails extends Vue {
  @Prop(Object)
  currentPackage!: IPackageClass
  @Prop(Object)
  selectedPaxComplects!: {
    [productId: string]: IProductDetailsPaxComplectClass[]
  }
  @Prop(String)
  currentLocale!: string
  @Prop(String)
  agencyId!: string

  get productIds(): string[] {
    return Object.keys(this.selectedPaxComplects) || []
  }

  get details(): IPackageProductDetails {
    return this.productIds.length
      ? this.currentPackage.products.getProduct(this.productIds[0]).details
          .content
      : null
  }

  get remarks(): string {
    return this.details &&
      (this.details.remarks === '<empty>' || !this.details.remarks)
      ? String(this.$t('package-order.common-details.remarks.null'))
      : this.details.remarks
  }

  get supplements(): string {
    return this.details &&
      (this.details.supplements === '<empty>' || !this.details.supplements)
      ? String(this.$t('package-order.common-details.supplements.null'))
      : this.details.supplements
  }

  get restrictions(): string {
    return this.details &&
      (this.details.restrictions === '<empty>' || !this.details.restrictions)
      ? String(this.$t('package-order.common-details.restrictions.null'))
      : this.details.restrictions
  }

  get benefitList(): {} {
    return this.productIds.length
      ? this.currentPackage.products
          .getProduct(this.productIds[0])
          .prices.find(price => price.agency.agencyId === this.agencyId).gifts
      : []
  }

  get giftGetter(): string {
    return `gift${this.normalizedLocale}`
  }

  get normalizedLocale(): string {
    return (
      this.currentLocale.substr(0, 1).toUpperCase() +
      this.currentLocale.substr(1).toLowerCase()
    )
  }
}
