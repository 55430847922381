
import Vue from "vue";
import { Prop, Component, Ref, Watch } from "vue-property-decorator";
import BtnAlpha from "ui-modules/buttons/btn-alpha.vue";
import PaxPricesTooltip from "ui-modules/tooltips/pax-prices-tooltip.vue";
import RoomTypePaxSelector from "ui-modules/selectors/room-type-pax-selector/room-type-pax-selector.vue";
import PaxIconsList from 'ui-modules/icons/pax-icons/pax-icons-list.vue';
import Multiselect from "vue-multiselect";
import InfoIconSmall from 'ui-modules/svg-icon/info-icon-small.vue';

import isEqual from "lodash/isEqual";
import decimalPlace from "common/filters/decimalPlace";
import { mixin as clickaway } from 'vue-clickaway';
import { addCurrency } from 'common/filters/addCurrency';

import { IProductDetailsPaxComplectClass } from "applications/desktop/package-app/components/order-product/types/order-product.types";
import { TProductPaxType } from "modules/product-result.v2/data/package-result/package-result.product/package-result.product.types";
import InfoAboutOrderTooltip from "../../../../../../../ui-modules/tooltips/info-about-order-tooltip.vue";
import {
  PRODUCT_RESULT_MODULE,
  PRODUCT_SEARCH_MODULE
} from 'applications/desktop/package-compare-app/store/store'
import { PackageProductDetails } from "modules/product-result.v2/data/package-result/package-result.types"

@Component({
  components: {
    InfoAboutOrderTooltip,
    BtnAlpha,
    PaxPricesTooltip,
    RoomTypePaxSelector,
    Multiselect,
    PaxIconsList,
    InfoIconSmall
  },
  filters: {
    decimalPlace,
    addCurrency,
  },
  mixins: [clickaway]
})
export default class RoomsSelector extends Vue {
  @Prop(Array) paxComplects!: IProductDetailsPaxComplectClass[];
  @Prop(Array) selectedPaxComplects!: IProductDetailsPaxComplectClass[];
  @Prop(Array) preSelectedRoomType!: string[];
  @Ref("roomsMultiselect") readonly roomsMultiselect!: HTMLFormElement;

  @Watch('paxComplects')
  paxComplectsChange(newVal: any) {
    if (this.wasFirstChange) { // to avoid repeated room selection
      this.selectCheapestRoom()
    }
    this.wasFirstChange = true
  }

  itemToReselect: Partial<IProductDetailsPaxComplectClass> = {};
  itemToReselectIndex: number = -1;
  selectedRoomType: string[] = []
  isOpen: boolean = false
  wasFirstChange: boolean = false // This is a Boolean type!

  mounted() {
    this.selectedRoomType = this.preSelectedRoomType
  }

  get selectedComplectsCount(): number {
    return this.selectedPaxComplects ? this.selectedPaxComplects.length : 0
  }

  get paxTypeList() {
    let list: string[][] = this.paxComplects.map(item => item.paxList)
    list.unshift([])
    return list
  }

  get filteredPaxComplects() {
    if (this.selectedRoomType.length) {
      return this.paxComplects.filter(item => isEqual(item.paxList, this.selectedRoomType))
    }
    return this.paxComplects
  }

  get haveUnavailableRooms() {
    return this.selectedRoomsList.some((el) => el.isUnavailible)
  }

  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }

  get searchStore() {
    return PRODUCT_SEARCH_MODULE
  }
  
  get selectedRoomsList() {
    const newList: Partial<IProductDetailsPaxComplectClass[]> = []
    const selectedPaxComplectsCopy = [...this.selectedPaxComplects]
    selectedPaxComplectsCopy.forEach(item => {
      for (let i = 0; i < this.paxComplects.length; i++) {
        if (item.roomType === this.paxComplects[i].roomType
          && isEqual(item.price, this.paxComplects[i].price)) {
          item.isUnavailible = false
          item.roomId = this.paxComplects[i].roomId
          break
        }
        if (i === this.paxComplects.length - 1) {
          item.isUnavailible = true
        }
      }
      newList.push(item)
    })
    return newList
  }

  getTotalRoomPrice(item: IProductDetailsPaxComplectClass, index?: number): number {
    if (
       index === 0 ||
      (index === -1 && this.itemToReselectIndex === 0) // for the reselected item
    ) {
      return item.content.price.total;
    }
    return item.content.originalPrice.total
  }

  getAveragePricePerPerson(item: IProductDetailsPaxComplectClass): number {
    let personsCounter: number = 0;
    const paxesArray = Object.values(this.getPaxes(item));
    paxesArray.forEach((item) => {
      personsCounter += item.quantity;
    });
    return this.getTotalRoomPrice(item) / personsCounter;
  }

  getPaxes(item: IProductDetailsPaxComplectClass): TProductPaxType {
    const paxes: Partial<TProductPaxType> = {};
    item.content.price.paxPrices?.forEach((pax) => {
      if (paxes[pax.paxType]) {
        paxes[pax.paxType].total = ((parseFloat(paxes[pax.paxType].total) * paxes[pax.paxType].quantity + parseFloat(pax.total)*pax.quantity) / (paxes[pax.paxType].quantity+pax.quantity))
        paxes[pax.paxType].quantity+=pax.quantity
      } else {
        paxes[pax.paxType] = {
          quantity: pax.quantity,
          total: pax.total,
        };
      }
    });
    return paxes;
  }

  setItemToReselect(item: IProductDetailsPaxComplectClass, index: number) {
    this.itemToReselect = item;
    this.itemToReselectIndex = index;
  }
  unselectRoom(index: number) {
    const tempArray = [...this.selectedPaxComplects];
    tempArray.splice(index, 1)
    this.updatePaxComplects(tempArray)
  }
  addRoom() {
    this.itemToReselect = {};
    this.itemToReselectIndex = -1;
    this.toggleRoomsMultiselect()
  }

  selectRoom(item: IProductDetailsPaxComplectClass) {
    const tempArray: any = [...this.selectedPaxComplects]
    if (this.itemToReselectIndex >= 0) {
      tempArray.splice(this.itemToReselectIndex, 1)
    }
    tempArray.push(item);
    this.updatePaxComplects(tempArray)
  }

  selectCheapestRoom() {
    const actualPaxComplect = PackageProductDetails.getActualPaxComplect(
      this.resultStore.selectedPackage.selectedProduct.details?.paxComplects,
      this.searchStore.searchQuery.paxes
    )

    const sortedPaxComplects = this.filteredPaxComplects
      .filter(pc => isEqual(pc.paxList, actualPaxComplect.paxList))
      .sort((pc1, pc2) => Number(pc1.price.total) - Number(pc2.price.total))

    this.updatePaxComplects([sortedPaxComplects[0]])
  }

  updatePaxComplects(newPaxComplectsList: IProductDetailsPaxComplectClass[]) {
    this.$emit("update-pax-complects", newPaxComplectsList.filter(item =>  !item.isUnavailible));
  }

  onOverlayClick() {
    this.$emit('close')
    this.isOpen = false
  }

  toggleRoomsMultiselect() {
    if (this.roomsMultiselect) {
      this.roomsMultiselect.toggle();
      this.isOpen = true
    }
  }
}
