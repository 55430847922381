import Component, { mixins } from 'vue-class-component'

import {
  IOrderPost,
  IFirtStepQuery,
  IProductDetailsPaxComplectClass,
  TRoomsCustomer,
  TRoomsAdditions,
  Customer,
  TCustomersAdditions,
  IRoomCustomer,
  getSymbolNumericRandomKey,
  IAdditionalPaymentCounter,
  ICustomerResource,
  ADDRESS_BLANK,
  ICustomerClass,
  AddressesCollection,
  convertAddressResourceToIAddressResource, ICustomerAdditions
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

import OrderCommon from 'applications/desktop/package-compare-app/components/order/order.common'
import SearchResultsCommon from 'applications/desktop/package-compare-app/components/search-results/search-results.common'
import PrepareSearchDataCommon from 'applications/desktop/package-compare-app/components/prepare-search-data/prepare-search-data.common'
import OrderStepper from 'applications/desktop/package-compare-app/components/order/ui/stepper/stepper.vue'
import ModalBase from 'ui-modules/modals/modal-base.vue'
import cloneDeep from 'lodash/cloneDeep'
import {CustomerResource, PaymentTransactionStatus} from "be-structures/typescript-generator";
import { PackageProductDetails } from "modules/product-result.v2/data/package-result/package-result.types"
import {Action, Getter} from "vuex-class";
import i18n from "../../../../../modules/i18n/i18n.name";
import PRODUCT_ORDER from "../../../../../modules/product-order/product-order.name";
import {
  GetReservationRequirementsHandler,
  GoToPaymentHandler,
  IGoToPaymentPayload
} from "../../../../../modules/payment/types/payment.types";

@Component({
  components: {
    ModalBase,
    OrderStepper
  }
})
export default class OrderComp extends mixins(PrepareSearchDataCommon, SearchResultsCommon, OrderCommon) {
  async created() {
    const selectedPackage = this.resultStore.selectedPackage
    const actualPaxComplect = PackageProductDetails.getActualPaxComplect(this.resultStore.selectedPackage.selectedProduct.details?.paxComplects, this.searchQuery.paxes)

    if (!actualPaxComplect) {
      this.showBadPaxComplects()
    }

    this.orderStoreView.setOrderStep({ stepNumber: 1 })
    this.orderStoreView.setDestinationPackage(this.resultStore.selectedPackage)
    this.orderStoreView.selectBoarding({ boarding: this.resultStore.selectedPackage.selectedProduct.fareBasis })
    this.orderStoreView.setProductDetails(selectedPackage.selectedProduct.details)
    this.orderStoreView.selectPaxComplects({ paxComplects: actualPaxComplect ? [actualPaxComplect] : null })

    this.updateOrderPriceByAccumulatePaxComplectsPrice([actualPaxComplect])
    this.updateOriginalOrderPriceByAccumulatePaxComplectsPrice([actualPaxComplect])

    await Promise.all(selectedPackage.getProductsWithoutSelectedProduct().map(product => {
      return this.resultStore.getAndSetDetailsForPackageProduct({
        product,
        agencyId: this.orderStore.agencyId
      })
    }))

    this.orderStoreView.setDestinationPackage(this.resultStore.selectedPackage)
  }

  get getIntervalId() {
    return this.resultStore.intervalId
  }
  get orderPost(): IOrderPost {
    return {
      stepNumber: this.orderStoreView.orderStepNumber,
      destinationPackage: this.orderStoreView.destinationPackage,
      boarding: this.orderStoreView.boarding,
      availableBoardings: this.orderStoreView.destinationPackage.products.productsFareBasis,
      productDetails: this.orderStoreView.productDetails,
      order: this.order,
      formsIds: this.orderStoreView.formsIds,
      customers: this.orderStoreView.customers,
      owner: this.orderStoreView.owner,
      additions: this.orderStoreView.additions,
      orderTotalPrice: this.orderTotalPrice,
      orderPendings: this.orderStoreView.pendings,
      countries: this.orderStore.countries
    }
  }

  async beginOrder() {
    await this.orderStore.beginOrder({ agencyId: this.orderStore.agencyId })
    this.orderStoreView.clearOwner();
    this.orderStoreView.setPending({ pendingName: 'startOrderReservation', value: false })
  }

  changeServiceQuantity({ roomId, additions }: { roomId: string, additions: TCustomersAdditions }) {
    this.orderStoreView.selectAdditions({
      additions: {
        ...this.orderStoreView.additions,
        [roomId]: additions
      }
    })
  }

  updateStep(d: IFirtStepQuery) {
    this.orderStoreView.destinationPackage.selectedProduct = d.product
    this.orderStoreView.selectBoarding({ boarding: d.boarding })
    this.orderStoreView.selectPaxComplects({ paxComplects: d.paxComplects })
    this.updateOrderPriceByAccumulatePaxComplectsPrice(d.paxComplects)
    this.updateOriginalOrderPriceByAccumulatePaxComplectsPrice(d.paxComplects)
  }

  updateOrderPriceByAccumulatePaxComplectsPrice(paxComplects: IProductDetailsPaxComplectClass[]) {
    if(!paxComplects) return
    const { currency, total } = paxComplects.reduce((a, b, index) => {
      return {
        currency: b?.price?.currency ?? null,
        total: Number(a?.total) + Number(index === 0 ? b?.price?.total : b?.originalPrice?.total) ?? 0
      }
    }, { currency: null, total: 0 })

    this.order.totalPrice.currency = currency
    this.order.totalPrice.total = total
  }

  updateOriginalOrderPriceByAccumulatePaxComplectsPrice(paxComplects: IProductDetailsPaxComplectClass[]) {
    if(!paxComplects) return
    const { currency, total } = paxComplects.reduce((a, b) => {
      return {
        currency: b?.originalPrice?.currency ?? null,
        total: Number(a?.total) + Number(b?.originalPrice?.total) ?? 0
      }
    }, { currency: null, total: 0 })

    this.order.totalPriceOriginal.currency = currency
    this.order.totalPriceOriginal.total = total
  }

  showHotelInfo() {
    this.setModalPost({modalPost: null})
    this.openHotelCardModal(this.orderStoreView.destinationPackage.hotel)
  }

  selectStep(stepNumber: number) {
    switch (stepNumber) {
      case 0:
        this.$router.go(-1)
      case 2: {
        gtag('event', 'start_book', {
          event_category: 'User Actions',
          event_label: 'Start Booking',
          value: 1
        });
        this.goToTheSecondStep()
      }
      case 3:
        this.goToTheThirdStep()
    }

    this.orderStoreView.setOrderStep({ stepNumber })
  }

  initializeSecondStepEntities() {
    try {
      const customerAdditions: ICustomerAdditions = {
        additionalPayments: this.orderStoreView.productDetails.additionalPayments.map(ap => ({ ...ap, quantity: 0 }))
      }

      let customers: TRoomsCustomer = {}
      let additions: TRoomsAdditions = {}

      this.orderStoreView.setFormsIds({ formsIds: [] })
      this.orderStoreView.productDetails.paxComplects.map(pc => {
        const roomId = !!customers[pc.roomId] ? `${pc.roomId}_${getSymbolNumericRandomKey()}` : pc.roomId

        pc.paxList.map(pax => {
          const customer = new Customer()
          const roomCustomer: IRoomCustomer = {
            type: pax,
            customer: this.setDefaultCountryForCustomer({
              customer,
              countryCode: 'IL'
            })
          }

          customers = { ...customers, [roomId]: [ ...(customers[roomId] || []), roomCustomer ] }
          additions = { ...additions, [roomId]: { ...(additions[roomId]), [customer.customerKey]: cloneDeep(customerAdditions) }}
        })

        this.orderStoreView.setFormsIds({ formsIds: [ ...this.orderStoreView.formsIds, roomId ] })
      })

      this.orderStoreView.setCustomers(customers)
      this.orderStoreView.selectAdditions({ additions })
    } catch(e) {
      console.log('initialization error', e)
    }
  }


  async goToTheSecondStep() {
    const product = this.resultStore.selectedPackage.selectedProduct
    const productId = product.productId;
    const paxComplectId = product.details.complectsIds[0]
    await this.orderStore.getPaxType({productId, paxComplectId })
    await this.orderStore.getCountriesList()

    this.initializeSecondStepEntities()
  }

  updateOwner(ownerData: ICustomerResource) {
      this.orderStoreView.owner.content = ownerData
  }

  updateCustomer({ roomId, customer }: { roomId: string, customer: any }) {
    const index = this.orderStoreView.customers[roomId].findIndex(c => c.customer.customerKey === customer.customerKey)

    this.orderStoreView.customers[roomId][index].customer.content = customer
  }

  useCustomerAsOwner({ roomId, customerKey }: { roomId: string, customerKey: string }) {
    const customersInRoom = this.orderStoreView.customers[roomId]
    const isUseCustomerAsOwner : boolean = !!customerKey
    const keyForFindInCustomers = customerKey || this.orderStoreView.owner.customerKey
    const indexCustomerAsOwner = customersInRoom.findIndex(c => c.customer.customerKey === keyForFindInCustomers)

    const newCustomer = new Customer()
    const changedRoomCustomer = isUseCustomerAsOwner
      ? this.orderStoreView.owner
      : newCustomer

    console.log("useCustomerAsOwner", customersInRoom[indexCustomerAsOwner].customer,  changedRoomCustomer, 'IL')

    customersInRoom[indexCustomerAsOwner].customer = this.setDefaultCountryForCustomer({
      customer: changedRoomCustomer,
      countryCode: 'IL'
    })

    // Save baggage
    const currentAdditions = this.orderStoreView.additions
    const newAdditions = {
      ...currentAdditions
    }
    const currentRoom = newAdditions[roomId]
    const {[keyForFindInCustomers] : customer, ...roomWithoutCustomer} = currentRoom
    const newRoom = roomWithoutCustomer

    const tmp = currentRoom[keyForFindInCustomers]

    if (isUseCustomerAsOwner) {
      newRoom[this.orderStoreView.owner.customerKey] = tmp
    } else {
      newRoom[newCustomer.customerKey] = tmp
    }

    newAdditions[roomId] = newRoom
    this.orderStoreView.selectAdditions({additions: newAdditions})
    this.orderStoreView.setKeyOfCustomerAsOwner(customerKey)
  }

  getRoom(roomId: string) {
    return this.orderStoreView.productDetails?.paxComplects?.find(pc => roomId.indexOf(pc.roomId) !== -1)
  }

  getAdditionTotalPrice({ addition }: { addition: IAdditionalPaymentCounter }) {
    if (addition) {
      return {
        ...addition.price,
        amount: addition.quantity * addition.price.amount
      }
    }
  }

  getAdditionsTotalPrice({ additions }: { additions: IAdditionalPaymentCounter[] }) {
    if (additions.length > 0) {
      return additions.reduce((a, b) => {
        return a + this.getAdditionTotalPrice({ addition: b }).amount
      }, 0)
    }
  }

  setDefaultCountryForCustomer({
    customer,
    countryCode
  }: {
    customer: ICustomerClass,
    countryCode: string
  }) {
    if (!customer.addresses?.content.REGISTRATION.country) {
      const country = this.orderStore.countries?.find(country => {
        return country.code.toUpperCase() === countryCode.toUpperCase()
      })

      const updatedCustomer = cloneDeep(customer)
      updatedCustomer.addresses = new AddressesCollection([convertAddressResourceToIAddressResource({
        address: {
          ...ADDRESS_BLANK,
          country: country || null
        }
      })])

      console.log("setDefaultCountryForCustomer", updatedCustomer, country, this.orderStore.countries)

      return updatedCustomer
    }

    return customer
  }

  get orderTotalPrice() {
    let total = { currency: 'USD', amount: 0 }

    this.orderStoreView.formsIds.map((formId, index) => {
      const room = this.getRoom(formId)
      const price = room ? (index === 0 ? room.price : room.originalPrice) : null

      total = {
        ...total,
        amount:
          Number(total.amount) +
          Number(price ? price.getTotalPriceResource().amount : 0)
        }

      Object.values(this.orderStoreView.additions[formId]).map(addition => {
        total = {
          ...total,
          amount:
            Number(total.amount) +
            Number(this.getAdditionsTotalPrice({ additions: addition.additionalPayments }) || 0)
        }
      })
    })

    return total
  }

  async startOrderReservation() { // the adapted mobile version
    try {
      this.orderStoreView.setPending({ pendingName: 'startOrderReservation', value: true })

      this.setModalPost({modalPost: null})
      const orderId = this.order.orderId

      await this.orderStore.addProductToOrder({
        orderId,
        productId: this.resultStore.selectedPackage.selectedProduct.productId,
        complectIds: this.orderStoreView.productDetails.getPaxComplectsIds()
      })

      const preReservationStatus = await this.orderStore.getPreReservationStatus({ orderId })

      if (preReservationStatus === 'OK') {
        // ADD OWNER, CUSTOMERS
        for (const roomId of this.orderStoreView.formsIds) {
          for (const customer of this.orderStoreView.customers[roomId]) {
            let customerResource: CustomerResource = null

            if (customer.customer.customerKey === this.orderStoreView.keyOfCustomerAsOwner) {
              customerResource = await this.orderStore.registationNewOwnerAndAddToOrder({
                orderId,
                customer: {
                  ...customer.customer.convertToCustomerCreate(),
                  media: this.orderStoreView.owner.convertToCustomerCreate().media
                }
              })

              this.orderStore.addCustomerToOrderItem({
                orderId,
                itemId: this.orderStore.order.items[this.orderStoreView.formsIds.indexOf(roomId)].entityId,
                customerId: customerResource.customerId
              })
            } else {
              customerResource = await this.orderStore.registationNewCustomerAndAddToOrder({
                orderId,
                itemId: this.orderStore.order.items[this.orderStoreView.formsIds.indexOf(roomId)].entityId,
                customer: customer.customer.convertToCustomerCreate()
              })
            }

            // ADD ADDITIONAL (BAGGAGE OR SKI) FOR CUSTOMERS
            this.orderStoreView.additions[roomId][customer.customer.customerKey].additionalPayments.map(addition => {
              if (addition.quantity > 0) {
                for (let i = 0; i < addition.quantity; i++) {
                  this.orderStore.addBaggageToCustomer({
                    orderId,
                    itemId: this.orderStore.order.items[this.orderStoreView.formsIds.indexOf(roomId)].entityId,
                    customerId: customerResource.entityId,
                    additionalPaymentId: addition.id
                  })
                }
              }
            })
          }
        }

        await this.orderStore.startReservationStatus({ orderId })
        await this.orderStore.getReservationStatus({ orderId })

        try {
          const locale = this.currentLocale;
          const mailsTo: string[] = [this.orderStoreView.owner.media.mail.value]
          const emailPayload = {
            locale,
            orderId,
            mailsTo,
            Customer_name: this.owner.fullName
          };
          await this.orderStore.sendPdfResult(emailPayload)
        } catch (e) {
          console.log(e)
        }
          const payPayload = {
            orderId,
            ccOwnerType: 'CUSTOMER'
          }
          const paymentResult =  await this.$store.dispatch("PAYMENT/goToPayment", payPayload)

        if (this.paymentStatus === 'PAID') {
          this.selectStep(3)
        }
      }
    } catch(e) {
      console.log('error', e)
    } finally {
      this.orderStoreView.setPending({ pendingName: 'startOrderReservation', value: false })
    }
  }

    async repeatPayment() {
      this.orderStoreView.setPending({ pendingName: 'startOrderReservation', value: true })
      try {
        const payPayload = {
          orderId: this.order.orderId,
          ccOwnerType: 'CUSTOMER'
        }
        const paymentResult = await this.$store.dispatch("PAYMENT/goToPayment", payPayload)
        if (this.paymentStatus === 'PAID') {
          this.selectStep(3)
        }
      } catch (e) {
        console.log('error', e)
      } finally {
        this.orderStoreView.setPending({ pendingName: 'startOrderReservation', value: false })
      }
    }

    @Getter("PAYMENT/paymentStatus")
    paymentStatus: PaymentTransactionStatus

  @Getter(`${i18n}/currentLocale`)
  currentLocale: string
  closeBadHotel() {
    this.$router.go(-1)
    this.setModalPost({modalPost: null})
  }

  showBadPaxComplects() {
    const modalPost = {
      component: 'bad-pax-complects',
      preventDefaultClose: true
    }
    this.setModalPost({modalPost})
  }

  showMFinishBookingModal() {
    const modalPost = {
      component: 'finish-booking',
      preventDefaultClose: true
    }
    this.setModalPost({modalPost})
  }

  showCancellationTermsModal() {
    const modalPost = {
      component: 'cancellation-terms',
      preventDefaultClose: true
    }
    this.setModalPost({modalPost})
  }

  showRemarksModal(params) {
    const modalPost = {
      component: 'remarks',
      preventDefaultClose: true,
      selectedPaxComplects: params.selectedPaxComplects,
      productRemarks: params.productRemarks,
    }
    this.setModalPost({modalPost})
  }


  goToTheThirdStep() {
    if(this.getIntervalId) {
      this.resultStore.deleteTimeout({intervalId: this.getIntervalId})
    }
  }
}
