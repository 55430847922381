
import Vue from 'vue'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'

import {
  PriceCalendarPeriodResource
} from 'be-structures/typescript-generator/assembly'

import {
  IStartEnd,
  TFlightGroups,
  IPriceCalendarPeriod
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import Datepicker from 'ui-modules/datepicker/datepicker.vue'
import mathCeil from 'common/filters/mathCeil'
import { addCurrency } from 'common/filters/addCurrency'
import {PRODUCT_SEARCH_MODULE} from "../../applications/desktop/package-compare-app/store/store";
import BoxLoader from "../marker-loader/marker-loader.vue";
import {isEmpty} from "lodash";

@Component({
  components: {
    BoxLoader,
    Datepicker
  },
  filters: {
    mathCeil,
    addCurrency
  }
})
export default class PriceDatepicker extends Vue {
  @Prop(String) locale!: string
  @Prop(Object) value!: IStartEnd<string>
  @Prop(Object) departureDates!: IPriceCalendarPeriod
  @Prop(Object) returnDates!: IPriceCalendarPeriod
  @Prop(Boolean) getAndSetReturnDatesByCountry!: boolean
  @Prop(Boolean) getAndSetDepartureDatesByCountry!: boolean

  @Ref('priceDatepicker') readonly priceDatepicker!: HTMLFormElement

  @Watch('firstAvaliableDate')
  check() {
    this.moveToFirstAvaliableDate()
  }
  get showCalendarDays(): boolean {
    return this.relevantMonthNumber
        && !this.getAndSetReturnDatesByCountry
        && !this.getAndSetDepartureDatesByCountry
  }

  get showCalendar() {
    return !this.getAndSetReturnDatesByCountry && !this.getAndSetDepartureDatesByCountry
  }

  mode: TFlightGroups = 'departures'
  relevantMonthNumber: number = dayjs().month() + 1
  dateValue: IStartEnd<string> = {
    start: null,
    end: null
  }

  created() {
    if (this.value) {
      this.dateValue = this.value

      if (this.value.start && !this.value.end) { // show return dates only when the departure date is selected
        this.mode = 'returns'
      }
    }
  }

  async mounted() {
    await this.moveToFirstAvaliableDate()
  }

  get directionPropertyName(): string {
    return this.mode === 'departures' ? 'departureDate' : 'returnDate'
  }

  get directionDates() {
    if (!this.getAndSetReturnDatesByCountry) {
      return this.mode === 'departures' ? this.departureDates : this.returnDates
    }
    return []
  }

  get firstAvaliableDate(): string {
    if (this.directionDates) {
      if (this.relevantMonthDates) {
        return Object.keys(this.relevantMonthDates)[0]
      }

      const avaliableMonthsNumbers = Object.keys(this.directionDates).filter(monthNumber => +monthNumber > this.relevantMonthNumber)

      if (avaliableMonthsNumbers.length > 0) {
        return Object.keys(this.directionDates[avaliableMonthsNumbers[0]])[0]
      }
    }
  }

  get relevantMonthDates() {
    if (this.directionDates) {
      return this.directionDates[this.relevantMonthNumber]
    }
    return []
  }

  get disabledDaysInMonth() {
    const relevantDate = dayjs().set('month', this.relevantMonthNumber - 1)
    const countDaysInMonth = relevantDate.daysInMonth()
    const allDaysInMonth = Array.from({ length: countDaysInMonth }, (day, i) => i + 1)

    if (this.relevantMonthDates) {
      const datesInMonth = Object.keys(this.relevantMonthDates)

      if (datesInMonth.length > 0) {
        return datesInMonth.reduce((result, date) => {
          const removeIndex = result.indexOf(dayjs(date).date())

          if (removeIndex !== -1) {
            result.splice(removeIndex, 1)
          }

          return result
        }, allDaysInMonth)
      }
    }

    return allDaysInMonth
  }

  get daysWithCheapestPrice() {
    const relevantMonthData: PriceCalendarPeriodResource[] = Object.values(this.relevantMonthDates || {})

    if (relevantMonthData.length > 0) {
      return relevantMonthData
        .sort((a, b) => Number(a.minPrice.amount) - Number(b.minPrice.amount))
        .filter((data, index, self) => self[0].minPrice.amount === data.minPrice.amount)
        // @ts-ignore
        .map(data => dayjs(data[this.directionPropertyName]).date()) || []
    }

    return []
  }

  dayWithCheapestPrice(day: number) {
    return this.daysWithCheapestPrice.includes(day)
  }

  updateRelevantMonthNumber(data: { month: number }) {
    this.relevantMonthNumber = data.month
  }

  moveToDate(date: string) {
    if (this.priceDatepicker) {
      this.relevantMonthNumber = dayjs(date).month() + 1
      return this.priceDatepicker.datepicker.move(date)
    }
  }

  moveToFirstAvaliableDate() {
    if (this.firstAvaliableDate) {
      this.moveToDate(this.firstAvaliableDate)
    }
  }

  getAvaliablePrice(day: any) {
    if(!isEmpty(this.directionDates)) {
      return this.directionDates[day.month]?.[day.id]?.minPrice?.amount ?? null
    }
    return null
  }

  onInput(date: IStartEnd<string>) {
    this.dateValue = date

    if (date.start && !date.end) {
      const dateObject = this.directionDates[this.relevantMonthNumber][date.start]
      this.$emit('get-return-dates', dateObject)
      this.mode = 'returns'
    }

    this.$emit('input', this.dateValue)
  }

  resetDatepicker() {
    this.mode = 'departures'

    this.priceDatepicker.dateValue = {
      start: null,
      end: null
    }

    this.dateValue = {
      start: null,
      end: null
    }
  }
}
