
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class RadioGroup extends Vue {
  @Prop({ type: Array, default: [] }) items!: string[]
  @Prop({ type: String, default: null }) value!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) inARow!: boolean
  @Prop({ type: Function, default: null }) radioLabel!: (item: string) => string
  @Prop({ type: String, default: null }) errorMessages!: string
  @Prop({ type: String, default: null }) persistentHint!: string
}
