
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class Selector extends Vue {
  @Prop(Array)
  list!: string[] | number[]
  @Prop(Array)
  storeListValue!: string[] | number[]
  @Prop(String)
  typeSelector!: string
  @Prop({ type: Boolean, default: false })
  disabled!: boolean
  @Prop(Boolean)
  isMandatory!: boolean

  @Watch('storeListValue')
  watchChanges(current: string[], prev: string[]) {
    if (prev[0] !== current[0]) {
      this.isChanged = true
    }
  }

  private _isOpen: boolean = false
  private _isChanged: boolean = false

  get selectorTranslateType(): string {
    switch (this.typeSelector) {
      case 'Day':
        return 'placeholders'
      case 'Year':
        return 'placeholders'
      case 'Airline':
        return 'none'
      case 'Frequent flyer number':
        return 'placeholders'
      case 'Month':
        return 'placeholders'
      case 'flightType':
        return 'default'
      case 'flightClass':
        return 'default'
      case 'Gender':
        return 'default'
      case 'meals':
        return 'default'
      case 'assistances':
        return 'default'
      default:
        return 'none'
    }
  }

  get isChanged(): boolean {
    return this.$data._isChanged
  }

  set isChanged(val: boolean) {
    this.$data._isChanged = val
  }

  get isOpen(): boolean {
    return this.$data._isOpen
  }

  set isOpen(val: boolean) {
    this.$data._isOpen = val
  }

  getNormalizedName(str: string): string {
    return str
      .toLowerCase()
      .split(' ')
      .join('')
  }

  closeSelector(): void {
    if (this.isOpen) {
      document.removeEventListener('click', this.closeSelector)
    } else {
      this.$emit('open')
    }
    this.isOpen = !this.isOpen
  }

  openMultiply(): void {
    if (!this.disabled) {
      document.addEventListener('click', this.closeSelector)
    }
  }

  onChangeListElement(listElement: number | string) {
    this.$emit('input', { type: this.typeSelector, value: listElement })
    document.addEventListener('click', this.closeSelector)
  }
}
