import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import dayjs from 'dayjs'

import {
  IPackageClass,
  IPackageHotelFacility,
  TAppMode,
  IPackageCompareQuery,
  TPageableHotelsSort,
  TPackageCompareAppPendings,
  PackageCompareAppPendingNames
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import {
  formatDateToYMD, getThursdayDates
} from 'common/filters/formattedDates'
import http from 'common/http/http'
import funcAPI from './package-compare-app.api'
import { VacationTerm } from 'be-structures/typescript-generator'

let api = funcAPI(http)

@Module({ name: 'PACKAGE_COMPARE_DESKTOP', namespaced: true })
export default class extends VuexModule {
  private _appMode: TAppMode = 'NORMAL'
  private _defaultHotelsSort: TPageableHotelsSort[] = [{ PRICE: 'ASC' }]
  private _replacedPackage: IPackageClass = null
  private _indexOfSelectedComparisonColumn: number = null
  private _disabledFacilities: IPackageHotelFacility[] = []
  private _showHotelModal: boolean = false
  private _vacationTermBlank: IPackageCompareQuery = {
    dealFeatures: ['BEACH', 'CITY'],
    dateInterval: getThursdayDates(),
    paxes: {
      'ADULT': 2,
      'CHILD': 0,
      'INFANT': 0
    },
    exactDates: false // TODO make false after BE fix range dates
  }
  private _pendings: TPackageCompareAppPendings = {
    getAndSetVacationTerm: false
  }

  get appMode() {
    return this._appMode
  }
  get defaultHotelsSort() {
    return this._defaultHotelsSort
  }
  get replacedPackage() {
    return this._replacedPackage
  }
  get indexOfSelectedComparisonColumn() {
    return this._indexOfSelectedComparisonColumn
  }
  get disabledFacilities() {
    return this._disabledFacilities
  }
  get showHotelModal() {
    return this._showHotelModal
  }
  get vacationTermBlank() {
    return this._vacationTermBlank
  }

  @Mutation
  setAppMode({ appMode }: { appMode: TAppMode }) {
    this._appMode = appMode
  }

  @Mutation
  setDisplayForModal({ show }: { show: boolean }) {
    this._showHotelModal = show
  }

  @Mutation
  setReplacedPackage(replacedPackage: IPackageClass) {
    this._replacedPackage = replacedPackage
  }

  @Mutation
  setIndexForSelectedComparisonColumn({ index }: { index: number }) {
    this._indexOfSelectedComparisonColumn = index
  }

  @Mutation
  disableFacility({ facility }: { facility: IPackageHotelFacility }) {
    this._disabledFacilities.push(facility)
  }

  @Mutation
  enableFacility({ facility }: { facility: IPackageHotelFacility }) {
    const index = this._disabledFacilities.findIndex(df => df.id === facility.id)

    if (index !== -1) {
      this._disabledFacilities.splice(index, 1)
    }
  }

  @Mutation
  setPending({ pendingName, value }: { pendingName: keyof typeof PackageCompareAppPendingNames, value: boolean }) {
    this._pendings[pendingName] = value
  }

  @Mutation
  setVacationTermBlank({ vacationTerm }: { vacationTerm: VacationTerm }) {
    this._vacationTermBlank = {
      dateInterval: {
        end: vacationTerm.returnTill,
        start: vacationTerm.departureSince
      },
      exactDates: vacationTerm.exactDates,
      dealFeatures: vacationTerm.vacationTypes,
      paxes: {
        'ADULT': vacationTerm.adults || 0,
        'CHILD': vacationTerm.children || 0,
        'INFANT': vacationTerm.infants || 0,
      }
    }
  }
  
  @Action
  async getAndSetCachedVacationTerm() {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetVacationTerm', value: true })
      const vacationTerm: VacationTerm = await api.getCachedVacationTerm()
      this.context.commit('setVacationTermBlank', { vacationTerm })
    }
    finally {
      this.context.commit('setPending', { pendingName: 'getAndSetVacationTerm', value: false })
    }
  }
}
