
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcons from 'ui-modules/icons/icon'

import { addCurrency } from 'common/filters/addCurrency'

import {
  IProductDetailsPaxComplectClass,
  IPackageClass
} from 'applications/desktop/package-app/components/order-product/types/order-product.types'

// @ts-ignore
import * as moment from 'moment'

@Component({
  components: {
    TlIcons
  },
  filters: {
    addCurrency
  },
})
export default class ReservationResult extends Vue {
  @Prop(Object)
  post!: {
    customers: any[]
    orderNumber?: any
    pnrNumber?: any
    product: any
    productPrice: any
  }
  @Prop(Object)
  paxComplects!: { [productId: string]: IProductDetailsPaxComplectClass[] }
  @Prop(Object)
  currentPackage!: IPackageClass

  get duration(): number {
    const arrDate: string = this.post.product.segmentGroups[0].segments[0]
      .arrival.time
    const retDate: string = this.post.product.segmentGroups[1].segments[0]
      .departure.time

    return moment(retDate).diff(arrDate, 'days')
  }

  get provider(): string {
    return this.post.product.provider.name
  }

  get airline(): string {
    return this.post.product.validatingCarrier.name
  }

  get flightNumber(): string {
    return (
      this.post.product.segmentGroups[0].segments[0].operatingAirline.code +
      this.post.product.segmentGroups[0].segments[0].operatingFlightNumber
    )
  }

  get hotel(): string {
    return this.post.product.hotel.name
  }

  get hotelStars(): number {
    return Math.floor(this.post.product.hotel.stars)
  }

  get boardBasis(): string {
    return this.post.product.fareBasis
  }

  get depDate(): string {
    return moment(
      this.post.product.segmentGroups[0].segments[0].departure.time
    ).format('DD.MM.YYYY, HH:mm')
  }

  get retDate(): string {
    return moment(
      this.post.product.segmentGroups[1].segments[0].arrival.time
    ).format('DD.MM.YYYY, HH:mm')
  }

  get passengersCount(): number {
    return this.post.customers.length
  }

  get totalPrice(): number | string {
    const paxComplectsForSelectedProduct = this.paxComplects[this.currentPackage.selectedProduct.content.productId]
    let total: number = 0

    if (paxComplectsForSelectedProduct) {
      paxComplectsForSelectedProduct.map(pc => {
        total += pc.content.price.stages[0].totalPrice.fare.formattedPrice
      })
    }

    return total
  }
}
