import { ValidationRuleset } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import {
  IAddresses,
  ICustomerInfo,
  IPassport
} from 'applications/desktop/package-app/types/package-app.types'

export interface ICustomerInput {
  customerInfo: {
    lastName: ICustomerInfo['lastName']
    firstName: ICustomerInfo['firstName']
    middleName: ICustomerInfo['middleName']
    gender: string
    birthday: {
      year: number
      month: number
      day: number
      formated?: string
    }
  }
  addresses: {
    country: IAddresses['country']
  }
  passport: {
    passportNumber: IPassport['passportNumber']
    passportExpirationDate: {
      year: number
      month: number
      day: number
      formated?: string
    }
  }
  [key: string]: {}
}

export const validations: ValidationRuleset<{
  customerData: { customerInfo: ICustomerInput }
}> = {
  customerData: {
    addresses: {
      country: { required }
    },
    customerInfo: {
      birthday: {
        day: { required },
        month: { required },
        year: { required }
      },
      firstName: {
        required
      },
      gender: {
        required
      },
      lastName: {
        required
      },
      middleName: {}
    },
    passport: {
      passportExpirationDate: {
        day: { required },
        month: { required },
        year: { required }
      },
      passportNumber: {
        required
      }
    }
  }
}
