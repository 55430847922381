
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import TlIcon from 'ui-modules/icons/icon'
import isRTL from 'common/isRTL'

import { IPackageHotelClass } from 'applications/desktop/package-app/components/order-product/types/order-product.types'

@Component({
  components: {
    TlIcon,
  }
})
export default class HotelPhotoGallery extends Vue {
  @Prop(Object) hotel!: IPackageHotelClass

  slidePosition: number = 0

  get imagesCount() {
    return this.hotel.getImages().length
  }

  get galleryArrowsSortByDirection() {
    const galleryArrows: string[] = ['arrow-left', 'arrow-right']
    return isRTL() ? galleryArrows.reverse() : galleryArrows
  }

  get galleryShift() {
    const offset = (100 / 4) * this.slidePosition
    return isRTL() ? offset : -offset
  }

}

