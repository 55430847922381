
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ModalWindow extends Vue {
  @Prop(String) icon: string
  @Prop(Boolean) customFooter: boolean
  @Prop({ type: Boolean, default: false }) withDontShowCheckbox!: boolean
  @Prop({ type: Boolean, default: false }) withCloseBtn!: boolean
  @Prop({ type: Boolean, default: false }) hideHRLine!: boolean
  @Prop({ type: Boolean, default: false }) large!: boolean

  dontShow: boolean = false

  get isAvailableSlot() {
    return !!this.$slots['icon']
  }
}
