export default (num: number) => {
  let lookup = { X: 10, IX: 9, V: 5, IV: 4, I: 1 }
  let roman: string = '';
  for (let i in lookup ) {
    while ( num >= lookup[i] ) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}
