
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import FilterGroup from "ui-modules/products-filter/filter.group.vue"
import FilterConditionSlider from 'ui-modules/products-filter/filter.condition-slider.vue'

import {
  IFilterSliderCondition,
  IResultPost,
  ISliderWithMarks
} from "ui-modules/products-filter/types/products-filter.types"
import {
  IRegularFilterPost
} from "applications/desktop/package-compare-app/components/search-results/types/search-results.types";

@Component({
  components: {
    FilterGroup,
    FilterConditionSlider
  }
})
export default class FilterDepartureTime extends Vue {
  @Prop(Object)
  post!: IRegularFilterPost

  @Watch('post.packageFilter.outDeparture')
  onOutDepartureChange(newValue: number[]) {
    this.formattingOutDepartureMin =
      this.outDeparturePost.filter.convertMinutesToHHmm(newValue[0])

    this.formattingOutDepartureMax =
      this.outDeparturePost.filter.convertMinutesToHHmm(newValue[1])  
  }

  @Watch('post.packageFilter.inDeparture')
  onInDepartureChange(newValue: number[]) {
    this.formattingInDepartureMin =
      this.inDeparturePost.filter.convertMinutesToHHmm(newValue[0])

    this.formattingInDepartureMax =
      this.inDeparturePost.filter.convertMinutesToHHmm(newValue[1])
  }


  get marksPost(): ISliderWithMarks {
    return {
      interval: 360,
      range: this.post.packageInitialFilter.inDeparture,
      titles: ['night', 'morning', 'day', 'evening', 'night']
    }
  }

  formattingInDepartureMin =
    this.inDeparturePost.filter.convertMinutesToHHmm(this.inDeparturePost.value[0])

  formattingInDepartureMax =
    this.inDeparturePost.filter.convertMinutesToHHmm(this.inDeparturePost.value[1])


  setVisibleInDepartureValue(partOfFilter: [number, number]) {
    const [minValue, maxValue] = partOfFilter
    this.formattingInDepartureMin = this.inDeparturePost.filter.convertMinutesToHHmm(minValue)
    this.formattingInDepartureMax = this.inDeparturePost.filter.convertMinutesToHHmm(maxValue)
  }
  setFilterValue(partOfFilter: {type: string, value: [number, number]}, typeFilter: 'inDepartureFilter' | 'outDepartureFilter') {
    if(typeFilter === "inDepartureFilter") {
      this.setVisibleInDepartureValue(partOfFilter.value)
    } else {
      this.setVisibleOutDepartureValue(partOfFilter.value)
    }
    this.$emit('set-filter', partOfFilter)
  }
  get inDeparturePost(): IFilterSliderCondition {
    return {
      filter: this.post.packageFilter,
      marks: this.marksPost,
      value: this.post.packageFilter.inDeparture,
      valueLimits: this.post.packageInitialFilter.inDeparture,
      type: 'inDeparture',
      direction: this.post.direction
    }
  }
  formattingOutDepartureMin =
    this.outDeparturePost.filter.convertMinutesToHHmm(this.outDeparturePost.value[0])

  formattingOutDepartureMax =
    this.outDeparturePost.filter.convertMinutesToHHmm(this.outDeparturePost.value[1])


  setVisibleOutDepartureValue(partOfFilter: [number, number]) {
    const [minValue, maxValue] = partOfFilter
    this.formattingOutDepartureMin = this.outDeparturePost.filter.convertMinutesToHHmm(minValue)
    this.formattingOutDepartureMax = this.outDeparturePost.filter.convertMinutesToHHmm(maxValue)
  }
  get outDeparturePost(): IFilterSliderCondition {
    return {
      filter: this.post.packageFilter,
      marks: this.marksPost,
      value: this.post.packageFilter.outDeparture,
      valueLimits: this.post.packageInitialFilter.outDeparture,
      type: 'outDeparture',
      direction: this.post.direction
    }
  }
}
