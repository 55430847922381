var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"control slider"},[_c('vue-slider',{ref:"slider",attrs:{"value":_vm.value,"width":"85%","tooltip":_vm.showMergeTooltip ? 'none' : _vm.tooltip,"height":2,"dotSize":14,"dotStyle":{
      'backgroundColor': '#53b8d4'
    },"processStyle":{
      'background': '#1d63a2'
    },"tooltipStyle":{
      'backgroundColor': 'transparent',
      'color': '#000',
      'borderColor': 'transparent',
      'marginBottom': '-8px'
    },"labelStyle":{
      'cursor': 'pointer',
    },"silent":true,"direction":_vm.post.direction,"lazy":true,"min":_vm.post.valueLimits[0],"max":_vm.post.valueLimits[1],"tooltip-formatter":value => _vm.getFormatter(value),"marks":_vm.marks,"included":_vm.sliderWithMarks},on:{"dragging":value => _vm.dragSlider(value),"change":value => _vm.changeSlider(value)},scopedSlots:_vm._u([{key:"process",fn:function({ start, end, style }){return [_c('div',{staticClass:"vue-slider-process",style:(style)},[(_vm.showMergeTooltip)?_c('div',{staticClass:"merge-tooltip vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top"},[_vm._v("\n          "+_vm._s(_vm.mergedTooltip)+"\n        ")]):_vm._e()])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }