
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ContactPersonDigest extends Vue {
  @Prop(Object) person!: any;

  get showDigest() {
    return this.person.fullName || this.person.phone || this.person.email
  }
}
