import { render, staticRenderFns } from "./best-deal-results.vue?vue&type=template&id=a0dead06"
import script from "./best-deal-results.vue?vue&type=script&lang=ts"
export * from "./best-deal-results.vue?vue&type=script&lang=ts"
import style0 from "./best-deal-results.vue?vue&type=style&index=0&id=a0dead06&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports