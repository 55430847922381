import cloneDeep from 'lodash/cloneDeep'

import type {
  AddressResource
} from 'be-structures/typescript-generator/assembly'

import {
  IAddressResource,
  ADDRESS_BLANK
} from 'modules/common/common.types'

export const convertAddressResourceToIAddressResource = (({ address }: { address?: AddressResource }): IAddressResource => {
  const convertedAddress = cloneDeep(ADDRESS_BLANK)

  if (address) {
    for (const item of Object.keys(ADDRESS_BLANK)) {
      convertedAddress[item] = address[item]
    }
  }
  
  return convertedAddress
})

export const convertIAddressResourceToRequest = (({ address }: { address?: IAddressResource }): any => {
  return {
    ...address,
    country: address.country.code
  }
})