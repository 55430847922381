import {
  PaxComplects,
  PriceResource
} from 'be-structures/typescript-generator/assembly';

import {
  IPackageProduct,
  IPackageProductClass,
  IPackageProductDetailsClass,
  IStatPackageSearchItemResource,
  TProductPaxType,
  PackageProductPrice,
  setClassName
} from 'modules/product-result.v2/data/product-result.types'

export default class PackageProduct implements IPackageProductClass, IStatPackageSearchItemResource {
  private _details: IPackageProductDetailsClass
  private _prices: PriceResource[] = []

  constructor(private _product: IPackageProduct) {
    setClassName(PackageProduct.name, this)

    // @ts-ignore
    this._product.price = new PackageProductPrice(_product.price)

  }

  get content(): IPackageProductClass['content'] {
    return this._product
  }
  get validatingCarrier(): IStatPackageSearchItemResource['validatingCarrier'] {
    return this._product.validatingCarrier
  }
  get supplierAirline(): IStatPackageSearchItemResource['validatingCarrier'] {
    return this._product.validatingCarrier
  }
  get codeShareCarrier(): IStatPackageSearchItemResource['codeShareCarrier'] {
    return this._product.codeShareCarrier
  }
  get segmentGroups(): IStatPackageSearchItemResource['segmentGroups'] {
    return this._product.segmentGroups
  }
  get fareBasis(): IStatPackageSearchItemResource['fareBasis'] {
    return this._product.fareBasis
  }
  get provider(): IStatPackageSearchItemResource['provider'] {
    return this._product.provider
  }
  get hotel(): IStatPackageSearchItemResource['hotel'] {
    return this._product.hotel
  }
  get roomType(): IStatPackageSearchItemResource['roomType'] {
    return this._product.roomType
  }
  get dealFeatures(): IStatPackageSearchItemResource['dealFeatures'] {
    return this._product.dealFeatures
  }
  get productId(): IStatPackageSearchItemResource['productId'] {
    return this._product.productId
  }
  get supplier(): IStatPackageSearchItemResource['supplier'] {
    return this._product.supplier
  }
  get prices() {
    return this._prices
  }
  get details(): IPackageProductDetailsClass {
    return this._details
  }
  get nights(): IPackageProduct['nights'] {
    return this._product.nights
  }
  get score(): IPackageProduct['score'] {
    return this._product.score
  }
  get scores(): IPackageProduct['scores'] {
    return this._product.scores
  }
  get hasTransfer(): boolean {
    // TODO: bad interface with CITY_PACKAGE_INCLUDING_TRANSFER
    return this._product.dealFeatures.includes(
      'CITY_PACKAGE_INCLUDING_TRANSFER'
    )
  }
  get departureSegments() {
    return this._product.segmentGroups[0].segments
  }
  get returnSegments() {
    return this._product.segmentGroups[1].segments
  }
  get price() {
    return this._product.price
  }


  set details(value: IPackageProductDetailsClass) {
    this._details = value
  }
  set validatingCarrier(value: IStatPackageSearchItemResource['validatingCarrier']) {
    this._product.validatingCarrier = value
  }
  set supplierAirline(value: IStatPackageSearchItemResource['validatingCarrier']) {
    this._product.validatingCarrier = value
  }
  set codeShareCarrier(value: IStatPackageSearchItemResource['codeShareCarrier']) {
    this._product.codeShareCarrier = value
  }
  set segmentGroups(value: IStatPackageSearchItemResource['segmentGroups']) {
    this._product.segmentGroups = value
  }
  set fareBasis(value: IStatPackageSearchItemResource['fareBasis']) {
    this._product.fareBasis = value
  }
  set provider(value: IStatPackageSearchItemResource['provider']) {
    this._product.provider = value
  }
  set hotel(value: IStatPackageSearchItemResource['hotel']) {
    this._product.hotel = value
  }
  set roomType(value: IStatPackageSearchItemResource['roomType']) {
    this._product.roomType = value
  }
  set dealFeatures(value: IStatPackageSearchItemResource['dealFeatures']) {
    this._product.dealFeatures = value
  }
  set productId(value: IStatPackageSearchItemResource['productId']) {
    this._product.productId = value
  }
  set supplier(value: IStatPackageSearchItemResource['supplier']) {
    this._product.supplier = value
  }
  set nights(value: IPackageProduct['nights']) {
    this._product.nights = value
  }

  addToPricesList(price: PriceResource[]): void {
    this._prices = price
  }

  isFastOk(): boolean {
    return this._product.dealFeatures.indexOf('FAST_OK') !== -1 ? true : false
  }

  isCarIncluded(): boolean {
    return this._product.dealFeatures.indexOf('CAR_INCLUDED') !== -1
      ? true
      : false
  }
  isDiscountTotal(): boolean {
    return this.getTotalPrice() > this.getDiscountTotalPrice()
  }

  getHotelPaxes(): TProductPaxType {
    const paxes: TProductPaxType = {}
    for (const pax of this._product.price.paxPrices) {
      paxes[pax.paxType] = {
        quantity: pax.quantity,
        total: Number(pax.total)
      }
    }
    return paxes
  }

  getTotalPrice(): number {
    return Number(this._product.originalPrice.total)
  }
  getDiscountTotalPrice(): number {
    return Number(this._product.price.total)
  }
  getPerOnePrice(): number {
    return Number(this._product.originalPrice.pricePerOne?.amount) || 0
  }
  getPerOnePriceDiscount(): number {
    return Number(this._product.price.pricePerOne?.amount) || 0
  }

  getQuantityOfPaxes(): number {
    let quantity : number = 0
    this._product.price.paxPrices.forEach(item => {
      quantity += item.quantity
    })
    return quantity
  }
}
