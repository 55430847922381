enum HotelsSort {
  'PRICE' = 'PRICE',
  'NAME'='NAME',
  'DISTANCE_TO_CITY_CENTER' = 'DISTANCE_TO_CITY_CENTER'
}

enum SortValues {
  'ASC' = 'ASC',
  'DESC' = 'DESC'
}

export {
  HotelsSort,
  SortValues
}
