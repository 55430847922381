
import ModalWindow from "../modal-window/modal-window";
import Vue from "vue";
import { Component} from "vue-property-decorator";
import {PRODUCT_RESULT_MODULE} from "../../../../applications/desktop/package-compare-app/store/store";
import {Getter} from "vuex-class";
import i18n from "../../../../modules/i18n/i18n.name";
import mathCeil from "../../../../common/filters/mathCeil";
import {addCurrency} from "common/filters/addCurrency";

@Component({
  components: {
    ModalWindow
  },
  filters: {
    mathCeil,
    addCurrency
  }
})
export default class ModalReferralGreetings extends Vue{
  @Getter(`${i18n}/currentLocale`)
  currentLocale: string
  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }
  get referralRule() {
    return this.resultStore.referralRule
  }


  get getWelcomeText() {
    if(this.referralRule?.condition?.childConditions.length > 0 && this.referralRule?.condition?.childConditions[0]?.welcomeMessage) {
      return this.referralRule?.condition?.childConditions[0]?.welcomeMessage[this.currentLocale.toLocaleUpperCase()]
    }
    else {
      return this.$t('common.referral.no-message')
    }
  }
  get price(): string {
    const ruleInterest = this.referralRule.markup.interest;
    if (!ruleInterest.absolute) {
      return ruleInterest.percentage + `%`
    }
    const amount =  this.referralRule.markup.interest.money.amount.toString().replace('-','')

    return  addCurrency(amount)
  }
  cancel() {
    this.resultStore.setModalPost({
      modalPost: null
    })
  }
}
