import Vue from 'vue'
import Component from 'vue-class-component'

import {
  i18n_MODULE,
  PACKAGE_COMPARE_DESKTOP_MODULE, PRODUCT_RESULT_MODULE
} from 'applications/desktop/package-compare-app/store/store'

import {
  AppMode, IModalPost
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import Accordion from 'ui-modules/accordion/accordion.vue'
import BoxLoader from 'ui-modules/marker-loader/marker-loader.vue'
import HotelCardModal from 'ui-modules/modals/hotel-card-modal/hotel-card-modal.vue'
import NotFoundPage from 'ui-modules/not-found-page/not-found-page.vue'

import ModalBase from 'ui-modules/modals/modal-base.vue'

import dateTime from 'common/filters/datetime'

@Component({
  components: {
    Accordion,
    BoxLoader,
    HotelCardModal,
    ModalBase,
    NotFoundPage
  },
  filters: {
    dateTime
  }
})
export default class RootAppComp extends Vue {
  get i18nStore() {
    return i18n_MODULE
  }

  get modalPost() {
    return this.productResult.modalPost
  }

  setModalPost({modalPost}: {modalPost: IModalPost}) {
    this.productResult.setModalPost({modalPost})
  }

  get productResult() {
    return PRODUCT_RESULT_MODULE
  }

  get intervalId() {
    return this.productResult.intervalId
  }

  get packageCompareAppStore() {
    return PACKAGE_COMPARE_DESKTOP_MODULE
  }

  get isPackageSelectionMode(): boolean {
    return this.packageCompareAppStore.appMode === 'PACKAGE_SELECTION'
  }

  onSetNormalAppMode(): void {
    this.packageCompareAppStore.setAppMode({ appMode: "NORMAL" })
  }

  goToResultPage(appMode: AppMode = AppMode.NORMAL): void {
    gtag('event', 'start_search', {
      event_category: 'User Actions',
      event_label: 'Start Search',
      value: 1
    });
    this.packageCompareAppStore.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.search-results', query: this.$route.query })
  }

  goToFlight() {
    // redirect to https://flighz.com/ in new tab
    gtag('event', 'start_search', {
      event_category: 'User Actions',
      event_label: 'Go to Flighz',
      value: 1
    });
    window.open('https://flighz.com/', '_blank')
  }

  goToFavoritePackages(appMode: AppMode = AppMode.NORMAL): void {
    this.packageCompareAppStore.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.favorite-search-results', query: this.$route.query })
  }

  goToComparablePackages(appMode: AppMode = AppMode.NORMAL): void {
    this.packageCompareAppStore.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.compare-products', query: this.$route.query })
  }

  goToHomePage(appMode: AppMode = AppMode.NORMAL): void {
    this.packageCompareAppStore.setAppMode({ appMode })
    if(this.intervalId) {
      this.productResult.deleteTimeout({intervalId: this.intervalId})
    }
    this.$router.push({ name: 'package-compare.prepare-search-data', query: this.$route.query })
  }

  goToHotelsSelectionPage(appMode: AppMode = AppMode.NORMAL): void {
    this.packageCompareAppStore.setAppMode({ appMode })
    this.$router.push({ name: 'package-compare.search-results', query: this.$route.query })
  }
}
