import { render, staticRenderFns } from "./breadcrumbs.vue?vue&type=template&id=4b95da57&scoped=true"
import script from "./breadcrumbs.vue?vue&type=script&lang=ts"
export * from "./breadcrumbs.vue?vue&type=script&lang=ts"
import style0 from "./breadcrumbs.vue?vue&type=style&index=0&id=4b95da57&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b95da57",
  null
  
)

export default component.exports