
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcon from 'ui-modules/icons/icon'
import normalizeText from 'common/filters/normalizeText'
import { addCurrency } from 'common/filters/addCurrency'

import {
  IProductDetailsPaxComplectClass,
  IGroupedComplect,
  IPackageClass
} from 'applications/desktop/package-app/components/order-product/types/order-product.types'

@Component({
  components: {
    TlIcon
  },
  filters: {
    normalizeText,
    addCurrency
  }
})
export default class PaxComplectListItem extends Vue {
  @Prop(Object)
  complectObject!: IGroupedComplect
  @Prop(Array)
  selectedComplects!: IProductDetailsPaxComplectClass[]
  @Prop(Object)
  currentPackage!: IPackageClass
  @Prop(Number)
  complectsCount!: number

  get complect(): IProductDetailsPaxComplectClass {
    return this.complectObject.complect
  }

  onWrapperClick() {
    this.$emit('update-pax-complects')
  }

  onRemoveComplect() {
    this.$emit('remove-complect')
  }

  onAddComplect() {
    this.$emit('add-complect')
  }

  getPaxIconName(complect: IProductDetailsPaxComplectClass): string[] {
    return complect.paxList.map(paxType =>
      paxType === 'ADULT' ? 'male' : 'child'
    )
  }

  getPaxComplectPrice(productId: string, complectId: string) {
    const product = this.currentPackage.products.getProduct(productId)
    const complectPrice = product.getPaxComplectPrice(complectId)
    return complectPrice && complectPrice.price.total
  }
}
