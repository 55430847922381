
import { Vue } from "vue-property-decorator";
import Component from 'vue-class-component'
import CustomTooltip from "ui-modules/tooltips/custom-tooltip.vue";

@Component({
  name: "TermsBtn",
  components: {
    CustomTooltip,
  }
})
export default class TermsBtn extends Vue {}
