
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BetaBtn from "ui-modules/buttons/beta-btn/beta-btn.vue"

@Component({
  components: {
    BetaBtn
  }
})
export default class SomethingWentWrong extends Vue {
  @Prop(Boolean) pending!: boolean
  @Prop(Boolean) error!: boolean
  @Prop({ type: Boolean, default: false, required: false }) withTooltip!: boolean

  showTooltip: boolean = true

  closeTooltip() {
    this.showTooltip = false
  }
}
