
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import JumpAndSlideLoader from 'ui-modules/loaders/jump-and-slide/jump-and-slide.vue'
import MarkerLoader from "../../../marker-loader/marker-loader.vue";

@Component({
  components: {
    MarkerLoader,
    ModalWindow,
    JumpAndSlideLoader
  },
})
export default class ModalSelectCountry extends Vue {}
