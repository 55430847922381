
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ModalCommon extends Vue {
  @Prop(String) icon: string
  @Prop(Boolean) hideCloseBtn: boolean
  @Prop(Boolean) hideOverlay: boolean
  @Prop(String) externalContentClass: string
}
