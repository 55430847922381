
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import TlIcon from "ui-modules/icons/icon";

@Component({
  components: { TlIcon }
})
export default class PaxIconsList extends Vue {
  @Prop(Array) paxList!: string[];

  get paxIconNamesNew(): string[] {
    const iconMap = {
      'ADULT': 'adult',
      'CHILD': 'child',
      'INFANT': 'infant'
    }
    return this.paxList.map((paxType) => iconMap[paxType]);
  }
}
