
import Vue from 'vue'
import { Prop, Component, Ref, Watch } from 'vue-property-decorator'

import MainContent from 'applications/desktop/package-compare-app/components/order/ui/stepper/third-step/main-content/main-content.vue'

import {
  IOrderPost,
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

@Component({
  components: {
    MainContent
  }
})
export default class OrderThirdStep extends Vue {
  @Prop(Object) post!: IOrderPost
}
