import { ICustomerResource } from 'applications/desktop/package-compare-app/components/order/types/order.types'

export const convertICustomerResourceToContactPersonInfoForm = ({ data }: { data: ICustomerResource }): any => {
  return {
    lastName: data.customerInfo.lastName,
    firstName: data.customerInfo.firstName,
    email: data.media.content.MAIL.value,
    phone: data.media.content.MOBILE_PHONE.value
  }
}
