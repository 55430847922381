
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import FilterGroup from "ui-modules/products-filter/filter.group.vue"
import FilterConditionItemsList from 'ui-modules/products-filter/filter.condition-items-list.vue'

import {
  IBoardingCategory,
  IFilterItemsListCondition,
  IResultPost
} from "ui-modules/products-filter/types/products-filter.types"

@Component({
  components: {
    FilterGroup,
    FilterConditionItemsList
  }
})
export default class FilterBoardings extends Vue {
  @Prop(Object)
  post!: IResultPost
  @Prop({ type: Boolean, default: false })
  noDisableConditions?: boolean

  get boardingPost(): IFilterItemsListCondition {
    return {
      items: this.post.packageInitialFilter.boarding,
      itemsCodesForDestinations: this.post.packageInitialFilterForSelectedResult?.boarding,
      noDisableConditions: this.noDisableConditions,
      selectedItems: this.post.packageFilter.boarding,
      selectedItemsCodes: this.post.packageFilter.boarding,
      type: 'boarding',
      direction: this.post.direction
    }
  }

  get showSpaSection(): Boolean {
    return this.getBoardings().spa.length > 0
  }

  get spaBoardingPost(): IFilterItemsListCondition {
    return {
      ...this.boardingPost,
      items: this.getBoardings().spa
    }
  }

  get basicBoardingPost(): IFilterItemsListCondition {
    let items = []
    this.getBoardings().basic.forEach(item => {
      items.push(item);
    })
    return {
      ...this.boardingPost,
      items
    }
  }

  getBoardings(): IBoardingCategory {
    return this.post.packageInitialFilter.boarding.reduce((a,b): IBoardingCategory => {
      if (/\d/.test(b) || b.includes('SPA') || b.includes('+')) {
        a.spa.push(b)
      } else {
        a.basic.push(b)
      }
      return a
    }, { basic: [], spa: [] })
  } 
}
