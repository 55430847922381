
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import LuggageInfoTooltip from 'ui-modules/tooltips/luggage-info-tooltip.vue'
import { OrderDealFeatures, DealFeature, IMoneyResource } from 'applications/desktop/package-compare-app/types/package-compare-app.types'
import {DealFeature} from "be-structures/typescript-generator";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

interface IBaggageInfo {
  id: string,
  restriction: string,
  value: string,
  rule: string,
  price: IMoneyResource,
  paxType: string,
  additionalPaymentType: string
}

@Component({
  components: {
    LuggageInfoTooltip
  }
})
export default class LuggageInfo extends Vue {
  @Prop(Object) luggageInfo: IBaggageInfo
  @Prop(Object) cabinInfo: IBaggageInfo
  @Prop(Array) dealFeatures!: DealFeature[]

  isRefClicked: boolean = false

  get dealFeaturesFindBaggageInfo() {
    return this.dealFeatures.find(DealFeature => DealFeature === 'NOT_INCLUDING_BAGGAGE' || DealFeature === 'INCLUDING_BAGGAGE')
  }

  get cabinText(): string {
    return this.$tc(`order-v2.luggage.cabin-${this.cabinInfo 
      ? 'included' 
      : 'only'
    }`)
  }

  get luggageText(): LocaleMessage {
    return this.$t(`order-v2.luggage.luggage-${this.dealFeaturesFindBaggageInfo === 'INCLUDING_BAGGAGE' ? 'included' : 'not-included'}`)
  }

  get baggageIncluded() {
    return this.dealFeatures.includes(OrderDealFeatures['INCLUDING_BAGGAGE'])
  }

  get transferIncluded() {
    return this.dealFeatures.includes(OrderDealFeatures['INCLUDING_TRANSFER'])
  }

  get luggageMaxWeight() {
    return this.luggageInfo?.maxWeight
  }

  get cabinMaxWeight() {
    return this.cabinInfo?.maxWeight
  }
}
