
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import FlightGeneralInfo from "ui-modules/flight-general-info/flight-general-info.vue"
import ProductFeatures from "ui-modules/product-features/product-features.vue"

import dayjs from 'dayjs'
import { formatDateToDMYDot, formatDateToHHmm, formatFlightTime} from 'common/filters/formattedDates'
import prettyPrice from 'common/filters/prettyPrice'

import { IPackageProductClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types'

@Component({
  components: {
    FlightGeneralInfo,
    ProductFeatures
  },
  filters: {
    formatDateToDMYDot,
    formatDateToHHmm,
    formatFlightTime,
    prettyPrice
  }
})
export default class FlightInfo extends Vue {
  @Prop(Object) product: IPackageProductClass
  @Prop({type: String, default: 'default'}) type: 'default' | 'option' | 'not-available' | 'selected'
  @Prop({ type: Boolean }) showFeaturesInHeader: boolean

  get features(): string[] {
    const featuresForShow = ['TRANSFER_INCLUDED', 'LOWCOST',
      'INCLUDING_BAGGAGE', 'FAST_OK', 'CITY_PACKAGE', 'INCLUDING_TRANSFER']
    const res = this.product.dealFeatures.filter((d) => featuresForShow.includes(d))
    if (!res.includes('INCLUDING_BAGGAGE')) {
      res.push('NOT_INCLUDING_BAGGAGE')
    }
    return res
  }

  get timeDifferenceBySegmentGroup(): number[] {
    return this.product.segmentGroups.map((segmentGroup: any) => {
      const departure = segmentGroup.segments[0].departure.time
      const arrival = segmentGroup.segments[0].arrival.time
      const startDeparture = dayjs(departure).hour(0)
      const startArrival = dayjs(arrival).hour(0)
      return dayjs(startArrival).diff(startDeparture, 'day')
    })
  }
}
