
import Vue from 'vue'
import { Prop, Component, Ref, Watch } from 'vue-property-decorator'

import FormWrapper from 'applications/desktop/package-compare-app/components/order/ui/form-wrapper/form-wrapper.vue'
import CustomerInfoForm from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/customer-info/customer-info-form.vue'
import RoomGuestsDigest from 'applications/desktop/package-compare-app/components/order/ui/room-guests-digest/room-guests-digest.vue'
import TlIcon from "ui-modules/icons/icon";
import ServicesSelect from 'applications/desktop/package-compare-app/components/order/ui/services-select/services-select.vue'
import cloneDeep from 'lodash/cloneDeep'
import CustomCheckbox from 'ui-modules/elements/checkbox/checkbox.vue'

import {
  IRoomCustomer,
  TCustomersAdditions,
  IProductDetailsPaxComplectClass,
  ICustomerResource,
  ICustomerClass,
  AddressesCollection,
  convertAddressResourceToIAddressResource,
  ADDRESS_BLANK,
  ILocation, IOrderPost, IPackageProductClass
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

import {
  convertICustomerResourceToCustomerInfoForm
} from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/customer-info/customer-info-form.converter'
import PaxIconsList from "ui-modules/icons/pax-icons/pax-icons-list.vue";

@Component({
  components: {
    FormWrapper,
    CustomerInfoForm,
    RoomGuestsDigest,
    TlIcon,
    ServicesSelect,
    CustomCheckbox,
    PaxIconsList
  }
})
export default class CustomerInfoFormWrapper extends Vue {
  @Prop(Boolean) fullSizeForm!: boolean
  @Prop(Array) customers!: IRoomCustomer[]
  @Prop(Object) additions!: TCustomersAdditions
  @Prop(Object) room!: IProductDetailsPaxComplectClass
  @Prop(String) ownerKey!: string
  @Prop(String) formId!: string
  @Prop(Array) countries!: ILocation[]
  @Prop(Object) post!: IPackageProductClass
  @Prop(Boolean) isUsedContactPerson!: boolean
  @Prop(Number) ind: number

  dirtyForm = false
  invalidForm = false
  getServiceDataForPax(roomCustomer: IRoomCustomer, serviceType: string) {
    const services = [...this.additions[roomCustomer.customer.customerKey]?.additionalPayments]
    if (services.length) {
      const index = services.findIndex(item => {
        return item.paxType === roomCustomer.type && item.additionalPaymentType === serviceType
      })
      return index === -1 ? null : services[index]
    }
    return null
  }

  changeServiceQuantity(direction: string, roomCustomer: IRoomCustomer, serviceType: string) {
    let additions = cloneDeep(this.additions)
    const index = additions[roomCustomer.customer.customerKey].additionalPayments.findIndex(item => {
      return item.paxType === roomCustomer.type && item.additionalPaymentType === serviceType
    })
    console.log('index:', index)
    if (index !== -1) {
      direction === 'increase' ? 
        additions[roomCustomer.customer.customerKey].additionalPayments[index].quantity += 1 : 
        additions[roomCustomer.customer.customerKey].additionalPayments[index].quantity -= 1
      console.log('additions mutated:', additions)
      this.$emit('change-service-quantity', additions)
    }
  }

  getCustomerInfo(customer: ICustomerClass) {
    return convertICustomerResourceToCustomerInfoForm({ data: customer.content })
  }

  mounted() {
    console.log('customers:', this.customers)
    console.log('additions:', this.additions)
    console.log('room:', this.room)
    console.log('additions:', this.additions)
  }

  onInput(customerData: ICustomerClass, d: any) {
    const customer = cloneDeep(customerData.content)
    // TODO: replace to converter
    const data: ICustomerResource = {
      ...customer,
      customerInfo: {
        ...customer.customerInfo,
        firstName: d.firstName,
        lastName: d.lastName,
        gender: d.gender,
        birthday: d.birthday
      },
      passport: {
        ...customer.passport,
        passportNumber: d.passportNumber,
        passportExpirationDate: d.passportExpiryDate
      },
    }
    
    data.addresses = new AddressesCollection([convertAddressResourceToIAddressResource({
      address: {
        ...ADDRESS_BLANK,
        country: d.sitizenshipCountry
      }
    })])

    this.$emit('input-customer-info', data)
  }


  useCustomerInfo(use: boolean, customerKey: string) {
    this.$emit('use-contact-person-info', use ? customerKey : null)
  }


  collapseForm() {
    this.invalidForm = this.customers.map(c => {
      const customerInfo = this.$refs[`customerInfo_${c.customer.customerKey}`][0]
      customerInfo.touchForm()
      this.dirtyForm = true
      return customerInfo['$v']['$invalid']
    }).every(el => !el)
    if(!this.invalidForm) {
      const indexCustomer = this.customers.findIndex(customer => this.$refs[`customerInfo_${customer.customer.customerKey}`][0].$v.$invalid)
      const {customerKey} =  this.customers[indexCustomer].customer
      this.$refs[`customerInfo_${customerKey}`][0].$el.scrollIntoView({behavior: 'smooth'});

    }
    if (this.invalidForm) {
      this.$emit('set-new-opened-form-id')
    }
  }
}
