
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator"

import StringItem from "ui-modules/overflow/string-item.package.vue"
import { TranslateResult } from 'vue-i18n'

type TItem = IPartOfList | THotelFacility | string

import {
  IFilterItemsListCondition,
  IPartOfList,
  THotelFacility
} from "ui-modules/products-filter/types/products-filter.types"

@Component({
  components: {
    StringItem
  }
})
export default class FilterConditionItemsList extends Vue {
  @Prop(Object)
  post!: IFilterItemsListCondition

  get selectedItems(): TItem[] {
    return JSON.parse(JSON.stringify(this.post.selectedItems))
  }

  get items() {
    return this.post.items;
  }

  isCheckedItem(itemCode: string): boolean {
    return this.post.selectedItemsCodes.includes(itemCode)
  }

  isFacility(item: TItem): item is THotelFacility {
    if (item && (item as THotelFacility).id) {
      return true
    }
    return false
  }

  isString(item: TItem): item is string {
    if (typeof item === 'string') {
      return true
    } else {
      return false
    }
  }

  getItemName(item: TItem): string | TranslateResult {
    if (this.isFacility(item)) {
      return item.title
    } else if (this.isString(item)) {
      return this.$t(`common.${this.post.type}.${item}`)
    } else if (!!item) {
      return item.code.name || item.code.code
    }
  }

  getItemCode(item: TItem): string {
    if (this.isFacility(item)) {
      return item?.code
    } else if (this.isString(item)) {
      return item
    } else {
      return item?.code?.code
    }
  }

  getItemIndexInSelected(item: TItem): number {
    if (this.isFacility(item)) {
      return (this.selectedItems as THotelFacility[]).findIndex(selectedItem => selectedItem.code === item.code)
    } else if (this.isString(item)) {
      return (this.selectedItems as string[]).findIndex(selectedItem => selectedItem === item)
    } else {
      return (this.selectedItems as IPartOfList[]).findIndex(selectedItem => selectedItem.code.code === item.code.code)
    }
  }

  checkItem(item: TItem): void {
    const index = this.getItemIndexInSelected(item)
  
    index === -1
      ? this.selectedItems.push(item)
      : this.selectedItems.splice(index, 1)

    this.$emit('check-item', { type: this.post.type, value: this.selectedItems })
  }
}
