
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import HotelActionTooltip from 'ui-modules/tooltips/hotel-action-tooltips.vue'

@Component({
  components: {
    HotelActionTooltip
  }
})
export default class HotelActionBtns extends Vue {
  showTooltipAdd: boolean = false
  showTooltipDelete: boolean = false
  showTooltipSelect: boolean = false
}
