import {
  DealFeature
} from 'be-structures/typescript-generator/assembly'

import {
  IVacationTerm,
  setClassName
} from 'modules/common/common.types'

import {
  IPackageCompareQueryClass,
  VACATION_GROUPS,
  IPackageCompareQuery
} from 'modules/product-search.v2/data/product-search.types'

export class PackageCompareQuery implements IPackageCompareQueryClass {
  constructor(private _packageQuery: IPackageCompareQuery) {
    setClassName(PackageCompareQuery.name, this)
  }

  get content() {
    return this._packageQuery
  }

  get dealFeatures() {
    return this._packageQuery.dealFeatures
  }

  get paxes() {
    return this._packageQuery.paxes
  }

  get exactDates() {
    return this._packageQuery.exactDates
  }

  get isAnyCountry() {
    return this._packageQuery.isAnyCountry
  }

  get dateInterval() {
    return this._packageQuery.dateInterval
  }

  get nights() {
    return this._packageQuery.nights
  }

  set exactDates(value) {
    this._packageQuery.exactDates = value
  }

  set isAnyCountry(value) {
    this._packageQuery.isAnyCountry = value
  }

  set dateInterval(value) {
    this._packageQuery.dateInterval = value
  }

  set paxes(value) {
    this._packageQuery.paxes = value
  }

  set nights(value) {
    this._packageQuery.nights = value
  }

  setDealFeatures(value: DealFeature[]) {
    const isVacationTypeAlreadySelectedIndex = this._packageQuery.dealFeatures.findIndex(vacation => vacation === value[0])

    if (isVacationTypeAlreadySelectedIndex !== -1) {
      if (this._packageQuery.dealFeatures.length > 1) {
        this._packageQuery.dealFeatures.splice(isVacationTypeAlreadySelectedIndex, 1)
      }
    } else {
      const firstSelectedVacationType = this._packageQuery.dealFeatures[0]
      // @ts-ignore
      const selectedVacationGroupIndex = VACATION_GROUPS.findIndex(vGroup => vGroup.includes(firstSelectedVacationType))
      // @ts-ignore
      const newVacationGroupIndex = VACATION_GROUPS.findIndex(vGroup => vGroup.includes(value[0]))

      if (newVacationGroupIndex === selectedVacationGroupIndex) {
        this._packageQuery.dealFeatures = [
          ...this._packageQuery.dealFeatures,
          value[0]
        ]
      } else {
        this._packageQuery.dealFeatures = value
      }
    }
  }

  dateIntervalExist(): boolean {
    return !!this._packageQuery.dateInterval.start && !!this._packageQuery.dateInterval.end
  }

  getQuantityOfPassengers(): number {
    return Object.entries(this.paxes).reduce(
      (sum: number, [paxType, count]) => sum + count, 0
    )
  }

  convertToRequest(): IVacationTerm {
    return {
      // ...this._packageQuery,
      vacationTypes: this._packageQuery.dealFeatures,
      exactDates: this._packageQuery.exactDates,
      adults: this._packageQuery.paxes.ADULT,
      children: this._packageQuery.paxes.CHILD,
      infants: this._packageQuery.paxes.INFANT,
      departureSince: this._packageQuery.dateInterval.start,
      returnTill: this._packageQuery.dateInterval.end
    }
  }
}
