import ProductFilter from 'modules/product-result.v2/data/product-filter/product-filter.class'

import {
  IConvertToPackageResult,
  VacationTypes,
  TArrayOfVacationTypes,
  IMinMax,
  setClassName
} from 'modules/common/common.types'

import {
  IPackageCompareProductFilterClass,
  IProductFilter,
  THotelsSort,
  TInstantConfirmation,
  TPageableHotelsSort,
} from 'modules/product-result.v2/data/product-result.types'

export default class PackageCompareProductFilter extends ProductFilter
  implements IPackageCompareProductFilterClass {

  private _hotelsSort: THotelsSort = ['NAME', 'ASC']
  private _vacationTypes: TArrayOfVacationTypes = null
  private _partOfHotelName: string | null = null;
  private _instantConfirmation: TInstantConfirmation = []

  constructor({
    filterData,
    isDefaultFilter = false,
    isPerOnePrice = false,
    vacationTypes
  }: {
    filterData: IProductFilter
    isDefaultFilter?: boolean
    isPerOnePrice?: boolean
    vacationTypes?: TArrayOfVacationTypes
  }) {
    super(filterData)

    setClassName(PackageCompareProductFilter.name, this)

    if (!isDefaultFilter) {
      // full filter condition equal empty array
      this.airlines = []
      this.boarding = []
      this.providers = []
      super.hotelFacilities = []
      this._vacationTypes = vacationTypes
    } else {
      this._vacationTypes = Object.keys(VacationTypes) as TArrayOfVacationTypes
      this.boarding = this.boarding.filter((el: string) => el !== 'AI_' && el !== 'UAI')
    }

    if (isPerOnePrice) {
      this.price = [this.pricesPerOne.prices.min, this.pricesPerOne.prices.max];
    }
  }

  get filter(): IProductFilter {
    return super.content;
  }
  get nights(): [number, number] {
    return this.getFilterConditionValue(super.content.nights)
  }
  get hotelsSort() {
    return this._hotelsSort
  }
  get partOfHotelName(): string {
    return this._partOfHotelName;
  }
  get vacationTypes(): TArrayOfVacationTypes {
    return this._vacationTypes
  }
  get instantConfirmation(): TInstantConfirmation {
      return this._instantConfirmation
  }

  set partOfHotelName(value: string) {
    this._partOfHotelName = value;
  }
  set nights(value: [number, number]) {
    super.content.nights = this.convertFilterConditionValue(value)
  }
  set hotelsSort(value: THotelsSort) {
    this._hotelsSort = value
  }
  set vacationTypes(value: TArrayOfVacationTypes) {
    this._vacationTypes = value
  }

  set instantConfirmation(value: TInstantConfirmation) {
    this._instantConfirmation = value
  }

  mixWithNewFilter(newFilter: IPackageCompareProductFilterClass): void {
    // sliders
    this._mixFilterPrices(newFilter)
    this._mixFilterNights(newFilter)
    this._mixFilterHotelStars(newFilter)

    // text-items
    this._mixFilterBoarding(newFilter)
    this._mixFilterProviders(newFilter)
    this._mixFilterAirlines(newFilter)
  }

  convertHotelsSortToPageable(): TPageableHotelsSort[] {
    return [{ [this.hotelsSort[0]]: this.hotelsSort[1] }]
  }

  // temp func
  convertFilterToPackageCompareResult({
    defaultFilter
  }: {
    defaultFilter?: IPackageCompareProductFilterClass
  }): IConvertToPackageResult {
    // @ts-ignore
    return {
      nights: this.content.nights,
      partOfHotelName: this.partOfHotelName,
      airlines: super.airlinesCodes,
      boarding: this.getBoardingCodesIncludingDuplicates(),
      hotelFacilityIds: super.hotelFacilitiesIds,
      hotelNames: this.selectedHotel && this.selectedHotel.length>0 ? [this.selectedHotel[0].code.code]: null,
      hotelStars: this.isHotelStarsNotUsedByUser(defaultFilter?.hotelStars) ? { min: 0, max: 5 } : this.convertFilterConditionValue(super.hotelStars),
      hotelsSort: this.convertHotelsSortToPageable(),
      dealFeatures: super.dealFeatures,
      inDeparture: super.convertFilterTimeConditionValue(super.inDeparture),
      outDeparture: super.convertFilterTimeConditionValue(super.outDeparture),
      pricePerOne: this.isPriceNotUsedByUser(defaultFilter?.price) ? {} : this.roundUsedPrices(),
      providers: super.providersCodes
    }
  }

  getBoardingCodesIncludingDuplicates() {
    return [
      ...super.boarding,
      ...(super.boarding.includes('AI') ? ['AI_'] : []),
      ...(super.boarding.includes('UA') ? ['UAI'] : [])
      ]
  }

  isPriceNotUsedByUser(defaultPrice: number[]): boolean {
    return defaultPrice
      ? JSON.stringify(super.price) === JSON.stringify(defaultPrice)
      : false
  }

  roundUsedPrices() : Partial<IMinMax<number>> {
    const prices : Partial<IMinMax<number>> = {}
    if (super.price[0] >= 100) {
      super.price[0] % 100 === 0 ? prices.min = super.price[0] : prices.min = Math.floor(super.price[0] / 100) * 100
    }
    super.price[1] % 100 === 0 ? prices.max = super.price[1] : prices.max = Math.ceil(super.price[1] / 100) * 100
    return prices
  }

  isHotelStarsNotUsedByUser(defaultHotelStars: number[]): boolean {
    return defaultHotelStars
      ? JSON.stringify(super.hotelStars) === JSON.stringify(defaultHotelStars)
      : false
  }

  isNightsNotUsedByUser(defaultNights: number[]): boolean {
    return defaultNights
      ? JSON.stringify(this.nights) === JSON.stringify(defaultNights)
      : false
  }

  private _mixFilterPrices(newFilter: IPackageCompareProductFilterClass): void {
    super.price = [
      Math.min(super.price[0], newFilter.price[0]),
      Math.max(super.price[1], newFilter.price[1])
    ]
  }

  private _mixFilterNights(newFilter: IPackageCompareProductFilterClass): void {
    this.nights = [
      Math.min(this.nights[0], newFilter.nights[0]),
      Math.max(this.nights[1], newFilter.nights[1])
    ]
  }

  private _mixFilterHotelStars(
    newFilter: IPackageCompareProductFilterClass
  ): void {
    this.hotelStars = [
      Math.min(this.hotelStars[0], newFilter.hotelStars[0]),
      Math.max(this.hotelStars[1], newFilter.hotelStars[1])
    ]
  }

  private _mixFilterBoarding(
    newFilter: IPackageCompareProductFilterClass
  ): void {
    super.boarding = [...new Set([...super.boarding, ...newFilter.boarding])]
  }

  private _mixFilterProviders(
    newFilter: IPackageCompareProductFilterClass
  ): void {
    newFilter.providers.map(newProvider => {
      if (
        !super.providers.find(
          provider => provider.code.code === newProvider.code.code
        )
      ) {
        super.providers = [...super.providers, newProvider]
      }
    })
  }

  private _mixFilterAirlines(
    newFilter: IPackageCompareProductFilterClass
  ): void {
    newFilter.airlines.map(newAirline => {
      if (
        !super.airlines.find(
          airline => airline.code.code === newAirline.code.code
        )
      ) {
        super.airlines = [...super.airlines, newAirline]
      }
    })
  }
}
