import * as moment from 'moment'
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { getModule } from 'vuex-module-decorators'

import {
  IProductSearchState,
  IGroupedComplect,
  IPackageProductClass,
  IProductDetailsPaxComplectClass
} from 'applications/desktop/package-app/types/package-app.types'

import {
  ICustomer,
  IPackageOrderState,
  IPackageProductPrice
} from 'modules/product-order/data/product-order.types'


import isEqual from 'lodash/isEqual'
import assignIn from 'lodash/assignIn'
import FLY_SEARCH from 'modules/fly-search/fly-search.name'
import i18n from 'modules/i18n/i18n.name'
import PRODUCT_ORDER from 'modules/product-order/product-order.name'
import AgencyRating from 'ui-modules/agency-rating/agency-rating.vue'
import TlIcons from 'ui-modules/icons/icon'
import CommonDetails from './ui/order-common-details.vue'
import OrderFlights from './ui/order-flights.vue'
import OrderHotel from './ui/order-hotel.vue'
import OrderPrice from './ui/order-price.vue'
import OrderCustomerRooms from './ui/order-product.customer-rooms.vue'
import OrderOwnerFields from './ui/order-product.owner-fields.vue'
import PaxComplects from './ui/order-product.pax-complects.vue'
import ReservationResultFail from './ui/order-product.reservation-result-fail.vue'
import ReservationResultSuccess from './ui/order-product.reservation-result-success.vue'
import OrderSummaryAlt from './ui/order-summary-alt.vue'
import OrderSummary from './ui/order-summary.vue'
import PRODUCT_SEARCH_MODULE_V2 from 'modules/product-search.v2/product-search.store'
import PRODUCT_RESULT_MODULE_V2 from 'modules/product-result.v2/product-result.store'

import googleMaps from 'common/googleMaps/maps'

@Component({
  components: {
    AgencyRating,
    CommonDetails,
    OrderCustomerRooms,
    OrderFlights,
    OrderHotel,
    OrderOwnerFields,
    OrderPrice,
    OrderSummary,
    OrderSummaryAlt,
    PaxComplects,
    ReservationResultFail,
    ReservationResultSuccess,
    TlIcons,
  }
})
export default class PackageOrderProduct extends Vue {
  @Getter(`${FLY_SEARCH}/lastEnteredLetter`)
  lastEnteredLetter: IProductSearchState['lastEnteredLetter']
  @Getter(`${PRODUCT_ORDER}/bookedProduct`)
  bookedProduct: IPackageOrderState['bookedProduct']
  @Getter(`${PRODUCT_ORDER}/bookedProductPrice`)
  bookedProductPrice: IPackageProductPrice
  @Getter(`${PRODUCT_ORDER}/itemIds`)
  itemIds: IPackageOrderState['itemIds']
  @Getter(`${PRODUCT_ORDER}/customers`)
  customers: IPackageOrderState['customers']
  @Getter(`${PRODUCT_ORDER}/owner`)
  owner: IPackageOrderState['owner']
  @Getter(`${PRODUCT_ORDER}/countries`)
  countries: IPackageOrderState['countries']
  @Getter(`${PRODUCT_ORDER}/isOrderPending`)
  isOrderPending: IPackageOrderState['isOrderPending']
  @Getter(`${PRODUCT_ORDER}/isCountryPending`)
  isCountryPending: IPackageOrderState['isCountryPending']
  @Getter(`${PRODUCT_ORDER}/isPricePending`)
  isPricePending: IPackageOrderState['isPricePending']
  @Getter(`${PRODUCT_ORDER}/typesOfAssistance`)
  typesOfAssistance: IPackageOrderState['typesOfAssistance']
  @Getter(`${PRODUCT_ORDER}/isAssistancePending`)
  isAssistancePending: IPackageOrderState['isAssistancePending']
  @Getter(`${PRODUCT_ORDER}/orderStatus`)
  orderStatus: IPackageOrderState['orderStatus']
  @Getter(`${PRODUCT_ORDER}/orderPrereservationStatus`)
  orderPrereservationStatus: IPackageOrderState['orderPrereservationStatus']
  @Getter(`${PRODUCT_ORDER}/error`)
  error: IPackageOrderState['error']
  @Getter(`${PRODUCT_ORDER}/orderNumber`)
  orderNumber: IPackageOrderState['orderNumber']
  @Getter(`${PRODUCT_ORDER}/reservationNumbers`)
  reservationNumbers: { [type: string]: string }
  @Getter(`${PRODUCT_ORDER}/orderId`)
  orderId: IPackageOrderState['orderId']
  @Getter(`${PRODUCT_ORDER}/agencyLogoUrl`)
  agencyLogoUrl: IPackageOrderState['agencyLogoUrl']
  @Getter(`${PRODUCT_ORDER}/agencyLogoPending`)
  agencyLogoPending: IPackageOrderState['agencyLogoPending']
  @Getter(`${i18n}/currentFormattedLocale`)
  currentFormattedLocale: string
  @Getter(`${i18n}/currentLocale`)
  currentLocale: string

  @Mutation(`${PRODUCT_ORDER}/INPUT_CUSTOMER`)
  inputCustomer: any
  @Mutation(`${PRODUCT_ORDER}/INPUT_OWNER`)
  inputOwner: any
  @Mutation(`${PRODUCT_ORDER}/INPUT_ASSISTANCE_PENDING`)
  inputAssistancePending: any
  @Mutation(`${PRODUCT_ORDER}/SET_ORDER_ITEM`)
  setOrderItem: any
  @Mutation(`${PRODUCT_ORDER}/SET_ORDER_PENDING`)
  setOrderPending: any
  @Mutation(`${PRODUCT_ORDER}/SET_ORDER_ERROR`)
  setOrderError: any
  @Mutation(`${FLY_SEARCH}/INPUT_LAST_ENTERED_LETTER`)
  inputLastEnteredLetter: any
  @Action(`${i18n}/changeLocale`)
  changeLocale: any

  @Action(`${PRODUCT_ORDER}/beginOrder`)
  beginOrder: any
  @Action(`${PRODUCT_ORDER}/addProductToOrder`)
  addProductToOrder: any
  @Action(`${PRODUCT_ORDER}/getOrderItems`)
  getOrderItems: any
  @Action(`${PRODUCT_ORDER}/findCountry`)
  findCountry: any
  @Action(`${PRODUCT_ORDER}/registrationNewCustomer`)
  registrationNewCustomer: any
  @Action(`${PRODUCT_ORDER}/registrationOwner`)
  registrationOwner: any
  @Action(`${PRODUCT_ORDER}/setContactPerson`)
  setContactPerson: any
  @Action(`${PRODUCT_ORDER}/addCustomerToOrderItem`)
  addCustomerToOrderItem: any
  @Action(`${PRODUCT_ORDER}/startOrderReservation`)
  startOrderReservation: any
  @Action(`${PRODUCT_ORDER}/getReservationStatus`)
  getReservationStatus: any
  @Action(`${PRODUCT_ORDER}/getOrderPreReservationStatus`)
  getOrderPreReservationStatus: any
  @Action(`${PRODUCT_ORDER}/getSpecialAssistance`)
  getSpecialAssistance: any
  @Action(`${PRODUCT_ORDER}/setSpecialAssistance`)
  setSpecialAssistance: any
  @Action(`${PRODUCT_ORDER}/getProductPrice`)
  getProductPrice: any
  @Action(`${PRODUCT_ORDER}/getAgencyLogo`)
  getAgencyLogo: any
  @Action(`${PRODUCT_ORDER}/updateCustomer`)
  updateCustomer: any
  @Action(`${PRODUCT_ORDER}/getDefaultWebsite`)
  getDefaultWebsite: any

  mapScript: any = null
  isFormConfirm: boolean = false
  isGalleryOpened: boolean = false
  isMapShow: boolean = false
  activeImage: {
    url: string
    title: string
  } = null
  prereserveProcess: number = null
  requestId: string = null
  agencyId: string = null

  tabIndex: number = 0
  activeProduct: number = 0
  selectedPaxComplects: {
    [productId: string]: IProductDetailsPaxComplectClass[]
  } = {}
  isOwnerRegistered: boolean = false

  packageOrderData: {
    resPackage: any
    agencyId: string
    requestId: string
    paxComplect: { [productId: string]: IProductDetailsPaxComplectClass[] }
  } = null

  flightFilter: {
    providersList: string[]
    providers: string[]
  } = {
    providers: [],
    providersList: []
  }

  complectFilter: {
    roomTypeList: string[]
    roomType: string | string[]
    fareBasisList: string[]
    fareBasis: string
    showAll: boolean
    fastOk: boolean
    carIncluded: boolean
    [key: string]: string[] | string | boolean
  } = {
    carIncluded: false,
    fareBasis: 'Choose boarding',
    fareBasisList: [],
    fastOk: false,
    roomType: 'Choose passengers',
    roomTypeList: [],    
    showAll: true
  }

  @Watch('fareBasisList')
  refreshComplectFilter(val: string[]) {
    this.complectFilter.fareBasisList = val
  }

  @Watch('roomTypeList')
  refreshFilterRoomList(val: any) {
    this.complectFilter.roomTypeList = val
  }

  async created() {
    this.initializeActiveProductIndex()
    this.initializeFareBasis()
    this.initializePaxesComplects()

    this.agencyId = (await this.getDefaultWebsite())?.agency.entityId
    await this.resultStore.addPaxPricesToPackageProducts({
      agencyId: this.agencyId,
      currentPackage: this.selectedPackage
    })

    if (this.pathOrderId !== 'new') {
      this.packageOrderData = JSON.parse(
        localStorage.getItem('packageOrderData')
      )

      const productId: string = Object.keys(
        this.packageOrderData.paxComplect
      )[0]
      const product: IPackageProductClass = this.selectedPackage.products.getProduct(
        productId
      )

      this.setOrderItem({
        bookedProduct: product,
        bookedProductPrice: product.prices.find(
          // @ts-ignore
          price => price.agency.agencyId === this.packageOrderData.agencyId
        )
      })

      this.setPrereserveProcess()
    }
  }

  mounted() {
    this.appendScript()
    // this.changeLocale(this.currentLocale)
    this.findCountry({ term: 'A' })

    this.flightFilter.providers = Array.from(this.providersList)
    this.flightFilter.providersList = this.providersList
  }

  get searchStore() {
    return getModule(PRODUCT_SEARCH_MODULE_V2)
  }

  get resultStore() {
    return getModule(PRODUCT_RESULT_MODULE_V2)
  }

  get selectedPackage() {
    return this.resultStore.selectedPackage
  }

  get isPaxComplectsSelected(): boolean {
    const keys = Object.keys(this.selectedPaxComplects)
    let complects: number = 0
    keys.forEach(key => {
      if (this.selectedPaxComplects[key].length) {
        complects += 1
      }
    })
    return complects > 0
  }

  // get activeAgency() {
  //   return this.selectedPackage.products.pricesList.find(
  //     price => price.agency.agencyId === this.agencyId
  //   )
  // }

  get fareBasisList(): string[] {
    const activeProductFareBasisList =
      (this.activeProduct !== null &&
        this.filteredProducts &&
        this.filteredProducts.length &&
        this.filteredProducts[this.activeProduct].fareBasis) ||
      []
    return activeProductFareBasisList && activeProductFareBasisList.length < 2
      ? activeProductFareBasisList
      : activeProductFareBasisList.concat(['Show all'])
  }

  get roomTypeList(): string[] {
    const activeProductRoomTypeList =
      (this.activeProduct !== null &&
        this.filteredProducts &&
        this.filteredProducts.length &&
        this.filteredProducts[this.activeProduct].roomTypes) ||
      []
    return activeProductRoomTypeList && activeProductRoomTypeList.length < 2
      ? activeProductRoomTypeList
      : activeProductRoomTypeList.concat(['Show all'])    
  }

  get providersList(): string[] {
    return (
      this.groupedProducts &&
      Array.from(
        new Set(this.groupedProducts.map(product => product.provider.name))
      )
    )
  }

  get filteredProducts() {
    return isEqual(this.flightFilter.providers, this.flightFilter.providersList)
      ? this.groupedProducts
      : this.groupedProducts.filter(product =>
          this.flightFilter.providers.includes(product.provider.name)
        )
  }

  get groupedProducts() {
    return this.getProductsForRender()
  }

  get productsPrepared() {
    return this.getProductsPrepared()
  }

  get groupedComplects(): IGroupedComplect[] {
    if (this.activeProduct !== null) {
      const product = this.filteredProducts[this.activeProduct]
      const finalComplects: IGroupedComplect[] = []
      product.ids.forEach((id: string) => {
        const product = this.selectedPackage.products.getProduct(id)
        const productFareBasis = product.content.fareBasis
        const productFlightClass =
          product.content.segmentGroups[0].segments[0].flightClass
        const productSeats = product.content.segmentGroups[0].segments[0].seats
        const productFastOk = product.isFastOk()
        const productCarIncluded = product.isCarIncluded()
        const productComplects = product.details.paxComplects
        productComplects.forEach(complect => {
          finalComplects.push({
            carIncluded: productCarIncluded,
            complect,
            fareBasis: productFareBasis,
            fastOk: productFastOk,
            flightClass: productFlightClass,
            productId: product.content.productId,
            seats: productSeats
          })
        })
      })
      return finalComplects
    }
    return []
  }

  get filteredComplects(): IGroupedComplect[] {
    const complectFilter = this.complectFilter
    let targetComplects: IGroupedComplect[] = []

    targetComplects =
      complectFilter.fareBasis === 'Show all'
        ? Array.from(this.groupedComplects)
        : this.groupedComplects.filter(
            c => complectFilter.fareBasis === c.fareBasis
          )
    
    targetComplects =
      complectFilter.roomType === 'Show all'
        ? targetComplects
        : targetComplects.filter(
          c => isEqual(complectFilter.roomType as string[], c.complect.paxList)
        ) 

    if (!complectFilter.showAll) {
      targetComplects = complectFilter.fastOk
        ? targetComplects.filter(c => c.fastOk)
        : targetComplects.filter(c => !c.fastOk)

      targetComplects = complectFilter.carIncluded
        ? targetComplects.filter(c => c.carIncluded)
        : targetComplects.filter(c => !c.carIncluded)
    }

    this.sortComplectsByCount(targetComplects)

    const sortedByPaxList: {
      [paxList: string]: IGroupedComplect[]
    } = this.sortByPaxList(targetComplects)
    this.sortComplectsByPrice(sortedByPaxList)
    const finalComplects = this.getFinalComplects(sortedByPaxList)

    return finalComplects
  }

  initializeActiveProductIndex() {
    this.groupedProducts.map((product, index) => {
      if (product.ids[0] === this.selectedPackage?.selectedProduct?.content.productId) {
        this.activeProduct = index 
      }
    })
  }

  initializeFareBasis() {
    this.complectFilter.fareBasis = this.selectedPackage.selectedProduct.content.fareBasis
  }

  initializePaxesComplects() {
    const roomType: string[] = []

    for (const paxType of Object.keys(this.searchStore.searchQuery.paxes)) {
      // @ts-ignore
      const paxCount: number = this.searchStore.searchQuery.paxes[paxType]

      switch (paxType) {
        case 'adults':
          roomType.push(...Array(paxCount).fill('ADULT'))
          break;
        case 'children':
          roomType.push(...Array(paxCount).fill('CHILD'))
          break;
      }
    }

    this.complectFilter.roomType = roomType
    const selectedPaxComplects = this.selectedPackage.selectedProduct.details.paxComplects.find(paxComplect => {
      return paxComplect.paxList.join(' ') === roomType.join(' ')
    })
    this.selectedPaxComplects = {
      [this.selectedPackage.selectedProduct.content.productId]: [selectedPaxComplects]
    }
  }

  getFinalComplects(sortedByPaxList: {
    [paxList: string]: IGroupedComplect[]
  }) {
    let finalComplects: IGroupedComplect[] = []

    const keys = Object.keys(sortedByPaxList)
    keys.sort((a, b) => {
      if (a.length < b.length) {
        return -1
      }

      if (a.length > b.length) {
        return 1
      }

      return 0
    })

    keys.forEach(key => {
      finalComplects = finalComplects.concat(sortedByPaxList[key])
    })

    return finalComplects
  }

  sortByPaxList(
    complects: IGroupedComplect[]
  ): { [paxList: string]: IGroupedComplect[] } {
    const sortedByPaxList: { [paxList: string]: IGroupedComplect[] } = {}

    complects.forEach(c => {
      const paxList = c.complect.paxList
        .map(string => string.substring(0, 1).toUpperCase())
        .join('')

      if (sortedByPaxList[paxList]) {
        sortedByPaxList[paxList].push(c)
      } else {
        sortedByPaxList[paxList] = []
        sortedByPaxList[paxList].push(c)
      }
    })

    return sortedByPaxList
  }

  sortComplectsByCount(complects: IGroupedComplect[]) {
    complects.sort((a, b) => {
      const countA = a.complect.paxList.length
      const countB = b.complect.paxList.length

      if (countA < countB) {
        return -1
      }

      if (countA > countB) {
        return 1
      }

      return 0
    })
  }

  sortComplectsByPrice(sorted: { [paxList: string]: IGroupedComplect[] }) {
    const keys = Object.keys(sorted)

    const getPrice = (complect: IGroupedComplect): number => {
      let totalPrice: number = 0
      complect.complect.content.price.stages.forEach(stage => {
        totalPrice += stage.totalPrice.fare.amount
        totalPrice += stage.totalPrice.tax.amount
        totalPrice += stage.totalPrice.markupAmount.amount
        totalPrice += stage.totalPrice.commissionAmount.amount
      })

      return totalPrice
    }

    const sortByPrice = (complects: IGroupedComplect[]) => {
      complects.sort((a, b) => {
        const priceA = getPrice(a)
        const priceB = getPrice(b)

        if (priceA < priceB) {
          return -1
        }

        if (priceA > priceB) {
          return 1
        }

        return 0
      })
    }

    keys.forEach(key => {
      const complects = sorted[key]
      sortByPrice(complects)
    })
  }

  get pathOrderId(): string {
    return this.$route.params.orderId
  }

  get customerPost(): any {
    return {
      countries: this.countries,
      customers: this.customers,
      isAssistancePending: this.isAssistancePending,
      isCountryPending: this.isCountryPending,
      operatingAirlines: this.operatingAirlines,
      owner: this.owner,
      // searchQuery: this.searchQuery,
      specialAssistance: this.typesOfAssistance
    }
  }

  get pricePost(): any {
    return {
      productDetails:
        (this.bookedProduct && this.bookedProduct.details.content) || null,
      productPrice: this.bookedProductPrice || null,
      selectedPaxComplects: this.selectedPaxComplects
    }
  }

  get reservationPost(): any {
    return {
      customers: this.customers,
      orderNumber: this.orderNumber,
      product: this.selectedPackage.selectedProduct.content,
      hotel: this.selectedPackage.hotel.content,
      productPrice: this.bookedProductPrice,
      reservationNumbers: this.reservationNumbers
    }
  }

  get renderRooms(): any[] {
    const paxObj: any[] = []
    this.packageOrderData.paxComplect[
      Object.keys(this.packageOrderData.paxComplect)[0]
    ].forEach(complect => {
      const complectObject: {
        complectId: string
        paxes: string[]
        roomType: string
      } = {
        complectId: complect.content.id,
        paxes: [],
        roomType: null
      }
      const complectPrices = complect.content.price.stages[0].paxPrices

      complectObject.roomType = this.bookedProduct.details.paxComplects.find(
        (existComplect: any) => existComplect.content.id === complect.content.id
      ).content.roomType
      complectPrices.forEach(price => {
        for (let i = 0; i < price.body.quantity; i++) {
          complectObject.paxes.push(price.body.paxType)
        }
      })
      paxObj.push(complectObject)
    })

    return paxObj
  }

  get ownerRefs(): HTMLFormElement {
    return this.$refs['ownerFields'] as HTMLFormElement
  }

  get customerRefs(): HTMLFormElement[] {
    return this.$refs['customerFields'] as HTMLFormElement[]
  }

  // get currentAgencyMail(): string {
  //   return this.bookedProductPrice.agency.email
  //     ? this.bookedProductPrice.agency.email
  //     : null
  // }

  // get currentAgencyPhone(): string {
  //   return this.bookedProductPrice.agency.phone
  //     ? this.bookedProductPrice.agency.phone
  //     : null
  // }

  get operatingAirlines(): string[] {
    const airlinesSet = new Set<string>()
    if (this.bookedProduct) {
      this.bookedProduct.content.segmentGroups.forEach((group: any) => {
        group.segments.forEach((segment: any) => {
          airlinesSet.add(segment.operatingAirline.name)
        })
      })
    }
    return Array.from(airlinesSet)
  }

  get totalComplectsPrice(): number {
    let price: number = 0
    const productIds: string[] = Object.keys(this.selectedPaxComplects)

    productIds.forEach(productId => {
      if (this.selectedPaxComplects[productId]) {
        this.selectedPaxComplects[productId].forEach(
          (complect: IProductDetailsPaxComplectClass) =>
            (price += this.getComplectPrice(productId, complect.content.id))
        )
      }
    })

    return price
  }

  getComplectPrice(productId: string, complectId: string) {
    if (this.selectedPackage && this.selectedPackage.products) {
      const product = this.selectedPackage.products.getProduct(productId)
      const complectPrice = product.getPaxComplectPrice(complectId)

      return (
        (complectPrice &&
          complectPrice.price &&
          Number(complectPrice.price.total)) ||
        0
      )
    }
    return 0
  }

  appendScript() {
    if (this.mapScript) {
      this.$el.removeChild(this.mapScript)
      // @ts-ignore
      google = undefined
    }

    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBFU8Xutwnzsh-FKpKuVlsREXNDn0e1jLY&language=${this.currentLocale}`

    this.mapScript = script
    this.$el.appendChild(script)
  }

  showMap() {
    this.isMapShow = true
    setTimeout(() => {
      const geo = this.selectedPackage.hotel.geo
      const address = this.selectedPackage.hotel.address
      const mapContainer = this.$refs.map

      googleMaps(geo, address, mapContainer)
    }, 100)
  }

  openGallery(image: { url: string; title: string }) {
    this.activeImage = image
    this.isGalleryOpened = true
  }

  setActiveImage(image: { url: string; title: string }) {
    this.activeImage = image
  }

  selectProduct(index: number): void {
    this.activeProduct = index
    this.selectedPaxComplects = {}

    this.complectFilter.fastOk = false
    this.complectFilter.carIncluded = false
  }

  confirmFlightFilter({ providers }: { providers: string[] }) {
    this.flightFilter.providers = providers
    this.activeProduct = null
    this.selectedPaxComplects = {}
  }

  confirmComplectsFilter(payload: {
    roomType?: string | string[]
    fareBasis?: string
    fastOk?: boolean
    carIncluded?: boolean
    [key: string]: any
  }) {
    Object.keys(payload).forEach(
      key => (this.complectFilter[key] = payload[key])
    )

    const productIds = Object.keys(this.selectedPaxComplects)
    productIds.forEach(id => {
      const productFareBasis = this.selectedPackage.products.getProduct(id)
          .content.fareBasis
        if (payload.fareBasis !== productFareBasis) {
          this.$delete(this.selectedPaxComplects, id)
        }
    })
  }

  getProductsPrepared(): any[] {
    const products =
      (this.selectedPackage &&
        this.selectedPackage.products &&
        this.selectedPackage.products.initialContent.slice()) ||
      []
    const productsPrepared: any[] = []

    products.forEach(product => {
      const segmentGroups = product.content.segmentGroups
      const segments: any = []
      const targetObject: any = {
        dealFeatures: [],
        fareBasis: [],
        roomTypes: [],
        ids: [],
        provider: null,
        segments: []
      }

      segmentGroups.forEach(group => {
        group.segments.forEach(s => {
          segments.push({
            airline: s.airline,
            arrival: s.arrival,
            departure: s.departure,
            flightClass: s.flightClass,
            flightNumber: s.flightNumber,
            flightTime: s.flightTime,
            seats: s.seats
          })
        })
      })

      targetObject.ids.push(product.content.productId)
      targetObject.segments = segments
      targetObject.dealFeatures = targetObject.dealFeatures.concat(
        product.content.dealFeatures
      )
      targetObject.fareBasis = [product.content.fareBasis]
      targetObject.roomTypes = product.details.paxComplects.map(complect => complect.paxList)
      targetObject.provider = product.content.provider

      productsPrepared.push(targetObject)
    })
    return productsPrepared
  }

  getProductsForRender(): any[] {
    const products: any[] = []
    const prepared = this.getProductsPrepared()

    prepared.forEach((product: any, index: number) => {
      if (index === 0) {
        products.push(product)
        return
      }

      const segments = product.segments
      const flightNumber = product.flightNumber
      const provider = product.provider
      const equalsIndex = products.findIndex(
        p =>
          isEqual(p.segments, segments) &&
          isEqual(p.flightNumber, flightNumber) &&
          isEqual(p.provider, provider)
      )

      if (equalsIndex !== -1) {
        products[equalsIndex].ids = products[equalsIndex].ids.concat(
          product.ids
        )
        products[equalsIndex].dealFeatures = products[
          equalsIndex
        ].dealFeatures.concat(product.dealFeatures)
        products[equalsIndex].fareBasis = [
          ...products[equalsIndex].fareBasis,
          ...product.fareBasis
        ]
      } else {
        products.push(product)
      }
    })
    return products.map(p => {
      return {
        ...p,
        dealFeatures: Array.from(new Set(p.dealFeatures)),
        fareBasis: Array.from(new Set(p.fareBasis))
      }
    })
  }

  onSelectComplects() {
    this.packageOrderData = {
      agencyId: this.agencyId,
      paxComplect: this.selectedPaxComplects,
      requestId: this.requestId,
      resPackage: this.selectedPackage.convertToStorage()
    }

    localStorage.setItem(
      'packageOrderData',
      JSON.stringify(this.packageOrderData)
    )

    const productId: string = Object.keys(this.packageOrderData.paxComplect)[0]
    const product: IPackageProductClass = this.selectedPackage.products.getProduct(
      productId
    )

    this.setOrderItem({
      bookedProduct: product,
      bookedProductPrice: product.prices.find(
        // @ts-ignore
        price => price.agency.agencyId === this.packageOrderData.agencyId
      )
    })
  }

  async initOrder() {
    const orderId = await this.beginOrder(this.agencyId)
    const productId: string = Object.keys(this.packageOrderData.paxComplect)[0]
    const paxComplects: IProductDetailsPaxComplectClass[] = this
      .packageOrderData.paxComplect[productId]
    
    if (orderId) {
      this.$router.replace({ name: 'package-order', params: { orderId } })
      await this.addProductToOrder({
        complectIds: paxComplects.map(complect => complect.content.id),
        orderId,
        productId: this.bookedProduct.content.productId
      })
      this.setPrereserveProcess()

      this.requestId = this.packageOrderData.requestId
    }
  }

  async onOrderContinue() {
    switch (this.tabIndex) {
      case 0:
        this.scrollTop()
        this.onSelectComplects()

        this.tabIndex++
        break
      case 1:
        this.scrollTop()
        await this.registerContactPerson()

        this.tabIndex++
        break
      case 2:
        this.scrollTop()
        await this.createOrder()

        this.tabIndex++
        break
      default:
        break
    }
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  async registerContactPerson() {
    this.setOrderPending(true)
    const owner = this.owner[0]
    await this.initOrder()
    
    if (this.orderId) {
      await this.registrationOwner({
        orderId: this.orderId,
        owner
      })
  
      this.isOwnerRegistered = true
  
      await this.setContactPerson({
        customerId: this.owner[0].customerId,
        orderId: this.orderId
      })
    }

    this.setOrderPending(false)
  }

  setPrereserveProcess(): void {
    this.prereserveProcess = window.setInterval(() => {
      this.getOrderPreReservationStatus(this.orderId)
      if (this.orderPrereservationStatus === 'OK') {
        clearInterval(this.prereserveProcess)
      } else if (this.orderPrereservationStatus === 'ERROR') {
        clearInterval(this.prereserveProcess)
        this.setOrderError({
          code: 200,
          type: 'get-order-prereservation'
        })
      }
    }, 3000)
  }

  onInputOwner(customerData: ICustomer) {
    const localOwner = this.owner[0] || {}

    assignIn(localOwner, customerData)

    this.inputOwner(localOwner)
  }

  onInputCustomer(customerData: ICustomer): void {
    const targetCustomerIndex = this.customers.findIndex(
      customer =>
        customer.roomIndex === customerData.roomIndex &&
        customer.paxIndex === customerData.paxIndex
    )

    if (!customerData.media) {
      customerData.media = [
        {
          mediaType: 'MOBILE_PHONE',
          value: '22-22-22'
        }
      ]
    }
    this.inputCustomer({ customerData, customerIndex: targetCustomerIndex })
  }

  checkFailedValidation(): boolean {
    const customerValidators: boolean[] = []

    for (const cv of this.customerRefs) {
      customerValidators.push(cv.$v.$invalid)
      cv.$v.$touch()
    }

    this.ownerRefs.$v.$touch()

    return this.ownerRefs.$v.$invalid || customerValidators.indexOf(true) !== -1
  }

  async workWithCustomers(): Promise<void> {
    return new Promise(res => {
      const customersCopy = Array.from(this.customers)
      let index: number = 0
      let roomIndex: number = null
      let itemId: string = null

      const register = async () => {
        if (customersCopy.length) {
          const customer = customersCopy.pop()

          await this.registrationNewCustomer({
            customer,
            index,
            orderId: this.orderId
          })

          if (roomIndex !== customer.roomIndex) {
            itemId = this.itemIds[customer.complectId].pop()
          }
          roomIndex = customer.roomIndex

          await this.addCustomerToOrderItem({
            customerId: this.customers[index].customerId,
            itemId,
            orderId: this.orderId
          })

          index++

          register()
        } else {
          res()
        }
      }

      register()
    })
  }

  async createOrder() {
    this.setOrderPending(true)
    await this.workWithCustomers()
    this.reservationOrder()
  }

  async reservationOrder() {
    this.startOrderReservation(this.orderId)

    const reservationProcces = setInterval(() => {
      this.getReservationStatus(this.orderId)
      if (this.orderStatus === 'OK') {
        clearInterval(reservationProcces)

        this.isFormConfirm = true
        this.getOrderItems(this.orderId).then(() => this.setOrderPending(false))
      } else if (this.orderStatus === 'ERROR') {
        clearInterval(reservationProcces)

        this.isFormConfirm = true
        this.getOrderItems(this.orderId).then(() => this.setOrderPending(false))

        this.setOrderError({
          code: 200,
          type: 'reservation-process-error'
        })
      }
    }, 3000)
  }

  onSearchLocations(query: string): void {
    if (query.length === 1) {
      this.inputLastEnteredLetter({ letter: query, timeOf: moment() })
    }

    const timeDiff: number = moment().diff(this.lastEnteredLetter.timeOf)

    if (timeDiff > 700) {
      this.findCountry({ term: query, locale: this.currentFormattedLocale })
    } else {
      setTimeout(() => {
        if (query === this.lastEnteredLetter.letter) {
          this.findCountry({ term: query, locale: this.currentFormattedLocale })
        }
      }, 700)
    }

    this.inputLastEnteredLetter({ letter: query, timeOf: moment() })
  }

  onUpdatePaxComplects(paxComplects: {
    [productId: string]: IProductDetailsPaxComplectClass[]
  }) {
    const productId = Object.keys(paxComplects)[0]
    const complectsCount = paxComplects[productId].length

    this.selectedPaxComplects = {}

    Object.keys(paxComplects).forEach(productId => {
      if (paxComplects[productId] && paxComplects[productId].length) {
        this.selectedPaxComplects[productId] = paxComplects[productId]
      }
    })

    if (complectsCount) {
      const selectedFareBasis = this.selectedPackage.products.getProduct(
        productId
      ).content.fareBasis
      this.complectFilter.fareBasis = selectedFareBasis
    }
  }
}
