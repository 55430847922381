
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { IPackageClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types'

@Component({})
export default class ProductProvider extends Vue {
  @Prop(Object)
  currentPackage!: IPackageClass
}
