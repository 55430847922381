
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import FlightDetails from 'ui-modules/cards/hotel/v2/flight-details.vue'
import HotelImage from 'ui-modules/cards/hotel/v2/hotel-image.vue'
import HotelName from 'ui-modules/cards/hotel/v2/hotel-name.vue'
import HotelDeal from 'ui-modules/cards/hotel/v2/hotel-deal.vue'
import HotelPrice from 'ui-modules/cards/hotel/v2/hotel-price.vue'
import HotelStars from 'ui-modules/cards/hotel/stars.vue'
import HotelFlySegments from 'ui-modules/cards/hotel/hotel-fly-segments.vue'
import TlIcon from 'ui-modules/icons/icon.js'

import {
  ComparisonItems,
  IPackageClass,
  IPackageCompareResultClass,
  IPackageCompareResultState
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    FlightDetails,
    HotelDeal,
    HotelImage,
    HotelName,
    HotelPrice,
    HotelStars,
    HotelFlySegments,
    TlIcon
  }
})
export default class HotelInfo extends Vue {
  @Prop(Object) currentPackage!: IPackageClass
  @Prop(String)
  comparisonItem!: ComparisonItems
  @Prop(Boolean) isFavoritePackage!: boolean

  comparisonItems = ComparisonItems

}
