export default function(value: string) {
  if (value) {
    return getNormallizedText(value)
  }
}

function getNormallizedText(val: string): string {
  const patternsDelete = [/[\^{}\**]/g, /(NULL)/g, /<br\/>/g, /<\/>/g]
  // const patternsSpace = [ /(#:#)/g, /\.(?!<br\/>)/g ]

  let targetVal: string = val
  patternsDelete.forEach(
    pattern => (targetVal = targetVal.replace(pattern, ''))
  )
  // patternsSpace.forEach(pattern => targetVal = targetVal.replace(pattern, '<br/>'))

  return targetVal.replace(/\:(?!\:)/g, ': ')
}
