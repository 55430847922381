
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class CustomAutocompleteForObjects extends Vue {
  @Prop({ type: Array, default: [] }) items!: any[]
  @Prop({ type: String, default: null }) label!: string
  @Prop({ type: String, default: null }) placeholder!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Object, default: null }) value!: any
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: String, default: null }) errorMessages!: string
  @Prop({ type: String }) objectPropertyName!: string
  @Prop({ type: String, default: null }) persistentHint!: string
  @Prop({ type: Function, default: null }) filterFunction!: () => boolean
  @Prop({ type: Function, default: null }) itemTextFunction!: () => string
  @Prop({ type: Boolean, default: false }) clearable!: boolean
  @Prop({ type: Boolean, default: false }) isWarningHint!: boolean

  @Watch('search')
  initSearch(search) {
    if (search && (!this.value || search !== this.value.name)) {
      this.$emit('search-input', search)
    }
  }

  search: string = null

  filterItem(item: any, query: string): boolean {
    return item[this.objectPropertyName].toLowerCase().indexOf(query.toLowerCase()) !== -1
  }
}
