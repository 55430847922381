
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IPackageProductClass
} from 'ui-modules/flight-table/flight-table.types'

import TlIcons from 'ui-modules/icons/icon'
import Checkbox from 'ui-modules/overflow/string-item.package'
import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'

import prettyPrice from 'common/filters/prettyPrice'
import { getImgUrlByPath } from 'common/getImgUrl'

@Component({
  components: {
    TlIcons,
    Checkbox,
    CustomTooltip
  },
  filters: {
    prettyPrice
  }
})
export default class FlightsFeaturesTable extends Vue {
  @Prop(Array) products!: IPackageProductClass[]
  @Prop(String) selectedSegmentId!: string
  @Prop(String) selectedProductId!: string

  prevSelectedSegmentId: string = ''

  getImgUrlByPath = getImgUrlByPath

  created() {
    const selectedProduct = this.localProducts.find(product => product.productId === this.selectedProductId)
    if (!selectedProduct) {
      this.$emit('select-product', this.localProducts[0])
      this.prevSelectedSegmentId = this.selectedSegmentId
    }
  }

  updated() {
    if (this.prevSelectedSegmentId !== this.selectedSegmentId) {
      this.$emit('select-product', this.localProducts[0]);
      this.prevSelectedSegmentId = this.selectedSegmentId
    }
  }

  get localProducts(): IPackageProductClass[] {
    const locProd = this.products.sort((a, b) => {
      return +a.price.total - +b.price.total
    })
    return locProd
  }

  get dealFeaturesColumns() {
    const featuresColumns = ['FAST_OK', 'CAR_INCLUDED', 'INCLUDING_TRANSFER', 'INCLUDING_BAGGAGE']
    const filteredColumns = Array.from(featuresColumns.reduce((acc, col) => {
      if (this.localProducts.find(((p) => p.dealFeatures.includes(col)))) {
        acc.add(col)
      }
      return acc
    }, new Set()))
    if (this.localProducts.find(p => p.dealFeatures.filter(deal => !filteredColumns.includes(deal)).length)) {
      filteredColumns.push('OTHER')
    }
    return Array.from(filteredColumns)
  }

  getOtherDealFeatures(product: IPackageProductClass) {
    return product.dealFeatures.filter((deal: string) => !['FAST_OK', 'CAR_INCLUDED', 'INCLUDING_TRANSFER', 'INCLUDING_BAGGAGE'].includes(deal))
  }
}
