import type {
  MediaResource
} from 'be-structures/typescript-generator/assembly'

import {
  IMediaCollectionClass,
  TMediaCollection,
  MEDIA_TYPES,
  Media,
  IMediaResource,
  setClassName
} from 'modules/common/common.types'

export class MediaCollection implements IMediaCollectionClass {
  private _mediaCollection: TMediaCollection = null

  constructor(media?: IMediaResource[]) {
    setClassName(MediaCollection.name, this)

    this._mediaCollection = MEDIA_TYPES.reduce((collection, type): TMediaCollection => {
      const existedMedia = media?.find(m => m.type === type)
      return {
        ...collection,
        [type]: new Media({ media: existedMedia, type })
      }
    }, {} as TMediaCollection)
  }

  get content() {
    return this._mediaCollection
  }

  get mobile() {
    return this._mediaCollection.MOBILE_PHONE
  }

  get mail() {
    return this._mediaCollection.MAIL
  }

  set content(value: TMediaCollection) {
    this._mediaCollection = value
  }

  convertToMediaResource(): MediaResource[] {
    return Object.entries(this._mediaCollection).reduce((resource, m) => {
      if (m[1] && m[1].value) {
        return [
          ...resource,
          m[1].content
        ]
      } else {
        return resource
      }
    }, [])
  }
}