
import Vue from 'vue'

import { Component, Prop } from 'vue-property-decorator'
import OrderCustomerFields from './order-product.customer-fields.vue'
import TlIcon from 'ui-modules/icons/icon'

import {
  ICustomerPost,
  IPricePost,
  ICustomer
} from 'applications/desktop/flight-app/types/desktop-app.types'

@Component({
  components: {
    OrderCustomerFields,
    TlIcon
  }
})
export default class PackageOrderCustomerRooms extends Vue {
  @Prop(Array)
  rooms!: any[]
  @Prop(Object)
  post!: ICustomerPost
  @Prop(Object)
  pricePost!: IPricePost

  onInputCustomer(
    roomGroup: any,
    customerData: ICustomer,
    roomIndex: number,
    paxIndex: number
  ) {
    const finalCustomerData = {
      ...customerData,
      complectId: roomGroup.complectId,
      roomIndex,
      paxIndex
    }

    this.$emit('input-customer', finalCustomerData)
  }

  getPaxIcon(paxType: string) {
    return paxType === 'ADULT' ? 'male' : 'child'
  }
}
