import { render, staticRenderFns } from "./city.vue?vue&type=template&id=0b8e0985&scoped=true"
import script from "./city.vue?vue&type=script&lang=ts"
export * from "./city.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8e0985",
  null
  
)

export default component.exports