
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import PriceDatepicker from 'ui-modules/datepicker/price-datepicker.vue'
import Datepicker from 'ui-modules/datepicker/datepicker.vue'
import ModalCommon from 'ui-modules/modals/modal-common.vue';
import ActionPanel from 'ui-modules/buttons/action-panel.vue'

@Component({
  components: {
    ActionPanel,
    Datepicker,
    PriceDatepicker,
    ModalCommon,
  }
})
export default class DatepickersDialog extends Vue {
  @Prop(String) locale: string
  @Prop(Object) inputDate: {start: string, end: string}
  @Prop(Object) departureDates: any
  @Prop(Object) returnDates: any
  @Prop(Boolean) getAndSetReturnDatesByCountry: any
  @Prop(Boolean) getAndSetDepartureDatesByCountry: any
  @Prop(Object) insideCurrentDate: any
  @Prop(Boolean) onlyOneDatePicked: boolean
  @Prop(Boolean) exactDates: boolean
  @Prop(Boolean) isAnyCountry: boolean
  
  @Ref() readonly priceDatepicker: PriceDatepicker

  isShowPriceHint = false

  onReset() {
    this.priceDatepicker.resetDatepicker()
    this.isShowPriceHint = false
  }

  created() {
    if (!this.isAnyCountry && this.inputDate.start && this.inputDate.end) {
      this.isShowPriceHint = true
    }
  }
}
