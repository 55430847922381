
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class LocaleSelector extends Vue {
  @Prop(Array)
  list!: string[] | number[]
  @Prop(Array)
  storeListValue!: string[] | number[]
  @Prop(Boolean)
  toPreviousValue?: boolean

  previousLang: string = null

  @Watch('storeListValue')
  watchChanges(current: string[], prev: string[]) {
    if (prev[0] !== current[0]) {
      this.previousLang = prev[0]
      this.isChanged = true
    }
  }

  @Watch('toPreviousValue')
  abort() {
    if (this.toPreviousValue) {
      this.storeListValue = [this.previousLang]
      this.$emit('cancel')
    }
  }

  private _isOpen: boolean = false
  private _isChanged: boolean = false

  get isChanged(): boolean {
    return this.$data._isChanged
  }

  set isChanged(val: boolean) {
    this.$data._isChanged = val
  }

  get isOpen(): boolean {
    return this.$data._isOpen
  }

  set isOpen(val: boolean) {
    this.$data._isOpen = val
  }

  closeSelector(): void {
    if (this.isOpen) {
      document.removeEventListener('click', this.closeSelector)
    } else {
      this.$emit('open')
    }
    this.isOpen = !this.isOpen
  }

  openMultiply(): void {
    document.addEventListener('click', this.closeSelector)
  }

  onChangeListElement(listElement: number | string) {
    this.$emit('input', { value: listElement })
    document.addEventListener('click', this.closeSelector)
  }
}
