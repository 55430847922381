
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import {
  PRODUCT_RESULT_MODULE,
  PRODUCT_SEARCH_MODULE
} from "../../../../applications/desktop/package-compare-app/store/store";

@Component({
  components: {
    ModalWindow
  }
})
export default class ModalSessionExpired extends Vue {
  get searchStore() {
    return PRODUCT_SEARCH_MODULE
  }
  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }

  get routeName() {
    const arrayPath = this.$route.path.split('/')
    const lengthPath = arrayPath.length - 1
    return arrayPath[lengthPath]
  }

  get isWizardBooking() {
    return this.routeName !== 'order'
  }

  async updateListCountry() {
    if(this.routeName !== 'hotels') {
      this.$router.push('/hotels')
    }
    this.$emit('update-destinations')
    this.cancel(true)
  }
  cancel(isSession) {
    this.resultStore.deleteTimeout({intervalId: this.getIntervalId})
    this.resultStore.setSessionReady({value: isSession})
    this.resultStore.setIsShowModal({value: false})
    this.resultStore.setModalPost({modalPost: null})
  }
  get getIntervalId() {
    return this.resultStore.intervalId
  }

}
