
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcon from 'ui-modules/icons/icon.js'

@Component({
  components: {
    TlIcon
  }
})
export default class Accordeon extends Vue {
  @Prop(String)
  transitionName!: string
  @Prop(Array)
  icons!: string[]
  @Prop(String)
  title!: string
  @Prop({ default: true })
  showDefault!: boolean

  created() {
    this.isContentShow = this.showDefault
  }

  isContentShow: boolean = true

  get needToRotate(): boolean {
    return this.icons.length > 1 ? false : true
  }
}
