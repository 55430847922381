
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'
import {PRODUCT_ORDER_MODULE} from "../../applications/desktop/package-compare-app/store/store";

@Component({
  components: {
    CustomTooltip
  }
})
export default class LuggageInfoTooltip extends Vue {
  @Prop(String) checkItems!: string
  @Prop(String) luggageType!: string

  get orderStore() {
    return PRODUCT_ORDER_MODULE
  }

  get agencyPhone() {
    return this.orderStore.agencyInfo.agency.phone.replace('+', '')
  }

}

