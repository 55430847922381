
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import dateTime from 'common/filters/datetime'
import TlIcon from 'ui-modules/icons/icon'
import TransitionSlide from 'ui-modules/transition/transition-slide.vue'
import {
  IPackageHotelClass,
  IPackageCompareResultClass
} from 'applications/desktop/package-compare-app/components/create-search/types/create-search.types'

@Component({
  components: {
    TransitionSlide,
    TlIcon
  },
  filters: {
    dateTime
  }
})
export default class HotelPrice extends Vue {
  @Prop(Object)
  packageResult!: IPackageCompareResultClass
  @Prop(Object)
  hotel!: IPackageHotelClass
  @Prop(Boolean)
  moreAvailable: boolean
  @Prop(Boolean)
  productView: boolean
  @Prop(Boolean)
  reverseSlide: boolean
  @Prop(Boolean)
  isPendingProducts: boolean

  get selectedProduct() {
    return this.packageResult?.selectedProduct || null
  }

  get nights(): number {
    return this.selectedProduct?.nights || this.hotel?.minProductsNight
  }

  get price(): string {
    return this.selectedProduct?.content.price.total || this.hotel?.minProductPriceForHotel
  }

  get priceKey(): string {
    return (
      this.nights +
      this.price +
      this.selectedProduct?.content.dealInterval.min +
      this.selectedProduct?.content.dealInterval.max
    )
  }
}
