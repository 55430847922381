
// using is prevented, use formattedDates instead
// @ts-ignore
import moment from 'moment'

export default function(value: any, format: string = 'DD.MM.YYYY') {
  if (value) {
    return moment(value).format(format)
  }
}
