export default (geo, address, mapContainer) => {
  let map = null
  let marker = null

  if (geo) {
    map = new google.maps.Map(mapContainer, {
      center: geo,
      controlSize: 22,
      zoom: 16
    })
    marker = new google.maps.Marker({
      position: geo,
      map
    })
  } else if (address) {
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        map = new google.maps.Map(mapContainer, {
          center: results[0].geometry.location,
          controlSize: 22,
          zoom: 16
        })
        marker = new google.maps.Marker({
          position: results[0].geometry.location,
          map
        })
      }
    })
  }
}
