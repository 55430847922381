import type {
  AddressType,
} from 'be-structures/typescript-generator/assembly'

import {
  IAddressClass,
  IAddressResource,
  setClassName
} from 'modules/common/common.types'

import {
  convertIAddressResourceToRequest
} from 'modules/common/address/address.types'

export class Address implements IAddressClass {
  private _address: IAddressResource = null

  constructor({ address, addressType }: { address?: IAddressResource, addressType?: AddressType }) {
    setClassName(Address.name, this)

    this._address = {
      ...address,
      addressType
    }
  }

  get content() {
    return this._address
  }

  get country() {
    return this._address.country;
  }

  set content(value: IAddressResource) {
    this._address = value
  }

  set country(value: string) {
    this._address.country = value
  }

  convertToRequest() {
    return convertIAddressResourceToRequest({ address: this._address })
  }
}