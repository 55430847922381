
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BoxLoader from 'ui-modules/marker-loader/marker-loader.vue'
import FilterTemplate from 'ui-modules/products-filter/filter.template.vue'

import {
  IFilterPost
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    BoxLoader,
    FilterTemplate
  }
})
export default class PackageFilter extends Vue {
  @Prop(Object)
  post!: IFilterPost
}
