
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ModalHotelGallery from 'ui-modules/modals/modal-content/modal-gallery/modal-gallery.vue'
import ModalSelectCountry from 'ui-modules/modals/modal-content/modal-select-country/modal-select-country.vue'
import ModalSelectPassengers from 'ui-modules/modals/modal-content/modal-select-passengers/modal-select-passengers.vue'
import ModalNoResults from 'ui-modules/modals/modal-content/modal-no-results.vue'
import ModalNewSearch from 'ui-modules/modals/modal-content/modal-new-search/modal-new-search.vue'
import ModalProductSelector from 'ui-modules/modals/modal-content/modal-product-selector.vue'
import ModalComparisonLimit from 'ui-modules/modals/modal-content/modal-comparison-limit.vue'
import ModalSoldOutProducts from 'ui-modules/modals/modal-content/modal-sold-out-products.vue'
import ModalSetAllParameters from 'ui-modules/modals/modal-content/modal-set-all-parameters/modal-set-all-parameters.vue'
import ModalDatepicker from 'ui-modules/modals/modal-content/modal-datepicker.vue'
import ModalPriceDatepicker from 'ui-modules/modals/modal-content/modal-price-datepicker.vue'
import ModalFinishBooking from 'ui-modules/modals/modal-content/modal-finish-booking/modal-finish-booking.vue'
import ModalBadPaxComplects from "ui-modules/modals/modal-content/modal-bad-pax-complects/modal-bad-pax-complects.vue";
import ModalCancellationTerms from 'ui-modules/modals/modal-content/modal-cancellation-terms/modal-cancellation-terms.vue'
import ModalLoadingBookingWizard from 'ui-modules/modals/modal-content/modal-loading-booking-wizard/modal-loading-booking-wizard.vue'
import ModalSelectBaggageDetails from "./modal-content/modal-select-baggage-details/modal-select-baggage-details.vue";
import ModalAgreement from "./modal-content/modal-agreement/modal-agreement.vue";
import ModalSessionExpired from 'ui-modules/modals/modal-content/modal-session-expired/modal-session-expired.vue'
import ModalNonReady from 'ui-modules/modals/modal-content/modal-non-ready/modal-non-ready.vue'
import ModalReferralGreetings from 'ui-modules/modals/modal-content/modal-referral-greetings/modal-referral-greetings.vue'
import ModalRemarks from 'ui-modules/modals/modal-content/modal-remarks/modal-remarks.vue'
import ModalFlightWarning from 'ui-modules/modals/modal-content/modal-flight-warning/modal-flight-warning.vue'
import ModalWelcomeToSite from 'ui-modules/modals/modal-content/modal-welcome-to-site/modal-welcome-to-site.vue'

@Component({
  components: {
    ModalBadPaxComplects,
    ModalComparisonLimit,
    ModalProductSelector,
    ModalHotelGallery,
    ModalNoResults,
    ModalNewSearch,
    ModalSoldOutProducts,
    ModalSetAllParameters,
    ModalSelectCountry,
    ModalSelectPassengers,
    ModalDatepicker,
    ModalPriceDatepicker,
    ModalCancellationTerms,
    ModalFinishBooking,
    ModalLoadingBookingWizard,
    ModalSelectBaggageDetails,
    ModalSessionExpired,
    ModalAgreement,
    ModalNonReady,
    ModalReferralGreetings,
    ModalWelcomeToSite,
    ModalRemarks,
    ModalFlightWarning,
  }
})
export default class ModalBase extends Vue {
  @Prop(Object) post: any;

  get isPreventDefaultClose() {
    return this.post?.preventDefaultClose
  }
}
