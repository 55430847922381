
import Vue from 'vue'
import { Component, Model, Prop } from 'vue-property-decorator'

type TRadioValueType = string | number | boolean

@Component({})
export default class RadioButtonSquare extends Vue {
  @Model('input') readonly model: TRadioValueType
  @Prop() value: TRadioValueType
  @Prop(Boolean) disabled: boolean

  onClick() {
    if (this.model !== this.value) {
      this.$emit('input', this.value)
    }
  }
}
