import Vue, { VueConstructor } from 'vue'
import Router, { RouteConfig } from 'vue-router'

// import OrderPaymentResult from 'applications/desktop/flight-app/components/order-product/ui/order.payment-result.vue'
import PackageOrderProduct from 'applications/desktop/package-app/components/order-product/order-product.template.vue'
import CompareProducts from 'applications/desktop/package-compare-app/components/compare-products/compare-products.template.vue'
import PrepareSearchData from 'applications/desktop/package-compare-app/components/prepare-search-data/prepare-search-data.template.vue'
import FavoriteSearchResults from 'applications/desktop/package-compare-app/components/search-results/search-results.favorite.template.vue'
import SearchResults from 'applications/desktop/package-compare-app/components/search-results/search-results.template.vue'
import Order from 'applications/desktop/package-compare-app/components/order/order.template.vue'
import * as queryString from "querystring";
import OrderById from "./package-compare-app/components/order/OrderById.vue";
import CancelPayment from "./payments/cancel-payment.vue";
import ErrorPayment from "./payments/error-payment.vue";
import SuccessPayment from "./payments/success-payment.vue";
Vue.use(Router)


const routes: RouteConfig[] = [
  {
    component: PrepareSearchData,
    name: 'package-compare.prepare-search-data',
    path: '/'
  },
  {
    children: [
      {
        component: CompareProducts,
        name: 'package-compare.compare-products',
        path: 'comparison'
      },
      {
        component: FavoriteSearchResults,
        name: 'package-compare.favorite-search-results',
        path: 'favorite'
      }
    ],
    component: SearchResults,
    name: 'package-compare.search-results',
    path: '/hotels'
  },
  // temp: to child component
  // {
  //   component: OrderPaymentResult,
  //   name: 'successPayment',
  //   path: '/success-payment'
  // },
  // {
  //   component: OrderPaymentResult,
  //   name: 'errorPayment',
  //   path: '/error-payment'
  // },
  {
    component: PackageOrderProduct,
    name: 'package-order',
    path: '/package-order/:orderId'
  },
  {
    component: Order,
    name: 'order',
    path: '/order'
  },
  {
    component: ErrorPayment,
    name: "errorPayment",
    path: "/error-payment/:orderId"
  },
  {
    component: SuccessPayment,
    name: "successPayment",
    path: "/success-payment/:orderId"
  },
  {
    component: CancelPayment,
    name: "cancelPayment",
    path: "/cancel-payment/:orderId"
  },
  {
    component: OrderById,
    name: 'orderById',
    path: '/orderById/:id'
  }
]

const newRouter = new Router({routes})


export default newRouter
