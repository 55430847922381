
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import MessageIcon from "ui-modules/svg-icon/message.vue";
import DealInfoIcon from "ui-modules/svg-icon/deal-info.vue";
import Stars from "ui-modules/cards/hotel/stars.vue";
import { IPackageHotelClass } from "modules/product-result.v2/data/package-result/package-result.hotel/package-result.hotel.types";
import { formatDateToDMYDot } from "common/filters/formattedDates";

@Component({
  components: {
    MessageIcon,
    DealInfoIcon,
    Stars
  }
})
export default class PaymentMessage extends Vue {
  @Prop(Boolean) isSuccess: boolean;
  @Prop(String) messageType!: "message" | "deal-info";
  @Prop(Object) owner: { fullName: string };
  @Prop(Object) hotel: IPackageHotelClass;
  @Prop(Object) dates: { min: string; max: string };

  get dateStart() {
    return formatDateToDMYDot(this.dates.min);
  }

  get dateEnd() {
    return formatDateToDMYDot(this.dates.max);
  }

  get dealCityAndCountry() {
    return this.$t('order-v2.order-stepper.3-step.payment-message.deal-info.city-and-country', {
      city: `<span class="text__bold">${this.hotel.city.name}</span>`,
      country: `<span class="text__bold">${this.hotel.country.name}</span>`
    })
  }

  get dealDates() {
    return this.$t('order-v2.order-stepper.3-step.payment-message.deal-info.dates', {
      departureDate: `<span class="text__bold">${this.dateStart}</span>`,
      arrivalDate: `<span class="text__bold">${this.dateEnd}</span>`
    })
  }
}
