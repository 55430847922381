
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import TooltipWrapper from 'ui-modules/modals/modal-content/tooltip-wrapper.vue'
import PaxesSelectorItem from './lists/paxes.vue'
import ActionPanel from 'ui-modules/buttons/action-panel.vue'
import Passenger from 'ui-modules/svg-icon/passenger.vue'
import PassengerSmall from 'ui-modules/svg-icon/passenger-small.vue'

@Component({
  components: {
    ActionPanel,
    Passenger,
    PaxesSelectorItem,
    PassengerSmall,
    TooltipWrapper
  }
})
export default class PaxSelector extends Vue {
  @Prop(Object)
  list!: { [name: string]: { description: string; val: [number, number] } }
  @Prop(Number)
  maxTotalPax!: number
  @Prop(Object)
  storeListValue!: { [name: string]: number }
  @Prop(String)
  viewMode: string
  @Prop(Boolean)
  exactDates!: boolean

  paxes: { [name: string]: number } = null

  @Watch('storeListValue')
  updatePaxes(paxes: { [name: string]: number }) {
    this.paxes = paxes
  }

  created() {
    this.paxes = this.storeListValue
  }

  private _isOpen: boolean = false
  viewModeClass: string = `selector-show--${this.viewMode}`

  get isOpen(): boolean {
    return this.$data._isOpen
  }

  set isOpen(val: boolean) {
    this.$data._isOpen = val && !this.exactDates
  }

  get valuePaxes(): { [name: string]: number } {
    return this.paxes || {}
  }

  get totalPaxSelected(): number {
    return Object.entries(this.storeListValue).reduce(
      (sum: number, [paxType, count]) => sum + <number>count,
      0
    )
  }

  onChangePax(paxType: string, value: number): void {
    this.paxes = {
      ...this.paxes,
      [paxType]: value
    }
  }

  onConfirm(): void {
   this.isOpen = false;
   this.$emit('confirm', this.paxes);
  }

  onCancel(): void {
    this.isOpen = false
    this.$emit('cancel')
  }

  onHide(): void {
    this.isOpen = false
  }

  onShow(): void {
    this.isOpen = true;
  }
}
