import {
  HOTEL_SCORE_TYPES,
  IPackage,
  IPackageClass,
  IPackageHotelClass,
  IPackageProduct,
  IPackageProductClass,
  IPackageProductsListClass,
  PackageHotel,
  setClassName,
  PackageProductsList
} from 'modules/product-result.v2/data/product-result.types'

export default class Package implements IPackageClass  {
  private _hotel: IPackageHotelClass
  private _products: IPackageProductsListClass
  // private _selectedProviderCode: string = null
  private _selectedProduct: IPackageProductClass = null
  // TODO: temp
  private _selectedProductsIds: string[][] = [[],[]]
  private _isSoldOut: boolean = false
  private _nightsInterval: IPackageClass['nightsInterval'] = null
  private _quantityOfBookingErrors: number = 0
  
  constructor(private _package: IPackage) {
    setClassName(Package.name, this)

    this._hotel = _package.hotel ? new PackageHotel({ hotel: _package.hotel }) : null

    if (_package.products.length > 0) {
      this.setProducts(_package.products)
    }
  }

  get content(): IPackageClass['content'] {
    return this._package
  }
  get hotel(): IPackageHotelClass {
    return this._hotel
  }
  get products(): IPackageProductsListClass {
    return this._products
  }
  get minPrice(): string {
    return this._package.minPrice
  }
  get selectedProduct(): IPackageProductClass {
    return this._selectedProduct
  }

  get selectedProductsIds(): string[][] {
    return this._selectedProductsIds
  }

  get isSoldOut() {
    return this._isSoldOut
  }

  get nightsInterval() {
    return this._nightsInterval
  }

  get quantityOfBookingErrors() {
    return this._quantityOfBookingErrors
  }

  // get selectedProviderCode(): string {
  //   return this._selectedProviderCode || this._products?.content[0].content.provider.code || null
  // }
  // get selectedProduct(): IPackageProductClass {
  //   if (this._selectedProviderCode) {
  //     return this._selectedProduct || this._products.getProductsGroupedByProvider()[this._selectedProviderCode][0]
  //   }
  // }

  // set selectedProviderCode(value: string) {
  //   this._selectedProviderCode = value
  // }
  // set selectedProduct(value: IPackageProductClass) {
  //   this._selectedProduct = value
  // }
  set hotel(value: IPackageHotelClass) {
    this._hotel = value
  }
  set products(value: IPackageProductsListClass) {
    this._products = value
  }
  set selectedProduct(value: IPackageProductClass) {
    this._selectedProduct = value
  }

  set nightsInterval(value) {
    this._nightsInterval = value
  }

  set quantityOfBookingErrors(value: number) {
    this._quantityOfBookingErrors = value
  }

  setSelectedProductsIds({ segmentGroupIndex, value }: { segmentGroupIndex: number, value: string[] }): void {
    this._selectedProductsIds[segmentGroupIndex] = value
  }

  // getNextProvider(): void {
  //   const providers = this.products.productsProviders
  //   const providerIndex = providers.indexOf(this.selectedProviderCode)
    
  //   /* tslint:disable */
  //   if (!providers[providerIndex + 1]) {
  //     this.selectedProviderCode = providers[0]
  //   } else {
  //     this.selectedProviderCode = providers[providerIndex + 1]
  //   }

  //   this.selectedProduct = this.products.getProductsGroupedByProvider()[this.selectedProviderCode][0]
  // }

  // getPrevProvider(): void {
  //   const providers = this.products.productsProviders
  //   const providerIndex = providers.indexOf(this.selectedProviderCode)
    
  //   if (!providers[providerIndex - 1]) {
  //     this.selectedProviderCode = providers[providers.length - 1]
  //   } else {
  //     this.selectedProviderCode = providers[providerIndex - 1]
  //   }

  //   this.selectedProduct = this.products.getProductsGroupedByProvider()[this.selectedProviderCode][0]
  // }

  // getNextProduct(): void {
  //   const productsGroupedByProvider = this.products.getProductsGroupedByProvider()[this.selectedProviderCode]
  //   const productIndex = productsGroupedByProvider.findIndex((product) => product.content.productId === this.selectedProduct.content.productId)
    
  //   if (!productsGroupedByProvider[productIndex + 1]) {
  //     this.selectedProduct = productsGroupedByProvider[0]
  //   } else {
  //     this.selectedProduct = productsGroupedByProvider[productIndex + 1]
  //   }
  // }

  // getPrevProduct(): void {
  //   const productsGroupedByProvider = this.products.getProductsGroupedByProvider()[this.selectedProviderCode]
  //   const productIndex = productsGroupedByProvider.findIndex((product) => product.content.productId === this.selectedProduct.content.productId)
    
  //   if (!productsGroupedByProvider[productIndex - 1]) {
  //     this.selectedProduct = productsGroupedByProvider[productsGroupedByProvider.length - 1]
  //   } else {
  //     this.selectedProduct = productsGroupedByProvider[productIndex - 1]
  //   }
  // }

  // TODO: Add type
  getScore(): any {
    if (!this._selectedProduct?.scores) {
      return null
    }

    return this._selectedProduct.scores.relative
  }

  setProducts(value: IPackageProduct[]): void {
    this._isSoldOut = value.length === 0

    // temp: delete after new api applying
    const uniqueProductsCombination: string[] = []
    const uniqueProducts = value.reduce((products, product) => {
      const targetString = JSON.stringify({
        flight: [
          {
            ...product.segmentGroups[0].segments[0],
            flightClass: null
          },
          {
            ...product.segmentGroups[1].segments[0],
            flightClass: null
          }
        ],
        boarding: product.fareBasis,
        roomType: product.roomType,
        dealFeatures: product.dealFeatures
      })

      if (!uniqueProductsCombination.includes(targetString)) {
        uniqueProductsCombination.push(targetString)
        products.push(product)
      }

      return products
    }, [])

    this._products = new PackageProductsList(uniqueProducts)
    this._selectedProduct = this._products.content[0]
  }

  convertToStorage(): any {
    return {
      content: this.content,
      details: this.products.content.map(pr => [
        pr.content.productId,
        pr.details.content
      ]),
      products: this.products.content.map(pr => pr.content)
    }
  }

  getProductsWithoutSelectedProduct() {
    return this.products.content.filter((product) => product.productId !== this.selectedProduct.productId)
  }


  bookingErrorOccured(quantityOfPossibleBookingErrors) {
    if (quantityOfPossibleBookingErrors !== this.quantityOfBookingErrors) {
      this._quantityOfBookingErrors++
    }
  }

  packageIsUnavailable(quantityOfPossibleBookingErrors) {
    return quantityOfPossibleBookingErrors === this.quantityOfBookingErrors
  }

  withSingleAvailableNight() : boolean {
    if (this._nightsInterval) {
      return this._nightsInterval.min === this._nightsInterval.max
    }
    return false
  }
}
