
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator"

import {
  IGalleryPost
} from 'ui-modules/modals/modal-content/modal-gallery/modal-gallery.types'

@Component({})

export default class ModalHotelGallery extends Vue {
  @Prop(Object)
  post: IGalleryPost;

  currentImageIndex: number = 0;

  get imagesList(): Array<{
    url: string;
    title: string;
  }> {
    return this.post?.hotel?.getImages();
  }

  get isLastImage(): boolean {
    return this.imagesList.length - 1 === this.currentImageIndex;
  }

  showPrevImage() {
    this.currentImageIndex = this.currentImageIndex
      ? --this.currentImageIndex
      : this.imagesList.length - 1;
  }

  showNextImage() {
    this.currentImageIndex = this.isLastImage ? 0 : ++this.currentImageIndex;
  }
}
