
import Vue from 'vue'
import { Prop, Component, Ref, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

import FormWrapper from 'applications/desktop/package-compare-app/components/order/ui/form-wrapper/form-wrapper.vue'
import ContactPersonInfo from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/contact-person-info/contact-person-info-form.vue'
import ContactPersonDigest from 'applications/desktop/package-compare-app/components/order/ui/contact-person-digest/contact-person-digest.vue'

import {
  ICustomerClass,
  ICustomerResource,
  Media
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

import {
  convertICustomerResourceToContactPersonInfoForm
} from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/contact-person-info/contact-person-info-form.converter'
import {PRODUCT_ORDER_MODULE, PRODUCT_ORDER_VIEW_MODULE} from "applications/desktop/package-compare-app/store/store";

@Component({
  components: {
    FormWrapper,
    ContactPersonInfo,
    ContactPersonDigest
  }
})
export default class ContactPersonInfoFormWrapper extends Vue {
  @Prop(Boolean) fullSizeForm!: boolean
  @Prop(Object) owner!: ICustomerClass
  @Prop(String) formId!: string

  @Ref("contactPersonInfo") readonly contactPersonInfo!: HTMLFormElement;

  dirtyForm = false
  invalidForm = false

  ownerData = null;

  get personDigestInfo() {
    return {
      fullName: this.owner.fullName,
      phone: this.owner.content.media.content.MOBILE_PHONE.value,
      email: this.owner.content.media.content.MAIL.value
    }
  }

  get ownerInfo() {
    return convertICustomerResourceToContactPersonInfoForm({ data: this.owner.content })
  }

  onInput(d: any) {
    const owner = cloneDeep(this.owner.content)

    // TODO: replace to converter
    const data: ICustomerResource = {
      ...owner,
      customerInfo: {
        ...owner.customerInfo,
        firstName: d.firstName,
        lastName: d.lastName
      }
    }

    data.media.content.MAIL = new Media({ media: { value: d.email }, type: 'MAIL' })
    data.media.content.MOBILE_PHONE = new Media({ media: { value: d.phone }, type: 'MOBILE_PHONE' })

    this.ownerData = data;
    this.$emit('input-contact-person-info', this.ownerData);
  }

  get orderStore() {

    return PRODUCT_ORDER_MODULE
  }

  get orderStoreView() {
    return PRODUCT_ORDER_VIEW_MODULE

  }

  get order() {
    return this.orderStore.order
  }

  collapseForm() {
    this.$emit('set-new-opened-form-id')

    // this.contactPersonInfo.touchForm()
    // this.dirtyForm = true
    // this.invalidForm = this.contactPersonInfo['$v']['$invalid']
    //
    // if (!this.invalidForm) {
    //   this.orderStore.registationNewOwnerAndAddToOrder({
    //     orderId: this.order.orderId,
    //     customer: this.owner.convertToCustomerCreate()
    //   })
    // }
  }
}
