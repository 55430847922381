
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import StringItem from "ui-modules/overflow/string-item.package.vue"

import {
  IFilterConditionGroup,
  IFilterItemsListCondition,
  IResultPost,
  TArrayOfVacationTypes,
  TVacationTypes,
  VacationTypes
} from "ui-modules/products-filter/types/products-filter.types"

@Component({
  components: {
    StringItem
  }
})
export default class FilterVacationTypes extends Vue {
  @Prop(Object)
  post!: IResultPost

  vacationGroups = [
    [VacationTypes.SKI, VacationTypes.BEACH, VacationTypes.FAMILY, VacationTypes.SPA, VacationTypes.CITY],
    [VacationTypes.GUIDED_TOUR],
    [VacationTypes.CIVIL_MARRIAGE],
    [VacationTypes.FLY_AND_DRIVE]
  ]

  get vacationTypes(): TArrayOfVacationTypes {
    return this.post.packageInitialFilter.vacationTypes
  }
  get selectedVacationTypes(): TArrayOfVacationTypes {
    return JSON.parse(JSON.stringify(this.post.packageFilter.vacationTypes))
  }

  isCheckedVacationType(item: VacationTypes): boolean {
    return this.selectedVacationTypes.includes(item)
  }

  checkItem(item: VacationTypes): void {
    // const index = this.selectedVacationTypes.findIndex(vacationItem => vacationItem === item)

    this.$emit('set-vacation-in-filter', { type: 'vacationTypes', value: [item] })

    // if (index === -1) {
    //   // @ts-ignore
    //   const currentGroupIndex = this.vacationGroups.findIndex(vacationGroup => vacationGroup.includes(this.selectedVacationTypes[0]))
    //   const groupIndex = this.vacationGroups.findIndex(vacationGroup => vacationGroup.includes(item))

    //   if (currentGroupIndex !== groupIndex) {
    //     this.$emit('set-vacation-in-filter', { type: 'vacationTypes', value: [item] })
    //   } else {
    //     this.selectedVacationTypes.push(item)
    //     this.$emit('set-vacation-in-filter', { type: 'vacationTypes', value: this.selectedVacationTypes })
    //   }
    // } else if (this.selectedVacationTypes.length > 1) {
    //   this.selectedVacationTypes.splice(index, 1)
    //   this.$emit('set-vacation-in-filter', { type: 'vacationTypes', value: this.selectedVacationTypes })
    // }
  }
}
