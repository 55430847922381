export * from 'applications/desktop/package-compare-app/types/package-compare-app.types'


import {
  AdditionalPayment,
  PaxTypeOld
} from 'be-structures/typescript-generator/assembly'

import {
  IPackageClass,
  ICustomerClass,
  IPackageProductDetailsClass,
  IOrderClass,
  IMoneyResource,
  ILocation
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'


export enum OrderPendingsNames {
  'startOrderReservation',
}


export type TOrderPendings = { -readonly [key in keyof typeof OrderPendingsNames]: boolean}
export type TOrderPendingsNames = keyof typeof OrderPendingsNames
export type TRoomsCustomer = { [key: string]: IRoomCustomer[] }
export type TRoomsAdditions = { [key: string]: TCustomersAdditions }
export type TCustomersAdditions = { [key: string]: ICustomerAdditions }


export interface IRoomCustomer {
  type: PaxTypeOld
  customer: ICustomerClass
}

export interface IAdditionalPaymentCounter extends AdditionalPayment {
  quantity: number
}

export interface ICustomerAdditions {
  additionalPayments: IAdditionalPaymentCounter[]
}

export interface IOrderPost {
  stepNumber: number
  destinationPackage: IPackageClass
  availableBoardings: string[]
  boarding: string
  productDetails: IPackageProductDetailsClass
  order: IOrderClass
  formsIds: string[]
  customers: TRoomsCustomer
  owner: ICustomerClass
  additions: TRoomsAdditions,
  orderTotalPrice: IMoneyResource
  orderPendings: TOrderPendings
  countries: ILocation[]
}

export interface IFirtStepQuery {
  boarding: IOrderPost['boarding']
  product: IOrderPost['destinationPackage']['selectedProduct']
  paxComplects: IOrderPost['productDetails']['paxComplects']
}
