
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'


@Component({
  components: {
  }
})
export default class FormWrapper extends Vue {
  @Prop(String) title!: string
  @Prop(Boolean) fullSizeForm!: boolean
  @Prop({type: Boolean, default: false, required: false }) isDirty!: boolean
  @Prop({type: Boolean, default: false, required: false }) formError!: boolean

  showError: boolean = false

  finishFormFill() {
    this.$emit('collapse-form')
    this.showError = true
  }
}

