
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ILocation } from 'applications/desktop/flight-app/types/desktop-app.types'
import TlIcons from 'ui-modules/icons/icon.js'

@Component({
  components: {
    TlIcons
  }
})
export default class LocationItem extends Vue {
  @Prop([Object, String])
  location!: ILocation | string
  @Prop(Boolean)
  isPending!: boolean
}
