
import { Component, Prop } from "vue-property-decorator";

import decimalPlace from 'common/filters/decimalPlace'
import PackageOrderProductComp from './order-product'
import { addCurrency } from 'common/filters/addCurrency'

@Component({
  filters: {
    decimalPlace,
    addCurrency
  }
})
export default class PackageOrderProduct extends PackageOrderProductComp {}
