
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import Multiselect from 'vue-multiselect'
import FilterGroup from "ui-modules/products-filter/filter.group.vue"

import {
  HotelsSort,
  SortValues,
  THotelsSort
} from "ui-modules/products-filter/types/products-filter.types"

@Component({
  components: {
    Multiselect,
    FilterGroup
  }
})
export default class FilterHotelsSort extends Vue {
  @Prop(Array) hotelsSort!: THotelsSort
  @Prop(Boolean) disabled: boolean

  sortValues = SortValues

  get hotelsSortOptions(): THotelsSort[] {
    const hotelsSort = Object.keys(HotelsSort) as HotelsSort[] 
    const sortValues = Object.keys(SortValues) as SortValues[]

    return hotelsSort.reduce((a, b): THotelsSort[] => {
      return [
        ...a,
        ...sortValues.map(val => [b, val])
      ]
    }, [])
  }

  get sortPlaceholder() {
    return this.$t("common.sort-placeholder")
  }

  updateSorting(sort: THotelsSort) {
    this.$emit('set-sorting', { sortingOptions: sort })
  }

  getName(hotelsSort) {
    const optionName = hotelsSort.join('-')
    const returnedName = {
      'NAME-ASC': this.$t('common.sort.name-up'),
      'NAME-DESC': this.$t('common.sort.name-down'),
      'PRICE-ASC': this.$t('common.sort.price-up'),
      'PRICE-DESC': this.$t('common.sort.price-down'),
      'DISTANCE_TO_CITY_CENTER-ASC': this.$t('common.sort.distance-to-city-center-up'),
      'DISTANCE_TO_CITY_CENTER-DESC': this.$t('common.sort.distance-to-city-center-down'),
    }
    
    return returnedName[optionName] ?? hotelsSort[0]
  }
}
