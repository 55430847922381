
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'
import SelectorCaret from 'ui-modules/svg-icon/selector-caret.vue'
import InfoAboutOrderTooltip from "../../../../../../../ui-modules/tooltips/info-about-order-tooltip.vue";
import BoardingChangeNotification from 'applications/desktop/package-compare-app/components/order/ui/boarding-change-notification/boarding-change-notification.vue'

@Component({
  components: {
    InfoAboutOrderTooltip,
    Multiselect,
    SelectorCaret,
    BoardingChangeNotification,
  }
})
export default class BoardingSelector extends Vue {
  @Prop({type: Array, default: () => []}) boardings: string[]
  @Prop(String) currentBoarding: string

  isOpen: boolean = false
}
