
import Vue from 'vue'
import { Prop, Component, Ref, Watch } from 'vue-property-decorator'

import LuggageInfo from 'applications/desktop/package-compare-app/components/order/ui/luggage-info/luggage-info.vue'
import FormWrapper from 'applications/desktop/package-compare-app/components/order/ui/form-wrapper/form-wrapper.vue'
import ContactPersonInfoFormWrapper from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/contact-person-info/contact-person-info-form.wrapper.vue'
import CustomerInfoFormWrapper from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/customer-info/customer-info-form.wrapper.vue'

import {
  IOrderPost,
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

@Component({
  components: {
    FormWrapper,
    ContactPersonInfoFormWrapper,
    CustomerInfoFormWrapper,
    LuggageInfo
  }
})
export default class OrderSecondStepMainContent extends Vue {
  @Prop(Object) post!: IOrderPost
  @Prop(String) openedFormId!: string

  isUsedContactPerson = false

  getRoom(roomId: string) {
    return this.post.productDetails.paxComplects.find(pc => roomId.indexOf(pc.roomId) !== -1)
  }

  formCompleted(form: any) {
    return !(form['dirtyForm'] && !form['invalidForm'] || !form['dirtyForm'])
  }

  setNewOpenedFormId(id: string) {
    if (this.openedFormId === '0' && id !== '0') {
      const contactPersonForm = (this.$refs.contactPersonInfoFormWrapper as ContactPersonInfoFormWrapper)


      contactPersonForm.contactPersonInfo.touchForm()
      contactPersonForm.dirtyForm = true
      contactPersonForm.invalidForm = contactPersonForm.contactPersonInfo['$v']['$invalid']

      if (!contactPersonForm.invalidForm) {
        contactPersonForm.orderStore.registationNewOwnerAndAddToOrder({
          orderId: contactPersonForm.order.orderId,
          customer: contactPersonForm.owner.convertToCustomerCreate()
        })
      }
    }

    if (id) {
      this.$emit('set-new-opened-form-id', id)
    } else {
      const contactPersonForm = this.$refs.contactPersonInfoFormWrapper

      if (this.formCompleted(contactPersonForm)) {
        this.$emit('set-new-opened-form-id', contactPersonForm['formId'])
      } else {
        const formId = this.post.formsIds.find(formId => {
          const customerForm = this.$refs[`customerInfoFormWrapper_${formId}`][0]
          return !this.formCompleted(customerForm)
        })
        this.$emit('set-new-opened-form-id', formId)
      }
    }
  }

  useCustomerInfo(roomId: string, customerKey: any) {
    if (customerKey) {
      this.isUsedContactPerson = true
    } else {
      this.isUsedContactPerson = false
    }
    this.$emit('use-contact-person-info', { roomId, customerKey })

  }
}
