
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import Stars from "ui-modules/cards/hotel/stars.vue";

import { IPackageHotelClass } from 'applications/desktop/package-app/components/order-product/types/order-product.types'
import Mark from 'ui-modules/mark/mark.vue';
import OrderSummaryItem from "../order-summary/order-summary-item.vue";

@Component({
  components: {
    OrderSummaryItem,
    Stars,
    Mark,
  }
})
export default class HotelInfo extends Vue {
  @Prop(Object) hotel!: IPackageHotelClass
	@Prop({ type: String }) title: string
}

