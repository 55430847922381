
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IOrderPost,
  IProductDetailsPaxComplectClass
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

import FlightGeneralInfo from "ui-modules/flight-general-info/flight-general-info.vue"
import Mark from 'ui-modules/mark/mark.vue'
import BoardingInfo from 'applications/desktop/package-compare-app/components/order/ui/boarding-info/boarding-info.vue'
import HotelInfo from 'applications/desktop/package-compare-app/components/order/ui/hotel-info/hotel-info.vue'
import PaxInfo from 'applications/desktop/package-compare-app/components/order/ui/pax-info/pax-info.vue'
import PaxIconsList from 'ui-modules/icons/pax-icons/pax-icons-list.vue'

import prettyPrice from 'common/filters/prettyPrice'
import convertToRoman from "common/filters/convertToRoman";
import OrderSummaryItem from "./order-summary-item.vue";
import ProductFeaturesBox from "../../../../../../../ui-modules/product-features/product-features-box.vue";

@Component({
  components: {
    OrderSummaryItem,
    FlightGeneralInfo,
    ProductFeaturesBox,
    BoardingInfo,
    HotelInfo,
    PaxInfo,
    PaxIconsList,
    Mark,
  },
  filters: {
    prettyPrice,
    convertToRoman,
  }
})
export default class OrderSummary extends Vue {
  @Prop(Object) order: IOrderPost


  get additions(): string[] {
    return this.order?.additions ? Object.keys(this.order.additions) : []
  }

  getPaxComplect({ complectId }: { complectId: string }): IProductDetailsPaxComplectClass {
    return this.order.productDetails.paxComplects.find(pc => pc.roomId === complectId)
  }

  getAdditionsByRoomId(formId: string) {
    return Object.values(this.order.additions[formId])
                                  .reduce((acc, customerAdditions) => [...acc, ...customerAdditions.additionalPayments],[])
  }
}
