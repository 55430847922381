
import Vue from 'vue'
import Component from 'vue-class-component'
import { Action, Getter, Mutation } from 'vuex-class'

import PackageCompareDesktopApp from 'applications/desktop/package-compare-app/components/root-app/root-app.component.vue'
import { restoreAppClasses } from 'applications/desktop/package-compare-app/store/restore-classes'

@Component({
  components: {
    PackageCompareDesktopApp
  }
})
export default class Desktop extends Vue {
  created() {
    restoreAppClasses()
  }
}
