
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import PaxSelector from 'ui-modules/selectors/pax-selector.vue'
import { IPackageComparePaxes } from 'applications/desktop/package-compare-app/types/package-compare-app.types'

@Component({
  components: {
    PaxSelector
  }
})
export default class PassengerSelector extends Vue {
  @Prop(String)
  viewMode: string
  @Prop(Object)
  paxes: IPackageComparePaxes
  @Prop(Boolean)
  exactDates!: boolean

  config: any = {
    ADULT: { val: [1, 9] },
    CHILD: { val: [0, 9] },
    INFANT: { val: [0, 0] }
  }

  updatePax(paxes: IPackageComparePaxes) {
    this.$emit('change-pax', paxes)
  }

  onConfirm(paxes: any) {
    this.$emit('select', paxes)
  }

  onCancel() {
    this.$emit('cancel')
  }
}
