
import Vue from 'vue'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
// @ts-ignore
import VDatepicker from 'v-calendar/lib/components/date-picker.umd.min.js'
import dayjs from 'dayjs'

import {
  IStartEnd
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import {
  formatTodayToYMD,
  formatDateToYMD
} from 'common/filters/formattedDates'

@Component({
  components: {
    VDatepicker
  }
})
export default class Datepicker extends Vue {
  @Prop(String) locale!: string
  @Prop(Object) value!: IStartEnd<string>
  @Prop({ type: Array, default: (): number[] => [] }) disabledDaysInMonth!: number[]

  @Ref('datepicker') readonly datepicker!: HTMLFormElement

  @Watch('value')
  watching() {
    if (this.value.end && this.value.start) {
      this.dateValue = this.value
    }
  }

  dateValue: IStartEnd<string> = {
    start: null,
    end: null
  }

  created() {
    if (this.value) {
      this.dateValue = this.value
    }
  }

  get minDate() {
    return formatTodayToYMD()
  }

  get isDraggable() {
    const dragValue = this.datepicker.dragValue
    return !!dragValue?.start || !!dragValue?.end
  }

  isDisabledDay(day: any) {
    return Boolean(day.isDisabled || this.disabledDaysInMonth.includes(day.day))
  }

  onMouseEnter(day: any) {
    if (!this.isDisabledDay(day) && this.isDraggable) {
      this.datepicker.dragValue = {
        start: this.datepicker.dragValue.start,
        end: dayjs(day.id).toDate()
      }
    }
  }

  getContentStyleForDrag(day: any) {
    if (day) {
      let typeHighlight: 'base' | 'start' | 'end' = 'base'
      const date = dayjs(day.id)

      if (date.isSame(dayjs(this.dateValue?.start))) {
        typeHighlight = 'start'
      } else if (date.isSame(dayjs(this.dateValue?.end))) {
        typeHighlight = 'end'
      }

      return day.attributesMap?.['select-drag']?.highlight[typeHighlight].contentStyle || {}
    }

    return {}
  }

  onDayClick(day: any) {
    this.onSelectDay();
    if (!this.isDisabledDay(day)) {

        if (!this.datepicker.dragValue) {
          this.datepicker.dragValue = {
            start: dayjs(day.id).toDate(),
            end: dayjs(day.id).toDate()
          }

          this.onInput({
            start: formatDateToYMD(day.id),
            end: null
          })
        } else {
          this.dateValue = {
            start: formatDateToYMD(this.datepicker.dragValue.start),
            end: formatDateToYMD(this.datepicker.dragValue.end)
          }
          this.datepicker.dragValue = null
          this.onInput(this.dateValue)
          this.onSelectDates(this.dateValue)
        }

    }
  }

  sortDates(value: IStartEnd<string>): IStartEnd<string> {
    if (value.start && value.end) {
      const sortedValue = Object.values(value).sort((a: string ,b: string) => {
        return dayjs(a).isAfter(dayjs(b)) ? 1 : -1
      })

      return {
        start: sortedValue[0],
        end: sortedValue[1]
      }
    }

    return value
  }

  onInput(value: IStartEnd<string>) {
    this.$emit('input', this.sortDates(value))
  }

  onSelectDates(value: IStartEnd<string>) {
    this.$emit('select-dates', this.sortDates(value))
  }

  onSelectDay() {
    this.$emit('select-day')
  }
}
