
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { IPackageHotelClass } from 'applications/desktop/package-app/components/order-product/types/order-product.types'
import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcon
  }
})
export default class OrderHotelInfo extends Vue {
  @Prop(Object)
  hotel!: IPackageHotelClass

  isFacilitiesShow: boolean = false
  imagesCount: number = this.hotel.getImages().length
  slidePosition: number = 0

  get galleryShift() {
    return -((100 / 4) * this.slidePosition)
  }
}
