import currenciesList from 'common/currency/currency'
import { IMoneyResource } from 'applications/desktop/package-compare-app/types/package-compare-app.types'
import isRTL from 'common/isRTL'

export const addCurrency = (amount: number | string, currency: string = 'USD') => {
  const currencySymbol = currenciesList[currency] || currency

  return isRTL()
    ? amount && `${amount} ${currencySymbol}` || ''
    : amount && `${currencySymbol} ${amount}` || ''
}

export const currencyInMoneyResourceFormat = (value: IMoneyResource) => {
	return addCurrency(value.amount, value.currency)
}
