
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import TlIcon from "ui-modules/icons/icon";

import {
  IRoomCustomer,
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

@Component({
  components: { TlIcon }
})
export default class RoomGuestsDigest extends Vue {
  @Prop(Array) guests!: IRoomCustomer[]
}
