
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import FlightDetails from "ui-modules/cards/hotel/flight-details.vue"
import PaxPricesTooltip from 'ui-modules/tooltips/pax-prices-tooltip.vue'
import InfoIconSmall from 'ui-modules/svg-icon/info-icon-small.vue'
import OrderButton from 'ui-modules/buttons/order-btn.vue'
import SomethingWentWrong from "ui-modules/something-went-wrong/something-went-wrong.vue"

import dateTime from "common/filters/datetime"
import mathCeil from 'common/filters/mathCeil'
import getSegments from 'common/getSegments'
import { addCurrency } from 'common/filters/addCurrency'
import { getImgUrlByPath } from 'common/getImgUrl'

import { IPackageProductClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types'
import { IOrderSegmentGroup } from 'modules/product-order/data/product-order.types'
import { IProductGroup } from 'ui-modules/modals/modal.types'

@Component({
  components: {
    FlightDetails,
    PaxPricesTooltip,
    InfoIconSmall,
    OrderButton,
    SomethingWentWrong
  },
  filters: {
    dateTime,
    mathCeil,
    addCurrency
  }
})
export default class FlightInfo extends Vue {
  @Prop(Object) selectedProduct: IPackageProductClass
  @Prop(Object) post: any

  getImgUrlByPath = getImgUrlByPath
  getSegments = getSegments

  get isFastOk(): boolean {
    return this.selectedProduct?.isFastOk()
  }

  get departureDate() {
    const date = this.selectedProduct?.segmentGroups[0].segments[0].departure.time
    return dateTime(date, 'DD.MM.YYYY')
  }
  get returnDate() {
    const date = this.selectedProduct?.segmentGroups[1].segments[0].departure.time
    return dateTime(date, 'DD.MM.YYYY')
  }

  get departureAirline() {
    return this.selectedProduct?.segmentGroups[0].segments[0].airline.name
  }

  get departureFlightNumber() {
    return this.selectedProduct?.segmentGroups[0].segments[0].flightNumber
  }

  get returnAirline() {
    return this.selectedProduct?.segmentGroups[1].segments[0].airline.name
  }

  get returnFlightNumber() {
    return this.selectedProduct?.segmentGroups[1].segments[0].flightNumber
  }
}
