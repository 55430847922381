
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'

import {
  IProductPax,
  TProductPaxType,
} from 'modules/product-result.v2/data/package-result/package-result.product/package-result.product.types'

import prettyPrice from 'common/filters/prettyPrice'

@Component({
  components: {
    CustomTooltip
  },
  filters: {
    prettyPrice
  }
})
export default class PaxPricesTooltip extends Vue {
  @Prop(Object) paxes!: TProductPaxType
  @Prop([Number, String]) totalPrice!: number | string
  @Prop(Number) averagePricePerPerson!: number
  @Prop({ type: Object, default: () => ({placement: 'bottom'}), required: false }) options: any


  get sortedPaxes(): string[] {
  return  Object.keys(this.paxes).sort((a, b) => {
      if (a === "ADULT" && b === "CHILD") {
        return -1;
      }
    if (a === "CHILD" && b === "ADULT") {
        return 1;
      }
      // a должно быть равным b
      return 0;
    })
  }
}

