
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {SegmentResource} from "be-structures/typescript-generator";
import TlIcon from 'ui-modules/icons/icon.js'
import {TranslateResult} from "vue-i18n";
import CustomTooltip from "../../../../../../../ui-modules/tooltips/custom-tooltip.vue";

@Component({
  components: {
    CustomTooltip,
    TlIcon,
  },
  name: "tooltip-flight-details-diagram"
})
export default class TooltipFlightDetailsDiagram extends Vue {
  @Prop({type: Object})
  segment!: SegmentResource
  @Prop({type: String})
  provider!: string
  @Prop(Boolean)
  isDeparture: boolean

  options = {
    modifiers: {
      preventOverflow: {
        boundariesElement: 'window'
      },
    }
  }
  get getRemarks(): string {
    return this.segment?.remark
  }
  get meal(): string {
    return this.segment?.meal
  }
  get providerName(): string {
    return this.provider ?? ''
  }
  get terminal(): string {
    return  this.isDeparture ? this.segment.departure.terminal : this.segment.arrival.terminal
  }
  get aircraft(): string {
    return this.segment?.aircraft?.name
  }
  get numberOfPieces(): number {
    return this.segment.baggageAllowance.numberOfPieces
  }
  get hangBaggage(): string {
    return `${this.segment.baggageAllowance.maxWeightHandbag} ${this.segment.baggageAllowance.unit}`
  }
  get baggage(): string {
    return `${this.segment.baggageAllowance.weight} ${this.segment.baggageAllowance.unit}`
  }
}
