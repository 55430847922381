
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import {
  IOrderPost
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

import Stepper from 'ui-modules/stepper/stepper.vue'
import FirstStep from 'applications/desktop/package-compare-app/components/order/ui/stepper/first-step/first-step.vue'
import SecondStep from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/second-step.vue'
import ThirdStep from 'applications/desktop/package-compare-app/components/order/ui/stepper/third-step/third-step.vue'

@Component({
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    Stepper
  }
})
export default class OrderStepper extends Vue {
  @Prop(Object) post!: IOrderPost

  get disabledSteps() {
    return {
      1: this.post.stepNumber === 3,
      2: this.post.stepNumber === 3,
      3: this.post.stepNumber !== 3
    }
  }

  getStepComponentName(stepNumber: number) {
    switch (stepNumber) {
      case 0:
        return ''
      case 1:
        return `FirstStep`
      case 2:
        return `SecondStep`
      case 3:
        return `ThirdStep`
    }
  }
}
