
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import mathCeil from 'common/filters/mathCeil'
import { IPackageClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types'
import PaxPricesTooltip from 'ui-modules/tooltips/pax-prices-tooltip.vue'
import { addCurrency } from 'common/filters/addCurrency'

@Component({
  filters: {
    mathCeil,
    addCurrency
  },
  components: {
    PaxPricesTooltip
  }
})
export default class HotelPrice extends Vue {
  @Prop(Object)
  currentPackage!: IPackageClass

  options = {
    placement: 'top'
  }
}
