import cloneDeep from 'lodash/cloneDeep'

import type {
  CustomerResource
} from 'be-structures/typescript-generator/assembly'

import {
  AddressesCollection,
  MediaCollection,
  ICustomerResource,
  CUSTOMER_BLANK
} from 'modules/common/common.types'

export const convertCustomerResourceToICustomerResource = (({ customer }: { customer?: CustomerResource }): ICustomerResource => {
  const convertedCustomer = cloneDeep(CUSTOMER_BLANK)

  if (customer) {
    for (const item of Object.keys(CUSTOMER_BLANK)) {
      convertedCustomer[item] = customer[item]
    }
    convertedCustomer.addresses = new AddressesCollection(customer.addresses)
    convertedCustomer.media = new MediaCollection(customer.media)
  }
  
  return convertedCustomer
})


// TODO: add CustomerResource type
export const convertToCustomerCreate = (({ customer }: { customer: ICustomerResource }): any => {
  return {
    ...customer,
    media: customer.media.convertToMediaResource(),
    addresses: customer.addresses.content.REGISTRATION.content.country ? [customer.addresses.content.REGISTRATION.convertToRequest()] : [],
    passport: customer.passport.passportNumber ? customer.passport : null
  }
})
