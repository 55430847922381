
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { IPackageHotelClass } from 'applications/desktop/package-compare-app/components/create-search/types/create-search.types'

@Component({})
export default class HotelName extends Vue {
  @Prop(Object) hotel!: IPackageHotelClass
  @Prop(Boolean) isFavoritePackage!: boolean

  parentWidth: number = null
  childWidth: number = null
  overflowDifference: number = null

  get scrolled():boolean{
    return window.scrollY>0
  }

  get isContentOverflow(): boolean {
    return this.parentWidth < this.childWidth
  }
  get isRTL(): boolean {
    return document.body.getAttribute('dir') === 'rtl'
  }

  showContent(isMouseOver: boolean) {
    if (isMouseOver && this.isContentOverflow) {
      (this.$refs.childHotelNameBlock as HTMLElement).style.transform = this.isRTL
        ? `translateX(${this.overflowDifference}px)`
        : `translateX(-${this.overflowDifference}px)`
    } else if (!isMouseOver && this.isContentOverflow) {
      (this.$refs.childHotelNameBlock as HTMLElement).style.transform = `translateX(0)`
    }
  }

  recalcSize() {
    this.parentWidth = parseInt(getComputedStyle(this.$refs.parentHotelNameBlock as Element).width)
    this.childWidth = parseInt(getComputedStyle(this.$refs.childHotelNameBlock as Element).width)
    this.overflowDifference = this.childWidth - this.parentWidth
  }

  mounted() {
    this.recalcSize()
    window.addEventListener('resize', this.recalcSize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.recalcSize)
  }

  updated() {
    if (parseInt(getComputedStyle(this.$refs.childHotelNameBlock as Element).width) !== this.childWidth) {
      this.recalcSize()
    }
  }
}
