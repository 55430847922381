<template>
  <button
    class="reset-btn action-panel__btn action-panel__btn--submit"
    @click="$emit('click')"
  >{{ $t('common.reset') }}</button>
</template>

<script>
export default {
  name: 'reset-btn'
}
</script>

<style lang="scss" scoped>
@import 'src/ui-modules/variable';
@import '~applications/desktop/package-compare-app/components/root-app/style';

.reset-btn {
  margin: 0 5px;
  //padding: calc(2px + .2vw) 1.8vw;

  background: $text-color;
  border: 1px solid $clr-chi;
  border-radius: 2em;

  //font-size: calc(10px + .5vw);
  color: $clr-gamma;
  line-height: 1.5;

  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover:not(:disabled), 
  &:focus {
    color: $clr-gamma;
    background: #1fa2c7;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
