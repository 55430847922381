export default function(value: number | string | any, countDecimal: number = 2, fieldWithValue?: string) {
  if (value) {
    if ( typeof value === 'object') {
      const n: number = +value[fieldWithValue]
      if (typeof n === 'number') {
        return {...value, [fieldWithValue]: n.toFixed(countDecimal)}
      }
    } else {
      const n: number = +value
      if (typeof n === 'number') {
        return n.toFixed(countDecimal)
      }
    }
  }
  return 0
}
