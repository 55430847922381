
import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'

import DealDetailsSection
  from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.deal-details-section.vue'
import HotelSection
  from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.hotel-section.vue'
import HotelHeaderSection
  from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.hotel-header-section.vue'
import FacilitiesTypesSection
  from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.facilities-types.section.vue'
import DisabledFacilitiesSection
  from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.disabled-facilities.section.vue'
import ScoreSection
  from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.score-section.vue'
import OrderSection
  from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.order-section.vue'

import {
  HOTEL_INFO_COMPARISON_MAP,
  HOTEL_INFO_HEADER_COMPARISON_MAP,
  FIXED_HOTEL_INFO_HEADER_COMPARISON_MAP,
  HotelFacilitiesTypes,
  IPackageHotelFacility,
  IPackagesListClass
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    DealDetailsSection,
    DisabledFacilitiesSection,
    FacilitiesTypesSection,
    HotelSection,
    ScoreSection,
    OrderSection,
    HotelHeaderSection
  }
})
export default class ComparisonTable extends Vue {
  @Prop(Object) comparablePackages!: IPackagesListClass
  @Prop(Number) quentityOfComparablePackages!: number
  @Prop(Boolean) noFavoritePackages!: boolean
  @Prop(Array) facilitiesGroupsNames!: string[]
  @Prop(Object) post!: any
  @Prop(Array) disabledFacilities!: IPackageHotelFacility[]
  @Prop(Array) hotelScoreTypes!: string[]

  scrollY = 0;

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.onResize);
    })
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onResize);
  }

  onResize() {
    this.scrollY = window.scrollY
    if (!!scrollY) {
    }
  }

  get scrolled(): boolean {
    return this.scrollY > 0
  }

  hotelInfoComparisonMap = HOTEL_INFO_COMPARISON_MAP
  hotelInfoHeaderComparisonMap = HOTEL_INFO_HEADER_COMPARISON_MAP
  fixedHotelInfoHeaderComparisonMap = FIXED_HOTEL_INFO_HEADER_COMPARISON_MAP


  get hotelHeaderComparisonMap() {
    return this.scrolled ? this.fixedHotelInfoHeaderComparisonMap : this.hotelInfoHeaderComparisonMap
  }

  get hotelFacilitiesTypes(): string[] {
    return Object.keys(HotelFacilitiesTypes)
  }
}
