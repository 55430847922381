import Vue from 'vue'
import * as Sentry from "@sentry/vue";

import Desktop from 'applications/desktop/desktop.vue'
import i18n from 'applications/desktop/i18n'
import router from 'applications/desktop/router'
import { store, i18n_MODULE } from 'applications/desktop/package-compare-app/store/store'
import vuetify from './vuetify'
import InputFacade from 'vue-input-facade'
import maskOptions from 'common/mask/facade'
import MobileDetect from 'mobile-detect'
import { clickOutside } from "../../common/directives/click-outside";

const md = new MobileDetect(window.navigator.userAgent)

if ((md.mobile() || md.phone() || md.tablet()) && window.location.host[0] !== "m") {
  window.location.host = "m."+ window.location.host
}

Vue.use(InputFacade, maskOptions)
Vue.directive('outside-click', clickOutside)

Sentry.init({
              Vue,
              dsn: "https://91ba42f3a6c6d8b63115b3c4ce3a14be@o1126534.ingest.us.sentry.io/4507961063768064",
              integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration(),
              ],
              // Tracing
              tracesSampleRate: 1.0, //  Capture 100% of the transactions
              // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: ["localhost", "toptours.co.il", "dev1.tripsoft.pro/compare"],
              // Session Replay
              replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
              replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });

i18n_MODULE.setDefaultLocale()

new Vue({
  el: '#app',
  i18n,
  render: h => h(Desktop),
  router,
  store,
  vuetify
})
