import type { ValidationRule } from 'vuelidate/lib/validators'
import { regex } from 'vuelidate/lib/validators/common'

import {
  carOsagoInsuranceRuAlphaNumValid,
  carOsagoInsuranceAlphaNumValid,
  carOsagoInsuranceRuAlphaNumSecondValid
} from 'common/regex/regex'

export type TValidationObject<T> = {
  [key: string]: {
    [key in keyof T]: { [key in string]: (v: number | string | Date, object: any) => ValidationRule | boolean }
  }
}

export type TValidationArray<T> = {
  [key: string]: {
    $each: {
      [key in keyof T]: { [key in string]: (v: number | string | Date, object: any) => ValidationRule | boolean }
    }
  }
}

export const getValidationRegexp = (validationRule: string) => {
  return new RegExp(`^${validationRule}*$`)
}

// First checked phoneNumber rule, then phoneNumberMustStartWithPlus
// phoneNumber is true, if it contains numbers with '+' symbol ('+' is optional)
// phoneNumberMustStartWithPlus is true, if '+' sign at the beginning

export const phoneNumber = regex('phoneNumber', /^\+?(\d+\+?){2}$/)
export const phoneNumberMustStartWithPlus = regex('phoneNumberMustStartWithPlus', /^\+?\d+$/)

export const ruAlpha = regex('ruAlpha', /^[а-яА-ЯёЁ]*$/)
export const alphaSentence = regex('alphaSentence', /^[a-zA-Z-\s]*$/)
export const anyAlphaSentence = regex('alphaSentence', /[\u0590-\u05FFa-zA-Zа-яА-ЯёЁ]/)
export const heAlphaSentence = regex('heAlphaSentence', /[\u0590-\u05FF]/)
export const ruAlphaSentence = regex('ruAlphaSentence', /^[а-яА-ЯёЁ-\s]*$/)
export const ruAlphaNum = regex('ruAlphaNum', /^[а-яА-ЯёЁ0-9]*$/)
export const carOsagoInsuranceAlphaNum = regex('carOsagoInsuranceAlphaNum', getValidationRegexp(carOsagoInsuranceAlphaNumValid))
export const carOsagoInsuranceRuAlphaNum = regex('carOsagoInsuranceRuAlphaNum', getValidationRegexp(carOsagoInsuranceRuAlphaNumValid))
export const carOsagoInsuranceRuAlphaNumSecond = regex('carOsagoInsuranceRuAlphaNumSecond', getValidationRegexp(carOsagoInsuranceRuAlphaNumSecondValid))

export const checkValidationRules = ({ $v, validationPath, field }: { $v, validationPath: string, field: string }): string => {
  const validation = $v[validationPath][field]

  if (!validation.$dirty && !validation.$model) return null
  else if (typeof validation.required !== 'undefined' && !validation.required) return 'validation.required'
  else if (typeof validation.numeric !== 'undefined' && !validation.numeric) return 'validation.numeric'
  else if (typeof validation.phoneNumber !== 'undefined' && !validation.phoneNumber) return 'validation.phoneNumber'
  else if (typeof validation.phoneNumberMustStartWithPlus !== 'undefined' && !validation.phoneNumberMustStartWithPlus) return 'validation.phoneNumberMustStartWithPlus'
  else if (typeof validation.alphaSentence !== 'undefined' && !validation.alphaSentence) return 'validation.alphaSentence'
  else if (typeof validation.minDateLimit !== 'undefined' && !validation.minDateLimit) return 'validation.minDateLimit'
  else if (typeof validation.dateLimitForADULT !== 'undefined' && !validation.dateLimitForADULT) return 'validation.dateLimitForADULT'
  else if (typeof validation.dateLimitForCHILD !== 'undefined' && !validation.dateLimitForCHILD) return 'validation.dateLimitForCHILD'
  else if (typeof validation.dateLimitForINFANT !== 'undefined' && !validation.dateLimitForINFANT) return 'validation.dateLimitForINFANT'
  else if (typeof validation.email !== 'undefined' && !validation.email) return 'validation.email'
  else if (typeof validation.ruAlpha !== 'undefined' && !validation.ruAlpha) return 'validation.ruAlpha'
  else if (typeof validation.ruAlphaSentence !== 'undefined' && !validation.ruAlphaSentence) return 'validation.ruAlphaSentence'
  else if (typeof validation.anyAlphaSentence !== 'undefined' && !validation.anyAlphaSentence) return 'validation.anyAlphaSentence'
  else if (typeof validation.heAlphaSentence !== 'undefined' && !validation.heAlphaSentence) return 'validation.heAlphaSentence'
  else if (typeof validation.ruAlphaNum !== 'undefined' && !validation.ruAlphaNum) return 'validation.ruAlphaNum'
  else if (typeof validation.alphaNum !== 'undefined' && !validation.alphaNum) return 'validation.alphaNum'
  else if (typeof validation.carOsagoInsuranceRuAlphaNum !== 'undefined' && !validation.carOsagoInsuranceRuAlphaNum) return 'validation.carOsagoInsuranceRuAlphaNum'
  else if (typeof validation.carOsagoInsuranceAlphaNum !== 'undefined' && !validation.carOsagoInsuranceAlphaNum) return 'validation.carOsagoInsuranceAlphaNum'
  else if (typeof validation.carOsagoInsuranceAlphaNumSecond !== 'undefined' && !validation.carOsagoInsuranceAlphaNumSecond) return 'validation.carOsagoInsuranceAlphaNumSecond'
  else if (typeof validation.minLength !== 'undefined' && !validation.minLength) return 'validation.minLength'
  else if (typeof validation.maxLength !== 'undefined' && !validation.maxLength) return 'validation.maxLength'
  else if (typeof validation.minValue !== 'undefined' && !validation.minValue) return 'validation.minValue'
  else if (typeof validation.maxValue !== 'undefined' && !validation.maxValue) return 'validation.maxValue'
  else null
}

export const getFieldError = ({ validation }: { validation: any }): string => {
  if (!validation.$dirty && !validation.$model) return null
  else if (typeof validation.phoneNumber !== 'undefined' && !validation.phoneNumber) return 'validation.phoneNumber'
  else if (typeof validation.phoneNumberMustStartWithPlus !== 'undefined' && !validation.phoneNumberMustStartWithPlus) return 'validation.phoneNumberMustStartWithPlus'
  else if (typeof validation.alphaSentence !== 'undefined' && !validation.alphaSentence) return 'validation.alphaSentence'
  else if (typeof validation.minDateLimit !== 'undefined' && !validation.minDateLimit) return 'validation.minDateLimit'
  else if (typeof validation.dateLimitForADULT !== 'undefined' && !validation.dateLimitForADULT) return 'validation.dateLimitForADULT'
  else if (typeof validation.dateLimitForCHILD !== 'undefined' && !validation.dateLimitForCHILD) return 'validation.dateLimitForCHILD'
  else if (typeof validation.dateLimitForINFANT !== 'undefined' && !validation.dateLimitForINFANT) return 'validation.dateLimitForINFANT'
  else if (typeof validation.required !== 'undefined' && !validation.required) return 'validation.required'
  else if (typeof validation.numeric !== 'undefined' && !validation.numeric) return 'validation.numeric'
  else if (typeof validation.email !== 'undefined' && !validation.email) return 'validation.email'
  else if (typeof validation.ruAlpha !== 'undefined' && !validation.ruAlpha) return 'validation.ruAlpha'
  else if (typeof validation.ruAlphaSentence !== 'undefined' && !validation.ruAlphaSentence) return 'validation.ruAlphaSentence'
  else if (typeof validation.anyAlphaSentence !== 'undefined' && !validation.anyAlphaSentence) return 'validation.anyAlphaSentence'
  else if (typeof validation.heAlphaSentence !== 'undefined' && !validation.heAlphaSentence) return 'validation.heAlphaSentence'
  else if (typeof validation.ruAlphaNum !== 'undefined' && !validation.ruAlphaNum) return 'validation.ruAlphaNum'
  else if (typeof validation.alphaNum !== 'undefined' && !validation.alphaNum) return 'validation.alphaNum'
  else if (typeof validation.carOsagoInsuranceRuAlphaNum !== 'undefined' && !validation.carOsagoInsuranceRuAlphaNum) return 'validation.carOsagoInsuranceRuAlphaNum'
  else if (typeof validation.carOsagoInsuranceAlphaNum !== 'undefined' && !validation.carOsagoInsuranceAlphaNum) return 'validation.carOsagoInsuranceAlphaNum'
  else if (typeof validation.carOsagoInsuranceAlphaNumSecond !== 'undefined' && !validation.carOsagoInsuranceAlphaNumSecond) return 'validation.carOsagoInsuranceAlphaNumSecond'
  else if (typeof validation.minLength !== 'undefined' && !validation.minLength) return 'validation.minLength'
  else if (typeof validation.maxLength !== 'undefined' && !validation.maxLength) return 'validation.maxLength'
  else if (typeof validation.minValue !== 'undefined' && !validation.minValue) return 'validation.minValue'
  else if (typeof validation.maxValue !== 'undefined' && !validation.maxValue) return 'validation.maxValue'
  else if (typeof validation.less60DaysFromToday !== 'undefined' && !validation.less60DaysFromToday) return 'validation.less60DaysFromToday'
  else null
}

export const getFieldErrorMessage = ({ localePath, validation, field }: { localePath: string, validation: any, field: string }): string => {
  const errorName = getFieldError({ validation })
  return errorName ? `${localePath}.${field}.validation.${errorName}` : null
}
