
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import FilterGroup from "ui-modules/products-filter/filter.group.vue"
import Multiselect from 'vue-multiselect'
import CustomTextField from "ui-modules/elements/text-field/text-field.vue"

@Component({
  components: {
    FilterGroup,
    Multiselect,
    CustomTextField
  }
})

export default class FilterHotelNameSearch extends Vue {
  @Prop(String)
  partOfHotelName!: string

  needIgnore = false
  timer: NodeJS.Timeout = null

  created() {
    if (this.partOfHotelName) {
      this.needIgnore = true // to avoid infinite reloading
    }
  }

  mounted() {
    this.$refs.hotelNameField.focus()
  }

  updateSorting(value: string) {
    if (value.length >= 3 || !value) {
      this.$emit('set-filter', {type: 'partOfHotelName', value})
    }
  }
  
  beforeDestroy() {
    clearTimeout(this.timer)
  }

  onInput(value: string) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (this.needIgnore) {
        this.needIgnore = false
        return
      }
      this.updateSorting(value)
    }, 1500)
  }
}
