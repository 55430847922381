
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class StringItem extends Vue {
  @Prop(String)
  item!: string
  @Prop(Boolean)
  disabled!: boolean
  @Prop(Boolean)
  checked!: boolean
  @Prop({ default: true })
  clickableLabel: boolean
  @Prop({ default: false })
  modal: boolean
}
