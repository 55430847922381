var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("tooltip"),_vm._v(" "),_c('div',{staticClass:"selector",class:{ 'selector--disabled': this.disabled },on:{"click":function($event){$event.preventDefault();return _vm.openMultiply.apply(null, arguments)}}},[(_vm.isMandatory)?_c('span',{staticClass:"selector__mandatory"},[_vm._v("*")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"selector-show"},[(_vm.selectorTranslateType === 'default')?_c('span',{class:{ changed: _vm.isChanged }},[_vm._v("\n        "+_vm._s(_vm.$t(
            `common.${_vm.getNormalizedName(_vm.typeSelector)}.${_vm.getNormalizedName(
              _vm.storeListValue[0]
            )}`
          ))+"\n      ")]):(_vm.selectorTranslateType === 'placeholders')?_c('span',{class:{ changed: _vm.isChanged }},[_vm._v("\n        "+_vm._s(_vm.isChanged
            ? _vm.storeListValue[0]
            : _vm.$t(
                `common.placeholders.${_vm.getNormalizedName(_vm.storeListValue[0])}`
              ))+"\n      ")]):(_vm.selectorTranslateType === 'none')?_c('span',{class:{ changed: _vm.isChanged }},[_vm._v("\n        "+_vm._s(_vm.storeListValue[0])+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"select-arrow",class:{ 'select-arrow--open': _vm.isOpen }}),_vm._v(" "),_c('div',{staticClass:"select-list",class:{ 'select-list--open': _vm.isOpen }},_vm._l((_vm.list),function(listElement,index){return _c('div',{key:index,class:{ active: _vm.storeListValue[0] === listElement },on:{"click":function($event){$event.preventDefault();return _vm.onChangeListElement(listElement)}}},[_vm._v("\n        "+_vm._s(_vm.selectorTranslateType === 'default'
            ? _vm.$t(
                `common.${_vm.getNormalizedName(
                  _vm.typeSelector
                )}.${_vm.getNormalizedName(listElement)}`
              )
            : listElement)+"\n      ")])}),0)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }