
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IPaxComplectsFilterPost,
  IPackageClass,
  IPackageProductClass
} from 'applications/desktop/package-app/components/result-search/types/result-search.types'

import PaxComplectsFlightFilter from './pax-complects.flight-filter.vue'
import TlIcon from 'ui-modules/icons/icon'
import dateTime from 'common/filters/datetime'
import countTime from 'common/filters/countTime'

import moment from 'moment'

@Component({
  components: {
    PaxComplectsFlightFilter,
    TlIcon
  },
  filters: {
    dateTime,
    countTime
  }
})
export default class OrderFlights extends Vue {
  @Prop(Object)
  filter!: {
    providersList: string[]
    providers: string[]
  }
  @Prop(Number)
  tabIndex!: number
  @Prop(Number)
  activeProduct!: number
  @Prop(Array)
  filteredProducts!: any
  @Prop(Object)
  currentPackage!: IPackageClass
  @Prop(Boolean)
  isTerminalSearch!: boolean

  get selectedProducts(): any[] {
    return this.tabIndex === 0
      ? this.filteredProducts
      : [this.filteredProducts[this.activeProduct]]
  }

  getTimeDifference(departure: string, arrival: string): number {
    const startDeparture = moment(departure).startOf('day')
    const startArrival = moment(arrival).startOf('day')

    return Number(moment(startArrival).diff(startDeparture, 'days'))
  }
}
