import {
  HotelFacilitiesCodes
} from 'ui-modules/products-filter/types/products-filter.types'

const ACTIVITIES_FACILITIES_CODES_FOR_FILTER = [
  HotelFacilitiesCodes.bar,
  HotelFacilitiesCodes.casino,
  HotelFacilitiesCodes.restaurants
]

const CHILDREN_FACILITIES_CODES_FOR_FILTER = [
  HotelFacilitiesCodes.childrenActivities,
  HotelFacilitiesCodes.childrenClub,
  HotelFacilitiesCodes.babySitting
]

const COMFORT_FACILITIES_CODES_FOR_FILTER = [
  HotelFacilitiesCodes.television,
  HotelFacilitiesCodes.WIFI,
  HotelFacilitiesCodes.aircondition
]

const HEALTH_BEAUTY_FACILITIES_CODES_FOR_FILTER = [
  HotelFacilitiesCodes.spa
]

const OTHERS_FACILITIES_CODES_FOR_FILTER = [
  HotelFacilitiesCodes.businessCtr,
  HotelFacilitiesCodes.parking
]

export {
  ACTIVITIES_FACILITIES_CODES_FOR_FILTER,
  CHILDREN_FACILITIES_CODES_FOR_FILTER,
  COMFORT_FACILITIES_CODES_FOR_FILTER,
  HEALTH_BEAUTY_FACILITIES_CODES_FOR_FILTER,
  OTHERS_FACILITIES_CODES_FOR_FILTER
}