var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hotel-info__wrapper",class:{ 
  'hotel-info__wrapper--overflow-standard': _vm.isStandardOverflow,
  'hotel-info__wrapper--overflow-reversed': _vm.isReversedOverflow,
}},[_c('div',{ref:"hotelInfoParent",staticClass:"hotel-info__container",class:{ 
      'hotel-info__container--overflow': _vm.isContentOverflow,
      'hotel-info__container--overflow-standard': _vm.isStandardOverflow,
      'hotel-info__container--overflow-reversed': _vm.isReversedOverflow,
    },on:{"mouseover":function($event){return _vm.showContent(true)},"mouseleave":function($event){return _vm.showContent(false)}}},[_c('div',{ref:"hotelInfoChild",staticClass:"hotel-info__content"},[_vm._v(_vm._s(_vm.text))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }