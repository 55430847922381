
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Multiselect from 'vue-multiselect'
import capitalizeDestination from "common/filters/capitalizeDestination";

import { IQueryDestinations, IQueryLocation } from 'modules/product-search.v2/data/product-search.types'

@Component({
  filters: {
    capitalizeDestination
  },
  components: {
    Multiselect
  }
})
export default class CountrySelector extends Vue {
  @Prop(Object) destinations: IQueryDestinations
  @Prop(Object) selectedDestination: IQueryLocation

  searchString: string = null
  $refs: {
    destinationCountryList: Multiselect
  }

  mounted() {
    this.$refs.destinationCountryList.activate()
  }

  get countriesFilteredList() {
    if (this.searchString) {
      return Object.values(this.destinations).filter(item => {
        return item.country.name.indexOf(this.searchString.toUpperCase()) === 0
        // return item.country.name.includes(this.searchString.toUpperCase(), 0)
      })
    } else {
      return Object.values(this.destinations)
    }
  }
}
