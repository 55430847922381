
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { TProductPaxType } from 'modules/product-result.v2/data/package-result/package-result.product/package-result.product.types'

import OrderButton from 'ui-modules/buttons/order-btn.vue'
import mathCeil from 'common/filters/mathCeil'
import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'
import PaxPricesTooltip from 'ui-modules/tooltips/pax-prices-tooltip.vue'
import InfoIconSmall from 'ui-modules/svg-icon/info-icon-small.vue'
import { addCurrency } from 'common/filters/addCurrency'
import {PRODUCT_RESULT_MODULE} from "../../../store/store";
import {IModalPost} from "../../../../../../ui-modules/modals/modal.types";

@Component({
  components: {
    OrderButton,
    CustomTooltip,
    PaxPricesTooltip,
    InfoIconSmall
  },
  filters: {
    mathCeil,
    addCurrency
  }
})
export default class PriceBlock extends Vue {
  @Prop(Boolean) isFastOk!: boolean
  @Prop(Object) paxes!: TProductPaxType
  @Prop(Number) totalPriceWithoutDiscount!: number
  @Prop(Number) discountTotalPrice!: number
  @Prop(Number) averagePricePerPersonWithoutDiscount!: number
  @Prop(Number) averagePricePerPersonDiscount!: number
  @Prop(Boolean) getProductsDetailsPending!: boolean
  @Prop(Boolean) isDiscountTotalPrice!: boolean


  get additionClassWIthCondition() {
    return {
      'order-info__price--column-2': !this.isDiscountTotalPrice,
      'order-info__price--column-3': this.isDiscountTotalPrice
    }
  }

  get totalPrice(): number {
    return this.isDiscountTotalPrice ? this.discountTotalPrice :  this.totalPriceWithoutDiscount
  }

  get averagePricePerPerson(): number {
    return this.isDiscountTotalPrice ? this.averagePricePerPersonDiscount :  this.averagePricePerPersonWithoutDiscount
  }

  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }
  get getSessionReady(): boolean {
    return this.resultStore.isSessionReady
  }

  get isDel(): string {
    return this.isDiscountTotalPrice ? 'del' : ''
  }
  get productResult() {
    return PRODUCT_RESULT_MODULE
  }


  setModalPost() {
    const modalPost = {
      component: 'session-expired',
      preventDefaultClose: true
    }
    this.productResult.setModalPost({modalPost})
  }

  initOrder() {
    if(this.getSessionReady) {
      this.$emit('init-order')
    } else {
      this.setModalPost()
      this.resultStore.setIsShowModal({value: true})
    }
  }
}
