
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class Breadcrumbs extends Vue {
  @Prop(Array)
  items!: string[]

  get lastNavigationElementIndex(): number {
    return this.items.length - 1
  } 

  isRouteActive(name: string): boolean {
    return this.$route.name === name
  }

  setRoute(routeName: string, routeIndex: number) {
    this.$emit('set-normal-app-mode')

    // if (routeIndex === 0) {
    //   this.$router.push({ path: '/' })
    // } else {
    //   this.$router.push({ name: routeName })
    // }
  }
}
