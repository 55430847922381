
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
@Component({
  components: {
    ModalWindow
  }
})
export default class ModalSetAllParameters extends Vue {}
