import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import {
  SearchResultsViewPendingsNames,
  TSearchResultsViewPendings,
  IQueryLocation
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

import PRODUCT_RESULT_MODULE_VIEW from 'applications/desktop/package-compare-app/components/search-results/vuex/search-result.name'
import {isEmpty} from "lodash";

@Module({ name: PRODUCT_RESULT_MODULE_VIEW, namespaced: true })
export default class extends VuexModule {
  private _selectedCountryCode: string = null
  private _selectedCityCode: string = null
  private _visibleDestinations: IQueryLocation = null
  private _quantityOfVisiblePackages: number = 5
  private _pendings: TSearchResultsViewPendings = {
    initializeFilter: false,
    applyFilter: false,
    gettingPrimaryData: false
  }


  get selectedCountryCode() {
    return this._selectedCountryCode
  }

  get selectedCityCode() {
    return this._selectedCityCode
  }

  get quantityOfVisiblePackages() {
    return this._quantityOfVisiblePackages
  }

  get visibleDestinations() {
    return this._visibleDestinations
  }

  get pendings() {
    return this._pendings
  }


  @Mutation
  resetStore() {
    this._selectedCountryCode = null
    this._selectedCityCode = null
    this._quantityOfVisiblePackages = 5
    this._visibleDestinations = null
    this._pendings = {
      initializeFilter: false,
      applyFilter: false,
      gettingPrimaryData: false
    }
  }

  @Mutation
  setPending({ pendingName, value }: { pendingName: keyof typeof SearchResultsViewPendingsNames, value: boolean }) {
    this._pendings[pendingName] = value
  }

  @Mutation
  setSelectedCountryCode({ countryCode }: { countryCode: string }) {
    this._selectedCountryCode = countryCode
    this._selectedCityCode = null
  }

  @Mutation
  setSelectedCityCode({ cityCode, countryCode }: { cityCode: string, countryCode: string }) {
    this._selectedCityCode = cityCode
    this._selectedCountryCode = countryCode
  }

  @Mutation
  initialVisibleDestination() {
    this._visibleDestinations = null
  }

  @Mutation
  setVisibleDestination({ destination }: { destination: IQueryLocation }) {
    if (destination && !isEmpty(destination?.country)) {
      this._visibleDestinations = {
        ...this._visibleDestinations,
        [destination.country.code]: destination
      }
    }
  }

  @Mutation
  setQuantityOfVisiblePackages({ count }: { count: number }) {
    this._quantityOfVisiblePackages = count
  }
}
