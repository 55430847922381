
import Vue from 'vue'
import { Prop, Component, Ref, Watch } from 'vue-property-decorator'

import FormFillStepper from 'applications/desktop/package-compare-app/components/order/ui/form-fill-stepper/form-fill-stepper.vue'
import MainContent from 'applications/desktop/package-compare-app/components/order/ui/stepper/second-step/main-content/main-content.vue'
import OrderSummary from 'applications/desktop/package-compare-app/components/order/ui/order-summary/order-summary.vue'
import GammaBtn from 'ui-modules/buttons/gamma-btn/gamma-btn.vue'

import {
  IOrderPost,
} from 'applications/desktop/package-compare-app/components/order/types/order.types'
import GammaBtnOrange from "../../../../../../../../ui-modules/buttons/gamma-btn/gamma-btn-orange.vue";
import {Getter} from "vuex-class";
import RippleLoader from "../../../../../../../../ui-modules/ripple-loader/ripple-loader.vue";

@Component({
  components: {
    RippleLoader,
    GammaBtnOrange,
    FormFillStepper,
    MainContent,
    OrderSummary,
    GammaBtn
  }
})
export default class OrderSecondStep extends Vue {
  @Prop(Object)
    post!: IOrderPost;

  @Getter(`PAYMENT/retryCount`)
  retryCount!: number;

  openedFormId: string = "0";

  get isDisabled(): boolean {
    return Boolean(this.openedFormId);
  }

  get isPaymentPending(): boolean {
    return this.post.orderPendings.startOrderReservation
  }

  get gammaBtnTitle(): string {
    if (this.retryCount === 0) {
      return this.isDisabled ? this.$t("order-v2.order-stepper.2-step.btn") : this.$t("order-v2.order-stepper.2-step.pay")
    }
    return this.$t("order-v2.order-stepper.2-step.repeat-payment")
  }

  created() {
    this.$emit('begin-order')
  }

  onGammaBtnClick(): void {
    if (this.retryCount === 0) {
      this.$emit('show-finish-booking-modal')
    } else {
      this.$emit('repeat-payment')
    }
  }
}
