import type {
  MediaType
} from 'be-structures/typescript-generator/assembly'
import {
  IMediaClass,
  IMediaResource,
  setClassName
} from 'modules/common/common.types'

export class Media implements IMediaClass {
  private _media: IMediaResource = null

  constructor({ media, type }: { media?: IMediaResource, type?: MediaType }) {
    setClassName(Media.name, this)

    this._media = {
      ...media,
      mediaType: type,
    }
  }

  get content() {
    return this._media
  }

  get type() {
    return this._media.mediaType
  }

  get value() {
    return this._media.value || null
  }

  set content(value: IMediaResource) {
    this._media = value
  }
}