
import { Prop, Component } from 'vue-property-decorator'
import ModalBase from 'ui-modules/modals/modal-base.vue'
import capitalizeDestination from "common/filters/capitalizeDestination";
import eventBus from 'applications/desktop/eventBus'
import Switcher from 'ui-modules/switcher/switcher.vue'

import {
  IQueryDestinations,
  IQueryLocation,
} from 'modules/product-search.v2/data/product-search.types'
import Vue from "vue";
import { PACKAGE_COMPARE_DESKTOP_MODULE, PRODUCT_SEARCH_MODULE } from 'applications/desktop/package-compare-app/store/store';

@Component({
  components: {
    ModalBase,
    Switcher
  },
  filters: {
    capitalizeDestination
  }
})
export default class DestinationBlockCompact extends Vue {
  @Prop(Object) selectedDestination: IQueryLocation
  @Prop(Object) destinations: IQueryDestinations
  @Prop(Boolean) destinationsPending: boolean
  @Prop(Boolean) isAnyCountry: boolean
  @Prop(Boolean) exactDates: boolean

  isExactCountry = false
  showDestinationModal = false

  get countrySelected() {
    return !!this.selectedDestination?.country
  }

  get exactLabel(): string {
    return this.countrySelected
      ? this.selectedDestination.country.name
      : this.$tc('common.countrySelector.countryExact')
  }

  get searchStore() {
    return PRODUCT_SEARCH_MODULE
  }

  get selectCountryModalPost() {
    return {
      component: 'select-country',
      destinationsPending: this.destinationsPending,
      destinations: this.destinations,
      preventDefaultClose: true,
      value: this.selectedDestination || {}
    }
  }

  get packageCompareAppStore() {
    return PACKAGE_COMPARE_DESKTOP_MODULE
  }

  created() {
    this.isExactCountry = !this.isAnyCountry

    eventBus.$on('country-type-any', () => {
      this.searchStore.initializeSearchQuery({ searchQuery: this.packageCompareAppStore.vacationTermBlank })
      this.onCountryTypeClick(false)
    })

    eventBus.$on('country-type-exact', () => {
      this.onCountryTypeClick(true)
    })
  }

  onSelectDestination(destination: IQueryLocation) {
    this.showDestinationModal = false
    this.$emit('apply', { [destination.country.code]: destination })
  }

  reselectDestination() {
    this.$emit('refresh-countries-list')
    this.openDestinationModal()
  }

  openDestinationModal() {
    this.showDestinationModal = true
  }

  closeDestinationModal() {
    this.showDestinationModal = false

    const querySnapshot = this.searchStore.searchQuerySnapshot
    this.isExactCountry = querySnapshot ? !querySnapshot.isAnyCountry : false
    this.$emit('close-exact-country')
  }

  changeDatePrecision(selectedDestination: boolean) {
    this.$emit('change-query-precision', selectedDestination)
    if (selectedDestination) {
      this.openDestinationModal()
    }
  }

  onCountryTypeClick(isExactCountry: boolean) {
    if (!isExactCountry && this.isExactCountry === isExactCountry) {
      return
    }
    this.isExactCountry = isExactCountry
    this.changeDatePrecision(this.isExactCountry)
  }
}
