
import Mark from 'ui-modules/mark/mark.vue'
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import OrderSummaryItem from "../order-summary/order-summary-item.vue";

@Component({
  components: {
    OrderSummaryItem,
    Mark,
  }
})
export default class BoardingInfo extends Vue {
  @Prop(String) boarding: string
	@Prop({ type: String }) title: string
}
