
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import dayjs from 'dayjs'

import dateTime from 'common/filters/datetime'
import countTime from 'common/filters/countTime'
import { formatDateToDMYDot } from 'common/filters/formattedDates'
import { getImgUrl } from 'common/getImgUrl'

import TlIcon from "ui-modules/icons/icon"

import { SegmentResource } from 'be-structures/typescript-generator/assembly'
import * as moment from "moment";
import {ItineraryPointResource} from "be-structures/typescript-generator";
import CustomTooltip from "../tooltips/custom-tooltip.vue";

type TFlightType = 'return' | 'departure'

@Component({
	components: {
    CustomTooltip,
		TlIcon
	},
	filters: {
		dateTime,
		countTime,
		formatDateToDMYDot
	}
})
export default class ProductFeatures extends Vue {
  @Prop(Array) segments!: SegmentResource[]
	@Prop(String) flightType!: TFlightType
	@Prop({ type: Boolean, default: false, required: false}) arrivalDate!: boolean
	@Prop({ type: Boolean, default: false, required: false}) flightNumber!: boolean
	@Prop({ type: Boolean, default: false, required: false}) airline!: boolean

	getImgUrl = getImgUrl

  get mainDeparture(): ItineraryPointResource {
    return this.firstSegment.departure
  }

  get isExtraDay() {
    const departureDayPlusOne = moment(this.mainDeparture?.time).add(1, 'days').format('DD.MM.YYYY')
    const arrivalDay = moment(this.mainDeparture?.time).format('DD.MM.YYYY')
    return departureDayPlusOne === arrivalDay
  }

  get flightTypeKey(): string {
  	if (this.flightType === 'departure') return 'departure-flight'
  	else if (this.flightType === 'return') return 'return-flight'
  }

  get firstSegment() {
    return this.segments[0];
  }

  get lastSegment() {
    return this.segments.at(-1);
  }

  stopDebug: boolean = false

  get isHiveStops() {
    return this.segments?.length > 1 || this.stopDebug
  }

  get stopAirportCode() {
    return this.segments[0]?.arrival?.airport
  }

  get stopAirportName() {
    return this.segments[0]?.arrival?.airportName
  }

  get stopAirportCountry() {
    return this.segments[0]?.arrival?.countryCode
  }

  get stopTime() {
    const departureTime = moment(this.segments[1]?.departure.time)
    const arrivalTime = moment(this.segments[0]?.arrival?.time)
    const diffHH = departureTime.diff(arrivalTime, 'hours')
    const diffMM = departureTime.diff(arrivalTime, 'minutes') % 60

    return `${diffHH}h ${diffMM}m`
  }
}
