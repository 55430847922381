
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import dateTime from "common/filters/datetime"

import {
  IPackageProductClass
} from "applications/desktop/package-compare-app/types/package-compare-app.types"

import WaitingFlightsTimingTooltip from 'ui-modules/tooltips/waiting-flights-timing-tooltip/waiting-flights-timing-tooltip.vue'

@Component({
  components: {
    WaitingFlightsTimingTooltip
  },
  filters: {
    dateTime
  }
})
export default class FlightDetails extends Vue {
  @Prop(Array) segmentGroups!: IPackageProductClass['segmentGroups']

}
