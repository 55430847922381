var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"main-page-selector date-select-block"},[_c('div',{staticClass:"main-page-selector__icon"},[_c('img',{attrs:{"src":require('images/screen1/calendar.png'),"alt":"Select dates"}})]),_vm._v(" "),_c('div',{staticClass:"main-page-selector__title"},[_vm._v(_vm._s(_vm.$t('common.dates'))+":")]),_vm._v(" "),_c('div',{staticClass:"main-page-selector__content cursor-pointer",on:{"click":_vm.openDatesModal}},[(!_vm.dateIntervalExist)?_c('div',{staticClass:"main-page-selector__placeholder"},[_vm._v(_vm._s(_vm.$t('common.clickToChoose')))]):_c('div',{staticClass:"main-page-selector__value"},[_vm._v(_vm._s(_vm.dateRangeForShow))]),_vm._v(" "),(_vm.dateIntervalExist)?_c('img',{staticClass:"main-page-selector__change-value",attrs:{"src":require('images/change-value.png'),"alt":"Change value"}}):_vm._e()])]),_vm._v(" "),_c('transition',{attrs:{"name":"opacity-fade"}},[(_vm.showDatesModal)?_c('modal-base',{attrs:{"post":{
        component: !_vm.selectedDestination ? 'datepicker' : 'price-datepicker',
        preventDefaultClose: true,
        value: _vm.dateInterval,
        departureDates: _vm.departureDates,
        returnDates: _vm.returnDates,
        getAndSetReturnDatesByCountry: _vm.searchStorePendings.getAndSetReturnDatesByCountry,
        getAndSetDepartureDatesByCountry: _vm.searchStorePendings.getAndSetDepartureDatesByCountry,
        locale: _vm.locale
      }},on:{"close":_vm.closeDatesModal,"get-return-dates":(d) => _vm.$emit('get-return-dates', d),"select-dates":_vm.selectDates}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }