import { ICustomerResource } from 'applications/desktop/package-compare-app/components/order/types/order.types'

export const convertICustomerResourceToCustomerInfoForm = ({ data }: { data: ICustomerResource }): any => {
  return {
    lastName: data.customerInfo.lastName,
    firstName: data.customerInfo.firstName,
    birthday: data.customerInfo.birthday,
    gender: data.customerInfo.gender,
    sitizenshipCountry: data.addresses.content.REGISTRATION.country,
    passportNumber: data.passport.passportNumber,
    passportExpiryDate: data.passport.passportExpirationDate
  }
}
