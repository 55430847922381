
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcon
  }
})

export default class BoardingChangeNotification extends Vue {
  @Prop({type: Boolean}) isSingleType: boolean

  get text(): string {
    return this.isSingleType ? this.$tc('selector.boarding.no-options') : this.$tc('order-v2.changing-boarding-type')
  }
}
