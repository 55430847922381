
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import Gmap from 'ui-modules/googleMaps/googleMaps.vue'
import Stars from "ui-modules/cards/hotel/stars.vue";

import { IPackageHotelClass } from 'applications/desktop/package-app/components/order-product/types/order-product.types'
import { TGoogleMapLocations } from 'ui-modules/googleMaps/googleMaps.types'

@Component({
  components: {
    Stars,
    Gmap
  }
})
export default class HotelDetails extends Vue {
  @Prop(Object) hotel!: IPackageHotelClass
  @Prop(Array) locations!: TGoogleMapLocations
  @Prop(String) boardingType!: String

  get preparedHotelMedia() {
    return this.hotel.media.filter(media => media.value && media.value.trim());
  }
}
