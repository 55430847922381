
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Multiselect from 'vue-multiselect'

import { IPaxComplectsFilterPost } from 'applications/desktop/package-app/components/result-search/types/result-search.types'

@Component({
  components: {
    Multiselect
  },
  name: 'pax-complects-filter'
})
export default class PaxComplectsFlightFilter extends Vue {
  @Prop(Object)
  filter!: {
    providersList: string[]
    providers: string[]
  }

  addProvider(targetProvider: string): void {
    const providers = Array.from(this.filter.providers)
    providers.push(targetProvider)
    this.$emit('confirm-filter', { providers })
  }

  removeProvider(targetProvider: string): void {
    let providers = Array.from(this.filter.providers)
    if (providers.length > 1) {
      providers = providers.filter(sp => sp !== targetProvider)
      this.$emit('confirm-filter', { providers })
    }
  }
}
