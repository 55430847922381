
import Vue from "vue";
import { Prop, Component, Ref, Watch } from "vue-property-decorator";

import FlightInformation from 'applications/desktop/package-compare-app/components/order/ui/flight-information/flight-information.vue'
import HotelDetails from 'applications/desktop/package-compare-app/components/order/ui/hotel-details/hotel-details.vue'
import PassengersAndAccomodation from 'applications/desktop/package-compare-app/components/order/ui/passengers-and-accomodation/passengers-and-accomodation.vue'
import ToBePaid from 'applications/desktop/package-compare-app/components/order/ui/to-be-paid/to-be-paid.vue'
import PaymentMessage from "applications/desktop/package-compare-app/components/order/ui/payment-message/payment-message.vue";
import TransferInfo from "applications/desktop/package-compare-app/components/order/ui/transfer-info/transfer-info.vue";
import OurContacts from "applications/desktop/package-compare-app/components/order/ui/our-contacts/our-contacts.vue";

import {
  ICustomerClass, IMoneyResource, IOrderClass,
  IOrderPost, IPackageClass, IPackageProductDetailsClass, TRoomsAdditions, TRoomsCustomer,
} from 'applications/desktop/package-compare-app/components/order/types/order.types'
import {PRODUCT_ORDER_MODULE} from "applications/desktop/package-compare-app/store/store";


@Component({
  components: {
    FlightInformation,
    HotelDetails,
    PassengersAndAccomodation,
    ToBePaid,
    PaymentMessage,
    TransferInfo,
    OurContacts
  }
})
export default class SuccessfulBookingWithNoPaymentNoPost extends Vue {
  @Prop({type: Object, required: true}) owner!:{ fullName: string }
  @Prop({type: Object, required: true}) destinationPackage!: IPackageClass
  @Prop({type: Object, required: true}) customers!:TRoomsCustomer
  @Prop({type: Object, required: true}) additions!: TRoomsAdditions
  @Prop({type: Object, required: true}) orderTotalPrice!: IMoneyResource
  @Prop({type: Object, required: true}) productDetails!: IPackageProductDetailsClass
  @Prop({type: Object, required: true}) order: {number: number}


  async created() {
    await this.orderStore.getAgencyInfo()
  }

  get agencyInfo() {
    return this.orderStore.agencyInfo
  }

  get agency() {
    return this.orderStore.agencyInfo.agency
  }

  get dealFeatures() {
    return this.destinationPackage?.selectedProduct?.dealFeatures
  }

  get bookingNumber() {
    return this.order.number
  }

  get luggageInfo() {
    if (this.productDetails.additionalPayments.length) return this.productDetails.additionalPayments[0]
  }

  get isIncludedTransfer() {
    return this.destinationPackage.selectedProduct.hasTransfer;
  }

  get dates() {
    return this.destinationPackage.selectedProduct.content.dealInterval;
  }

  get orderStore() {
    return PRODUCT_ORDER_MODULE
  }

  get ourContacts() {
    return {
      phone: this.agency.phone,
      mail: this.agency.email,
      address: this.agency.addresses,
      name: this.agency.name
    }
  }
}
