
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'

import CustomTextField from 'ui-modules/elements/text-field/text-field.vue'
import { formatDateToYMD, formatDateToDMYDot } from 'common/filters/formattedDates'
import {Getter} from "vuex-class";
import i18n from "../../../modules/i18n/i18n.name";

@Component({
  components: {
    CustomTextField
  },
  filters: {
    formatDateToYMD,
    formatDateToDMYDot
  }
})
export default class CustomDatePicker extends Vue {
  @Prop({ type: String, default: null }) label!: string
  @Prop({ type: String, default: null }) placeholder!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: [Date, String], default: null }) value!: Date | string
  @Prop({ type: Boolean, default: true }) startWithYearSelecting!: boolean
  @Prop({ type: [Date, String], default: null }) maxDate!: Date | string
  @Prop({ type: [Date, String], default: null }) minDate!: Date | string
  @Prop({ type: String, default: null }) errorMessages!: string
  @Prop({ type: String, default: null }) persistentHint!: string
  @Prop({ type: Boolean, default: false }) isWarningHint!: boolean
  @Prop({ type: Boolean, default: false }) hideDetails!: boolean
  @Getter(`${i18n}/currentLocale`)
  currentLocale: string

  @Watch('showDatePicker')
  setYearMode(showDatePicker) {
    // @ts-ignore
    this.startWithYearSelecting && showDatePicker && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
  }

  showDatePicker: boolean = false
  readonly calendarDateFormat: string = 'YYYY-MM-DD'
  readonly inputDateFormat: string = 'DD.MM.YYYY'

  get getLocale(): string {
    return this.currentLocale
  }

  onInputDate(d: string, withCalendar?: boolean) {
    let date = moment(d, withCalendar ? this.calendarDateFormat : this.inputDateFormat, true)

    if (date.isValid()) {
      this.$emit('input', formatDateToYMD(date.toString()))
    } else {
      this.$emit('input', null)
      this.$refs['input']['model'] = null
    }

    this.showDatePicker = false
  }
}
