
import SearchResultsComp from "applications/desktop/package-compare-app/components/search-results/search-results";
import Shepherd from "shepherd.js";
import 'shepherd.js/dist/css/shepherd.css';

import {Component} from "vue-property-decorator";
import {
  initSharpedConfig,
  userTrainingCountryDetails,
  userTrainingResultPage
} from "../../../../../common/userTrainingSteps";
import eventBus from "../../../eventBus";
import ClockLoader from "../../../../../ui-modules/clock-loader/clock-loader.vue";
import RippleLoader from "ui-modules/ripple-loader/ripple-loader.vue";
import ModalIntroduction from "ui-modules/modals/modal-content/modal-introduction/modal-introduction.vue";

@Component({
  components: {
    ClockLoader,
    ModalIntroduction,
    RippleLoader,
  }
})
export default class SearchResults extends SearchResultsComp {
  tour = new Shepherd.Tour({
    ...initSharpedConfig
  });
  created() {
    this.initUserTrainingOnResultPage()
  }
  showUserTrainingOnResultPage() {
    // setTimeout(() => {
    //   if(!localStorage.getItem('isShowUserTrainingResultPage')) {
    //     this.tour.start()
    //     console.log("showUserTrainingOnResultPage")
    //     localStorage.setItem('isShowUserTrainingResultPage', 'true')
    //   }
    // }, 4000)
  }
  initUserTrainingOnResultPage() {
    this.tour.addSteps(userTrainingResultPage(this.tour));
  }

  isNextStepTrainingOnceUsed = false
  nextStepTraining() {
    // this.tour = new Shepherd.Tour({
    //   ...initSharpedConfig
    // });
    // this.tour.addSteps(userTrainingCountryDetails(this.tour));
    // if(!localStorage.getItem('isShowUserTrainingCountryDetails') && !this.isNextStepTrainingOnceUsed) {
    //   this.tour.start()
    //   this.isNextStepTrainingOnceUsed = true;
    //   console.log("nextStepTraining")
    // }
    // localStorage.setItem('isShowUserTrainingCountryDetails', 'true')
  }
  stopTraining() {
    eventBus.$emit('stop-training')
    if (this.tour) {
      this.tour.complete()
      this.tour = null
    }
  }

}
