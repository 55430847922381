
import {Component} from 'vue-property-decorator';
import SuccessfulBookingWithNoPayment
  from "./ui/stepper/third-step/successful-booking-with-no-payment/successful-booking-with-no-payment.vue";
import {
  IPackageProductDetailsClass,
  PackageProductDetails
} from "../../../../../modules/product-result.v2/data/package-result/package-result.product-details/package-result.product-details.types";
import {
  IPackageClass,
  Package
} from "../../../../../modules/product-result.v2/data/package-result/package-result.package/package-result.package.types";
import {Customer, IMoneyResource, IPackageHotelConstructor, TRoomsAdditions, TRoomsCustomer} from "./types/order.types";
import SuccessfulBookingWithNoPaymentNoPost
  from "./ui/stepper/third-step/successful-booking-with-no-payment/successful-booking-with-no-payment-no-post.vue";
import http from "../../../../../common/http/http";
import {
  PaxComplectResource,
  PaxComplects,
  StatPackageOrderItemResource,
  StatPackageSearchItemResource,
  TravelOrderResource
} from "be-structures/typescript-generator";
import Vue from 'vue'
import {i18n_MODULE} from "../../store/store";

interface MappedPackageData {
  hotel: IPackageHotelConstructor
  products: StatPackageSearchItemResource[]
  minPrice: string
}

@Component({
             components: {SuccessfulBookingWithNoPaymentNoPost, SuccessfulBookingWithNoPayment}
           })
export default class OrderById extends Vue {

  created() {
    i18n_MODULE.changeLocale({ locale:this.langForDocument });

    this.getOrderData();
  }

  paxComplects: PaxComplects = null;

  packageData: MappedPackageData = null;

  totalPrice: IMoneyResource = null;

  orderItem: StatPackageOrderItemResource = null;

  order: TravelOrderResource = null;

  orderNumber: number = null;

  rawCustomers: TRoomsCustomer = null;

  rawAdditions: TRoomsAdditions = null;


  get orderId() {
    // return '3afd1561-5bfe-44a4-bad0-31c46612ef77';
    // return "f73f52c4-7d4c-4439-b41a-d5ab006e20bd";
    return this.$route.params['id'];
  }

  get langForDocument() {
    return this.$route.query['lang'] ?? 'he'
  }


  isLoaded = false;

  async getOrderData() {
    this.isLoaded = false;
    const packageOrderItems: StatPackageOrderItemResource[] = (await http.get(
        `/customer/orders/common/${this.orderId}/items`,
    ))
    const packageOrderItem: StatPackageOrderItemResource = packageOrderItems[0];
    const order: TravelOrderResource = await http.get(
        `/customer/orders/${this.orderId}/`,
    )

    this.order = order;

    this.orderItem = packageOrderItem;

    if (order.totalPrice) {
      this.totalPrice = order.totalPrice;
    }

    this.orderNumber = packageOrderItem.orderNumber
    const product = packageOrderItem.product


    const realComplects: PaxComplectResource[] = packageOrderItems.flatMap(item =>  item.product.preReservationDetails.paxComplectsResource.complects);
    realComplects.forEach((complect, ind) => {
      complect.originalPrice = packageOrderItems[ind].product.originalPrice;
      complect.price = packageOrderItems[ind].product.originalPrice;
    });
    this.paxComplects = {...packageOrderItem.product.preReservationDetails.paxComplectsResource, complects: realComplects};
    this.packageData = {
      hotel: {
        ...packageOrderItem.product.hotel,
        city: packageOrderItem.product.city
      } ,
      products: [packageOrderItem.product],
      minPrice: packageOrderItem.product.preReservationDetails.minPricePerOne.amount
    }

    this.rawCustomers = Object.values(packageOrderItems).reduce((acc, item, currentIndex) => {

      acc[currentIndex] = item.paxSlots.map((slot) => {
        return {
          customer: new Customer(slot.customer),
          type: slot.paxType
        }
      })

      return acc;
    }, {})

    this.rawAdditions =  Object.keys(this.customers).reduce((acc, roomId) => {

      acc[roomId] = {};

      this.customers[roomId].forEach((customer) => {
        acc[roomId][customer.customer.customerKey] = {
          additionalPayments: []
        }
      })

      return acc;
    }, {})

    this.isLoaded = true;
  }

  get owner(): { fullName: string } {
    if (!this.isLoaded) {
      return
    }
    const { firstName, lastName } = this.order.contactPerson.customerInfo
    const fullName = `${lastName} ${firstName}`
    return {fullName}
  }

  get destinationPackage(): IPackageClass {
    if (!this.isLoaded) {
      return
    }

    const details = new PackageProductDetails(this.paxComplects)
    const destinationPackage = new Package(this.packageData)

    destinationPackage.selectedProduct.details = details
    return destinationPackage
  }

  get customers(): TRoomsCustomer {
return this.rawCustomers
  }

  get additions(): TRoomsAdditions {
    if (!this.isLoaded) {
      return
    }
return this.rawAdditions
    /*
    {
      "[roomId]": {
        "[customerKey]": {
        additionalPayments: ...
      },
        "[customerKey]": {
        additionalPayments: ...
      },
    }
    }
     */

  }

  get orderTotalPrice(): IMoneyResource {
    if (!this.isLoaded) {
      return;
    }

    return this.totalPrice
  }

  get productDetails(): IPackageProductDetailsClass {
    if (!this.isLoaded) {
      return
    }
    return  new PackageProductDetails(this.paxComplects)
  }

  // get order(): { number: number } {
  //   if (!(this.paxComplects && this.packageData)) {
  //     return
  //   }
  //   return {number: this.orderNumber}
  // }
}
