
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import StringItem from "ui-modules/overflow/string-item.package.vue"
import ModalAgreement from 'ui-modules/modals/modal-content/modal-agreement/modal-agreement.vue'
import DeltaBtn from 'ui-modules/buttons/delta-btn/delta-btn.vue'

@Component({
  components: {
    ModalWindow,
    StringItem,
    DeltaBtn,
    ModalAgreement,
  }
})
export default class ModalFinishBooking extends Vue {

  terms = [
    {
      key: 'transaction-terms',
      id: 1,
      checked: false,
      componentName: 'GeneralTerms'
    },
    {
      key: 'cancellation-and-billing-terms',
      id: 2,
      checked: false,
      componentName: 'CancellationTerms'
    }
  ]

  modalPost: object = null

  beforeCreate() {
    this.$options.components.ModalBase = require('ui-modules/modals/modal-base.vue').default // to avoid circular dependencies
  }

  get getWarning() {
    const content: any = this.$t('modal.finish-booking.content.warning-read-terms')
    const text = content.match(/your\sresponsibility/)
    return content.replace(text, `<strong>${text}</strong>`)
  }

  get disabled() {
    const checkedTerms = this.terms.filter(item => item.checked)
    return !(checkedTerms.length === this.terms.length)
  }

  markTerm(item) {
    item.checked = !item.checked
  }

  openModalAgreement(componentName: string) {
    this.modalPost = {
      component: 'agreement',
      preventDefaultClose: true,
      componentName
    }
  }

  onAgree(componentName: string) {
    const requiredTerm = this.terms.find(term => term.componentName === componentName)
    requiredTerm.checked = true
    this.modalPost = null
  }
}
