import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import http from 'common/http/http'
import funcAPI from 'modules/product-order.v2/product-order.api'
import PRODUCT_ORDER_MODULE from 'modules/product-order.v2/product-order.name'

import {
  AgencyWebsiteResource,
  AsyncCallStatus, CustomerResource, WebsiteResource
} from 'be-structures/typescript-generator/assembly'

import {
  IOrderClass,
  Order,
  ICustomerResource,
  ICustomerClass,
  Customer,
  IOrderResource,
  ILocation, IPaxTypeInformation
} from 'modules/product-order.v2/data/product-order.types'
import {AxiosResponse} from "axios";
import I18nStore from "../i18n/i18n.store";

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
const api = funcAPI(http)

@Module({ name: PRODUCT_ORDER_MODULE, namespaced: true })
export default class extends VuexModule {
  private _order: IOrderClass = new Order(null)
  private _agencyId: string = null
  private _countries: ILocation[] = []
  private _agencyInfo: AgencyWebsiteResource = null
  private _customerDOBrule: IPaxTypeInformation = null
  private _error: Error = null


  get customerDOBrule() {
    return this._customerDOBrule
  }
  get agencyInfo() {
    return this._agencyInfo
  }
  get order() {
    return this._order
  }

  get agencyId() {
    return this._agencyId
  }

  get countries() {
    return this._countries
  }
  get error() {
    return this._error
  }



  @Mutation
  setCustomerDOBRule({rule}: {rule: IPaxTypeInformation}) {
    this._customerDOBrule = rule
}
  @Mutation
  setAgencyId({ agencyId }: { agencyId: string }) {
    this._agencyId = agencyId
  }

  @Mutation
  setOrder(order: IOrderClass) {
    this._order = order
  }

  @Mutation
  setOrderContactPerson({ contactPerson }: { contactPerson: ICustomerClass }) {
    this._order.contactPerson = contactPerson
  }

  @Mutation
  setOrderItems({ items }: { items: IOrderResource['items'] }) {
    this._order.items = items
  }

  @Mutation
  setCountriesList({ countries }: { countries: ILocation[] }) {
    this._countries = countries
  }
  @Mutation
  setAgencyInfo({ agencyInfo }: { agencyInfo: AgencyWebsiteResource }) {
    this._agencyInfo = agencyInfo
  }
  @Mutation
  setError({ error }: {error: Error}) {
    this._error = error
  }


  @Action
  async getPaxType({ productId, paxComplectId }: {productId: string, paxComplectId: string }) {
    try {
      const getPaxType: AxiosResponse<IPaxTypeInformation> = await api.getPaxType({productId, paxComplectId})
      this.context.commit('setCustomerDOBRule', {rule: getPaxType})
    } catch (e) {
      this.context.commit('setError', {error:e})
    }
  }

  @Action
  async getAgencyInfo() {
    const agencyInfo: AgencyWebsiteResource = await api.getDefaultWebsite()
    this.context.commit('setAgencyInfo', { agencyInfo })
  }
  @Action
  async getAndSetDefaultWebsite() {
    const agencyId: string = (await api.getDefaultWebsite()).agency.entityId
    this.context.commit('setAgencyId', { agencyId })
    return agencyId
  }

  @Action
  async beginOrder({ agencyId }: { agencyId: string }) {
    const orderResource = await api.beginOrder(agencyId)
    this.context.commit('setOrder', new Order(orderResource))
  }


  @Action
  async addProductToOrder(
    {
      orderId,
      productId,
      complectIds
    }: {
      orderId: string,
      productId: string,
      complectIds: string[]
    }
  ) {
    const items = await api.addProductToOrder({ orderId, productId, complectIds })
    this.context.commit('setOrderItems', { items })
  }

  @Action
  async addBaggageToCustomer(
    {
      orderId,
      itemId,
      customerId,
      additionalPaymentId
    }: {
      orderId: string,
      itemId: string,
      customerId: string,
      additionalPaymentId: string
    }
  ) {
    return api.addBaggageToCustomer({ orderId, itemId, customerId, additionalPaymentId })
  }

  @Action
  async registrationNewCustomer({ orderId, customer }: { orderId: string, customer: ICustomerResource }): Promise<CustomerResource> {
    const customerData: CustomerResource = await api.registrationNewCustomer({ orderId, customer })
    this.context.commit('setOrderContactPerson', { contactPerson: new Customer(customerData) })

    return customerData
  }

  @Action
  async setContactPerson({ orderId, customerId }: { orderId: string, customerId: string }) {
    return await api.setContactPerson({ orderId, customerId })
  }

  @Action
  async registationNewOwnerAndAddToOrder({ orderId, customer }: { orderId: string, customer: ICustomerResource }): Promise<CustomerResource> {
    const owner = await this.registrationNewCustomer({ orderId, customer })
    await this.setContactPerson({ orderId, customerId: owner.customerId })
    this.context.commit('setOrderContactPerson', { contactPerson: new Customer(owner) })
    return owner
  }

  @Action
  async addCustomerToOrderItem({ orderId, itemId, customerId }: { orderId: string, itemId: string, customerId: string }) {
    return api.addCustomerToOrderItem({ orderId, itemId, customerId })
  }

  @Action
  async registationNewCustomerAndAddToOrder({ orderId, itemId, customer }: { orderId: string, itemId: string, customer: ICustomerResource }): Promise<CustomerResource> {
    const owner = await this.registrationNewCustomer({ orderId, customer })
    await this.addCustomerToOrderItem({ orderId, itemId, customerId: owner.customerId })
    return owner
  }

  @Action
  async getPreReservationStatus({ orderId }: { orderId: string }): Promise<AsyncCallStatus> {
    return new Promise(async (resolve, reject) => {
      const data = await api.startOrderPreReservation({ orderId })

      if (data.status === 'AWAIT') {
        await delay(800)
        resolve(this.getPreReservationStatus({ orderId }))
      } else {
        resolve(data.status)
      }
    })
  }

  @Action
  async startReservationStatus({ orderId }: { orderId: string }): Promise<AsyncCallStatus> {
    return api.startOrderReservation({ orderId })
  }

  @Action
  async sendPdfResult({
    locale,
    orderId,
    mailsTo,
    Customer_name
  }: {
    locale: string
    orderId: string
    Customer_name: string
    mailsTo: string[]
  }) {
    console.log("sendPdfResult")

    return api.sendPdfEmail({
                              Agency_name: this.agencyInfo.agency.name,
                              Agent_mail: this.agencyInfo.agency.email,
                              Agent_phone: this.agencyInfo.agency.phone,
                              Customer_name: Customer_name,
                              locale,
                              orderId,
                              mailsTo
                            })
  }

  @Action
  async getReservationStatus({ orderId }: { orderId: string }): Promise<AsyncCallStatus | "ERROR"> {
    let interval;
    let poolExp =  false;
    let loopStatus = true;
    return new Promise(async (resolve, reject) => {
      interval = setInterval(() => {
        poolExp = true;
      }, 1000*2*60); // 1000 ms to sec 60 sec to min // total 2 min
      while (loopStatus) {
        try {
          const data = await api.getReservationStatus({orderId})
          if (poolExp) {
            clearInterval(interval);
            loopStatus = false;
            resolve("ERROR");
          }

          if (data.status === 'OK' || data.status === 'ERROR') {
            loopStatus = false;
            clearInterval(interval);
            resolve(data.status)
          }
          if (data.status === 'AWAIT') {
            await delay(800)
          } else {
            loopStatus = false;
            clearInterval(interval);
            resolve(data.status)
          }
        } catch (e) {
          resolve("ERROR")
          clearInterval(interval);
          loopStatus = false;
        }
      }
    })
  }

  @Action
  async getCountriesList() {
    const countries: ILocation[] = await api.getCountriesList()
    this.context.commit('setCountriesList', { countries })
  }
}
