import i18n from 'applications/desktop/i18n'
import {
  LocaleTypes,
  TLocaleType,
  ILocaleClass,
  setClassName
} from 'modules/i18n/data/i18n.types'

import * as en from 'modules/i18n/locales/en'
import * as fr from 'modules/i18n/locales/fr'
import * as he from 'modules/i18n/locales/he'
import * as ru from 'modules/i18n/locales/ru'

export default class Locale implements ILocaleClass {
  private _html: HTMLElement = document.getElementsByTagName('html')[0]
  private _messagesList: { [type in LocaleTypes]: { [keyword: string]: {} } } = {
    en,
    fr,
    he,
    ru
  }

  constructor(private _locale: TLocaleType) {
    setClassName(Locale.name, this)
  }

  get locale() {
    return this._locale
  }

  get usedLocale() {
    return this.getLocaleFromBrowser() || this.getLocaleFromStorage() || this.getLangHTMLAttribute() || this._locale
  }

  getLocaleFromBrowser() {
    return window.navigator.languages.find(language =>
        language === LocaleTypes.he
        || language === LocaleTypes.en
        || language === LocaleTypes.ru
        || language === LocaleTypes.fr
    ) as TLocaleType
  }

  set locale(localeValue) {
    this._locale = localeValue
  }

  getLocaleFromStorage() {
    return window.localStorage.getItem('app_locale') as TLocaleType || null
  }

  getLangHTMLAttribute() {
    if (this._html) {
      return this._html.getAttribute('lang') as TLocaleType
    }

    return null
  }

  getLocaleDirection(locale: TLocaleType): string {
    return locale === LocaleTypes.he ? 'rtl' : 'ltr'
  }

  getLocaleMessagesList(locale: TLocaleType): { [keyword: string]: {} } {
    return this._messagesList[locale]
  }

  getFormattedLocale(): string {
    switch (this.locale.toLowerCase()) {
      case 'ru':
        return 'ru-RU'
      case 'en':
        return 'en-US'
      case 'he':
        return 'iw-IL'
      default:
        return 'en-US'
    }
  }

  changeLocale(locale: TLocaleType) {
    const list: { [keyword: string]: {} } = this.getLocaleMessagesList(
      locale
    )
    i18n.locale = locale
    i18n.setLocaleMessage(locale, list?.default)
  }

  applyLocale(locale: TLocaleType) {
    if (this._html) {
      this._html.setAttribute('lang', locale)
    }
  }
}
