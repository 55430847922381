<template>
  <g>
    <path
      d="M11.61 23.07C11.35 23.07 11.14 22.99 10.98 22.83C10.82 22.65 10.74 22.43 10.74 22.17C10.74 21.91 10.82 21.7 10.98 21.54C11.14 21.38 11.35 21.3 11.61 21.3H18.09C18.35 21.3 18.56 21.39 18.72 21.57C18.9 21.75 18.99 21.97 18.99 22.23C18.99 22.47 18.9 22.67 18.72 22.83C18.56 22.99 18.35 23.07 18.09 23.07H11.61Z"
      fill="#434343"
      fill-opacity="0.5"
    />
    <circle cx="14.5" cy="21.5" r="14" stroke="#434343" stroke-opacity="0.5" />
  </g>
</template>

<script>
export default {
  name: 'minus'
}
</script>

<style scoped></style>
