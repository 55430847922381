
import { Prop, Component } from 'vue-property-decorator'
import ModalBase from 'ui-modules/modals/modal-base.vue'
import capitalizeDestination from "common/filters/capitalizeDestination";

import Switcher from 'ui-modules/switcher/switcher.vue'

import {
  IQueryDestinations,
  IQueryLocation,
} from 'modules/product-search.v2/data/product-search.types'
import Vue from "vue";

@Component({
  components: {
    ModalBase,
    Switcher
  },
  filters: {
    capitalizeDestination
  }
})
export default class DestinationSelector extends Vue {
  @Prop(Object) selectedDestination: IQueryLocation
  @Prop(Object) destinations: IQueryDestinations
  @Prop(Boolean) destinationsPending: boolean
  @Prop(Boolean) exactDates: boolean

  showDestinationModal = false

  get countrySelected() {
    return !!this.selectedDestination?.country
  }

  get countryName(): string {
    return this.countrySelected ?
        this.selectedDestination.country.name
        : this.$t('common.specific-country')
  }
  get selectCountryModalPost() {
    return {
      component: 'select-country',
      destinationsPending: this.destinationsPending,
      destinations: this.destinations,
      preventDefaultClose: true,
      value: this.selectedDestination || {}
    }
  }

  onSelectDestination(destination: IQueryLocation) {
    this.showDestinationModal = false
    this.$emit('apply', { [destination.country.code]: destination })
  }

  reselectDestination() {
    this.$emit('refresh-countries-list')
    this.openDestinationModal()
  }

  openDestinationModal() {
    this.showDestinationModal = true
  }

  closeDestinationModal(destination: IQueryLocation) {
    this.showDestinationModal = false

    if (!destination) {
      this.changeDatePrecision(false)
    }
  }

  changeDatePrecision(selectedDestination: boolean) {
    if (selectedDestination) {
      this.openDestinationModal()
    }
    this.$emit('change-query-precision', selectedDestination)
  }
}
