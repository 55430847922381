
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import {
  IRegularResultPost,
  ICity,
  IPackagesListClass,
  ILocation
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

import mathCeil from 'common/filters/mathCeil'
import capitalizeDestination from "common/filters/capitalizeDestination";
import isRTL from 'common/isRTL'
import { addCurrency } from "common/filters/addCurrency";

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
  filters: {
    mathCeil,
    capitalizeDestination
  }
})
export default class DestinationCitiesCarousel extends Vue {
  @Prop(Object) post!: IRegularResultPost
  @Prop(Object) destinationPackages!: IPackagesListClass;
  @Prop(Object) country!: ILocation
  @Prop(Array) cities!: ICity[];
  @Prop(Object) selectedCity!: ICity;
  @Prop(Boolean) isOpenedDestination!: boolean;

  addCurrency = addCurrency

  swiperOptions = {
    slidesPerView: 2,
    spaceBetween: 15,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      disabledClass: 'swiper-button-disabled'
    },
    breakpoints: this.swiperBreakpoints
  }

  get swiperBreakpoints() {
    if (isRTL()) {
      return {
        1000: {
          slidesPerView: 4,
        },
        1300: {
          slidesPerView: 5,
        },
        1400: {
          slidesPerView: 6,
        },
        1600: {
          slidesPerView: 7,
          spaceBetween: 20
        },
        1850: {
          slidesPerView: 8,
          spaceBetween: 20
        },
        2000: {
          slidesPerView: 9,
          spaceBetween: 25
        },
      }
    } else {
      return {
        1000: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
        1300: {
          slidesPerView: 5,
        },
        1700: {
          slidesPerView: 6,
          spaceBetween: 20
        },
        1850: {
          slidesPerView: 7,
          spaceBetween: 20
        },
        2000: {
          slidesPerView: 8,
          spaceBetween: 20
        },
        2200: {
          slidesPerView: 7,
          spaceBetween: 25
        },
      }
    }
  }

  get allDestinationsInfo() {
    const destinationInfo = this.post.allDestinationsInfo[this.country.code]

    if (destinationInfo && destinationInfo?.quantityOfPackages > 0) {
      return this.getDestinationInfo({
        minPrice: destinationInfo?.minPrice,
        quantityOfPackages: destinationInfo?.quantityOfPackages
      })
    }

    return null
  }

  get cityInfo() {
    return this.getDestinationInfo({
      minPrice: this.destinationPackages.minPrice,
      quantityOfPackages: this.destinationPackages.getQuantityOfPackages()
    })
  }

  getDestinationInfo({ minPrice, quantityOfPackages }: { minPrice: number, quantityOfPackages: number }): string {
    return `${quantityOfPackages} ${this.$t('order.search-results.hotel-card.hotels-lowercase')} - 
            ${this.$t('order.search-results.hotel-card.from')} ${minPrice !== Number.MAX_VALUE ? addCurrency(mathCeil(minPrice)) : '-'}`
  }

  onSlideClick(countryCode: string) {
    this.$emit("slide-click", countryCode);
  }
}
