
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Car from './car'
import Active from './active'

@Component({
  components: { Car, Active }
})
export default class svgIcon extends Vue {
  @Prop({ type: [Number, String], default: 65 })
  width?: [number, string]
  @Prop({ type: [Number, String], default: 65 })
  height?: string
  @Prop(String)
  iconName: string
  @Prop({ type: String, default: '0 0 120 120' })
  viewBox: string
}
