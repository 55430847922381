
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import TlIcon from "ui-modules/icons/icon";
import PaxIconsList from 'ui-modules/icons/pax-icons/pax-icons-list.vue'

import detectLinks from 'common/detectLinks'

import { IProductDetailsPaxComplectClass } from "applications/desktop/package-app/components/order-product/types/order-product.types";

@Component({
  components: { TlIcon, PaxIconsList }
})
export default class Remarks extends Vue {
  @Prop(Array) selectedPaxComplects!: IProductDetailsPaxComplectClass[];
  @Prop(Array) productRemarks!: string[];

  get remarkList() {
   return this.productRemarks.map(remark => {
      const url = detectLinks(remark)[0]
      return remark.replace(url,
        `<a class="order-remarks__product--remark-link" href="${url}" target="_blank">${url}</a>`
      )
    })
  }

  get showRemarksBlock() {
    return this.productRemarks || (this.complectsWithRemarks.length > 0)
  }

  get complectsWithRemarks() {
    return this.selectedPaxComplects.filter(item => {
      return item.roomRemark
    })
  }
}
