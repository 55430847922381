
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import DeltaBtn from 'ui-modules/buttons/delta-btn/delta-btn.vue'
import Remarks from 'applications/desktop/package-compare-app/components/order/ui/remarks/remarks.vue'
import { IProductDetailsPaxComplectClass } from "applications/desktop/package-app/components/order-product/types/order-product.types";

@Component({
  components: {
    ModalWindow,
    DeltaBtn,
    Remarks,
  }
})

export default class ModalRemarks extends Vue {
  @Prop(Array) selectedPaxComplects: IProductDetailsPaxComplectClass[];
  @Prop(Array) productRemarks: string[];
  @Prop(Object) post!: {
    selectedPaxComplects: IProductDetailsPaxComplectClass[],
    productRemarks: string[],
  };
}
