
import { Component, Vue } from 'vue-property-decorator';
import ModalCommon from 'ui-modules/modals/modal-common.vue';
import BtnAlpha from "ui-modules/buttons/btn-alpha.vue";

@Component({
  components: {
    ModalCommon,
    BtnAlpha,
  }
})
export default class ChangeLanguageConfirmation extends Vue {}
