import {
  ICustomerResource
} from 'modules/common/customer/customer.types';

import {
  AddressesCollection,
  MediaCollection,
  convertAddressResourceToIAddressResource,
  convertMediaResourceToIMediaResource
} from 'modules/common/common.types'

export const CUSTOMER_BLANK: ICustomerResource = {
  customerKey: null,
  customerId: null,
  type: 'Adult',
  customerInfo: {
    firstName: null,
    lastName: null,
    gender: null,
    birthday: null
  },
  addresses: new AddressesCollection([convertAddressResourceToIAddressResource({})]),
  media: new MediaCollection([convertMediaResourceToIMediaResource({})]),
  passport: {
    passportType: 'INTERNATIONAL_ID',
    passportNumber: null,
    passportExpirationDate: null
  }
}