import Component, { mixins } from 'vue-class-component'

import {
  IFavoriteResultPost,
  IPackageProductClass,
  IPackageHotelClass
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

import SearchResultsCommon from 'applications/desktop/package-compare-app/components/search-results/search-results.common'
import FavoriteResultsList from 'applications/desktop/package-compare-app/components/search-results/ui/search-results-list.favorite.vue'
import FavoritePackageFilter from 'applications/desktop/package-compare-app/components/search-results/ui/favorite-filter.vue'

@Component({
  components: {
    FavoritePackageFilter,
    FavoriteResultsList
  }
})
export default class FavoriteSearchResultsComp extends mixins(SearchResultsCommon) {
  get favoriteResultPost(): IFavoriteResultPost {
    return {
      ...this.resultPost
    }
  }

  get locations() {
    return this.resultStore.favoritePackages.content.map(fp => fp.hotel.locations[0])
  }

  onSelectProduct({ product }: { product: IPackageProductClass }) {
    this.setModalPost({modalPost: null})
    this.usedPackage.selectedProduct = product
  }

  showHotelInfo(hotel: IPackageHotelClass) {
    this.setModalPost({modalPost: null})
    this.openHotelCardModal(hotel)
  }
}
