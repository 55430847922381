import Component, { mixins } from 'vue-class-component'

import {
  PriceCalendarPeriodResource,
  DealFeature
} from 'be-structures/typescript-generator/assembly'

import {
  IStartEnd,
  IQueryDestinations,
  TPaxes
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import DestinationBlock from "applications/desktop/package-compare-app/components/prepare-search-data/ui/destination-block.vue";
import DateSelectBlock from "applications/desktop/package-compare-app/components/prepare-search-data/ui/date-select-block.vue";
import PassengersSelectBlock from "applications/desktop/package-compare-app/components/prepare-search-data/ui/passengers-select-block.vue";
import TypeRestFilter from 'ui-modules/typeRestFilter.vue'
import BestDeals from 'applications/desktop/package-compare-app/components/best-deal-results/best-deal-results.vue'
import ModalBase from 'ui-modules/modals/modal-base.vue'
import PrepareSearchDataCommon from 'applications/desktop/package-compare-app/components/prepare-search-data/prepare-search-data.common'
import RootCommon from 'applications/desktop/package-compare-app/components/root-app/root-app.common'
import SearchResultsCommon from 'applications/desktop/package-compare-app/components/search-results/search-results.common'
import BetaBtn from "ui-modules/buttons/beta-btn/beta-btn.vue"
import eventBus from 'applications/desktop/eventBus';

@Component({
  components: {
    BestDeals,
    ModalBase,
    TypeRestFilter,
    DestinationBlock,
    DateSelectBlock,
    PassengersSelectBlock,
    BetaBtn
  }
})
export default class PrepareSearchDataComp extends mixins(PrepareSearchDataCommon, SearchResultsCommon, RootCommon) {
  componentName = ''
  showNoResultsModal: boolean = false
  dontShowNoResultsModal: boolean = false
  showSetAllParamsModal: boolean = false
  isShowSpinner: boolean = false
  isShowDatepicker = false

  get hasVisibleDestinations(): boolean {
    return !!(this.resultStoreView.visibleDestinations && Object.keys(this.resultStoreView.visibleDestinations).length)
  }

  async created() {
    if (this.componentName === 'SiteHeader') {
      return // to avoid duplicating `created` method call from the SiteHeader component
    }

    if ( this.searchQuery &&
       ((this.searchQuery.isAnyCountry && this.hasVisibleDestinations) || (!this.searchQuery.isAnyCountry && this.selectedDestination))
       && this.$route.name === 'package-compare.search-results'
    ) {
      return // to avoid empty list when return back from the order screen
    }
    this.resultStore.setModalPost({modalPost: null})
    this.resultStore.resetStore()
    this.resultStore.setExpirationProductsTime(0)
    this.searchStore.initializeSearchQuery({ searchQuery: this.packageCompareAppStore.vacationTermBlank })

    if (!this.searchQuery.exactDates) {
      this.resultStoreView.resetStore()
      await this.packageCompareAppStore.getAndSetCachedVacationTerm()
      this.getCountriesDataPrimary({ isAnyCountry: true })
    }
  }
  get currentLocale(): string {
    return this.i18nStore.currentLocale
  }
  async setQueryDateInterval(interval: IStartEnd<string>) {
    this.searchQuery.dateInterval = interval
    await this.updateDestinationsAfterChangingQuery()
  }

  async setQueryDatePrecision(selectedDestination: boolean, isExactDate?: boolean) {
    // this.searchQuery.exactDates = exactDates // TODO make exactDates after BE fix range dates

    this.searchQuery.isAnyCountry = !selectedDestination
    if (selectedDestination) {
      this.searchQuery.exactDates = true;
      this.getCountriesDataPrimary({ initializeFilter: false }) // don't re-initialize filters until a country is selected
    } else {
      this.searchStore.takeSearchQuerySnapshot() // to start destination updating
      this.searchQuery.exactDates = isExactDate
      this.resultStoreView.setSelectedCountryCode({ countryCode: null })
      const dateInterval = this.searchQuery.dateInterval || this.packageCompareAppStore.vacationTermBlank.dateInterval
      await this.setQueryDateInterval(dateInterval)
    }
  }

  setQueryDealFeatures(dealFeatures: DealFeature[]) {
    this.searchQuery.setDealFeatures(dealFeatures)
    this.updateDestinationsAfterChangingQuery()
  }

  async setQueryDestination(destinations: IQueryDestinations) {
    const countryCode = Object.keys(destinations)[0]

    this.searchStore.setDestinations({ destinations })
    this.resultStoreView.setSelectedCountryCode({ countryCode })

    this.isShowDatepicker = true
    await this.searchStore.getAndSetDatesByCountry({
      countryCode,
      flightGroup: 'departures'
    })
  }

  setQueryPaxes(paxes: TPaxes) {
    this.searchQuery.paxes = paxes
    this.updateDestinationsAfterChangingQuery()
  }

  async updateDestinationsAfterChangingQuery() {
    if (this.searchQuery.isAnyCountry) {
      await this.getCountriesDataPrimary({isAnyCountry: this.searchQuery.isAnyCountry})
    }
  }

  getReturnDates(dateObject: PriceCalendarPeriodResource) {
    this.searchStore.getAndSetDatesByCountry({
      flightGroup: 'returns',
      // @ts-ignore
      departureDate: dateObject.departureDate
    })
  }

  canGoToSearch() {
    this.resultStore.setExpirationProductsTime(0)
    if (!this.searchStore.pendings.getAndSetCountries) {
      if (!this.searchQuery.dateIntervalExist()) {
        this.showSetAllParamsModal = true

      } else if (this.quantityOfDestinations > 0) {
        this.goToResultPage()

      } else {
        this.showNoResultsModal = !this.dontShowNoResultsModal

      }
    } else {
      this.isShowSpinner = true
    }
  }

  closeNoResultsModal(dontShow: boolean) {
    this.showNoResultsModal = false
    this.dontShowNoResultsModal = dontShow
  }

  closeSetAllParamsModal() {
    this.showSetAllParamsModal = false
  }
 
  onSelectDates(dates: string[]) {
    this.$emit('change-query-date-interval', { start: dates[0], end: dates[1] })
    this.isShowDatepicker = false
  }
}
