import Component, { mixins } from 'vue-class-component'

import {
  IPackageHotelClass,
  IPackageProductClass,
  IProductFilter,
  IRegularResultPost,
  IRegularFilterPost,
  TFilterAction,
  IAllDestinationInfo, IStatPackageSearchDigestResource, IPackageCompareProductFilterClass, IQueryLocation,
  THotelsSort
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

import {
  IConvertedQuery,
} from 'modules/common/common.types'

import eventBus from 'applications/desktop/eventBus'
import PackageFilter from 'applications/desktop/package-compare-app/components/search-results/ui/filter.vue'
import DestinationItem from "applications/desktop/package-compare-app/components/search-results/ui/destination-item.vue"
import SearchResultsCommon from 'applications/desktop/package-compare-app/components/search-results/search-results.common'
import RootCommon from 'applications/desktop/package-compare-app/components/root-app/root-app.common'
import OpenedDestinationItem from 'applications/desktop/package-compare-app/components/search-results/ui/opened-destination-item.vue'
import Accordion from 'ui-modules/accordion/accordion.vue'
import BoxLoader from 'ui-modules/marker-loader/marker-loader.vue'
import timeout from 'common/timeout'
import DestinationItemContent from "applications/desktop/package-compare-app/components/search-results/ui/destination-item-content/destination-item-content.vue"
import DestinationItemPlug from "applications/desktop/package-compare-app/components/search-results/ui/destination-item-plug/destination-item-plug.vue"
import { direction } from 'common/direction'
import { getCountriesLatLng } from 'ui-modules/googleMaps/googleMaps.handler'
import NoResultResetFiltersModal from "applications/desktop/package-compare-app/components/search-results/ui/search-modal.no-result/search-modal.no-result.reset-filters.vue";
import moment from "moment";
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    Accordion,
    BoxLoader,
    PackageFilter,
    OpenedDestinationItem,
    DestinationItem,
    DestinationItemContent,
    DestinationItemPlug,
    NoResultResetFiltersModal
  }
})
export default class SearchResultsComp extends mixins(SearchResultsCommon, RootCommon) {
  usedHotelId: string = null

  initialSegmentGroupIndex: number = 0
  isEmptyVisibleList = false
  isShowIntroduction = false
  filteredCountries: string[] = []
  filterAction: TFilterAction = null
  newFilterDigest: IStatPackageSearchDigestResource = null
  pendingTimer = null

  $refs: {
    openedDestinationItem: OpenedDestinationItem,
  }

  async created() {
    eventBus.$on('update-destinations-confirm', (reloadSession: boolean) => {
      this.updateDestinations(reloadSession)
    })

    eventBus.$on('open-introduction-dialog', () => {
      this.isShowIntroduction = true
    })

    this.isShowIntroduction = localStorage.getItem('dontShowIntroductionModalAgain') !== 'true'

    if(!!this.searchQuery) {
      if (!this.isTimeExpired && !this.i18nStore.langIsChangedOnOrder) {
        return
      }
      this.i18nStore.setLangIsChangedOnOrder({ flag: false })

      await this.initializeResultPage({
        initializeFilter: !this.filterInUse
      })
    } else {
      const isClearRoute =  ["orderById", "errorPayment", "cancelPayment", "successPayment"].includes(this.$route.name)
      if (!(isClearRoute)) {
        this.goToHomePage()
      }
    }

    if (this.resultStoreView.selectedCountryCode) {
      this.initializeDestinationContent()
    }

    eventBus.$on('update-destinations', () => {
      this.showSearchResetModal()
    })
  }

  beforeDestroy() {
    eventBus.$off('update-destinations-confirm')
  }

  get isTimeExpired() {
    const currentTime = Number(moment().format('x'))
    return this.resultStore.expirationProductsTime - currentTime <= 0
  }

  get isGettingPrimaryData(): boolean {
    return this.resultStoreView.pendings.gettingPrimaryData
  }

  get isSearchResultsPending(): boolean {
    return this.searchStore.pendings.getCountries as boolean
  }

  get filtersHasNaN(): boolean {
    return !this.regularFilterPost.packageFilter
        || this.regularFilterPost.packageFilter.price     .some(item => isNaN(item))
        || this.regularFilterPost.packageFilter.hotelStars.some(item => isNaN(item))
        || this.regularFilterPost.packageFilter.nights    .some(item => isNaN(item))
  }

  get hasDatesByCountry(): boolean {
    return this.searchStore.departureDatesByCountry ? !!Object.keys(this.searchStore.departureDatesByCountry).length : false
  }

  get regularResultPost(): IRegularResultPost {
    return {
      ...this.resultPost,

      exactDates: this.searchQuery.exactDates,
      isAnyCountry: this.searchQuery.isAnyCountry,
      dateInterval: this.searchQuery.dateInterval,
      hasDatesByCountry: this.hasDatesByCountry,
      resultPendings: this.resultStore.pendings,
      resultViewPendings: this.resultStoreView.pendings,
      searchPendings: this.searchStore.pendings,
      isSelectedCountry: (data) => this.isSelectedCountry(data),
      isSelectedCity: (data) => this.isSelectedCity(data),
      quantityOfVisiblePackages: this.resultStoreView.quantityOfVisiblePackages,
      allDestinationsInfo: this.resultStore.allDestinationsInfo
    }
  }

  get regularFilterPost(): IRegularFilterPost {
    return {
      ...this.filterPost,
      isFilterUsed: this.filterInUse,
      direction: direction(),
      exactDates: this.searchQuery.exactDates,
      previousFilter: this.resultStore.previousFilter,
      selectedCountryCode: this.resultStoreView.selectedCountryCode
    }
  }

  get destinationsCoordsForGoogleMap() {
    const countryCode = this.resultStoreView.selectedCountryCode

    if (countryCode) {
      return this.getDestinationPackages({ countryCode })?.content.map(p => p.hotel.geo) || []
    } else {
      return this.searchStore.countriesCodes.map(countryCode => getCountriesLatLng({ countryCode }))
    }
  }

  get actuallyVisibleDestinations(): IQueryLocation[] {
    const countries = this.resultStoreView.visibleDestinations ? Object.keys(this.resultStoreView.visibleDestinations) : []
    return countries.reduce((acc: IQueryLocation[], country) => {
      const destination = this.resultStoreView.visibleDestinations[country]
      if (this.noLoaded(destination.country.code)) {
        acc.push(destination)
      }
      return acc
    }, [])
  }

  get singleDestinationCountry() {
    return !this.searchQuery.isAnyCountry
  }

  noLoaded(countryCode: string) {
    return !this.searchStore.pendings.getCountry[countryCode] && !this.noHotelsReceived(this.resultStore.allDestinationsInfo[countryCode])
  }

  noHotelsReceived(destinationPackages: IAllDestinationInfo) {
    if (destinationPackages) {
      return !destinationPackages.minPrice
    }
    return true
  }

  isSelectedCountry({ countryCode }: { countryCode: string }) {
    return this.resultStoreView.selectedCountryCode === countryCode
  }

  isSelectedCity({ cityCode }: { cityCode: string }) {
    return this.resultStoreView.selectedCityCode === cityCode
  }

  selectDestination({ cityCode, countryCode }: { cityCode?: string, countryCode?: string }) {
    if (cityCode) {
      this.resultStoreView.setSelectedCityCode({ countryCode, cityCode })
    } else {
      this.resultStoreView.setSelectedCountryCode({ countryCode })
    }
  }

  async initializeDestinationContent() {
    if (!this.resultStoreView.selectedCountryCode) {
      this.initializeVisibleDestinations({ms: 0})
    } else {
      const countryCode = this.resultStoreView.selectedCountryCode

      this.resultStore.removeDestinationPackage({ countryCode })
      // TODD: вынести в константы 6, 5
      this.resultStoreView.setQuantityOfVisiblePackages({ count: this.singleDestinationCountry ? 6 : 5 })

      if (this.resultStoreView.selectedCountryCode) {
        if (!this.isSearchResultsPending) {
          this.scrollUpToContent()
        }
        this.initializeVisibleDestinations({ ms: 0 })
      }

      await this.resultStore.getAndSetDestinationHotels(this.resultPayload)
      await this.getAndSetPackageProductsForSelectedDestination({})
    }
  }

  toggleDestinationContent(destination: { cityCode?: string, countryCode?: string }) {
    this.selectDestination({ ...destination })
    this.initializeDestinationContent()
  }

  getAndSetPackageProductsForSelectedDestination({ quantityOfVisiblePackages = 0 }: { quantityOfVisiblePackages?: number }) {
    const countryCode = this.resultStoreView.selectedCountryCode
    for (const hotelId of this.getDestinationPackages({ countryCode }).hotelsIds.slice(quantityOfVisiblePackages, quantityOfVisiblePackages + 6)) {
      this.resultStore.getAndSetPackageProducts({
        ...this.resultPayload,
        hotelId,
        countryCode
      })
    }
  }

  scrollUpToContent() {
    const scrollableContent: HTMLElement = this.$refs.scrollableContent as HTMLElement
    if (!scrollableContent) {
      return
    }
    scrollableContent.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  changeQuantityOfVisiblePackages({ count }: { count: number }) {
    this.resultStoreView.setQuantityOfVisiblePackages({ count })
  }

  async regularApplyFilter() {
    if (this.selectedDestination) {
      this.toggleDestinationContent({})
    }

    this.resultStoreView.initialVisibleDestination()
    this.resultStore.setPrevFilter(this.resultStore.filter)

    await this.getCountriesDataPrimary({ ...this.resultPayload, isAnyCountry: true, initializeFilter: false })
    await this.initializeVisibleDestinations({ms: 0})
    await this.createCountryList({ initializeFilter: false })
  }

  async applyFilterForOneCountry() {
    await this.createCountryList({ initializeFilter: false })
    await this.initializeDestinationContent()
  }

  async applyFilter() {
    try {
      this.resultStoreView.setPending({ pendingName: 'applyFilter', value: true })

      this.resultStore.initializeAllDestinationsPackages()
      this.resultStore.initializeAllDestinationInfo()
      this.searchQuery.nights = this.filter.convertFilterConditionValue<number>(this.filter.nights)

      if (!this.searchQuery.isAnyCountry) {
        this.applyFilterForOneCountry()
      } else {
        this.regularApplyFilter()
      }

    } catch(error) {
      this.resetFilter()
      throw new Error(error)
    } finally {
      this.resultStoreView.setPending({ pendingName: 'applyFilter', value: false })
    }
  }

  setSorting({ sortingOptions }: { sortingOptions: THotelsSort }) {
    this.$refs.openedDestinationItem.scrollToTop()
    this.resultStoreView.setQuantityOfVisiblePackages({ count: 5 })
    this.getDestinationPackages({ countryCode: this.resultStoreView.selectedCountryCode }).sortPackageList({ sortingOptions })
    this.getAndSetPackageProductsForSelectedDestination({})
  }

  setFilter({ type, value }: { type: keyof IPackageCompareProductFilterClass, value: any }) {
    this.resultStore.filter[type]  = value;
    if (type === 'instantConfirmation') {
      this.resultStore.filter['dealFeatures'] = value
    }
    if (type === 'hotelsSort' || type === 'partOfHotelName') {
      this.applyFilter();
    }
  }

  resetFilter() {
    if (this.filterInUse) {
      this.resultStore.resetFilter()
      this.applyFilter()
      this.resultStore.clearPreviousFilter()
    }
  }

  undoFilter() {
    if (this.filterInUse) {
      this.resultStore.undoFilter()
    }
  }

  onGotItClick(dontShowAgain: boolean) {
    this.isShowIntroduction = false
    localStorage.setItem('dontShowIntroductionModalAgain', dontShowAgain.toString())
  }

  onSelectProduct({ product }: { product: IPackageProductClass }) {
    this.setModalPost({modalPost: null})
    this.usedPackage.selectedProduct = product
  }

  onSetVacationInFilter({ type, value }: { type: keyof IProductFilter, value: any }) {
    // console.log('set vacation filter')
  }

  showHotelInfo(hotel: IPackageHotelClass) {
    this.setModalPost({modalPost: null})
    this.openHotelCardModal(hotel)
  }

  showSearchResetModal(filterAction?: TFilterAction) {
    this.filterAction = filterAction
    if (this.filterAction === 'reset' && this.resultStore.previousFilter === null) {
      this.notUpdateDestinations()
      return
    }

    if (filterAction) {
      // show the warning for filters only
      const modalPost = {
        component: 'new-search',
        preventDefaultClose: true
      }
      this.setModalPost({modalPost: modalPost})
    }
    else {
      this.updateDestinations()
    }
  }

  async updateDestinations(reloadSession: boolean = false) {
    const querySnapshot = this.searchStore.searchQuerySnapshot

    const reloadCondition = reloadSession || (!!querySnapshot && JSON.stringify(this.searchQuery.content) !== JSON.stringify(querySnapshot.content))
    this.setModalPost({modalPost: null})
    eventBus.$emit('update-destinations-apply') // to hide the calendar too

    if (reloadCondition) {
      if (this.filterAction  === 'reset') {
        this.resultStore.resetFilter()
      }
      this.filterAction = null
      this.searchStore.takeSearchQuerySnapshot()
      this.resultStoreView.initialVisibleDestination()

      await this.getCountriesDataPrimary({ ...this.resultPayload, initializeFilter: false, isAnyCountry: this.searchQuery.isAnyCountry })

      this.initializeResultPage({})

      // NOTE: removed when handle selected destination
      if (this.searchStore.countriesCodes.length && this.searchQuery.isAnyCountry) {
        this.resultStoreView.setSelectedCountryCode({ countryCode: null })
      }

      if (!this.searchQuery.isAnyCountry) {
        this.resultStoreView.setSelectedCityCode({ cityCode: null, countryCode: this.resultStoreView.selectedCountryCode })
        await this.initializeDestinationContent()
      }

    } else {
      if (this.filterAction === 'apply') {
        this.applyFilter()
      } else if (this.filterAction === 'reset') {
        this.resetFilter()
      }
    }
  }

  notUpdateDestinations() {
    this.setModalPost({modalPost: null})

    if (!this.filterAction) {
      this.searchStore.applySearchQuerySnapshot()
    } else if (this.filterAction === 'apply'
      || this.filterAction === 'reset') {
      this.undoFilter()
    }
    this.filterAction = null
  }
}
