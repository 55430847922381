import {
  IPriceResource
} from 'modules/common/price/price.types';
import {PriceResource} from "be-structures/typescript-generator/assembly";

export const PRICE_BLANK: PriceResource = {
  appliedCommissions: [],
  appliedMarkups: [],
  commission: 0,
  empty: false,
  id: undefined,
  links: [],
  markup: 0,
  vat: 0,
  currency: null,
  fare: null,
  tax: null,
  total: null
}
