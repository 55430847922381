
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator"
import FilterBoardings from "ui-modules/products-filter/conditions/filter.boardings.vue"
import FilterHotelStars from "ui-modules/products-filter/conditions/filter.hotel-stars.vue"
import FilterNights from "ui-modules/products-filter/conditions/filter.nights.vue"
import FilterPrice from "ui-modules/products-filter/conditions/filter.price.vue"
import FilterHotelsSort from "ui-modules/products-filter/conditions/filter.hotels-sort.vue"

import {
  IPackageCompareProductFilterClass,
  IPartOfList,
  IProductFilter,
} from "applications/desktop/package-compare-app/types/package-compare-app.types";

import { IRegularFilterPost } from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'
@Component({
  components: {
    FilterBoardings,
    FilterHotelStars,
    FilterNights,
    FilterPrice,
    FilterHotelsSort
  },
  name: "result-search-filter"
})
export default class FilterTemplate extends Vue {
  @Prop(Object)
  post!: IRegularFilterPost
  @Prop(Boolean)
  isFilterPending!: boolean
  @Prop(Boolean)
  showResetPanel!: boolean

  get disabledResetBtn(): boolean {
    return this.isFilterPending || !this.post.isFilterUsed
  }
}
