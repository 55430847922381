
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component({})
export default class Mark extends Vue {
  @Prop({ type: String }) text!: string
  @Prop({ type: String, default: '#0077d1' }) bgColor: string
  @Prop({ type: String, default: 'white' }) color: string

  get textStyles(): string {
    // return `background: ${this.bgColor}; color: ${this.color};`
    return ''
  }

  get firstCornerStyles(): string {
    return `background: linear-gradient(to left top, transparent 0% 45%, ${this.bgColor} 55% 100%)`
  }

  get lastCornerStyles(): string {
    return `background: linear-gradient(to right top, transparent 0% 45%, ${this.bgColor} 55% 100%)`
  }
}
