
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class FareBasisSelector extends Vue {
  @Prop(Array)
  list!: string[] | number[]
  @Prop(Array)
  storeListValue!: string[] | number[]

  @Watch('storeListValue')
  watchChanges(current: string[], prev: string[]) {
    if (prev[0] !== current[0]) {
      this.isChanged = true
    }
  }

  private _isOpen: boolean = false
  private _isChanged: boolean = false

  get boardingType() {
    if (this.storeListValue[0] === 'Choose boarding') return this.$t('order.package-order.choose-boarding')
    else if (this.storeListValue[0] === 'Show all') return this.$t('common.show-all')
    return this.$t(`common.boarding.${this.storeListValue[0]}`)
  }

  get isChanged(): boolean {
    return this.$data._isChanged
  }

  set isChanged(val: boolean) {
    this.$data._isChanged = val
  }

  get isOpen(): boolean {
    return this.$data._isOpen
  }

  set isOpen(val: boolean) {
    this.$data._isOpen = val
  }

  closeSelector(): void {
    if (this.isOpen) {
      document.removeEventListener('click', this.closeSelector)
    } else {
      this.$emit('open')
    }
    this.isOpen = !this.isOpen
  }

  openMultiply(): void {
    document.addEventListener('click', this.closeSelector)
  }

  onChangeListElement(listElement: number | string) {
    this.$emit('input', listElement)
    document.addEventListener('click', this.closeSelector)
  }
}
