
import Vue from 'vue'
import { Prop, Component, Ref, Watch } from 'vue-property-decorator'

import {
  IOrderPost,
  IFirtStepQuery,
  IPackageProductClass, IModalPost
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

import MainContent from 'applications/desktop/package-compare-app/components/order/ui/stepper/first-step/main-content/main-content.vue'
import ModalBase from "../../../../../../../../ui-modules/modals/modal-base.vue";
import {PRODUCT_RESULT_MODULE} from "../../../../../store/store";
import NextBtn from 'ui-modules/buttons/next-btn/next-btn.vue'

@Component({
  components: {
    ModalBase,
    MainContent,
    NextBtn,
  }
})
export default class OrderFirstStep extends Vue {
  @Prop(Object) post!: IOrderPost

  @Ref("first-step-content") readonly firstStepContent!: HTMLFormElement;

  @Watch('post')
  updateStepQuery(value: IOrderPost) {
    this.initializeStepQuery(value)
  }

  stepQuery: IFirtStepQuery = null

  created() {
    this.initializeStepQuery(this.post)
    this.setModalNonReady()
  }

  get conditionOnDisplayFirstStep(): boolean {
    return !!this.post.productDetails && !! this.post.productDetails.paxComplects
  }

  get productResult() {
    return PRODUCT_RESULT_MODULE
  }

  get modalPost() {
    return this.productResult.modalPost
  }

  get selectedProduct() {
    return this.post.destinationPackage.selectedProduct
  }

  initializeStepQuery(post: IOrderPost) {
    this.stepQuery = {
      boarding: post.boarding,
      product: post.destinationPackage.selectedProduct,
      paxComplects: post.productDetails?.paxComplects
    }
  }

  selectBoarding(boarding: string) {
    // почему-то при выборе boarding вызывается product selector
    // из-за не надобности необходимо выпилить
    if (this.stepQuery.product.fareBasis !== boarding) {
      // this.firstStepContent.productSelector.open()
    }

    this.stepQuery.boarding = boarding
  }

  selectProduct(product: IPackageProductClass) {
    if (this.stepQuery.product.productId !== product.productId) {
      this.setModalFlightWarning()
    }

    this.stepQuery.product = product
  }

  selectRooms(rooms: any[]) {
    this.stepQuery.paxComplects = rooms
    this.$emit('update-first-step-query', this.stepQuery)
  }

  setModalFlightWarning() {
    const modalPost = {
      component: 'flight-warning',
      preventDefaultClose: true
    }
    this.setModalPost({modalPost})
  }

  setModalNonReady() {
    if(!this.conditionOnDisplayFirstStep) {
      const modalPost = {
        component: 'non-ready',
        preventDefaultClose: true
      }
      this.setModalPost({modalPost})
    }
  }

  setModalPost({modalPost}: {modalPost: IModalPost}) {
    this.productResult.setModalPost({modalPost})
  }

  applyStepQuerySnapshot() {
    this.$emit('update-first-step-query', this.stepQuery)
    this.initializeStepQuery(this.post)
  }
}
