
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class OrderBtn extends Vue {
  @Prop(String)
  classes: string
  @Prop(Boolean)
  disabled: boolean
}
