
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { getImgUrlByPath } from 'common/getImgUrl'

import {
  TFlightDirections,
  SegmentResourceExtended
} from 'ui-modules/flight-table/flight-table.types'

import {
  IPackageProductClass
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import FlightsTable from 'ui-modules/flight-table/flights-table-v2.vue'
import FlightsFeaturesTable from 'ui-modules/flight-table/flights-features-table.vue'
import BetaBtn from "ui-modules/buttons/beta-btn/beta-btn.vue"
import {PRODUCT_RESULT_MODULE} from "../../applications/desktop/package-compare-app/store/store"
import { SegmentGroupOptionsResource, SegmentResource, StatPackageInSegmentGroupOptionResource } from "be-structures/typescript-generator/assembly"

@Component({
  components: {
    FlightsTable,
    FlightsFeaturesTable,
    BetaBtn
  }
})
export default class FlightSelector extends Vue {
  @Prop(Array) products!: IPackageProductClass[]
  @Prop(Array) productsComposed!: SegmentGroupOptionsResource[]
  @Prop(Object) selectedProduct: IPackageProductClass
  @Prop(String) parentDirection: TFlightDirections

  getImgUrlByPath = getImgUrlByPath

  selectedSegmentDeparture:  SegmentResourceExtended = null
  selectedSegmentReturn: SegmentResourceExtended = null

  get flightSegmentsDeparture(): SegmentResourceExtended[] {
    if (!this.productsComposed) {
      return []
    }

    return this.productsComposed.map(productComposed => {
      const segmentGroup = productComposed.groups[0].products[0].segmentGroups[0]
      const segment = segmentGroup.segments[0] as SegmentResourceExtended
      segment.groupHashDeparture = segmentGroup.groupHash
      return segment
    })
  }

  get flightSegmentsReturn(): SegmentResourceExtended[] {
    if (!this.productsComposed) {
      return []
    }

    return this._productComposedDeparture.groups.map(group => {
      const segmentGroup = group.products[0].segmentGroups[1]
      const segment = segmentGroup.segments[0] as SegmentResourceExtended
      segment.groupHashReturn = segmentGroup.groupHash
      segment.parentProductId = group.products[0].productId // need for return segments
      return segment
    })
  }

  get selectedSegmentDepartureId(): string {
    if (this.selectedSegmentDeparture) {
      return this.selectedSegmentDeparture.segmentId
    }
    else if (this.flightSegmentsDeparture.length) {
      return this.flightSegmentsDeparture[0].segmentId
    }
    return ''
  }

  get selectedSegmentReturnId(): string {
    if (this.selectedSegmentReturn) {
      return this.selectedSegmentReturn.segmentId
    }
    else if (this.flightSegmentsReturn.length) {
      return this.flightSegmentsReturn[0].segmentId
    }
    return ''
  }

  get _productComposedDeparture(): SegmentGroupOptionsResource {
    const groupHashDeparture = this.selectedSegmentDeparture  ? this.selectedSegmentDeparture.groupHashDeparture : null
    let productComposedDeparture = this.productsComposed[0]
    if (groupHashDeparture) {
      productComposedDeparture = this.productsComposed.find(productComposed => productComposed.groupHash === groupHashDeparture)
    }
    return productComposedDeparture
  }

  get _productComposedReturn(): StatPackageInSegmentGroupOptionResource {
    const groupHashReturn = this.selectedSegmentReturn ? this.selectedSegmentReturn.groupHashReturn : null
    let productComposedReturn = this._productComposedDeparture.groups[0]
    if (groupHashReturn) {
      productComposedReturn = this._productComposedDeparture.groups.find(group => group.groupHash === groupHashReturn)
    }
    return productComposedReturn
  }

  get selectedFlightProducts(): IPackageProductClass[] {
    const composedProductIds = this._productComposedReturn.products.map(product => product.productId)
    return this.products.filter(product => composedProductIds.includes(product.productId))
  }

  get dealFeatures(): string[] {
    return this.selectedFlightProducts.length ? this.selectedFlightProducts[0].dealFeatures : []
  }

  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }
  get getSessionReady() {
    return this.resultStore.isSessionReady
  }

  get productResult() {
    return PRODUCT_RESULT_MODULE
  }


  setModalPost() {
    const modalPost = {
      component: 'session-expired',
      preventDefaultClose: true
    }
    this.productResult.setModalPost({modalPost})
  }
  applyProduct() {
    if(this.getSessionReady){
    this.$emit('apply-product', this.selectedProduct)
    } else {
    this.setModalPost()
      this.resultStore.setIsShowModal({value: true})
  }
  }

  selectFlightSegment({ segmentGroupsIndex, segment }: { segmentGroupsIndex: number, segment: SegmentResource }) {
    if (segmentGroupsIndex === 0) { // flight departure/there
      this.selectedSegmentDeparture  = segment
      this.selectedSegmentReturn = null
    }
    if (segmentGroupsIndex === 1) { // flight return
      this.selectedSegmentReturn = segment
    }
  }
}
