
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})

export default class BetaBtn extends Vue {
  @Prop(Boolean) pending!: boolean
  @Prop(String) text!: string
  @Prop({ type: Boolean, default: false }) secondary!: boolean
}
