<template>
  <g>
    <path
      d="M20.7 21.45C20.96 21.45 21.17 21.54 21.33 21.72C21.49 21.88 21.57 22.09 21.57 22.35C21.57 22.57 21.49 22.77 21.33 22.95C21.17 23.11 20.96 23.19 20.7 23.19H15.45V28.68C15.45 28.96 15.36 29.2 15.18 29.4C15 29.58 14.77 29.67 14.49 29.67C14.23 29.67 14 29.58 13.8 29.4C13.62 29.2 13.53 28.97 13.53 28.71V23.19H8.31C8.05 23.19 7.84 23.11 7.68 22.95C7.52 22.77 7.44 22.55 7.44 22.29C7.44 22.05 7.52 21.85 7.68 21.69C7.84 21.53 8.05 21.45 8.31 21.45H13.53V15.99C13.53 15.71 13.62 15.48 13.8 15.3C13.98 15.12 14.21 15.03 14.49 15.03C14.79 15.03 15.02 15.12 15.18 15.3C15.36 15.46 15.45 15.68 15.45 15.96V21.45H20.7Z"
      fill="#434343"
      fill-opacity="0.5"
    />
    <circle cx="14.5" cy="21.5" r="14" stroke="#434343" stroke-opacity="0.5" />
  </g>
</template>

<script>
export default {
  name: 'plus'
}
</script>

<style scoped></style>
