
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcon
  }
})
export default class PopupUniversal extends Vue {
  @Prop(String)
  className!: string
  @Prop(String)
  title!: string

  defaultStyles: string[] = [
    'margin: auto;',
    'top: 50%;',
    'transform: translateY(-50%);'
  ]
}
