
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcon from '../icons/icon.js'

@Component({
  components: {
    TlIcon
  }
})
export default class AgencyRating extends Vue {
  @Prop(String)
  agencyRating!: string
  @Prop(Boolean)
  isSmall!: boolean

  get stars(): number {
    return Math.trunc(+this.agencyRating / 20)
  }
}
