
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixin as clickaway } from 'vue-clickaway'

@Component({
  mixins: [clickaway]
})
export default class CustomSelector extends Vue {
  @Prop(Array) items!: string[] | number[] | Array<string[]>
  @Prop([Array, String, Number]) value!: string[] | string | number
  @Prop({ type: String, required: false }) placeholder!: string
  @Prop({ type: Boolean, default: false, required: false }) customOptions!: boolean

  isOpen: boolean = false

  changeSelectorState() {
    this.isOpen = !this.isOpen
  }
  onOverlayClick() {
    this.isOpen = false
  }
  onInput(item: [string | number | string[]]) {
    this.isOpen = false
    this.$emit('input', item)
  }
}
