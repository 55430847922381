
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class SuccessPayment extends Vue {
  timerSeconds = 10;

  created() {
    setInterval(() => {
      if (this.timerSeconds <= 0) {
        this.onClose();
      }
      if (this.timerSeconds <= 0) return;
      this.timerSeconds--;
    }, 1000);
  }

  onClose() {
    window.close();
  }
}
