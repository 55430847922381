
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcon
  }
})
export default class OrderBtn extends Vue {
  @Prop(String)
  classes: string
  @Prop({ type: String, default: 'common.book_now' })
  label: string
  @Prop(Boolean) loading!: boolean
}
