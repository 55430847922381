
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import ActionPanel from 'ui-modules/buttons/action-panel.vue'
import PassengersSelector from 'ui-modules/selectors/passengers-selector.vue'

import { IDestinationModalPost } from 'ui-modules/modals/modal.types'
import {
  IPackageComparePaxes
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

@Component({
  components: {
    ModalWindow,
    ActionPanel,
    PassengersSelector,
  },
})
export default class ModalSelectPassengers extends Vue {
  @Prop(Object) post: IDestinationModalPost

  currentSelectedPax: Partial<IPackageComparePaxes> = {}

  onPaxChange(pax: IPackageComparePaxes) {
    this.currentSelectedPax = pax
  }

  created() {
    this.currentSelectedPax = this.post.paxes
  }
}
