
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import EtaBtn from 'ui-modules/buttons/eta-btn/eta-btn.vue'
import GeneralTerms from './terms/general-terms.vue'
import CancellationTerms from './terms/cancellation-terms.vue'

@Component({
  components: {
    ModalWindow,
    EtaBtn,
    GeneralTerms,
    CancellationTerms,
  }
})
export default class ModalAgreement extends Vue {
  @Prop(Object) post: any;
}
