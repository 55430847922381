
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ComparisonTableAside from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-aside.vue'
import ComparisonTableContent from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-content.vue'

import {
  ComparisonItems,
  IComparablePackagesListClass
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    ComparisonTableAside,
    ComparisonTableContent
  }
})
export default class OrderSection extends Vue {
  @Prop(Number) quentityOfComparablePackages!: number
  @Prop(Object) comparablePackages!: IComparablePackagesListClass
  @Prop(Object) post!: any

  comparisonItems = ComparisonItems

  checkError(index) {
    return this.post.checkBookingErrorOccured({ hotelId: this.comparablePackages.content[index]?.hotel?.hotelId }) || false
  }

  checkUnavailablePackage(index) {
    return this.post.isPackageUnavailable({ hotelId: this.comparablePackages.content[index]?.hotel?.hotelId }) || false
  }
    
}
