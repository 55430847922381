
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import DeltaBtn from 'ui-modules/buttons/delta-btn/delta-btn.vue'

@Component({
  components: {
    ModalWindow,
    DeltaBtn
  }
})

export default class ModalCancellationTerms extends Vue {}
