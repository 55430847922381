
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcon
  }
})
export default class ErrorTooltip extends Vue {
  @Prop(String)
  msg!: string

  showTooltip: boolean = false
}
