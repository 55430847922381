
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Gmap from 'ui-modules/googleMaps/googleMaps.vue'

import {
  ComparisonItems,
  IPackageCompareResultClass,
  IPackageCompareResultState,
  IPackagesListClass
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    Gmap
  }
})
export default class ComparisonTableAside extends Vue {
  @Prop(String) comparisonItem!: string
  @Prop(Object) comparablePackages!: IPackagesListClass
  @Prop(Array) hotelScoreTypes!: string[]
  @Prop(String)rawType?: string

  comparisonItems = ComparisonItems

  get comparableLocations() {
    return this.comparablePackages.content.map(cp => cp?.hotel?.locations[0])
  }
}
