
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

import PaxesSelectorItem from 'ui-modules/selectors/lists/paxes.vue'
import ActionPanel from 'ui-modules/buttons/action-panel.vue'
import Passenger from 'ui-modules/svg-icon/passenger.vue'
import PassengerSmall from 'ui-modules/svg-icon/passenger-small.vue'

@Component({
  components: {
    ActionPanel,
    Passenger,
    PaxesSelectorItem,
    PassengerSmall
  }
})
export default class PaxSelector extends Vue {
  @Prop(Object) list!: { [name: string]: { val: [number, number] } }
  @Prop(Number) maxTotalPax!: number
  @Prop(Object) storeListValue!: { [name: string]: number }

  paxes: { [name: string]: number } = null

  @Watch('storeListValue')
  updatePaxes(paxes: { [name: string]: number }) {
    this.paxes = paxes
  }

  created() {
    this.paxes = this.storeListValue
  }

  get totalPaxSelected(): number {
    return Object.entries(this.storeListValue).reduce(
      (sum: number, [paxType, count]) => sum + <number>count,
      0
    )
  }

  onChangePax(paxType: string, value: number) {
    this.paxes = {
      ...this.paxes,
      [paxType]: value
    }
    this.$emit('change-pax', this.paxes)
  }
}
