
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IPackageProductClass
} from 'ui-modules/modals/modal.types'
import {
  IPackageHotelClass,
} from 'modules/product-result.v2/data/product-result.types'

import { formatDateToDMYDot } from 'common/filters/formattedDates'

import HotelStars from "ui-modules/cards/hotel/stars.vue";

@Component({
  components: {
    HotelStars,
  },
  filters: {
    formatDateToDMYDot
  }
})
export default class ModalWindowV2 extends Vue {
  @Prop(String) icon: string
  @Prop(Boolean) customFooter: boolean
  @Prop(String) hotelName: string
  @Prop(Object) hotel: IPackageHotelClass
  @Prop(Object) selectedProduct

  dontShow: boolean = false

  get departureTime() {
    return this.selectedProduct?.departureSegments[0]?.arrival?.time
  }

  get returnTime() {
    return this.selectedProduct?.returnSegments[0]?.departure?.time
  }

  get nights() {
    return this.selectedProduct?.nights
  }

  startUserTrainingInMoreDetails() {
    this.$emit('start-user-training-in-more-details')
  }
}
