
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IMediaCollectionClass } from "modules/common/media-collection/media-collection.types";
import {Getter} from "vuex-class";
import i18n from "../../../../../../../modules/i18n/i18n.name";

@Component({})
export default class OurContacts extends Vue {
  @Prop(Object) contacts!: IMediaCollectionClass;
  @Getter(`${i18n}/direction`)
  direction: string


  get keys() {
    return Object.keys(this.contacts)
  }

}
