
import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator"

import FilterGroup from "ui-modules/products-filter/filter.group.vue"
import FilterConditionItemsList from "ui-modules/products-filter/filter.condition-items-list.vue"

import {
  IFilterItemsListCondition,
  IResultPost,
  TInstantConfirmation
} from "ui-modules/products-filter/types/products-filter.types"
import {
  IRegularFilterPost
} from "applications/desktop/package-compare-app/components/search-results/types/search-results.types";

@Component({
  components: {
    FilterGroup,
    FilterConditionItemsList
  }
})

export default class FilterInstantConfirmation extends Vue {
  @Prop(Object)
  post!: IRegularFilterPost

  items: TInstantConfirmation = ["FAST_OK", "INCLUDING_BAGGAGE"]

  get instantConfirmationPost(): IFilterItemsListCondition {
    return {
      type: 'instantConfirmation',
      items: this.items,
      selectedItems: this.post.packageFilter.instantConfirmation,
      selectedItemsCodes: this.post.packageFilter.instantConfirmation
    }
  }
}
