
import PrepareSearchDataComp from "applications/desktop/package-compare-app/components/prepare-search-data/prepare-search-data";
import {Component, Watch} from "vue-property-decorator";
import Shepherd from "shepherd.js";
import {initSharpedConfig, userTrainingSearchPage} from "../../../../../common/userTrainingSteps";

@Component({})
export default class PrepareSearchData extends PrepareSearchDataComp {
  tour = new Shepherd.Tour({
    ...initSharpedConfig
  });

  @Watch('currentLocale')
  reInitUserTraining() {
    this.tour = null
    this.tour = new Shepherd.Tour({
      ...initSharpedConfig
    });
    this.tour.addSteps(userTrainingSearchPage(this.tour, this.currentLocale));
  }

  created() {
    this.startLudiCounter();
    // if (!this.referralCode) {
    //   this.initWelcomeModal();
    // }
    // this.initUserTrainingOnSearchPage()
  }

  beforeDestroy() {
    if (this.ludiTimer) {
      clearInterval(this.ludiTimer)
    }
  }

  get packageBtnClass(): string {
    return `start-btn-pckage-${this.currentLocale}`
  }
  get flightBtnClass(): string {
    return `start-btn-flight-${this.currentLocale}`
  }

  get referralCode() {
    return this.$route.query.ref
  }

  initWelcomeModal() {
    // const modalPost = {
    //   component: 'welcome-to-site',
    //   preventDefaultClose: true
    // }
    // this.setModalPost({modalPost})
  }
  // initUserTrainingOnSearchPage() {
    // this.tour.addSteps(userTrainingSearchPage(this.tour, this.currentLocale));
  // }
  startUserTraining(isStartTraining) {
    // if(isStartTraining) {
    //   this.tour.start()
    //   console.log("startUserTraining")
    // }
  }
  get referralRule() {
    return this.resultStore.referralRule
  }
  get isShowReferralModal() {
    return !!this.referralRule && this.modalPost?.component === 'referral-greetings'
  }
  get isWelcomeModal() {
    return localStorage.getItem('isShowWelcomeModal')
  }
  get isShowWelcome() {
    return !this.isShowReferralModal && !this.isWelcomeModal && this.$route.name === 'package-compare.prepare-search-data'
  }


  activeLudiIndex = 1;
  ludiTimer: ReturnType<typeof setInterval> = null;

  nextLudi() {
    this.activeLudiIndex = this.activeLudiIndex === 5 ? 1 : this.activeLudiIndex + 1
  }

  startLudiCounter() {
    this.ludiTimer = setInterval(() => {
      this.nextLudi()
    }, 5000)
  }
}
