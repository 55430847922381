
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import PAYMENT_MODULE from "../../../modules/payment/payment.name";
import { AxiosResponse } from "axios";

@Component({})
export default class errorPayment extends Vue {
  timerSeconds = 10;

  @Action(`${PAYMENT_MODULE}/cancelPayment`)
    cancelPayment: ({ orderId, uuid }: { orderId: string, uuid?: string }) => Promise<AxiosResponse>;

  get orderId(): string {
    return this.$route.params.orderId;
  }

  async sendCancelPayment() {
    // get orderId and uuid from query string from vue router

    const orderId = this.orderId;
    // const uuid = this.$route.query.uuid;

    if (!orderId || typeof orderId !== "string") { // || !uuid || typeof uuid !== "string") {
      throw new Error("orderId or uuid is not defined");
    }

    this.cancelPayment({ orderId });
  }

  created() {
    this.sendCancelPayment();
    setInterval(() => {
      if (this.timerSeconds <= 0) {
        this.onClose();
      }
      if (this.timerSeconds <= 0) return;
      this.timerSeconds--;
    }, 1000);
  }

  onClose() {
    window.close();
  }
}
