import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'

import http from 'common/http/http'
import funcAPI from 'modules/product-search.v2/product-search.api'
import PRODUCT_SEARCH_MODULE from 'modules/product-search.v2/product-search.name'

import {
  AgencyWebsiteResource,
  IATACityResource,
  IATACountryResource,
  PriceCalendarPeriodResource
} from 'be-structures/typescript-generator/assembly'

import {
  IConvertedQuery,
  IConvertToPackageResult,
  TFlightGroups
} from 'modules/common/common.types'

import {
  formatDateToYMD
} from 'common/filters/formattedDates'

import {
  IQueryLocation,
  PackageCompareQuery,
  SearchPendingsNames,
  TSearchPendings,
  IPriceCalendarPeriod,
  IPackageCompareQueryClass,
  ICountriesWithDigest,
  ICitiesWithDigest,
  IQueryDestinations,
  IPackageCompareQuery
} from 'modules/product-search.v2/data/product-search.types'

import {
  IStatPackageSearchDigestResource
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'
import PRODUCT_ORDER_MODULE from 'modules/product-order.v2/product-order.name'
import i18n_MODULE from 'modules/i18n/i18n.name'

let api = funcAPI(http)

@Module({ name: PRODUCT_SEARCH_MODULE, namespaced: true })
export default class extends VuexModule {
  private _pendings: TSearchPendings = {
    getCountries: false,
    getCountriesForLocale: {},
    getAndSetCountries: false,
    getCities: true,
    getCountry: {},
    getCountryAll: false,
    getCountryDigest: false,
    getAndSetCities: true,
    bestDeals: false,
    getDepartureDatesByCountry: false,
    getReturnDatesByCountry: false,
    getAndSetDepartureDatesByCountry: false,
    getAndSetReturnDatesByCountry: false
  }
  private _searchQuery: IPackageCompareQueryClass = null
  private _searchQuerySnapshot: IPackageCompareQueryClass = null
  private _countriesCodes: string[] = []
  private _destinations: IQueryDestinations = null
  private _destinationsForCalendar: IQueryDestinations = null
  private _destinationsSnapshot: IQueryDestinations = null
  private _departureDatesByCountry: IPriceCalendarPeriod = null
  private _returnDatesByCountry: IPriceCalendarPeriod = null
  private _bestDeals: any = []
  private _cityEffectiveDigest: IStatPackageSearchDigestResource = null
  private _countryDigest: IStatPackageSearchDigestResource = null


  get pendings() {
    return this._pendings
  }
  get searchQuery() {
    return this._searchQuery
  }
  get searchQuerySnapshot() {
    return this._searchQuerySnapshot
  }
  get bestDeals() {
    return this._bestDeals
  }

  get departureDatesByCountry() {
    return this._departureDatesByCountry
  }

  get returnDatesByCountry() {
    return this._returnDatesByCountry
  }

  get destinations() {
    return this._destinations
  }

  get destinationsForCalendar() {
    return this._destinationsForCalendar
  }

  get destinationsSnapshot() {
    return this._destinationsSnapshot
  }

  get countriesCodes() {
    return this._countriesCodes
  }

  get cityEffectiveDigest() {
    return this._cityEffectiveDigest
  }

  get countryDigest() {
    return this._countryDigest
  }

  @Mutation
  resetStore() {
    this._pendings = {
      getCountries: false,
      getCountriesForLocale: {},
      getAndSetCountries: true,
      getCities: true,
      getCountry: {},
      getCountryAll: false,
      getCountryDigest: false,
      getAndSetCities: true,
      bestDeals: false,
      getDepartureDatesByCountry: false,
      getReturnDatesByCountry: false,
      getAndSetDepartureDatesByCountry: false,
      getAndSetReturnDatesByCountry: false
    }
    this._searchQuery = null
    this._searchQuerySnapshot = null
    this._departureDatesByCountry = null
    this._returnDatesByCountry = null
    this._destinations = null
    this._destinationsSnapshot = null
    this._countriesCodes = []
  }


  @Mutation
  setSearchQuery(searchQuery: IPackageCompareQueryClass) {
    this._searchQuery = searchQuery
  }

  @Mutation
  initializeSearchQuery({ searchQuery }: { searchQuery: IPackageCompareQuery }) {
    this._searchQuery = new PackageCompareQuery(cloneDeep(searchQuery))
    this._searchQuery.isAnyCountry = true
  }

  @Mutation
  setSearchQuerySnapshot({ searchQuery }: { searchQuery: IPackageCompareQuery }) {
    this._searchQuerySnapshot = new PackageCompareQuery(cloneDeep(searchQuery))
  }

  @Mutation
  takeSearchQuerySnapshot() {
    this._searchQuerySnapshot = new PackageCompareQuery(cloneDeep(this._searchQuery.content))
    this._destinationsSnapshot = cloneDeep(this._destinations)
  }

  @Mutation
  applySearchQuerySnapshot() {
    this._searchQuery = new PackageCompareQuery(cloneDeep(this._searchQuerySnapshot.content))
    this._destinations = this._destinationsSnapshot
  }

  @Mutation
  setPending({ pendingName, value, countryCode, locale }: { pendingName: keyof typeof SearchPendingsNames, value: boolean, countryCode?: string, locale?: string }) {
    if (pendingName === "getCountry") {
      this._pendings.getCountry[countryCode] = value;
      this._pendings.getCountryAll = Object.values(this._pendings.getCountry).includes(true)
    }
    else if (pendingName === "getCountriesForLocale") {
      this._pendings.getCountriesForLocale[locale] = value;
    }
    else {
      this._pendings[pendingName] = value
    }
  }

  @Mutation
  setDestinations({ destinations, updateCountriesCodes = true }: { destinations: IQueryDestinations, updateCountriesCodes?: boolean }) {
    this._destinations = destinations || {}

    if (updateCountriesCodes) {
      this._countriesCodes = destinations ? [...new Set(Object.keys(destinations || {}))] : []
    }
  }

  @Mutation
  setDestinationsForCalendar({ destinations }: { destinations: IQueryDestinations }) {
    this._destinationsForCalendar = destinations || {}
  }

  @Mutation
  setDatesByCountry({ flightGroup, dates }: { flightGroup: TFlightGroups, dates: IPriceCalendarPeriod }) {
    if (flightGroup === 'departures') {
      this._departureDatesByCountry = dates
      this._returnDatesByCountry = null
    } else if (flightGroup === 'returns') {
      this._returnDatesByCountry = dates
    }
  }

  @Mutation
  setCityDigest({ digest }: { digest: IStatPackageSearchDigestResource }) {
    this._cityEffectiveDigest = digest
  }

  @Mutation
  setCountryDigest({ digest }: { digest: IStatPackageSearchDigestResource }) {
    this._countryDigest = digest
  }

  @Mutation
  setBestDeals({ bestDeals }: { bestDeals: any[] }) {
    this._bestDeals = bestDeals
  }

  @Mutation
  updateCoutryCities({ countryCode, cities }: { countryCode: string, cities: IATACityResource[]}) {
    if (this._destinations[countryCode]) {
      this._destinations[countryCode].cities = cities
    }
  }


  @Action
  setDestination<K extends keyof IQueryLocation>({
    countryCode,
    propertyName,
    value,
    forCalendar
  }: {
    countryCode: string
    propertyName: K
    value: IATACountryResource | IATACityResource[] | string
    forCalendar?: boolean
  }) {
    const dest = forCalendar ? this._destinationsForCalendar : this._destinations
    const destinations = {
      ...dest,
      [countryCode]: {
        ...dest[countryCode],
        [propertyName]: value
      }
    }

    const mutation = forCalendar ? 'setDestinationsForCalendar' : 'setDestinations'
    this.context.commit(mutation, { destinations, updateCountriesCodes: propertyName === 'country' })
  }

  @Action
  clearDestinations(forCalendar?: boolean) {
    const mutation = forCalendar ? 'setDestinationsForCalendar' : 'setDestinations'
    this.context.commit(mutation, { destinations: null })
  }

  @Action
  initializeDestinations({ countries, forCalendar }: { countries: IATACountryResource[], forCalendar?: boolean }) {
    if (countries?.length > 0) {
      this.clearDestinations(forCalendar)
      countries.map(country => {
        this.setDestination({
          countryCode: country.code,
          propertyName: 'country',
          value: country,
          forCalendar
        })
      })
    }
  }

  @Action
  async getBestDeals() {
    // this.context.commit('setPending', { pendingName: 'bestDeals', value: true })
    // const query = new PackageCompareQuery()
    // const bestDeals = await api.getBestDeals({ query })
    // this.context.commit('setBestDeals', { bestDeals })
    // Promise.resolve()
    // this.context.commit('setPending', { pendingName: 'bestDeals', value: false })
  }

  @Action
  async getDestinationCountries({
    filter
  }: {
    filter?: IConvertToPackageResult
  }): Promise<ICountriesWithDigest> {
    const locale = this.context.rootGetters[`${i18n_MODULE}/currentLocale`]
    try {
      this.context.commit('setPending', { pendingName: 'getCountries', value: true })
      this.context.commit('setPending', { pendingName: 'getCountriesForLocale', value: true, locale })
      
      const { content, page } = await api.getActiveCountries({
        query: this._searchQuery.convertToRequest(),
        filter,
        locale,
      })

      return { countries: content, digest: page.effectiveDigest }
    } finally {
      this.context.commit('setPending', { pendingName: 'getCountriesForLocale', value: false, locale })
      this.context.commit('setPending', { pendingName: 'getCountries', value: false })
    }
  }

  @Action
  async getDestinationCountriesForCalendar() {
    try {
      this.context.commit('setPending', { pendingName: 'getCountries', value: true })

      const agencyInfo: AgencyWebsiteResource = this.context.rootGetters[`${PRODUCT_ORDER_MODULE}/agencyInfo`]
      const websiteKey = agencyInfo ? agencyInfo.accessKey : ''
      return api.getActiveCountriesForCalendar(websiteKey)
    } finally {
      this.context.commit('setPending', { pendingName: 'getCountries', value: false })
    }
  }

  @Action
  async getAndSetDestinationCountriesWithDigest({
    filter
  }: {
    filter?: IConvertToPackageResult
  }) {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: true })
      this.clearDestinations()
      const { countries, digest } = await this.getDestinationCountries({ filter })
      this.initializeDestinations({ countries })

      return { countries, digest }
    } finally {
      this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: false })
    }
  }

  @Action
  async getAndSetDestinationCountriesForCalendar() {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: true })
      const countries = await this.getDestinationCountriesForCalendar()
      this.initializeDestinations({ countries, forCalendar: true })

      return countries
    } finally {
      this.context.commit('setPending', { pendingName: 'getAndSetCountries', value: false })
    }
  }

  @Action
  async getDestinationCities({
    filter,
    query
  }: {
    filter?: IConvertToPackageResult,
    query: IConvertedQuery
  }): Promise<ICitiesWithDigest> {
    try {
      this.context.commit('setPending', { pendingName: 'getCities', value: true })
      this.context.commit('setPending', { pendingName: 'getCountry', value: true, countryCode: query.countryCode })

      if (!query.countryCode) {
        throw new Error('Selected country code is empty')
      }
      const data = await api.getActiveCities({ query, filter })

      return { cities: data.content, digest: data.page.effectiveDigest }
    } finally {
      this.context.commit('setPending', { pendingName: 'getCountry', value: false, countryCode: query.countryCode })
      this.context.commit('setPending', { pendingName: 'getCities', value: false })
    }
  }

  @Action
  async getAndSetDestinationCitiesWithDigest({
    filter,
    query
  }: {
    filter?: IConvertToPackageResult,
    query: IConvertedQuery
  }) {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetCities', value: true })

      let { cities, digest } = await this.getDestinationCities({ filter, query })

      if (cities?.length > 0) {
        cities = cities.filter(c => c.country.code === query.countryCode);

        this.setDestination({
          countryCode: query.countryCode,
          propertyName: 'cities',
          value: cities
        })
      }

      return { cities, digest }
    } finally {
      this.context.commit('setPending', { pendingName: 'getAndSetCities', value: false })
    }
  }

  @Action
  async getDatesByCountry({
    countryCode,
    flightGroup,
    departureDate,
  }: {
    countryCode: string
    flightGroup: TFlightGroups
    departureDate?: string,
  }): Promise<PriceCalendarPeriodResource[]> {
    const pendingName: keyof typeof SearchPendingsNames = flightGroup === 'departures' ? 'getDepartureDatesByCountry' : 'getReturnDatesByCountry'
    this.context.commit('setPending', { pendingName, value: true })

    try {
      let result = null
      const { accessKey } = this.context.rootGetters[`${PRODUCT_ORDER_MODULE}/agencyInfo`]
      if (flightGroup === 'departures') {
        result = await api.getDepartureDatesByCountry({ countryCode, websiteKey: accessKey })
      } else if (flightGroup === 'returns') {
        result = await api.getReturnDatesByCountry({ countryCode, departureDate, websiteKey: accessKey })
      }

      return result
    } finally {
      this.context.commit('setPending', { pendingName, value: false })
    }
  }

  @Action
  async getAndSetDatesByCountry({
    countryCode,
    flightGroup,
    departureDate,
    // cityCode
  }: {
    countryCode?: string
    flightGroup: TFlightGroups
    departureDate?: string
    // cityCode?: string
  }): Promise<IPriceCalendarPeriod> {
    const pendingName: keyof typeof SearchPendingsNames = flightGroup === 'departures' ? 'getAndSetDepartureDatesByCountry' : 'getAndSetReturnDatesByCountry'

    try {
      this.context.commit('setPending', { pendingName, value: true })

      const dates = await this.getDatesByCountry({ countryCode, flightGroup, departureDate })

      if (dates.length > 0) {
        const restucturedDates = dates.reduce((result: IPriceCalendarPeriod, data: PriceCalendarPeriodResource) => {
          const date = dayjs(flightGroup === 'departures' ? data.departureDate : data.returnDate)
          const monthNumber = date.month() + 1
          return {
            ...result,
            [monthNumber]: {
              ...(result[monthNumber] || {}),
              [formatDateToYMD(date.toDate())]: data
            }
          }
        }, {})

        this.context.commit('setDatesByCountry', { flightGroup, dates: restucturedDates })

        return restucturedDates
      }
      else {
        this.context.commit('setDatesByCountry', { flightGroup, dates: null })
        return null
      }

    } finally {
      this.context.commit('setPending', { pendingName, value: false })
    }
  }

  @Action
  async getCountryDigest({
    filter,
    query,
  }: {
    filter?: IConvertToPackageResult,
    query?: IConvertedQuery
  }) {
    this.context.commit('setPending', { pendingName: 'getCountryDigest', value: true })

    try {
      const result = await api.getCountryDigest({
        vacationTerm: this._searchQuery.convertToRequest(),
        filter,
        query
      })

      this.context.commit('setCountryDigest', { digest: result.page.query.digest })

      return { digest: result.page.query.digest, effectiveDigest: result.page.effectiveDigest }
    }
    finally {
      this.context.commit('setPending', { pendingName: 'getCountryDigest', value: false })
    }
  }

  @Action
  async getCityDigest({ filter, query }: { filter?: IConvertToPackageResult, query: IConvertedQuery }) {
    const result = await api.getCityDigest({
      filter,
      query
    })

    this.context.commit('setCityDigest', { digest: result.page.effectiveDigest })

    return result.page.effectiveDigest
  }
}
