
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CloseCrossBtn extends Vue {
  @Prop(String)
  mode: string

  get fillColor() {
    if (this.mode) {
      return this.mode
    }

    return 'white'
  }
}
