
import Vue from 'vue'
import {Prop, Component, Ref} from 'vue-property-decorator'
import Stars from "ui-modules/cards/hotel/stars.vue";
import HotelPhotoGallery from 'ui-modules/cards/hotel/hotel-photo-gallery.vue'
import TlIcon from 'ui-modules/icons/icon'
import FastOk from 'ui-modules/fast-ok/fast-ok.vue'
import Gmap from 'ui-modules/googleMaps/googleMaps.vue'
import {IPackageHotelClass} from 'applications/desktop/package-app/components/order-product/types/order-product.types'
import {TGoogleMapLocations} from 'ui-modules/googleMaps/googleMaps.types';
import {API_KEY} from "../../../../../../../ui-modules/googleMaps/googleMaps.init";
import axios from "axios";

@Component({
             components: {
               Stars,
               HotelPhotoGallery,
               TlIcon,
               FastOk,
               Gmap,
             }
           })
export default class HotelDescription extends Vue {
  @Prop(Object) hotel!: IPackageHotelClass
  @Prop(Boolean) fastOk!: boolean
  @Ref('hotel-description') hotelDescriptionContainer: HTMLElement
  @Ref('hotel-description-text') hotelDescriptionText: HTMLElement


  get locations(): TGoogleMapLocations {
    return this.hotel.locations
  }

  hotelDescriptionTruncate() {
    const words = this.hotelDescriptionText.innerText.split(' ')
    while (this.hotelDescriptionContainer.scrollHeight > this.hotelDescriptionContainer.offsetHeight) {
      words.pop()
      this.hotelDescriptionText.innerText = `${words.join(' ')}...`
    }
  }
}

