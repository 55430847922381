const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

async function poll<T>(fn: () => Promise<T>, fnValidate: (arg: T) => boolean, retries = Infinity, timeoutBetweenAttempts = 1000, fnStop?: () => boolean) {
  async function retry(err: any): Promise<T> {
    if (!!fnStop && fnStop()) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject("stoped");
    }
    if (retries-- > 0) {
      await delay(timeoutBetweenAttempts);
      try {
        const result = await fn();

        if (fnValidate(result)) {
          return result;
        } else {
          return retry(result);
        }
      } catch (e) {
        return retry(e);
      }
    }
    throw err;
  }

  try {
    if (!!fnStop && fnStop()) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject("stoped");
    }

    const result = await fn();

    if (fnValidate(result)) {
      return result;
    } else {
      return retry(result);
    }
  } catch (e) {
    return retry(e);
  }
}

export {
  poll
};
