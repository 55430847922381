import { ISegmentGroupResource } from 'modules/product-result.v2/data/product-result.types'

export default (segmentGroups: ISegmentGroupResource[]): ISegmentGroupResource[] =>  {

  const changedSegmentGroups = JSON.parse(JSON.stringify(segmentGroups))

  const bothFlightsTimeisUnKnown = !!(segmentGroups[0].segments.filter(segment => !segment.departure.timeIsKnown).length
        && segmentGroups[1].segments.filter(segment => !segment.departure.timeIsKnown).length)

  const departureSegments = changedSegmentGroups[0].segments.map(segment => {
    return {
      ...segment,
      flightType: 'departure',
      bothFlightsTimeisUnKnown: bothFlightsTimeisUnKnown
    }
  })

  const returnSegments = changedSegmentGroups[1].segments.map(segment => {
    return {
      ...segment,
      flightType: 'return',
      bothFlightsTimeisUnKnown: bothFlightsTimeisUnKnown
    }
  })

  changedSegmentGroups[0].segments = departureSegments
  changedSegmentGroups[1].segments = returnSegments

  return [ changedSegmentGroups[0], changedSegmentGroups[1] ]
}