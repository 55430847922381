<template>
  <svg
    width="70"
    height="71"
    viewBox="0 0 70 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="34.6932"
      cy="35.5"
      rx="34.6932"
      ry="35.5"
      fill="white"
      fill-opacity="0.7"
    />
    <path
      d="M48.7065 23.0693H51.4455C52.5085 23.0693 53.528 23.4916 54.2797 24.2433C55.0314 24.995 55.4536 26.0145 55.4536 27.0775V49.3665C55.4536 50.4295 55.0314 51.449 54.2797 52.2007C53.528 52.9523 52.5085 53.3746 51.4455 53.3746H21.2035C20.1405 53.3746 19.121 52.9523 18.3693 52.2007C17.6176 51.449 17.1953 50.4295 17.1953 49.3665V27.0775C17.1953 26.0145 17.6176 24.995 18.3693 24.2433C19.121 23.4916 20.1405 23.0693 21.2035 23.0693H48.7065Z"
      fill="#878787"
    />
    <path
      d="M46.0587 20.6055H48.7389C49.8174 20.6055 50.8517 21.0339 51.6142 21.7964C52.3768 22.559 52.8052 23.5933 52.8052 24.6717V46.8445C52.8052 47.9229 52.3768 48.9572 51.6142 49.7198C50.8517 50.4823 49.8174 50.9108 48.7389 50.9108H18.6131C17.5347 50.9108 16.5004 50.4823 15.7379 49.7198C14.9753 48.9572 14.5469 47.9229 14.5469 46.8445V24.6717C14.5469 23.5933 14.9753 22.559 15.7379 21.7964C16.5004 21.0339 17.5347 20.6055 18.6131 20.6055H46.0587Z"
      fill="#FFFEFD"
    />
    <path
      opacity="0.2"
      d="M36.9377 28.2578L15.6484 49.547C16.0283 49.9564 16.4887 50.2829 17.0007 50.506C17.5127 50.7291 18.0653 50.844 18.6238 50.8436H48.7496C49.828 50.8436 50.8623 50.4152 51.6249 49.6526C52.3874 48.89 52.8158 47.8558 52.8158 46.7773V28.2578H36.9377Z"
      fill="#C4C4C4"
    />
    <path
      d="M52.8052 24.709V28.292H14.5469V24.709C14.547 23.5758 14.9973 22.4891 15.7986 21.6879C16.6 20.8866 17.6868 20.4365 18.8199 20.4365H48.5327C49.0938 20.4365 49.6494 20.547 50.1677 20.7617C50.6861 20.9765 51.1571 21.2912 51.5538 21.6879C51.9506 22.0846 52.2653 22.5556 52.48 23.074C52.6947 23.5924 52.8052 24.1479 52.8052 24.709Z"
      fill="#0071BC"
    />
    <path
      opacity="0.7"
      d="M52.8157 24.6034C52.8157 23.5249 52.3873 22.4907 51.6247 21.7281C50.8621 20.9655 49.8279 20.5371 48.7494 20.5371H44.6553L36.9375 28.2572H52.8157V24.6034Z"
      fill="#044A78"
    />
    <path
      d="M25.5999 20.4365V23.3706C25.5989 23.7199 25.4596 24.0545 25.2127 24.3014C24.9657 24.5484 24.6311 24.6876 24.2819 24.6887H23.4001C23.0508 24.6876 22.7162 24.5484 22.4693 24.3014C22.2223 24.0545 22.0831 23.7199 22.082 23.3706V20.4365H25.5999Z"
      fill="#0A5A8E"
    />
    <path
      d="M46.106 20.4365V23.3706C46.1049 23.7199 45.9657 24.0545 45.7188 24.3014C45.4718 24.5484 45.1372 24.6876 44.7879 24.6887H43.9079C43.5587 24.6876 43.224 24.5484 42.9771 24.3014C42.7301 24.0545 42.5909 23.7199 42.5898 23.3706V20.4365H46.106Z"
      fill="#094368"
    />
    <path
      d="M23.8528 16.625H22.9704C22.2425 16.625 21.6523 17.2151 21.6523 17.9431V22.93C21.6523 23.658 22.2425 24.2481 22.9704 24.2481H23.8528C24.5807 24.2481 25.1708 23.658 25.1708 22.93V17.9431C25.1708 17.2151 24.5807 16.625 23.8528 16.625Z"
      fill="#383633"
    />
    <path
      d="M44.4153 16.625H43.5329C42.805 16.625 42.2148 17.2151 42.2148 17.9431V22.93C42.2148 23.658 42.805 24.2481 43.5329 24.2481H44.4153C45.1432 24.2481 45.7333 23.658 45.7333 22.93V17.9431C45.7333 17.2151 45.1432 16.625 44.4153 16.625Z"
      fill="#383633"
    />
    <path
      d="M44.3514 38.3386C45.814 38.3386 46.9997 37.1529 46.9997 35.6903C46.9997 34.2277 45.814 33.042 44.3514 33.042C42.8888 33.042 41.7031 34.2277 41.7031 35.6903C41.7031 37.1529 42.8888 38.3386 44.3514 38.3386Z"
      fill="#F4B40F"
    />
    <path
      d="M23.0038 38.3396C24.4664 38.3396 25.6521 37.1539 25.6521 35.6913C25.6521 34.2287 24.4664 33.043 23.0038 33.043C21.5412 33.043 20.3555 34.2287 20.3555 35.6913C20.3555 37.1539 21.5412 38.3396 23.0038 38.3396Z"
      fill="#979797"
    />
    <path
      d="M33.6756 38.3396C35.1383 38.3396 36.3239 37.1539 36.3239 35.6913C36.3239 34.2287 35.1383 33.043 33.6756 33.043C32.213 33.043 31.0273 34.2287 31.0273 35.6913C31.0273 37.1539 32.213 38.3396 33.6756 38.3396Z"
      fill="#979797"
    />
    <path
      d="M22.953 46.8484C24.4156 46.8484 25.6013 45.6627 25.6013 44.2001C25.6013 42.7374 24.4156 41.5518 22.953 41.5518C21.4904 41.5518 20.3047 42.7374 20.3047 44.2001C20.3047 45.6627 21.4904 46.8484 22.953 46.8484Z"
      fill="#979797"
    />
    <path
      d="M33.6288 46.8484C35.0914 46.8484 36.2771 45.6627 36.2771 44.2001C36.2771 42.7374 35.0914 41.5518 33.6288 41.5518C32.1662 41.5518 30.9805 42.7374 30.9805 44.2001C30.9805 45.6627 32.1662 46.8484 33.6288 46.8484Z"
      fill="#979797"
    />
    <path
      d="M44.3006 46.8484C45.7633 46.8484 46.949 45.6627 46.949 44.2001C46.949 42.7374 45.7633 41.5518 44.3006 41.5518C42.838 41.5518 41.6523 42.7374 41.6523 44.2001C41.6523 45.6627 42.838 46.8484 44.3006 46.8484Z"
      fill="#979797"
    />
    <path
      d="M22.3991 22.7081C22.3606 22.7081 22.3237 22.6928 22.2964 22.6656C22.2692 22.6383 22.2539 22.6014 22.2539 22.5629V17.9157C22.2539 17.8772 22.2692 17.8403 22.2964 17.813C22.3237 17.7858 22.3606 17.7705 22.3991 17.7705C22.4376 17.7705 22.4746 17.7858 22.5018 17.813C22.5291 17.8403 22.5444 17.8772 22.5444 17.9157V22.5629C22.5444 22.582 22.5407 22.6009 22.5335 22.6186C22.5262 22.6362 22.5155 22.6523 22.502 22.6658C22.4885 22.6793 22.4724 22.6899 22.4548 22.6972C22.4371 22.7045 22.4182 22.7082 22.3991 22.7081Z"
      fill="#4D4D4D"
    />
    <path
      d="M43.0085 22.7081C42.9894 22.7082 42.9705 22.7045 42.9528 22.6972C42.9352 22.6899 42.9192 22.6793 42.9056 22.6658C42.8921 22.6523 42.8815 22.6362 42.8742 22.6186C42.8669 22.6009 42.8632 22.582 42.8633 22.5629V17.9157C42.8633 17.8772 42.8786 17.8403 42.9058 17.813C42.9331 17.7858 42.97 17.7705 43.0085 17.7705C43.047 17.7705 43.084 17.7858 43.1112 17.813C43.1384 17.8403 43.1537 17.8772 43.1537 17.9157V22.5629C43.1537 22.6014 43.1384 22.6383 43.1112 22.6656C43.084 22.6928 43.047 22.7081 43.0085 22.7081Z"
      fill="#4D4D4D"
    />
  </svg>
</template>

<script>
export default {
  name: 'calendar'
}
</script>

<style scoped></style>
