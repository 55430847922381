
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Popup from 'ui-modules/popups/popup.vue'
import Selector from 'ui-modules/selectors/selector.vue'
import TlIcon from 'ui-modules/icons/icon.js'
import {
  IAssistance,
  IAssistanceList,
  ICustomer,
  IError
} from 'applications/desktop/flight-app/types/desktop-app.types'

@Component({
  components: {
    Selector,
    Popup,
    TlIcon
  }
})
export default class SpecialAssistances extends Vue {
  @Prop(Object)
  assistanceList!: IAssistanceList
  @Prop(Object)
  customer!: ICustomer
  @Prop({ type: Boolean, default: false })
  isPending!: boolean
  @Prop(Object)
  error!: IError
  @Prop(String)
  agencyPhone!: string

  get isError(): boolean {
    return this.error && this.error.type === 'assistance'
  }

  localStoreAssitance: IAssistance = {
    meals: this.getCustomerAssistance('meals'),
    assistances: this.getCustomerAssistance('assistances')
  }

  getCustomerAssistance(type: string): string {
    if (
      this.customer.hasOwnProperty('assistanceList') &&
      this.customer.assistanceList.hasOwnProperty(type)
    ) {
      return this.customer.assistanceList[type]
    }
    return null
  }

  confirmAssistance(): void {
    this.$emit('confirm-assistance', this.localStoreAssitance)
    this.$emit('close')
  }
}
