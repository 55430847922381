
import ModalWindow from "../modal-window/modal-window";
import Vue from "vue";
import Component from "vue-class-component";
import {IModalPost} from "../../modal.types";
import {PRODUCT_RESULT_MODULE} from "../../../../applications/desktop/package-compare-app/store/store";

@Component({
  components: {
    ModalWindow
  }
})
export default class ModalNonReady extends Vue{
  back() {
    this.$router.go(-1)
    this.setModalPost({modalPost: null})
  }

  setModalPost({modalPost}: {modalPost: IModalPost}) {
    this.productResult.setModalPost({modalPost})
  }

  get productResult() {
    return PRODUCT_RESULT_MODULE
  }
}
