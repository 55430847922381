
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon.js'
import Selector from 'ui-modules/selectors/selector.vue'

@Component({
  components: {
    TlIcon,
    Selector
  }
})
export default class PackageOrderPrice extends Vue {
  @Prop(Object)
  post!: any

  get totalPackagesPrice(): number {
    let total: number = 0
    this.selectedComplects.forEach(complect => {
      complect.price.stages[0].paxPrices.forEach((price: any) => {
        total += price.fare.amount
      })
    })
    return total
  }

  // Temporary
  get productId(): string {
    return Object.keys(this.post.selectedPaxComplects)[0]
  }

  get selectedComplects(): any[] {
    return this.post.productDetails.complects.filter((complect: any) => {
      const complectIds: string[] = this.post.selectedPaxComplects[
        this.productId
      ]
      return complectIds.includes(complect.id)
    })
  }
}
