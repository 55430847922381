
import Vue from 'vue'
import { Prop, Component, Ref } from 'vue-property-decorator'

import {
  IOrderPost,
  IFirtStepQuery
} from 'applications/desktop/package-compare-app/components/order/types/order.types'

import TlIcon from 'ui-modules/icons/icon'
import BtnAlpha from "ui-modules/buttons/btn-alpha.vue";
import BoardingSelector from 'applications/desktop/package-compare-app/components/order/ui/boarding-selector/boarding-selector.vue'
import RoomsSelector from 'applications/desktop/package-compare-app/components/order/ui/rooms-selector/rooms-selector.vue'
import ProductSelector from 'applications/desktop/package-compare-app/components/order/ui/product-selector/product-selector.vue'
import HotelDescription from 'applications/desktop/package-compare-app/components/order/ui/hotel-description/hotel-description.vue'
import TotalInfoV2 from 'applications/desktop/package-compare-app/components/order/ui/total-info-v2/total-info-v2.vue'

@Component({
  components: {
    BtnAlpha,
    BoardingSelector,
    RoomsSelector,
    ProductSelector,
    HotelDescription,
    TlIcon,
    TotalInfoV2,
  }
})
export default class FirstStepMainContent extends Vue {
  @Prop(Object) post!: IOrderPost
  @Prop(Object) query!: IFirtStepQuery

  @Ref("product-selector") readonly productSelector!: HTMLFormElement;
  @Ref("rooms-selector") readonly roomsSelector!: HTMLFormElement;

  get selectedProduct() {
    return this.query.product
  }

  get showRemarks() {
    return this.productRemarks || (this.query.paxComplects.length > 0)
  }

  get isSelectedProductActual() {
    return this.query.boarding !== this.selectedProduct.fareBasis
  }

  get paxComplects() {
    return this.selectedProduct.details.paxComplects || []
  }

  get productRemarks() {
    return this.selectedProduct.details.remarkList
  }

  get productsFilteredByFareBasis() {
    let result = this.post.destinationPackage.products.getProductsFilteredByFareBasis(this.query.boarding)
    return result.filter(product => typeof product.details !== 'undefined');
  }

  addRoom() {
    this.roomsSelector.addRoom()
  }
}
