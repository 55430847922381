
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import FareBasisSelector from 'ui-modules/selectors/fare-basis-selector.vue'
import RoomTypeSelector from 'ui-modules/selectors/room-type-selector.vue'

import { IPaxComplectsFilterPost } from 'applications/desktop/package-app/components/result-search/types/result-search.types'
import isEqual from 'lodash/isEqual'

@Component({
  components: {
    FareBasisSelector,
    RoomTypeSelector
  },
  name: 'pax-complects-room-filter'
})
export default class PaxComplectsRoomFilter extends Vue {
  @Prop(Object)
  filter!: {
    fareBasisList: string[]
    fareBasis: string
    fastOk: boolean
    carIncluded: boolean
    showAll: boolean
    roomTypeList: Array<string[] | string>
  }
  @Prop(Object)
  post!: {
    fastOkFiltering: boolean
    carFiltering: boolean
  }
  
  get uniqueRoomTypes() {
    return this.filter.roomTypeList.filter((arr, index, self) => {
      return self.findIndex((el) => isEqual(arr, el)) === index;
    });
  }

  changeFareBasis(targetFB: string): void {
    this.$emit('confirm-filter', { fareBasis: targetFB })
  }

  changeRoomType(targetRoomType: string[] | string) {
    this.$emit('confirm-filter', { roomType: targetRoomType })
  }

  changeAllStatus(): void {
    const showAll = !this.filter.showAll
    const fastOk = showAll ? false : this.post.fastOkFiltering ? true : false
    const carIncluded = showAll ? false : this.post.carFiltering ? true : false

    this.$emit('confirm-filter', { showAll, fastOk, carIncluded })
  }

  changeFastOkStatus(): void {
    const fastOk = !this.filter.fastOk
    const carIncluded = this.filter.carIncluded

    const showAll = !fastOk ? (!carIncluded ? true : false) : false

    this.$emit('confirm-filter', { fastOk, showAll })
  }

  changeCarStatus(): void {
    const fastOk = this.filter.fastOk
    const carIncluded = !this.filter.carIncluded

    const showAll = !carIncluded ? (!fastOk ? true : false) : false

    this.$emit('confirm-filter', { carIncluded, showAll })
  }
}
