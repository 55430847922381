
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import ActionPanel from 'ui-modules/buttons/action-panel.vue'
import PriceDatepicker from 'ui-modules/datepicker/price-datepicker.vue'
import ResetBtn from 'ui-modules/buttons/reset-btn.vue'

@Component({
  components: {
    ModalWindow,
    ActionPanel,
    PriceDatepicker,
    ResetBtn
  },
})
export default class ModalPriceDatepicker extends Vue {
  @Prop(Object) post: any

  get showResetButton() {
    return this.priceDatepicker.dateValue.start || this.priceDatepicker.dateValue.end
  }

  get priceDatepicker() {
    return this.$refs.priceDatepicker
  }

  resetDatepickerValue() {
    this.priceDatepicker.resetDatepicker()
  }
}
