
import Vue from 'vue'
import svgIcon from './svg-icon/svg-icon.vue'
import BEACH from './svg-icon/beach.vue'
import FLY_AND_DRIVE from './svg-icon/car.vue'
import SKI from './svg-icon/active.vue'
import CITY from './svg-icon/city.vue'
import YOUNG_VACATION from './svg-icon/young_vacation.vue'
import FAMILY from './svg-icon/family.vue'
import GUIDED_TOUR from './svg-icon/guide.vue'
import SPA from './svg-icon/spa.vue'
import CIVIL_MARRIAGE from './svg-icon/marriage.vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'

import {
  VACATION_GROUPS,
  VacationTypes
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

//TODO: move this to proper place
// interface ISVGSetItem {
//   component: string
//   isActive: boolean
//   value: string
// }

@Component({
  components: {
    svgIcon,
    CustomTooltip,
    BEACH,
    FLY_AND_DRIVE,
    SKI,
    CITY,
    YOUNG_VACATION,
    FAMILY,
    GUIDED_TOUR,
    SPA,
    CIVIL_MARRIAGE
  }
})
export default class typeRestFilter extends Vue {
  @Prop({ type: Number, default: 120 })
  size: number
  @Prop(String)
  viewMode?: string
  @Prop(Array)
  typeList: string[]
  @Prop(Boolean)
  toPreviousValue: boolean

  // @Watch('toPreviousValue')
  // abort() {
  //   if (this.toPreviousValue) {
  //     const restItem = this.SVGSet[this.lastCheckedIndex]
  //     restItem.isActive = !restItem.isActive
  //     this.$emit('cancel')
  //   }
  // }

  // SVGSet: ISVGSetItem[] = null
  // activeTypesIndexList: number[] = []
  // groupList: string[][] = [
  //   ['Beach', 'Family', 'City', 'Spa', 'Active'],
  //   ['Car', 'Marriage', 'Guide']
  // ]
  activeGroupIndex: number = 0
  lastCheckedIndex: number = null

  created() {
    // this.SVGSet = this.items.map(item => {
    //   return this.ConstructorSVG(item)
    // })
  }

  // get SVGSet() {
  //   return this.vacationTypes.map(vacationType => this.ConstructorSVG(vacationType))
  // }

  get vacationTypes(): string[] {
    return Object.keys(VacationTypes)
  }

  isActiveVacationType({ vacationType }: { vacationType: string }): boolean {
    return this.typeList.includes(vacationType)
  }

  // ConstructorSVG(component: any): ISVGSetItem {
  //   // const value = typesEnum[component]
  //   const itemIndex = this.typeList?.findIndex(type => type === component)
  //   if (itemIndex > -1) this.activeTypesIndexList.push(itemIndex)
  //   return {
  //     component,
  //     isActive: itemIndex > -1,
  //     value: component
  //   }
  // }

  // isIndexFromAnotherGroup(index: number): boolean {
  //   if (this.activeGroupIndex === 0) {
  //     return index + 1 > this.groupList[0].length
  //   } else {
  //     return index + 1 <= this.groupList[0].length
  //   }
  // }

  onChecked({ vacationType }: { vacationType: string }) {
    // this.lastCheckedIndex = index
    // const typeItem = this.SVGSet[index]
    // if (this.activeTypesIndexList.length !== 1 || !typeItem.isActive) {
    //   // if (!typeItem.isActive) {
    //   //   if (this.isIndexFromAnotherGroup(index)) {
    //   //     this.activeGroupIndex = this.activeGroupIndex ? 0 : 1
    //   //     this.SVGSet.forEach(item => (item.isActive = false))
    //   //     this.activeTypesIndexList = []
    //   //   }
    //   //   this.activeTypesIndexList.push(index)
    //   // } else {
    //   //   this.activeTypesIndexList = this.activeTypesIndexList.filter(
    //   //     el => el !== index
    //   //   )
    //   // }
    //   // typeItem.isActive = !typeItem.isActive
    //   const activeTypes: string[] = this.activeTypesIndexList.map(
    //     index => typesEnum[this.items[index]]
    //   )
    // if(vacationType !== 'GUIDED_TOUR' && vacationType !== 'FLY_AND_DRIVE') {
      this.$emit('check', [vacationType])
    // }
    // }
  }
}
