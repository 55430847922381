
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Minus from 'ui-modules/svg-icon/minus'
import Plus from 'ui-modules/svg-icon/plus'
import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    Minus,
    Plus,
    TlIcon
  }
})
export default class PaxSelectorItem extends Vue {
  @Prop(String)
  paxType!: string
  @Prop({ type: Number, default: 0 })
  min!: number
  @Prop({ type: Number, default: 0 })
  max!: number
  @Prop({ type: Number, default: 0 })
  value!: number
  @Prop({ type: String, default: '' })
  description: string

  get isDisabledMin(): boolean {
    return (this.value || 0) <= this.min
  }

  get isDisabledMax(): boolean {
    return (this.value || 0) >= this.max
  }

  onClickMinus(): void {
    if (!this.isDisabledMin) {
      this.$emit('input', this.value - 1)
    }
  }

  onClickPlus(): void {
    if (!this.isDisabledMax) {
      this.$emit('input', this.value + 1)
    }
  }
}
