
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { IPackageProductClass } from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import dateTime from 'common/filters/datetime'
import decimalPlace from 'common/filters/decimalPlace'

@Component({
  filters: {
    dateTime,
    decimalPlace
  }
})
export default class HotelDeal extends Vue {
  @Prop(Object)
  product!: IPackageProductClass

  get dealInterval(): string {
    return `
      ${dateTime(this.product.segmentGroups[0].segments[0].departure.time, 'DD.MM.YY')}
      -
      ${dateTime(this.product.segmentGroups[1].segments[0].departure.time, 'DD.MM.YY')}
    `
  }
}
