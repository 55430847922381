
import ModalWindow from "../modal-window/modal-window.vue";
import Vue from "vue";
import { Component} from "vue-property-decorator";
import {PRODUCT_RESULT_MODULE} from "../../../../applications/desktop/package-compare-app/store/store";
@Component({
  components: {
    ModalWindow
  }
})
export default class ModalWelcomeToSite extends Vue {
  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }

  get getWelcomeText() {
    return this.$t('common.welcome_to_site').split('...')
  }

  cancel() {
    this.resultStore.setModalPost({
      modalPost: null
    })

    localStorage.setItem('isShowWelcomeModal', 'true')
    localStorage.setItem('isShowUserTrainingResultPage', 'true')
    localStorage.setItem('isShowUserTrainingCountryDetails', 'true')
    localStorage.setItem('isShowUserTrainingMoreDetails', 'true')
  }
  startUserTraining() {
    this.$emit('start-user-training')
    this.resultStore.setModalPost({
      modalPost: null
    })
    localStorage.setItem('isShowWelcomeModal', 'true')
  }
}
