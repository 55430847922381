
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import VerticalStepper from 'ui-modules/steppers/vertical-stepper/vertical-stepper.vue'
import convertToRoman from "common/filters/convertToRoman";

import { IOrderFormIStepperStep } from 'modules/product-order.v2/data/order/order.types';
import { IStepperStep } from 'ui-modules/steppers/vertical-stepper/vertical-stepper.types';

@Component({
  components: { VerticalStepper },
  filters: {
    convertToRoman
  }
})
export default class FormFillStepper extends Vue {
  @Prop(Array) stepsConfig!: IOrderFormIStepperStep[]
  @Prop(String) activeStepId!: string

  get steps() {
    return this.stepsConfig.map((item, index) => {
      return {
        ...item,
        isActive: !!(item.id === this.activeStepId),
        label: index === 0 ? "C" : convertToRoman(index)
      }
    })
  }

  onStepClick(step: IStepperStep) {
    this.$emit('scroll-to-step', step.id)
  }
}

