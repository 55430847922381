
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import CustomTooltip from "./custom-tooltip.vue";

@Component({
  components: {CustomTooltip}
})
export default class InfoAboutOrderTooltip extends Vue{
  @Prop(String) aboutInfo: string
  @Prop(Number) optionsLength: number
}
