
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import FilterCondition from "ui-modules/products-filter/filter.condition.vue"
import FilterConditionItemsList from 'ui-modules/products-filter/filter.condition-items-list.vue'

import {
  ACTIVITIES_FACILITIES_CODES_FOR_FILTER,
  CHILDREN_FACILITIES_CODES_FOR_FILTER,
  COMFORT_FACILITIES_CODES_FOR_FILTER,
  IFilterConditionGroup,
  IFilterItemsListCondition,
  IResultPost,
  HEALTH_BEAUTY_FACILITIES_CODES_FOR_FILTER,
  OTHERS_FACILITIES_CODES_FOR_FILTER,
  HotelFacilitiesCodes,
  THotelFacility
} from "ui-modules/products-filter/types/products-filter.types"

@Component({
  components: {
    FilterCondition,
    FilterConditionItemsList
  }
})
export default class FilterFacilities extends Vue {
  @Prop(Object)
  post!: IResultPost

  activities_facilities_codes_for_filter = ACTIVITIES_FACILITIES_CODES_FOR_FILTER
  children_facilities_codes_for_filter = CHILDREN_FACILITIES_CODES_FOR_FILTER
  comfort_facilities_codes_for_filter = COMFORT_FACILITIES_CODES_FOR_FILTER
  health_beauty_facilities_codes_for_filter = HEALTH_BEAUTY_FACILITIES_CODES_FOR_FILTER
  others_facilities_codes_for_filter = OTHERS_FACILITIES_CODES_FOR_FILTER

  get facilityPost(): IFilterItemsListCondition {
    return {
      items: this.post.packageInitialFilter.hotelFacilities,
      itemsCodesForDestinations: this.post.packageInitialFilterForSelectedResult?.hotelFacilitiesCodes,
      selectedItems: this.post.packageFilter.hotelFacilities,
      selectedItemsCodes: this.post.packageFilter.hotelFacilitiesCodes,
      type: 'hotelFacilities'
    }
  }

  getFacilityCategoryPost(facilitiesCodes: HotelFacilitiesCodes[]) {
    return {
      ...this.facilityPost,
      items: this.getFacilitiesListForCategory(facilitiesCodes)
    }
  }

  getConditionGroupPost(groupTitle: string) {
    return {
      groupTitle,
      useAccordionTitleInFilter: this.post.useAccordionTitleInFilter
    }
  }

  getFacilitiesListForCategory(facilitiesCodes: string[]) {
    return this.post.packageInitialFilter?.hotelFacilities.filter(facility => facilitiesCodes.includes(facility.code)) || []
  } 
}
