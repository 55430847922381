import {
  PRODUCT_SEARCH_MODULE,
  PRODUCT_RESULT_MODULE
} from 'applications/desktop/package-compare-app/store/store'

import {
  PackagesList,
  PackageCompareQuery,
  ComparablePackagesList,
  PackageHotel,
  Package,
  PackageProductsList,
  PackageProduct,
  PackageProductPrice,
  Price,
  ProductDetailsPaxComplect,
  PackageProductDetails,
  Customer,
  MediaCollection,
  Media,
  AddressesCollection,
  Address,
  PackageCompareProductFilter,
  ProductFilter
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'


export const restoreClass = (entity: any, classes: any[]) => {
  if (Array.isArray(entity)) {
    return entity.map(property => restoreClass(property, classes))
  } else if (entity != null && typeof entity === 'object') {
    const c = classes.find((c: any) => c && c.name === entity['__class'])

    if (c) {
      entity = Object.assign(Object.create(c.prototype), entity)
    }

    for (const key in entity) {
      if (entity.hasOwnProperty(key)) {
        entity[key] = restoreClass(entity[key], classes)
      }
    }

    return entity
  } else {
    return entity
  }
}

export const restoreVuexClass = ((vuexModule: any, mutationName: string, className: string, depClasses: any[]) => {
  try {
    if (!vuexModule[mutationName]) {
      throw(`no mutation ${mutationName} in vuex module` )
    }

    if (vuexModule[className]) {
      vuexModule[mutationName](restoreClass(vuexModule[className], depClasses))
    }
  } catch(e) {
    throw new Error(e)
  }
}) 

export const restoreAppClasses = (() => {
    const PackageDepClasses = [Package, PackageProductsList, PackageProduct, PackageHotel, PackageProductPrice, Price, ProductDetailsPaxComplect, PackageProductDetails]
    const PackagesListDepClasses = [PackagesList, ...PackageDepClasses]
    const CustomerDepClasses = [Customer, MediaCollection, Media, AddressesCollection, Address]

    restoreVuexClass(PRODUCT_SEARCH_MODULE, 'setSearchQuery', 'searchQuery', [PackageCompareQuery])
    
    restoreVuexClass(PRODUCT_RESULT_MODULE, 'setFilter', 'filter', [PackageCompareProductFilter, ProductFilter])
    restoreVuexClass(PRODUCT_RESULT_MODULE, 'setDefaultFilter', 'defaultFilter', [PackageCompareProductFilter, ProductFilter])
    restoreVuexClass(PRODUCT_RESULT_MODULE, 'setSelectedPackage', 'selectedPackage', PackageDepClasses)
    restoreVuexClass(PRODUCT_RESULT_MODULE, 'setFavoritePackages', 'favoritePackages', [PackagesList, ...PackagesListDepClasses])
    restoreVuexClass(PRODUCT_RESULT_MODULE, 'setComparablePackages', 'comparablePackages', [ComparablePackagesList, ...PackagesListDepClasses])
})

