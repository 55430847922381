
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { IPackageHotelClass } from "applications/desktop/package-compare-app/components/create-search/types/create-search.types";

@Component({})
export default class HeaderImage extends Vue {
  @Prop(Object)
  hotel!: IPackageHotelClass;
  @Prop(Boolean)
  hideGallery: boolean;
  @Prop(Boolean)
  hideInfoIcon: boolean;

  get hotelImage(): string {
    return this.hotel.getMainImage() !== null
      ? `url(${this.hotel.getMainImage()})`
      : `url(${require(`images/default-hotel.jpg`)})`;
  }

  coords(event: Event) {
    const obj = event.target as HTMLElement;
    const rect = obj.getBoundingClientRect();
    return { top: rect.top, left: rect.left };
  }
}
