type TInputType = 'datepicker' | 'selector' | 'textField'

export default {
  bind: (el, binding) => {
    const elContent = el.getElementsByClassName('v-input').item(0)
    const label = el.querySelector('label')
    const input = el.querySelector('input')
    const inputType: TInputType = binding.arg as TInputType || 'textField'

    el.dir = 'ltr'
    elContent.style.textAlign = 'left'
    input.dir = 'ltr'
    label.dir = 'ltr'
    label.style.left = '0px';
    label.style.right = 'auto';
      
    if (inputType !== 'textField') {
      const checkMenuModals = () => {
        const menuModalId = `menu-${input.id.split('-')[1]}`
        const menuModals = document.getElementsByClassName('v-menu__content')
        const newMenuModal = menuModals.item(menuModals.length - 1)

        if (newMenuModal.getAttribute('data') !== menuModalId) {
          newMenuModal.setAttribute('data', menuModalId)
          newMenuModal.dir = 'ltr'
        }
      }

      el.addEventListener('click', checkMenuModals)
    }
  },
  unbind: (el, binding) => {
    el.removeEventListener('click', null)
  }
}
