import {
  IPaxComplects,
  IPackageProductDetailsClass,
  IProductDetailsPaxComplectClass,
  ProductDetailsPaxComplect,
  PAX_COMPLECTS_BLANK,
  setClassName
} from 'modules/product-result.v2/data/product-result.types'
import { IPackageCompareQuery } from 'modules/product-search.v2/data/package-compare-query/query.types'
import { isEqual } from "lodash";

export default class PackageProductDetails
  implements IPackageProductDetailsClass {
  private _details: IPaxComplects = null
  private _complects: IProductDetailsPaxComplectClass[] = []
  private _complectsIds: string[] = []

  constructor(details?: IPaxComplects) {
    setClassName(PackageProductDetails.name, this)

    this._details = details || PAX_COMPLECTS_BLANK
    this._complects = this._details.complects.map(complect => {
      this._complectsIds.push(complect.id)
      return new ProductDetailsPaxComplect(complect)
    })
  }

  get content(): IPackageProductDetailsClass['content'] {
    return this._details
  }
  get complectsIds(): string[] {
    return this._complectsIds
  }
  get paxComplects(): IProductDetailsPaxComplectClass[] {
    return this._complects
  }
  get remarkList() {
    return this._details.remarkList
  }
  get remarks() {
    return this._details.remarks
  }
  get additionalPayments() {
    return this._details.additionalPayments
  }

  set paxComplects(value: IProductDetailsPaxComplectClass[]) {
    this._complects = value
  }
  
  getPaxComplectsIds() {
    return this._complects.map(complect => complect.content.id)
  } 

  static getActualPaxComplect(selectedProductPaxComplects: IProductDetailsPaxComplectClass[], searchQueryPaxes: IPackageCompareQuery['paxes']) {
    return selectedProductPaxComplects.find(pc => {
      const paxes = Object.entries(searchQueryPaxes).reduce((a, b) => {
        return [
          ...a,
          ...new Array(b[1]).fill(b[0])
        ]
      }, [])

      if( isEqual(paxes, pc.paxList) ) {
        return pc
      }
    })
  }
}
