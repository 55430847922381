import {
  IOrderResource
} from 'modules/product-order.v2/data/order/order.types';

import {
  Price,
  Customer
} from 'modules/common/common.types'

export const ORDER_BLANK: IOrderResource = {
  number: null,
  entityId: null,
  contactPerson: new Customer(),
  customers: {},
  totalPrice: new Price(),
  totalPriceOriginal: new Price(),
  paxPrice: new Price(),
  items: []
}