
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IPackageProductClass
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import dateTime from 'common/filters/datetime'

import WaitingFlightsTimingTooltip from 'ui-modules/tooltips/waiting-flights-timing-tooltip/waiting-flights-timing-tooltip.vue'
import getSegments from 'common/getSegments'

@Component({
  components: {
    WaitingFlightsTimingTooltip
  },
  filters: {
    dateTime
  }
})
export default class FlightDetailsV2 extends Vue {
  @Prop(Object)
  product!: IPackageProductClass

  getSegments = getSegments

  // @ts-ignore
  get segmentGroups(): IOrderSegmentGroup[] {
    return this.getSegments(this.product?.segmentGroups)
  }
}
