
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import CustomTooltip from 'ui-modules/tooltips/custom-tooltip.vue'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    CustomTooltip
  }
})

export default class WaitingFlightsTimingTooltip extends Vue {
  @Prop({ type: Boolean, default: false }) bothFlightsTimeisUnKnown!: boolean
  @Prop(String) flightType!: string

  get title(): TranslateResult {
    if (this.bothFlightsTimeisUnKnown) {
      return this.$t('order-v2.tooltips.waiting-both-flights-timing.title')
    } else if (this.flightType === 'departure') {
      return this.$t('order-v2.tooltips.waiting-departure-flight-timing.title')
    } else {
      return this.$t('order-v2.tooltips.waiting-return-flight-timing.title')
    }
  }

  get text(): TranslateResult {
    if (this.bothFlightsTimeisUnKnown) {
      return this.$t('order-v2.tooltips.waiting-both-flights-timing.text')
    } else {
      return this.$t('order-v2.tooltips.waiting-flight-timing.text')
    }
  }
}

