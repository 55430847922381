
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import FilterGroup from "ui-modules/products-filter/filter.group.vue"
import FilterConditionSlider from 'ui-modules/products-filter/filter.condition-slider.vue'

import {
  IFilterSliderCondition,
  IResultPost
} from "ui-modules/products-filter/types/products-filter.types"
import {
  IRegularFilterPost
} from "applications/desktop/package-compare-app/components/search-results/types/search-results.types";

@Component({
  components: {
    FilterGroup,
    FilterConditionSlider
  }
})
export default class FilterNights extends Vue {
  @Prop(Object)
  post!: IRegularFilterPost

  @Watch('post.packageFilter.nights')
  onNightsChange(newValue: number[]) {
    this.minValue = newValue[0]
    this.maxValue = newValue[1]  
  }

  get hasNaNValues(): boolean {
    return isNaN(this.minValue) || isNaN(this.maxValue)
  }

  get nightPost(): IFilterSliderCondition {
    return {
      value: this.post.packageFilter.nights,
      valueLimits: this.post.packageInitialFilter.nights,
      type: 'nights',
      direction: this.post.direction
    }
  }
  minValue = this.nightPost.value[0]
  maxValue = this.nightPost.value[1]

  setVisibleValue(partOfFilter: [number, number]) {
    const [minValue, maxValue] = partOfFilter
    this.minValue = minValue
    this.maxValue = maxValue
  }


  setFilterValue(partOfFilter: {type: string, value: [number, number]}) {
    this.setVisibleValue(partOfFilter.value)
    this.$emit('set-filter', partOfFilter)
  }

}
