
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Active extends Vue {
  @Prop(String)
  iconName: string
  @Prop({ type: [Number, String], default: 65 })
  width?: [number, string]
  @Prop({ type: [Number, String], default: 65 })
  height?: string
}
