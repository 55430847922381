let images = null

function setImagesFunction(extention: string) {
  if (extention === 'png') images = require.context('images/', false, /\.png$/)
  else if (extention === 'svg') images = require.context('images/', false, /\.svg$/)
}

export const getImgUrl = (path: string, extention: string = 'png') => {
  setImagesFunction(extention)
  try {
    return images('./' + path + `.${extention}`)
  } catch (e) {
    images = require.context('images/', false, /\.png$/)
    return images('./BD.png')
  } finally {
    images = null
  }
}

export const isImgUrl = (path: string, extention: string = 'png') => {
  setImagesFunction(extention)
  try {
    return !!images('./' + path + `.${extention}`)
  } catch (e) {
    return false
  } finally {
    images = null
  }
}

export const getImgUrlByPath = (name: string, path: string = './') => {
  let images = require.context('images/', true, /\.png$/)
  try {
    return images(path + name + '.png')
  } catch (e) {
    return images('./BD.png')
  }
}