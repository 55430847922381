
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import PaxIconsList from 'ui-modules/icons/pax-icons/pax-icons-list.vue'
import prettyPrice from 'common/filters/prettyPrice'
import TlIcon from "ui-modules/icons/icon";
import convertToRoman from "common/filters/convertToRoman";

import {
  IProductDetailsPaxComplectClass
} from 'modules/product-result.v2/data/product-result.types'
import {
  IAdditionalPaymentCounter
} from 'applications/desktop/package-compare-app/components/order/types/order.types';
import {PaxPriceResource} from "be-structures/typescript-generator/assembly";
import _ from "lodash";

@Component({
  components: {
    PaxIconsList,
    TlIcon
  },
  filters: {
    prettyPrice,
    convertToRoman
  }
})
export default class PaxInfo extends Vue {
  @Prop(Object) pax: IProductDetailsPaxComplectClass
  @Prop(Number) index: number
  @Prop(Array) additions: IAdditionalPaymentCounter[]

  iconMap = {
    'ADULT': 'male',
    'CHILD': 'child',
    'INFANT': 'baby'
  }

  get paxPrices(): PaxPriceResource[] {
    return this.pax.price.paxPrices.reduce((result: PaxPriceResource[], paxPrice) => {
      const index =result.findIndex(px => paxPrice.paxType === px.paxType)
      if (index !== -1) {
        result[index].total = ((parseFloat(result[index].total) * result[index].quantity + parseFloat(paxPrice.total)*paxPrice.quantity) / (result[index].quantity+paxPrice.quantity))
        result[index].fare = ((parseFloat(result[index].fare) * result[index].quantity + parseFloat(paxPrice.fare)*paxPrice.quantity) / (result[index].quantity+paxPrice.quantity))
        result[index].quantity+=paxPrice.quantity
      } else {
        result.push(_.cloneDeep(paxPrice))
      }

      return result
    }, []);
  }

  romanize(num: number) {
    const lookup = {X:10,IX:9,V:5,IV:4,I:1}
    let roman = ''
    for ( let i in lookup ) {
      while ( num >= lookup[i] ) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }

  pricePerOne(type: string) {
    const {total, quantity} = this.paxPrices.filter(p => p.paxType === type)[0]
    return {
      total: total
    }
  }

  get totalPrice() {
    const paxPrices = this.pax.price
    return this.sortedAdditions.reduce((acc, addition) => {
      const price = addition.price.amount * addition.quantity
      const newAmount = Number(acc.total) + price
      return {...acc, total: newAmount}
    }, paxPrices)
  }

  get sortedAdditions() {
    if (!this.additions) {
      return []
    }
    return Object.values(this.additions.reduce((acc, addition: IAdditionalPaymentCounter) => {
        if (addition.additionalPaymentType === 'OTHER') {
          return acc
        }
        const key = addition.additionalPaymentType === 'SKI' 
                    ? addition.additionalPaymentType 
                    : addition.additionalPaymentType + addition.paxType
        if (!acc[key]) {
          acc[key] = {...addition}
        } else {
          acc[key].quantity += addition.quantity
        }
        return acc
      },
      {
        'BAGGAGEADULT': null,
        'BAGGAGECHILD': null,
        'SKI': null
      })).filter((addition: IAdditionalPaymentCounter) => addition && addition.quantity > 0)
  }
}
