import {
  IPaxComplects
} from "modules/product-result.v2/data/product-result.types";

import {
  PAX_COMPLECT_BLANK
} from 'modules/product-result.v2/data/package-result/package-result.details-complect/package-result.details-complect.consts'

import {
  PRODUCT_PRICE_BLANK
} from 'modules/product-result.v2/data/package-result/package-result.product-price/package-result.product-price.consts'

export const PAX_COMPLECTS_BLANK: IPaxComplects = {
  additionalPayments: [],
  remarks: null,
  taxes: null,
  remarkList: [],
  dealFeatures: [],
  id: null,
  uuid: null,
  complects: [{
    ...PAX_COMPLECT_BLANK,
    price: {
      ...PRODUCT_PRICE_BLANK,
      vat: null,
      appliedCommissions: null,
      appliedMarkups: null,
      empty: null,
      links: null,
      id: null
    },
  }]
}
