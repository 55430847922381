
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import CustomTooltip from "ui-modules/tooltips/custom-tooltip.vue";
import ActionPanel from 'ui-modules/buttons/action-panel.vue'

@Component({
  components: {
    CustomTooltip,
    ActionPanel
  }
})
export default class TooltipWrapper extends Vue {
  @Prop(Boolean)
  isBtnApplyEnabled: boolean;
  @Prop(Boolean)
  resetInsteadConfirm: boolean
  @Prop({type: Boolean, default: true})
  appendToBody: boolean

  confirm(): void {
    this.closeTooltip();
    this.$emit('confirm')
  }

  reset(): void {
    this.$emit('reset')
  }

  close(): void {
    this.closeTooltip();
    this.$emit('close');
  }

  show(): void {
    this.$emit('show');
  }

  hide(): void {
    this.$emit('hide');
  }

  closeTooltip(): void{
      this.$refs.tooltipDate.$refs.popper.doClose()
  }
}
