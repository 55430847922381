
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class HotelInfoScrollable extends Vue {
  @Prop(String) text!: string

  parentWidth: number = null
  childWidth: number = null
  overflowDifference: number = null

  get isContentOverflow(): boolean {
    return this.parentWidth < this.childWidth
  }
  get isRussianOrEnglish() {
    return /^[a-zа-я\s]+$/i.test(this.text)
  }
  get isRTL(): boolean {
    return document.body.getAttribute('dir') === 'rtl'
  }
  // RLT with Hebrew or LTR wih Russian/English text
  get isStandardOverflow() {
    return (this.isContentOverflow && this.isRTL && !this.isRussianOrEnglish) || 
      (this.isContentOverflow && !this.isRTL && this.isRussianOrEnglish)
  }
  // RTL with English text
  get isReversedOverflow() {
    return this.isContentOverflow && this.isRTL && this.isRussianOrEnglish
  }

  showContent(isMouseOver: boolean) {
    if (isMouseOver && this.isContentOverflow) {
      (this.$refs.hotelInfoChild as HTMLElement).style.transform = 
        this.isRTL && !this.isRussianOrEnglish
        ? `translateX(${this.overflowDifference}px)`
        : `translateX(-${this.overflowDifference}px)`
    } else if (!isMouseOver && this.isContentOverflow) {
      (this.$refs.hotelInfoChild as HTMLElement).style.transform = `translateX(0)`
    }
  }

  recalcSize() {
    this.parentWidth = parseInt(getComputedStyle(this.$refs.hotelInfoParent as Element).width)
    this.childWidth = parseInt(getComputedStyle(this.$refs.hotelInfoChild as Element).width)
    this.overflowDifference = this.childWidth - this.parentWidth
  }

  mounted() {
    this.recalcSize()
    window.addEventListener('resize', this.recalcSize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.recalcSize)
  }

  updated() {
    if (parseInt(getComputedStyle(this.$refs.hotelInfoChild as Element).width) !== this.childWidth) {
      this.recalcSize()
    }
  }
}
