
import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class Stepper extends Vue {
  @Prop(Number) numberOfSteps!: number
  @Prop(Number) stepNumber!: number
  @Prop(Object) disabledSteps!: { [key: number]: boolean }

  @Watch('stepNumber')
  updatedSelectedStepNumber(value: number) {
    this.selectedStepNumber = value
  }

  selectedStepNumber: number = null

  created() {
    if (this.stepNumber) {
      this.selectedStepNumber = this.stepNumber
    } else {
      for (let step = 1; step <= this.numberOfSteps; step++) {
        if (!this.isDisabledStep(step)) {
          return this.selectedStepNumber = step
        }
      }
    }
  }

  isActiveStep(step: number) {
    return this.selectedStepNumber === step
  }
  
  isDisabledStep(step: number) {
    return this.disabledSteps?.[step]
  }

  selectStep(step: number) {
    if (!this.isDisabledStep(step) && !this.isActiveStep(step)) {
      this.selectedStepNumber = step
      this.$emit('select-step', step)
    }
  }
}
