
import Vue from "vue";
import ModalBase from 'ui-modules/modals/modal-base.vue'
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {
  IPackageProductClass,
  IStatPackageSearchItemResource
} from "../../../../../../../modules/product-result.v2/data/product-result.types";
import {IBaggageInfo} from "../services-select/services-select.vue";
export interface selectBaggageDetailsPost {
  baggageData: IBaggageInfo
  baggageNum: number
  post: IPackageProductClass['segmentGroups']
}
@Component({
  components: {
    ModalBase
  }
})
export default class SelectBaggageDetails extends Vue{
  @Prop(Object)
  post!: selectBaggageDetailsPost

  get modalPost(): {component: string, preventDefaultClose: boolean, post: selectBaggageDetailsPost} {
    return {
      component: 'select-baggage-details',
      preventDefaultClose: false,
      post: this.post
    }
  }
}
