export * from 'modules/common/common-methods/common-methods'
export * from 'modules/common/addresses-collection/addresses-collection.types'
export * from 'modules/common/address/address.types'
export * from 'modules/common/media-collection/media-collection.types'
export * from 'modules/common/media/media.types'
export * from 'modules/common/customer/customer.types'
export * from 'modules/common/price/price.types'
import { 
  IQuery,
  TPageableHotelsSort,
  TInstantConfirmation
} from "modules/product-result.v2/data/product-result.types";

import {
  StatPackageSearchDigestResource,
  VacationTerm,
} from 'be-structures/typescript-generator/assembly'

export enum VacationTypes {
  BEACH = 'BEACH',
  CITY = 'CITY',
  FAMILY = 'FAMILY',
  SPA = 'SPA',
  SKI = 'SKI',
  FLY_AND_DRIVE = 'FLY_AND_DRIVE',
  GUIDED_TOUR = 'GUIDED_TOUR',
  CIVIL_MARRIAGE = 'CIVIL_MARRIAGE',
  YOUNG_VACATION = 'YOUNG_VACATION'
}

export enum PaxType {
  'ADULT' = 'ADULT',
  'CHILD' = 'CHILD',
  'INFANT' = 'INFANT'
}

export enum FlightGroups {
  'departures' = 'departures',
  'returns' = 'returns'
}


export type TOmitVacationTerm = Omit<VacationTerm, 'departureSince' | 'returnTill' | 'nights'>
export type TStatPackageSearchDigestResource = Omit<StatPackageSearchDigestResource, 'price'>
export type TResourceWithoutLinksAndId<T> = Omit<T, | 'links' | 'id'>
export type TArrayOfVacationTypes = Array<keyof typeof VacationTypes>
export type TFlightGroups = keyof typeof FlightGroups
export type TPaxes = Record<PaxType, number>


// TODO: DEPRECATED INTERFACE, USE TPaxes
export interface IPaxes {
  ADULT: number | number[]
  CHILD?: number | number[]
  INFANT?: number | number[]
  SENIOR?: number | number[]
}

export interface IStartEnd<T> {
  start: T
  end: T
}

export interface IMinMax<T> {
  min: T
  max: T
}

export interface IStartEnd<T> {
  start: T
  end: T
}

export interface IStatPackageSearchDigestResource extends TStatPackageSearchDigestResource {
  price: IMinMax<number>
}

export interface IPage {
  effectiveDigest: IStatPackageSearchDigestResource
  number: number
  size: number
  totalElements: number
  totalPages: number
  query: IQuery
}

export interface IContentResponse<T> {
  content?: T
  page?: IPage
}

export interface IPageable {
  sort?: [{ [key: string]: string }]
  pageSize?: number
  offset?: number
}

export interface IConvertToPackageResult {
  partOfHotelName: string
  airlines: string[]
  boarding: string[]
  dealFeatures: string[]
  pricePerOne: Partial<IMinMax<number>>
  hotelNames: string[]
  hotelStars: IMinMax<number>
  nights: IMinMax<number>
  hotelsSort: TPageableHotelsSort[]
  providers: string[]
  inFlightTime: IMinMax<string>
  outFlightTime: IMinMax<string>
  connectionTime: IMinMax<string>
  outDeparture: IMinMax<string>
  instantConfirmation: TInstantConfirmation
  inDeparture: IMinMax<string>
  outArrival: IMinMax<string>
  inArrival: IMinMax<string>
  hotelFacilityIds: string[]
  page: number
}

export interface IVacationTerm extends TOmitVacationTerm {
  departureSince: string
  returnTill: string
}

export interface IConvertedQuery {
  vacationTerm?: IVacationTerm
  countryCode?: string
  cityCodes?: string[]
}
