import { VacationTerm } from "be-structures/typescript-generator"

export default (http: any) => {
  const getCachedVacationTerm = (): Promise<VacationTerm> => {
    return http.get('/customer/compare/statpackage/calendar/vacation/term/cached', {})
  }

  return {
    getCachedVacationTerm,
  }
}
