
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { Validations } from "vuelidate-property-decorators"
import { validationMixin } from "vuelidate"

import { IPersonInfoForm } from "../custom-forms.types"
import {PaxType} from 'be-structures/typescript-generator/assembly'


import { ILocation } from "modules/product-order.v2/data/product-order.types"

import { checkValidationRules } from "common/validation/validation"
import validationRules from "./customer-info-form.validate"
import CustomTextField from "ui-modules/elements/text-field/text-field.vue"
import CustomRadioGroup from "ui-modules/elements/radio-group/radio-group.vue"
import CustomDatePicker from "ui-modules/elements/date-picker/date-picker.vue"
import CustomAutocompleteForObjects from 'ui-modules/elements/autocomplete/autocomplete-for-objects.vue'
import CustomCheckbox from 'ui-modules/elements/checkbox/checkbox.vue'
import useLtrInput from 'common/directives/vuetify-form.use-rtl-input'
import moment from "moment";
import {
  ISegmentGroupResource,
  IStatPackageSearchItemResource
} from "../../../../../../../../../modules/product-result.v2/data/product-result.types";
import {PRODUCT_ORDER_MODULE} from "../../../../../../store/store";

@Component({
  components: {
    CustomDatePicker,
    CustomRadioGroup,
    CustomAutocompleteForObjects,
    CustomTextField,
    CustomCheckbox
  },
  directives: {
    useLtrInput
  },
  mixins: [validationMixin],
})
export default class CustomerInfoForm extends Vue {
  @Prop(Array) segmentGroups: IStatPackageSearchItemResource['segmentGroups']
  @Prop(Object) person!: IPersonInfoForm;
  @Prop(Array) genders!: string[];
  @Prop(Array) sitizenshipCountries!: ILocation[];
  @Prop(String) paxType!: PaxType;
  @Prop({ type: Boolean, default: true, required: false }) requiredForm!: boolean;
  @Prop({ type: Boolean, default: false, required: false }) isContactPerson!: boolean;
  @Prop({ type: Boolean, default: false, required: false }) useContactPersonInfo!: boolean;


  mounted() {
    if (this.defaultCountry) {
      this.onInputCustomerInfo({ validationPath: 'person', field: 'sitizenshipCountry', value: this.defaultCountry})
    }
  }

  @Validations()
  initValidations() {
    return validationRules({
      requiredForm: this.requiredForm,
      paxType: this.paxType,
      minDateLimit: this.minDateLimit,
      maxDateLimit: this.maxDateLimit,
      minDate: moment(this.getFirstDeparture.departure.time).add(3, 'months').format('YYYY-MM-DD')
    });
  }
  get orderStore() {
    return PRODUCT_ORDER_MODULE
  }

  get customerDOBrule() {
    return this.orderStore.customerDOBrule
  }

  get validForm() {
    return !this.$v.$invalid;
  }

  get getFirstDeparture() {
    return this.segmentGroups[0].segments[0]
  }

  get getLastSegmentGroup(): ISegmentGroupResource {
    const lastSegment = this.segmentGroups.length - 1
    return this.segmentGroups[lastSegment]
  }
  get getLastSegment(): Date {
    return this.getLastSegmentGroup.segments[0].departure.time
  }

  get maxDatePassport(): Date {
    return moment().add(25, 'years').toDate()
  }

  get defaultCountry() {
    return this.sitizenshipCountries.filter(country => country.code === 'IL')[0] || null
  }


 get maxDateLimit(): string {
    return this.getFormattedCustomerRule("maxDate")
  }


  get minDateLimit(): string {
    return this.getFormattedCustomerRule("minDate")
  }

  getFormattedCustomerRule(typeDate: 'minDate' | 'maxDate'): string {
    if(typeDate === 'minDate') {
      return moment(this.customerDOBrule?.rangeOfBirthdays?.[this.paxType]?.min).format("YYYY-MM-DD")
    } else {
      return moment(this.customerDOBrule?.rangeOfBirthdays?.[this.paxType]?.max).format("YYYY-MM-DD")
    }
  }

  get sortedCountriesList() {
    const countries = [...this.sitizenshipCountries]

    return countries.sort((a, b) => {
      return (b.name.toUpperCase() > a.name.toUpperCase())
        ? -1
        : 1
    }) || []
  }

  getGenderRadioLabel(item: string) {
    return `common.gender.${item.toLowerCase()}`
  }

  getErrorMessage({ validationPath, field }: { validationPath: string; field: string; }): string {
    const errorName = checkValidationRules({
      $v: this.$v,
      validationPath,
      field,
    });
    return errorName || null;
  }

  touchForm() {
      this.$v.$touch();
  }

  onInputCustomerInfo({
    validationPath,
    field,
    value,
  }: {
    validationPath: string;
    field: keyof IPersonInfoForm;
    value: string;
  }) {
    this.$v[validationPath][field].$touch();
    this.$emit("input-customer-info", {
      ...this.person,
      [field]: value,
    } as IPersonInfoForm);
  }
}
