
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ComparisonTableAside from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-aside.vue'
import ComparisonTableContent from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-content.vue'
import GridAccordion from 'ui-modules/accordion/grid-accordion.vue'
import FacilitiesGroups from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.facilities-groups.vue'

import {
  ComparisonItems,
  HotelFacilitiesTypes,
  HOTEL_FACILITIES_GROUPED_BY_TYPES,
  IComparablePackagesListClass,
  IPackageCompareResultClass,
  IPackageCompareResultState,
  IPackagesListClass,
  IPackageHotelFacility,
  THotelFacilitiesTypes
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    ComparisonTableAside,
    ComparisonTableContent,
    GridAccordion,
    FacilitiesGroups
  }
})
export default class FacilitiesTypesSection extends Vue {
  @Prop(Array) hotelFacilitiesTypes!: string[]
  @Prop(Object) comparablePackages!: IComparablePackagesListClass
  @Prop(Number) quentityOfComparablePackages!: number
  @Prop(Object) post!: any
  @Prop(Array) disabledFacilities!: IPackageHotelFacility[]

  comparisonItems = ComparisonItems

  getFacilitiesGroupsNames({ facilitiesType }: { facilitiesType: THotelFacilitiesTypes }): string[] {
    return HOTEL_FACILITIES_GROUPED_BY_TYPES[facilitiesType]
  }
}
