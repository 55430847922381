
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TransferIsIncludedIcon from "ui-modules/svg-icon/transfer-is-included.vue";
import TransferNotIncludedIcon from "ui-modules/svg-icon/transfer-not-included.vue";

@Component({
  components: {
    TransferIsIncludedIcon,
    TransferNotIncludedIcon
  }
})
export default class TransferInfo extends Vue {
  @Prop(Boolean) isIncluded: boolean;
}
