
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component({})
export default class BtnWithCounter extends Vue {
  @Prop({ type: String, default: 'Title' }) title!: string
  @Prop({ type: Number, default: 0 }) countItems!: number
  @Prop({ type: Boolean, default: false }) isActive!: boolean
  @Prop({ type: Boolean, default: false }) addTransparency: boolean

  get isEmptyItemsList(): boolean {
    return this.countItems === 0
  }
}
