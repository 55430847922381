var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"selector",on:{"click":function($event){$event.stopPropagation();}}},[_c('tooltip-wrapper',{attrs:{"isBtnApplyEnabled":false},on:{"confirm":_vm.onConfirm,"close":_vm.onCancel,"show":_vm.onShow,"hide":_vm.onHide}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"selector-show"},[(_vm.isOpen)?_c('div',{staticClass:"selector-show--isActive"},[_c('div',{staticClass:"selector-show__selected"},[_vm._v("\n              "+_vm._s(_vm.totalPaxSelected)+"\n              "+_vm._s(_vm.totalPaxSelected > 1
                  ? _vm.$t('common.passengers')
                  : _vm.$t('common.passenger'))+"\n            ")])]):_c('div',{staticClass:"selector-show--full-height",class:{ viewModeClass: _vm.viewMode }},[(!_vm.viewMode)?_c('div',{staticClass:"selector-show--default"},[_c('div',{staticClass:"selector-show-header"},[_c('passenger'),_vm._v(" "),_c('div',{staticClass:"selector-show-header__title"},[_vm._v("\n                  "+_vm._s(_vm.$t('common.selectPassengers'))+"\n                ")])],1),_vm._v(" "),_c('div',{staticClass:"selector-show-selected"},[_c('span',{staticClass:"selector-show-selected__count"},[_vm._v(_vm._s(_vm.totalPaxSelected)+"\n                  "+_vm._s(_vm.totalPaxSelected > 1
                      ? _vm.$t('common.passengers')
                      : _vm.$t('common.passenger'))+"\n                ")])])]):_c('div',{staticClass:"selector-show-flex-container"},[_c('div',{staticClass:"selector-show-flex-container__icon"},[_c('passenger-small')],1),_vm._v(" "),_c('div',[_c('div',{staticClass:"selector-show-flex-container__title"},[_vm._v(_vm._s(_vm.totalPaxSelected)+"\n                  "+_vm._s(_vm.totalPaxSelected > 1 ? _vm.$t('common.passengers') : _vm.$t('common.passenger'))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"selector-show-flex-container__action"},[_vm._v("\n                  "+_vm._s(_vm.$t('common.change'))+"\n                ")])])])])])]),_vm._v(" "),_c('div',{staticClass:"pax-body",attrs:{"slot":"body"},slot:"body"},_vm._l((_vm.list),function(paxItem,paxeType){return _c('paxes-selector-item',{key:paxeType,staticClass:"pax-selector-item",attrs:{"value":_vm.valuePaxes[paxeType],"pax-type":paxeType,"description":paxItem.description,"min":paxItem.val[0],"max":_vm.maxTotalPax - _vm.totalPaxSelected
              ? Math.min(paxItem.val[1], _vm.maxTotalPax)
              : _vm.paxes[paxeType]},on:{"input":val => _vm.onChangePax(paxeType, val)}})}),1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }