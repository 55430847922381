var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"ownerFields"},[_c('h2',{staticClass:"customer-title px-2"},[_vm._v("\n    "+_vm._s(_vm.$t('order.owner-fields.trip-contact'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"content col"},[_c('p',{staticClass:"form-required-input mb-1"},[_vm._v("\n      ("),_c('span',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('order.placeholders.required'))+")\n    ")]),_vm._v(" "),_c('div',{staticClass:"form-row no-gutters py-2"},[_c('div',{staticClass:"col-4 input-group flex-nowrap"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"input-wrapper with-icon",class:{
            inputError:
              _vm.$v.ownerData.email.value.$invalid &&
              _vm.$v.ownerData.email.value.$dirty
          }},[_c('span',{staticClass:"input-wrapper__mandatory"},[_vm._v("*")]),_vm._v(" "),_c('transition',{attrs:{"name":"bounce"}},[(
                _vm.$v.ownerData.email.value.$invalid &&
                  _vm.$v.ownerData.email.value.$dirty
              )?_c('error-tooltip',{attrs:{"msg":!_vm.$v.ownerData.email.value.required
                  ? 'required'
                  : 'wrong-format'}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"wrapped-control"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.ownerData.email.value.$model),expression:"$v.ownerData.email.value.$model",modifiers:{"trim":true}}],staticClass:"wrapped-control__input",attrs:{"type":"email"},domProps:{"value":(_vm.$v.ownerData.email.value.$model)},on:{"change":_vm.onInputOwnerData,"focus":function($event){return _vm.onFocus($event.target)},"blur":[function($event){return _vm.onBlur($event.target)},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.ownerData.email.value, "$model", $event.target.value.trim())}}}),_vm._v(" "),_c('div',{staticClass:"wrapped-control__placeholder",class:{
                'wrapped-control__placeholder--hidden':
                  _vm.$v.ownerData.email.value.$model
              }},[_c('p',[_vm._v(_vm._s(_vm.$t('order.placeholders.mail')))]),_vm._v(" "),_c('i',[_vm._v(" "+_vm._s(_vm.$t('order.placeholders.mail-stay-informed'))+" ")])])])],1)]),_vm._v(" "),_c('div',{staticClass:"col-4 input-group flex-nowrap"},[_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_c('tl-icon',{attrs:{"icon":"mobile-alt"}})],1)]),_vm._v(" "),_c('div',{staticClass:"input-wrapper with-icon",class:{
            inputError:
              _vm.$v.ownerData.mobile.value.$invalid &&
              _vm.$v.ownerData.mobile.value.$dirty
          }},[_c('span',{staticClass:"input-wrapper__mandatory"},[_vm._v("*")]),_vm._v(" "),_c('transition',{attrs:{"name":"bounce"}},[(
                _vm.$v.ownerData.mobile.value.$invalid &&
                  _vm.$v.ownerData.mobile.value.$dirty
              )?_c('error-tooltip',{attrs:{"msg":!_vm.$v.ownerData.mobile.value.required
                  ? 'required'
                  : 'wrong-format'}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"wrapped-control"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.ownerData.mobile.value.$model),expression:"$v.ownerData.mobile.value.$model",modifiers:{"trim":true}}],staticClass:"wrapped-control__input",attrs:{"type":"text"},domProps:{"value":(_vm.$v.ownerData.mobile.value.$model)},on:{"change":_vm.onInputOwnerData,"focus":function($event){return _vm.onFocus($event.target)},"blur":[function($event){return _vm.onBlur($event.target)},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.ownerData.mobile.value, "$model", $event.target.value.trim())}}}),_vm._v(" "),_c('div',{staticClass:"wrapped-control__placeholder",class:{
                'wrapped-control__placeholder--hidden':
                  _vm.$v.ownerData.mobile.value.$model
              }},[_c('p',[_vm._v(_vm._s(_vm.$t('order.placeholders.phone')))]),_vm._v(" "),_c('i',[_vm._v(" "+_vm._s(_vm.$t('order.placeholders.phone-sms'))+" ")])])])],1)]),_vm._v(" "),_c('div',{staticClass:"col-4 input-group flex-nowrap"},[_c('div',{staticClass:"input-wrapper with-icon"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.$v.ownerData.name.$model),expression:"$v.ownerData.name.$model",modifiers:{"trim":true,"lazy":true}}],staticClass:"wrapped-control",attrs:{"type":"text","placeholder":`${_vm.$t('order.placeholders.first-name')}/${_vm.$t(
                'order.placeholders.last-name'
              )}`},domProps:{"value":(_vm.$v.ownerData.name.$model)},on:{"change":[function($event){_vm.$set(_vm.$v.ownerData.name, "$model", $event.target.value.trim())},_vm.onInputOwnerData],"focus":function($event){return _vm.onFocus($event.target)},"blur":[function($event){return _vm.onBlur($event.target)},function($event){return _vm.$forceUpdate()}]}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v("@")])])
}]

export { render, staticRenderFns }