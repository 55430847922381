
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import FilterCondition from "ui-modules/products-filter/filter.condition.vue"
import FilterConditionItemsList from 'ui-modules/products-filter/filter.condition-items-list.vue'

import {
  IFilterConditionGroup,
  IFilterItemsListCondition,
  IResultPost
} from "ui-modules/products-filter/types/products-filter.types"

@Component({
  components: {
    FilterCondition,
    FilterConditionItemsList
  }
})
export default class FilterProviders extends Vue {
  @Prop(Object)
  post!: IResultPost
  @Prop({ type: Boolean, default: false })
  noDisableConditions?: boolean

  get providersPost(): IFilterItemsListCondition {
    return {
      items: this.post.packageInitialFilter.providers,
      itemsCodesForDestinations: this.post.packageInitialFilterForSelectedResult?.providersCodes,
      noDisableConditions: this.noDisableConditions,
      selectedItems: this.post.packageFilter.providers,
      selectedItemsCodes: this.post.packageFilter.providersCodes,
      type: 'providers'
    }
  }

  get conditionGroupPost(): IFilterConditionGroup {
    return {
      groupTitle: 'filter.providers',
      useAccordionTitleInFilter: this.post.useAccordionTitleInFilter
    }
  }
}
