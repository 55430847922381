
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import {
  IFirtStepQuery,
  IMoneyResource,
  IPriceClass,
  IProductDetailsPaxComplectClass
} from 'applications/desktop/package-compare-app/components/order/types/order.types'
import prettyPrice from 'common/filters/prettyPrice'
import mathCeil from "common/filters/mathCeil";
import {addCurrency} from "common/filters/addCurrency";
import TlIcon from 'ui-modules/icons/icon.js'

@Component({
  components: {
    TlIcon,
  },
  filters: {
    prettyPrice,
    mathCeil,
    addCurrency,
  }
})
export default class HotelSummary extends Vue {
  @Prop(Object) product!: IFirtStepQuery['product']
  @Prop(Object) price!: IPriceClass
  @Prop(Object) originalPrice: IPriceClass
  @Prop(Array) selectedPaxComplects!: IProductDetailsPaxComplectClass[]

  // Events:
  // show-remarks-modal: void
  // show-cancellation-terms-modal: void

  get roomTypeData() {
    const customerTypes = this.selectedPaxComplects
      .map(pc => pc.paxList)
      .reduce((acc: string[], types: string[]) => {
        return acc.concat(types)
      })

    return {
      adults: customerTypes.filter((type: string) => type === 'ADULT').length,
      children: customerTypes.filter((type: string) => type === 'CHILD').length,
      infants: customerTypes.filter((type: string) => type === 'INFANT').length,
    }
  }

  get summaryDiff(): IMoneyResource {
    return {
      amount: this.originalPrice.getTotalPriceResource().amount - this.price.getTotalPriceResource().amount,
      currency: this.originalPrice.getTotalPriceResource().currency
    }
  }
}
