import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import http from 'common/http/http'
import funcAPI from 'modules/product-result.v2/product-result.api'
import PRODUCT_RESULT_MODULE from 'modules/product-result.v2/product-result.name'

import {
  StatPackagePreReservationDetailsResource,
} from 'be-structures/typescript-generator/assembly'

import {
  IConvertToPackageResult,
  IPageable,
  IConvertedQuery,
  IStatPackageSearchDigestResource
} from 'modules/common/common.types'

import {
  ComparablePackagesList,
  IComparablePackagesListClass,
  IPackageClass,
  IPackageCompareProductFilterClass,
  IProductFilter,
  IPackageProduct,
  IPackageResponse,
  IPackagesListClass,
  PackageCompareProductFilter,
  PackagesList,
  TResultPendings,
  ResultPendingsNames,
  TResultPendingsNames,
  IDestinationsPackages,
  PackageProductDetails,
  Package,
  IAllDestinationsInfo,
  IAllDestinationInfo,
  IPackageProductClass, IReferralRule
} from 'modules/product-result.v2/data/product-result.types'
import moment from "moment";
import {IModalPost} from "../../ui-modules/modals/modal.types";

const api = funcAPI(http)
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

@Module({ name: PRODUCT_RESULT_MODULE, namespaced: true })
export default class extends VuexModule {
  private _quentityOfComparablePackages: number = 5
  private _quantityOfPossibleBookingErrors: number = 3
  private _comparablePackages: IComparablePackagesListClass = new ComparablePackagesList(null, this._quentityOfComparablePackages)
  private _expirationProductsTime: number = null
  private _favoritePackages: IPackagesListClass = new PackagesList(null)
  private _packagesWithBookingError: IPackagesListClass = new PackagesList(null)
  private _allDestinationsPackages: IDestinationsPackages = {}
  private _allDestinationsInfo: IAllDestinationsInfo = {}
  private _filter: IPackageCompareProductFilterClass = null
  private _defaultFilter: IPackageCompareProductFilterClass = null
  private _selectedPackage: IPackageClass = null
  private _isSessionReady: boolean = true
  private _iShowSessionModal: boolean = false
  private _intervalIds = []
  private _modalPost: IModalPost = null
  private _referralRule: IReferralRule = null
  private _productsComposed: any = null
  private _pendings: TResultPendings = {
    getHotels: true,
    getAndSetHotels: true,
    getPackageProducts: true,
    getAndSetPackageProducts: true,
    getAndSetPackageProductsComposed: true,
    getAndSetFirstPackageProduct: true,
    getProductsDetails: false
  }
  private prevValuesFilter: IPackageCompareProductFilterClass = null;

  get referralRule() {
    return this._referralRule
  }
  get intervalId() {
    return this._intervalIds
  }
  get isSessionReady() {
    return this._isSessionReady
  }
  get iShowSessionModal() {
    return this._iShowSessionModal
  }
  get modalPost() {
    return this._modalPost
  }
  get pendings() {
    return this._pendings
  }

  get productsComposed() {
    return this._productsComposed
  }

  get quentityOfComparablePackages() {
    return this._quentityOfComparablePackages
  }

  get quantityOfPossibleBookingErrors() {
    return this._quantityOfPossibleBookingErrors
  }

  get comparablePackages() {
    return this._comparablePackages
  }

  get favoritePackages() {
    return this._favoritePackages
  }

  get packagesWithBookingError() {
    return this._packagesWithBookingError
  }

  get allDestinationsPackages() {
    return this._allDestinationsPackages
  }

  get filter(): IPackageCompareProductFilterClass {
    return this._filter
  }

  get defaultFilter(): IPackageCompareProductFilterClass {
    return this._defaultFilter
  }

  get previousFilter(): IPackageCompareProductFilterClass {
    return this.prevValuesFilter
  }

  get allDestinationsInfo() {
    return this._allDestinationsInfo
  }

  get selectedPackage() {
    return this._selectedPackage
  }

  get expirationProductsTime() {
    return this._expirationProductsTime
  }

  @Mutation
  resetStore() {
    this._quentityOfComparablePackages = 5
    this._comparablePackages = new ComparablePackagesList(null, this._quentityOfComparablePackages)
    this._favoritePackages = new PackagesList(null)
    this._packagesWithBookingError = new PackagesList(null)
    this._allDestinationsPackages = {}
    this._allDestinationsInfo = {}
    this._filter = null
    this._defaultFilter = null
    this._selectedPackage = null
    this._pendings = {
      getHotels: true,
      getAndSetHotels: true,
      getPackageProducts: true,
      getAndSetPackageProducts: true,
      getAndSetFirstPackageProduct: true,
      getProductsDetails: false
    }
  }


  @Mutation
  setReferralRule({referralRule}: {referralRule: IReferralRule}) {
    this._referralRule = referralRule
  }

  @Mutation
  setModalPost({modalPost}: {modalPost: IModalPost}) {
    this._modalPost = modalPost
  }
  @Mutation
  setPending({ pendingName, value }: { pendingName: keyof typeof ResultPendingsNames, value: boolean }) {
    this._pendings[pendingName] = value
  }

  @Mutation
  setProductsComposed({ products }: { products: any }) {
    this._productsComposed = products
  }

  @Mutation
  setAllDestinationsPackages(allDestinationsPackages: IDestinationsPackages) {
    this._allDestinationsPackages = allDestinationsPackages
  }

  @Mutation
  setDestinationPackage({ countryCode, packagesResult }: { countryCode: string, packagesResult: IPackageResponse }) {
    this._allDestinationsPackages = {
      ...this._allDestinationsPackages,
      [countryCode]: new PackagesList(packagesResult.content, packagesResult.page.effectiveDigest.pricesPerOne.prices.min)
    }
  }

  @Mutation
  setSessionReady({ value }: { value: boolean }) {
    this._isSessionReady = value
  }
  @Mutation
  setIsShowModal({value}: {value: boolean}) {
    this._iShowSessionModal = value
  }

  @Mutation
  setIntervalId({ intervalId }: { intervalId: number }) {
    this._intervalIds.push(intervalId)
  }
  @Mutation
  deleteTimeout({ intervalId }: { intervalId: number[] }) {
    intervalId.forEach(el => {
      clearTimeout(el)
    })
  }

  @Mutation
  setNightsIntervalForPackage({ countryCode, hotelId, nightsInterval }: { countryCode: string, hotelId: string, nightsInterval: IPackageClass['nightsInterval'] }) {
    const destinationPackage = this._allDestinationsPackages[countryCode].getPackage({ hotelId })
    destinationPackage.nightsInterval = nightsInterval
  }

  @Mutation
  setPackageProducts({ countryCode, hotelId, products }: { countryCode: string, hotelId: string, products: IPackageProduct[] }) {
    const destinationPackages = this._allDestinationsPackages[countryCode].getPackage({ hotelId })
    destinationPackages.setProducts(products)
  }

  @Mutation
  setFilter(filter: IPackageCompareProductFilterClass) {
    this._filter = filter
  }

  @Mutation
  setDefaultFilter(defaultFilter: IPackageCompareProductFilterClass) {
    this._defaultFilter = defaultFilter
  }

  @Mutation
  resetFilter() {
    this._filter = new PackageCompareProductFilter({
      filterData: this._defaultFilter.content,
      vacationTypes: this._filter ? this._filter.vacationTypes : null
    })
  }

  @Mutation
  clearPreviousFilter() {
    this.prevValuesFilter = null
  }

  @Mutation
  setPrevFilter(filter: IPackageCompareProductFilterClass) {
    this.prevValuesFilter = new PackageCompareProductFilter({
      filterData: filter.filter,
      vacationTypes: this._filter.vacationTypes
    });
  }

  @Mutation
  undoFilter() {
    let prevValues: IProductFilter = JSON.parse(JSON.stringify(this._defaultFilter.content));
    if (this.prevValuesFilter) {
      Object.assign(prevValues, this.prevValuesFilter.filter);
    } 

    this._filter = new PackageCompareProductFilter({
      filterData: prevValues,
      vacationTypes: this._filter.vacationTypes
    });
  }

  @Mutation
  setFavoritePackages(favoritePackages: IPackagesListClass) {
    this._favoritePackages = favoritePackages
  }

  @Mutation
  addPackageToFavorite({ currentPackage }: { currentPackage: IPackageClass }) {
    this._favoritePackages.addPackage({ currentPackage })
  }

  @Mutation
  removePackageFromFavorite({ currentPackage }: { currentPackage: IPackageClass }) {
    this._favoritePackages.removePackage({ currentPackage })
  }

  @Mutation
  setComparablePackages(comparablePackages: IComparablePackagesListClass) {
    this._comparablePackages = comparablePackages
  }

  @Mutation
  addPackageToCompare({ currentPackage, index }: { currentPackage: IPackageClass, index?: number }) {
    this._comparablePackages.addPackage({ currentPackage, index })
  }

  @Mutation
  removePackageFromCompare({ currentPackage }: { currentPackage: IPackageClass }) {
    this._comparablePackages.removePackage({ currentPackage })
  }

  @Mutation
  addPackageToPackagesWithBookingError({ currentPackage }: { currentPackage: IPackageClass }) {
    this._packagesWithBookingError.addPackage({ currentPackage })
  }

  @Mutation
  removePackageFromPackagesWithBookingError({ currentPackage }: { currentPackage: IPackageClass }) {
    this._packagesWithBookingError.removePackage({ currentPackage })
  }

  @Mutation
  initializeAllDestinationInfo() {
    this._allDestinationsInfo = {}
  }

  @Mutation
  setAllDestinationsInfo({ countryCode, info }: { countryCode: string, info: IAllDestinationInfo }) {
    this._allDestinationsInfo = {
      ...this._allDestinationsInfo,
      [countryCode]: info
    }
  }

  @Mutation
  initializeAllDestinationsPackages() {
    this._allDestinationsPackages = {}
  }

  @Mutation
  removeDestinationPackage({ countryCode }: { countryCode: string }) {
    this._allDestinationsPackages[countryCode] = null
  }

  // TODO: перенести во view-store
  @Mutation
  setSelectedPackage(selectedPackage: IPackageClass) {
    this._selectedPackage = selectedPackage
  }

  @Mutation
  setExpirationProductsTime(time: number) {
    this._expirationProductsTime = time
  }

  @Action
  async getReferralRule({referralCode}: {referralCode: string}) {
    try {
      const referralRule = await api.getReferralRule({referralCode})

      this.context.commit('setReferralRule', { referralRule })

      // TODO disable first page referral modal

      // const modalPost = {
      //   component: 'referral-greetings',
      //   preventDefaultClose: true
      // }
      // this.context.commit('setModalPost', {modalPost})
    } catch (e) {
      return console.error(e)
    }
  }
  @Action
  initializeResultFilter({ digest, effectiveDigest }: { digest: IStatPackageSearchDigestResource, effectiveDigest?: IStatPackageSearchDigestResource }) {
    // TODO: resolve types in filter class
    // @ts-ignore
    const defaultFilter = digest ? new PackageCompareProductFilter({ filterData: digest, isDefaultFilter: true, isPerOnePrice: true }) : null
    // @ts-ignore
    const filter = effectiveDigest ? new PackageCompareProductFilter({ filterData: effectiveDigest, isPerOnePrice: true }) : null

    this.context.commit('setFilter', filter)
    this.context.commit('setDefaultFilter', defaultFilter)
  }

  @Action
  async getDestinationHotels({
    query,
    filter,
    pageable,
  }: {
    query: IConvertedQuery
    filter?: IConvertToPackageResult
    pageable?: IPageable
  }): Promise<IPackageResponse> {
    try {
      this.context.commit('setPending', { pendingName: 'getHotels' as TResultPendingsNames, value: true })
      return await api.getActiveHotels({
        filter,
        pageable,
        query,
      })
    } finally {
      this.context.commit('setPending', { pendingName: 'getHotels' as TResultPendingsNames, value: false })
    }
  }

  @Action
  async getAndSetDestinationHotels({
    query,
    filter,
    pageable,
  }: {
    query: IConvertedQuery
    filter?: IConvertToPackageResult
    pageable?: IPageable
  }): Promise<IPackageResponse> {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetHotels' as TResultPendingsNames, value: true })

      const packagesResult = await this.getDestinationHotels({
        query,
        filter,
        pageable,
      })

      this.context.commit('setDestinationPackage', { countryCode: query.countryCode, packagesResult })

      return packagesResult
    } finally {
      this.context.commit('setPending', { pendingName: 'getAndSetHotels' as TResultPendingsNames, value: false })
    }
  }

  @Action
  async getPackageProducts({
    hotelId,
    filter,
    query
  }: {
    hotelId: string,
    filter?: IConvertToPackageResult,
    query?: IConvertedQuery
  }) {
    try {
      this.context.commit('setPending', { pendingName: 'getPackageProducts' as TResultPendingsNames, value: true })

      return await api.getProductsByHotel({
        filter,
        hotelId,
        query
      })
    } finally {
      this.context.commit('setPending', { pendingName: 'getPackageProducts' as TResultPendingsNames, value: false })
    }
  }

  @Action
  async getAndSetFirstPackageProduct({
    countryCode,
    hotelId,
    filter,
    query
  }: {
    countryCode: string,
    hotelId: string,
    filter?: IConvertToPackageResult,
    query?: IConvertedQuery
  }) {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetFirstPackageProduct' as TResultPendingsNames, value: true })

      const data = await api.getProductsByHotel({
        filter,
        hotelId,
        query,
        pageable: { pageSize: 1 }
      })

        this.context.commit('setNightsIntervalForPackage', {
        countryCode,
        hotelId,
        nightsInterval: data.page.effectiveDigest.nights
      })

      this.context.commit('setPackageProducts', {
        countryCode,
        hotelId,
        products: data.content
      })
    } finally {
      this.context.commit('setPending', { pendingName: 'getAndSetFirstPackageProduct' as TResultPendingsNames, value: false })
    }
  }

  @Action
  async getAndSetPackageProducts({
    countryCode,
    hotelId,
    filter,
    query
  }: {
    countryCode: string,
    hotelId: string,
    filter?: IConvertToPackageResult,
    query?: IConvertedQuery
  }) {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetPackageProducts' as TResultPendingsNames, value: true })
      const data = await api.getProductsByHotel({
        filter,
        hotelId,
        query
      })
      const expiredAtProduct = moment(data.page.query?.expireAt).format('x')
      const currentTime = moment().format('x')
      const compareTime = expiredAtProduct - currentTime
      if(data.page.query?.expireAt) {
        const intervalId = setTimeout(() => {
          this.context.commit('setExpirationProductsTime', 0)
          this.context.commit('setIsShowModal', {value: true})
          this.context.commit('deleteTimeout', {intervalId: [intervalId]})
        }, compareTime)
        this.context.commit('setExpirationProductsTime', Number(expiredAtProduct))
        this.context.commit('setSessionReady', {value: true})
        this.context.commit('setIntervalId', { intervalId })
        const modalPost = {
          component: 'session-expired',
          preventDefaultClose: true
        }
        this.context.commit('setModalPost', {modalPost})
      }
      this.context.commit('setNightsIntervalForPackage', {
        countryCode,
        hotelId,
        nightsInterval: data.page.effectiveDigest.nights
      })

      this.context.commit('setPackageProducts', {
        countryCode,
        hotelId,
        products: data.content
      })
    } finally {
      this.context.commit('setPending', { pendingName: 'getAndSetPackageProducts' as TResultPendingsNames, value: false })
    }
  }

  @Action
  async getAndSetPackageProductsComposed({
    hotelId,
    filter,
    query
  }: {
    hotelId: string,
    filter?: IConvertToPackageResult,
    query?: IConvertedQuery
  }) {
    try {
      this.context.commit('setPending', { pendingName: 'getAndSetPackageProductsComposed' as TResultPendingsNames, value: true })
      const data = await api.getProductsComposedByHotel({
        filter,
        hotelId,
        query
      })

      this.context.commit('setProductsComposed', {
        products: data
      })
      return data
    } finally {
      this.context.commit('setPending', { pendingName: 'getAndSetPackageProductsComposed' as TResultPendingsNames, value: false })
    }
  }

  @Action
  async getDetailsForPackageProduct({
    productId
  }:{
    productId: string
  }): Promise<StatPackagePreReservationDetailsResource> {
    return new Promise(async (resolve, reject) => {
      const details = await api.getProductDetails({ productId })

      if (!details) {
        resolve(null)
      } else if (details.status === 'AWAIT') {
        await delay(800)
        resolve(this.getDetailsForPackageProduct({ productId }))
      } else if (details.status === 'ERROR') {
        reject(null)
      } else {
        resolve(details)
      }
    })
  }

  @Action
  async getDetailsPriceForPackageProduct({ agencyId, productId, complectIds }: { agencyId: string, productId: string, complectIds: string[] }) {
    return api.getPaxComplectAgencyPrices({
      agencyId,
      complectIds,
      productId
    })
  }

  @Action
  async getAndSetDetailsForPackageProduct({
    product,
    agencyId
  }:{
    product: IPackageProductClass
    agencyId: string
  }) {
    try {
      // TODO: must be done with mutation
      this.context.commit('setPending', { pendingName: 'getProductsDetails' as TResultPendingsNames, value: true })

      const productId = product.productId
      const result = await this.getDetailsForPackageProduct({ productId })

      if (result) {
        const details = result.paxComplects
        const detailsPrice = await this.getDetailsPriceForPackageProduct({
          agencyId,
          productId,
          complectIds: details.complects.map(c => c.id)
        })
        if(!!detailsPrice) {
          product.details = new PackageProductDetails({
            ...details,
            complects: details.complects.map(detail => {
              return {
                ...detail,
                price: detailsPrice?.find(dp => dp.complectId === detail.id)?.price,
                originalPrice: detailsPrice?.find(dp => dp.complectId === detail.id)?.originalPrice
              }
            }),
          })
        }
      }
    } finally {
      this.context.commit('setPending', { pendingName: 'getProductsDetails' as TResultPendingsNames, value: false })
    }
  }

  // TODO: DEPRECATED ACTION
  @Action
  async initPackage({ packageContent }: { packageContent: any }) {
    const packageClass = new Package({
      ...packageContent.content,
      products: packageContent.products
    })

    if (packageContent.details) {
      packageClass.products.productsIds.map(id => {
        const product = packageClass.products.getProduct(id)
        product.details = new PackageProductDetails(
          packageContent.details.find((det: any) => det[0] === id)[1]
        )
      })
    }

    return packageClass
  }
}

