
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcon from 'ui-modules/icons/icon'

import { addCurrency } from 'common/filters/addCurrency'

import {
  IPackageClass,
  IProductDetailsPaxComplectClass
} from 'applications/desktop/package-app/components/order-product/types/order-product.types'

@Component({
  components: {
    TlIcon
  },
  filters: {
    addCurrency
  }
})
export default class OrderSummaryAlt extends Vue {
  @Prop(Object)
  currentPackage!: IPackageClass
  @Prop(Object)
  post!: any

  get selectedComplects(): IProductDetailsPaxComplectClass[] {
    let complects: IProductDetailsPaxComplectClass[] = []
    const productIds: string[] = Object.keys(this.post.selectedPaxComplects)
    productIds.forEach(
      id => (complects = complects.concat(this.post.selectedPaxComplects[id]))
    )
    return complects
  }

  get totalComplectsLength(): number {
    let total: number = 0
    Object.keys(this.post.selectedPaxComplects).forEach(productId => {
      this.post.selectedPaxComplects[productId] &&
        this.post.selectedPaxComplects[productId].forEach(
          (complect: IProductDetailsPaxComplectClass) => (total += 1)
        )
    })
    return total
  }

  get totalComplectsPrice(): number {
    let price: number = 0
    Object.keys(this.post.selectedPaxComplects).forEach(productId => {
      this.post.selectedPaxComplects[productId] &&
        this.post.selectedPaxComplects[productId].forEach(
          (complect: IProductDetailsPaxComplectClass) =>
            (price += this.getPaxComplectPrice(productId, complect.content.id))
        )
    })
    return price
  }

  getPaxComplectPrice(productId: string, complectId: string): number {
    return Number(
      this.currentPackage.products
        .getProduct(productId)
        .getPaxComplectPrice(complectId)?.price.total || null
    )
  }

  getQuantity(complect: IProductDetailsPaxComplectClass) {
    let quantity = 0
    complect.content.price.stages[0].paxPrices.forEach(
      price => (quantity += price.body.quantity)
    )
    return quantity
  }

  getPaxIcon(paxType: string) {
    return paxType === 'ADULT' ? 'male' : 'child'
  }
}
