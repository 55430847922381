
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ComparisonTableAside from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-aside.vue'
import ComparisonTableContent from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-content.vue'
import GridAccordion from 'ui-modules/accordion/grid-accordion.vue'

import {
  ComparisonItems,
  IComparablePackagesListClass,
  IPackageCompareResultClass,
  IPackageCompareResultState,
  IPackagesListClass,
  IPackageHotelFacility,
  HotelFacilityGroupsTypes
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    ComparisonTableAside,
    ComparisonTableContent,
    GridAccordion
  }
})
export default class FacilitiesGroups extends Vue {
  @Prop(Number) quentityOfComparablePackages!: number

  @Prop(Array) facilitiesGroupsNames!: string[]
  @Prop(Object) comparablePackages!: IComparablePackagesListClass
  // @Prop(Array) comparisonMap!: string[]
  @Prop(Object) post!: any
  @Prop(Array) disabledFacilities!: IPackageHotelFacility[]

  comparisonItems = ComparisonItems

  getEnabledFacilitiesClassifiedByGroups({ groupName }: { groupName: HotelFacilityGroupsTypes }): IPackageHotelFacility[] {
    const facilities = this.comparablePackages.classifyFacilitesIntoGroupsForAllPackages({ groupName })
    return facilities.filter(f => this.disabledFacilities.findIndex(df => f.code === df.code) === -1)
  }
}
