
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import { IStepperStep } from 'ui-modules/steppers/vertical-stepper/vertical-stepper.types';

@Component({
  components: {}
})
export default class VerticalStepper extends Vue {
  @Prop(Array) steps!: IStepperStep[]
}

