export const carOsagoInsuranceRuAlphaResourse = 'АВЕКМНОРСТУХ'
export const carOsagoInsuranceRuAlphaSecondResourse = 'АВЕКЛМНОРСТУХ'
export const carOsagoInsuranceAlphaResourse = 'ABCDEFGHJKLMNPRSTUVWXYZ'
export const ruAlphaResourse = 'а-яё'
export const alphaResourse = 'a-z'
export const numeric = '0-9' 

export const carOsagoInsuranceRuAlphaValid = `[${carOsagoInsuranceRuAlphaResourse}]`
export const carOsagoInsuranceRuAlphaNumValid = `[${carOsagoInsuranceRuAlphaResourse}${numeric}]`
export const ruAlphaSentenceValid = `[${ruAlphaResourse}-\s]`
export const ruAlphaNumSentenceValid = `[${ruAlphaResourse}${numeric}-\s]`

export const carOsagoInsuranceRuAlphaSecondValid = `[${carOsagoInsuranceRuAlphaSecondResourse}]`
export const carOsagoInsuranceRuAlphaNumSecondValid = `[${carOsagoInsuranceRuAlphaSecondResourse}${numeric}]`

export const carOsagoInsuranceAlphaValid = `[${carOsagoInsuranceAlphaResourse}]`
export const carOsagoInsuranceAlphaNumValid = `[${carOsagoInsuranceAlphaResourse}${numeric}]`
export const alphaSentenceValid = `[${alphaResourse}-\s]`
export const alphaNumSentenceValid = `[${alphaResourse}${numeric}-\s]`

export const alphaNum = `[${ruAlphaResourse}${alphaResourse}${numeric}]`