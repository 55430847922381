
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import hotelMainImage from 'common/placeholder-image'
import { IPackageHotelClass } from 'applications/desktop/package-compare-app/components/create-search/types/create-search.types'

@Component({})
export default class HotelImage extends Vue {
  @Prop(Object)
  hotel!: IPackageHotelClass

  hotelMainImage = hotelMainImage
}
