import {
  alphaNum,
  carOsagoInsuranceRuAlphaValid,
  carOsagoInsuranceRuAlphaSecondValid,
  carOsagoInsuranceRuAlphaNumValid,
  carOsagoInsuranceAlphaNumValid
} from 'common/regex/regex' 

export default {
  name: 'facade',
  tokens: {
    '#': { pattern: /\d/ },
    '%': { pattern: /[0-9а-яА-ЯЁё]/i },
    'M': { pattern: new RegExp(`${alphaNum}`, 'i'), transform: (v) => v.toLocaleUpperCase() },
    'T': { pattern: new RegExp(`${carOsagoInsuranceRuAlphaValid}`, 'i'), transform: (v) => v.toLocaleUpperCase() },
    'S': { pattern: new RegExp(`${carOsagoInsuranceRuAlphaSecondValid}`, 'i'), transform: (v) => v.toLocaleUpperCase() },
    'R': { pattern: new RegExp(`${carOsagoInsuranceRuAlphaNumValid}`, 'i'), transform: (v) => v.toLocaleUpperCase() },
    'A': { pattern: new RegExp(`${carOsagoInsuranceAlphaNumValid}`, 'i'), transform: (v) => v.toLocaleUpperCase() }
  }
}