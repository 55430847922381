import Vue from 'vue'
import Component from 'vue-class-component'

import {
  PRODUCT_SEARCH_MODULE,
  PRODUCT_RESULT_MODULE
} from 'applications/desktop/package-compare-app/store/store'

import {
  IPackageHotelClass,
  IConvertToPackageResult,
  ICountriesWithDigest
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

import Accordion from 'ui-modules/accordion/accordion.vue'
import Advice from 'ui-modules/advice/Advice.vue'
import BoxLoader from 'ui-modules/marker-loader/marker-loader.vue'
import HotelCardModal from 'ui-modules/modals/hotel-card-modal/hotel-card-modal.vue'
import NotFoundPage from 'ui-modules/not-found-page/not-found-page.vue'

import ModalBase from 'ui-modules/modals/modal-base.vue'

import dateTime from 'common/filters/datetime'

@Component({
  components: {
    Accordion,
    Advice,
    BoxLoader,
    HotelCardModal,
    ModalBase,
    NotFoundPage
  },
  filters: {
    dateTime
  }
})
export default class PrepareSearchDataCommon extends Vue {

  showHotelCardModal: boolean = false
  hotel: IPackageHotelClass = null

  get searchStore() {
    return PRODUCT_SEARCH_MODULE
  }

  get searchQuery() {
    return this.searchStore.searchQuery
  }

  get resultStore() {
    return PRODUCT_RESULT_MODULE
  }

  get destinations() {
    return this.searchStore.destinations
  }

  get destinationsForCalendar() {
    return this.searchStore.destinationsForCalendar
  }

  get quantityOfDestinations(): number {
    return Object.keys(this.destinations).length
  }

  get quantityOfPassengers(): number {
    return this.searchQuery.getQuantityOfPassengers()
  }
}
