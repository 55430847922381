
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import {
  IAdditional,
  IGoogleGeo,
  IPackageProductClass,
  IPackageProductsListClass,
  PackageHotel,
  IPackageHotelClass
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

import { IBestDeal } from "modules/product-result.v2/data/product-result.types";

import HotelActionBtns from "ui-modules/cards/hotel/action-buttons.vue";
import Stars from "ui-modules/cards/hotel/stars.vue";
import HeaderImage from "ui-modules/cards/hotel/header-image.vue";
import HotelFlySegments from "ui-modules/cards/hotel/hotel-fly-segments.vue";
import HotelPrice from "ui-modules/cards/hotel/hotel-price.vue";
import OrderBtn from "ui-modules/buttons/order-btn.vue";
import CompareBtn from "ui-modules/buttons/compare-btn.vue";
import CloseCrossBtn from "ui-modules/buttons/close-cross-btn.vue";

@Component({
  components: {
    CompareBtn,
    CloseCrossBtn,
    OrderBtn,
    HeaderImage,
    HotelActionBtns,
    HotelFlySegments,
    HotelPrice,
    Stars
  }
})
export default class BestDealModal extends Vue {
  @Prop(Object)
  deal: IBestDeal;

  reverseSlide: boolean = false;
  hotelFacilityName: string = "Hotel";
  roomFacilityName: string = "Room";
  imageIndex: number = 0;

  get hotel(): IPackageHotelClass {
    return new PackageHotel(this.deal as any);
  }

  get product() {
    return {
      supplierAirline: this.deal.supplierAirline,
      productId: this.deal.productId,
      segmentGroups: this.deal.segmentGroups
    };
  }

  get hasTransfer() {
    return this.deal.dealFeatures?.includes("CITY_PACKAGE_INCLUDING_TRANSFER");
  }

  hotelImage(index: number) {
    return this.deal.hotel.images[index].url;
  }

  getFacilities(type: string): any[] {
    return this.deal.hotel.facilities?.filter((facility: any) => {
      if (facility.type === type) {
        return facility;
      }
    });
  }
}
