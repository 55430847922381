
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ComparisonTableAside from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-aside.vue'
import ComparisonTableContent from 'applications/desktop/package-compare-app/components/compare-products/ui/compare-products.table-content.vue'

import {
  IPackageCompareResultClass,
  IPackageCompareResultState,
  IPackagesListClass
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    ComparisonTableAside,
    ComparisonTableContent
  }
})
export default class HotelHeaderSection extends Vue {
  @Prop(Array) comparisonMap!: string[]
  @Prop(Object) comparablePackages!: IPackagesListClass
  @Prop(Number) quentityOfComparablePackages!: number
  @Prop(Boolean) noFavoritePackages!: boolean

  @Prop(Object) post!: any

  getComparisonItemText(item: string) {
    if (['CONTROL_PANEL', 'HOTEL_NAME', 'HOTEL_IMAGE', 'HOTEL_DIGEST'].includes(item)) {
      return item
    } else {
      return this.$t(`facility_groups.${item}`)
    }
  }
}
