
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

import PaxIconsList from 'ui-modules/icons/pax-icons/pax-icons-list.vue'
@Component({
	components: {
		PaxIconsList
	}
})

export default class RoomPaxInfo extends Vue {
	@Prop(Array) paxList!: string[]
	@Prop(String) roomType!: string
	@Prop({ type: Boolean, default: false, required: false }) reverse!: boolean
	@Prop({ type: Boolean, default: false, required: false }) fullWidth!: boolean
}
