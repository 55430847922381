
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import TlIcon from 'ui-modules/icons/icon.js'

@Component({
  components: {
    TlIcon
  }
})
export default class GridAccordion extends Vue {
  @Prop(String)
  transitionName!: string
  @Prop(Array)
  icons!: string[]
  @Prop({ type: String, default: 'Accordion title' })
  title!: string
  @Prop({ default: true })
  showDefault!: boolean
  @Prop({ default: null }) showContentFromOutside!: boolean

  created() {
    this.showContentFromInside = this.showDefault
  }

  showContentFromInside: boolean = true

  get showContent(): boolean {
    if (this.showContentFromOutside === null) {
      return this.showContentFromInside
    } else {
      return this.showContentFromOutside
    }
  }
  get needToRotate(): boolean {
    return this.icons.length > 1 ? false : true
  }
  get icon(): string {
    return this.icons.length > 1 && this.showContent ? this.icons[1] : this.icons[0]
  }
}
