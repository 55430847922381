
import Vue from "vue";
import { Prop, Component, Ref, Watch } from "vue-property-decorator";

import FlightInformation from 'applications/desktop/package-compare-app/components/order/ui/flight-information/flight-information.vue'
import HotelDetails from 'applications/desktop/package-compare-app/components/order/ui/hotel-details/hotel-details.vue'
import PassengersAndAccomodation from 'applications/desktop/package-compare-app/components/order/ui/passengers-and-accomodation/passengers-and-accomodation.vue'
import ToBePaid from 'applications/desktop/package-compare-app/components/order/ui/to-be-paid/to-be-paid.vue'
import PaymentMessage from "applications/desktop/package-compare-app/components/order/ui/payment-message/payment-message.vue";
import TransferInfo from "applications/desktop/package-compare-app/components/order/ui/transfer-info/transfer-info.vue";
import OurContacts from "applications/desktop/package-compare-app/components/order/ui/our-contacts/our-contacts.vue";

import {
  IOrderPost,
} from 'applications/desktop/package-compare-app/components/order/types/order.types'
import {PRODUCT_ORDER_MODULE} from "applications/desktop/package-compare-app/store/store";


@Component({
  components: {
    FlightInformation,
    HotelDetails,
    PassengersAndAccomodation,
    ToBePaid,
    PaymentMessage,
    TransferInfo,
    OurContacts
  }
})
export default class SuccessfulBookingWithNoPayment extends Vue {
  @Prop(Object) post!: IOrderPost

  get agency() {
    return this.orderStore.agencyInfo.agency
  }

  get dealFeatures() {
    return this.post.destinationPackage?.selectedProduct?.dealFeatures
  }

  get isSuccess() {
    return true
  }

  get bookingNumber() {
    return this.post.order.number
  }

  get luggageInfo() {
    if (this.post.productDetails.additionalPayments.length) return this.post.productDetails.additionalPayments[0]
  }

  get isIncludedTransfer() {
    return this.post.destinationPackage.selectedProduct.hasTransfer;
  }

  get dates() {
    return this.post.destinationPackage.selectedProduct.content.dealInterval;
  }

  get orderStore() {
    return PRODUCT_ORDER_MODULE
  }

  get ourContacts() {
    return {
      phone: this.agency.phone,
      mail: this.agency.email,
      address: this.agency.addresses,
      name: this.agency.name
    }
  }
}
