
import { Component, Vue } from 'vue-property-decorator';
import ModalCommon from 'ui-modules/modals/modal-common.vue';

@Component({
  components: {
    ModalCommon
  }
})
export default class ModalIntroduction extends Vue {
  dontShowAgain = false

  onGotItClick() {
    this.$emit('got-it', this.dontShowAgain)
  }
}
