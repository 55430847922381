enum ComparisonItems {
  CONTROL_PANEL = 'CONTROL_PANEL',
  HOTEL_IMAGE = 'HOTEL_IMAGE',
  HOTEL_NAME = 'HOTEL_NAME',
  HOTEL_RATE = 'HOTEL_RATE',
  HOTEL_PRICE = 'HOTEL_PRICE',
  DEAL_DETAILS = 'DEAL_DETAILS',
  FLIGHT_DETAILS = 'FLIGHT_DETAILS',
  HOTEL_FACILITIES = 'HOTEL_FACILITIES',
  HOTEL_FACILITIES_GROUPS = 'HOTEL_FACILITIES_GROUPS',
  HOTEL_FACILITIES_TYPES = 'HOTEL_FACILITIES_TYPES',
  HOTEL_SCORE = 'HOTEL_SCORE',
  ORDER_BUTTON = 'ORDER_BUTTON',
  ACCORDION_TITLE = 'FACILITIES_GROUP_TITLE',
  HOTEL_DIGEST = 'HOTEL_DIGEST'
}

const HOTEL_INFO_HEADER_COMPARISON_MAP = [
  ComparisonItems.CONTROL_PANEL,
  ComparisonItems.HOTEL_IMAGE,
  ComparisonItems.HOTEL_NAME,
  ComparisonItems.HOTEL_RATE,
  ComparisonItems.HOTEL_PRICE,
]

const FIXED_HOTEL_INFO_HEADER_COMPARISON_MAP = [
  ComparisonItems.HOTEL_DIGEST
]

const HOTEL_INFO_COMPARISON_MAP = [
  ComparisonItems.DEAL_DETAILS,
  ComparisonItems.FLIGHT_DETAILS
]

const DEAL_DETAILS_COMPARISON_MAP = [
  'PROVIDER',
  'NIGHTS',
  'BOARDING',
  'ROOM',
  'TRANSFER'
]

const FLIGHT_DETAILS_COMPARISON_MAP = [
  'AIRLINE',
  'DEPARTURE_FLIGHT',
  'RETURN_FLIGHT'
]

const HOTEL_FACILITIES_COMPARISON_MAP = [
  ComparisonItems.HOTEL_FACILITIES,
  ComparisonItems.HOTEL_FACILITIES_GROUPS
]

export {
  ComparisonItems,
  DEAL_DETAILS_COMPARISON_MAP,
  FLIGHT_DETAILS_COMPARISON_MAP,
  HOTEL_INFO_COMPARISON_MAP,
  HOTEL_INFO_HEADER_COMPARISON_MAP,
  HOTEL_FACILITIES_COMPARISON_MAP,
  FIXED_HOTEL_INFO_HEADER_COMPARISON_MAP
}
