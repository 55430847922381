
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import InfiniteLoading from "vue-infinite-loading";

import BoxLoader from "ui-modules/marker-loader/marker-loader.vue";
import NotFoundPage from "ui-modules/not-found-page/not-found-page.vue";
import dateTime from "common/filters/datetime";
import DestinationItem from "applications/desktop/package-compare-app/components/search-results/ui/destination-item.vue";
import GridAccordion from "ui-modules/accordion/grid-accordion.vue";
import SearchResultCard from "applications/desktop/package-compare-app/components/search-results/ui/search-result.card.vue";
import timeout from 'common/timeout'
import DestinationItemContent from "applications/desktop/package-compare-app/components/search-results/ui/destination-item-content/destination-item-content.vue"
import SearchHint from 'ui-modules/search-hint/search-hint.vue'

import {
  IPackageClass,
  IPackagesListClass,
  IQueryLocation,
  IRegularResultPost
} from "applications/desktop/package-compare-app/components/search-results/types/search-results.types";

@Component({
  components: {
    BoxLoader,
    NotFoundPage,
    DestinationItem,
    GridAccordion,
    SearchHint,
    SearchResultCard,
    InfiniteLoading,
    DestinationItemContent
  },
  filters: {
    dateTime
  }
})
export default class OpenedDestinatonItem extends Vue {
  @Prop(Object)
  post!: IRegularResultPost;
  @Prop(Object)
  destinationPackages!: IPackagesListClass;
  @Prop(Object)
  destination!: IQueryLocation;
  @Prop(Number) quantityComparablePackages!: number;
  @Prop(Number) quantityFavoritePackages!: number;

  indexToScroll = null;
  @Watch("indexToScroll")
  watching(newValue: number) {
    if (!newValue) {
      this.$refs.card[newValue]?.$el.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
    }
  }
  @Watch("visiblePackagesWithoutSoldOut")
  visiblePackagesWithoutSoldOutChange(newValue: IPackageClass[], oldValue: IPackageClass[]) {
    const numberOfHotelsWithoutScroll = 3
    if ((newValue.length === 0 && oldValue.length !== 0 && this.visiblePackages.length>0) || (newValue.length <= numberOfHotelsWithoutScroll)) {
      this.loadMorePackages({
        loaded: () => {},
        complete: () => {}
      });
    }
  }

  created() {
    this.$emit('stop-training')
  }

  nextStepTraining() {
    this.$emit('next-step-training')
  }

  get isOpenedDestination(): boolean {
    return this.post.isSelectedCountry({
      countryCode: this.destination.country.code
    });
  }
  get IsNotSoldOutPackages(): boolean {
    return this.destinationPackages?.content?.filter(destinationPackage => !destinationPackage.isSoldOut).length > 0
  }
  get visiblePackagesWithoutSoldOut(): IPackageClass[] {
    return this.visiblePackages.filter((vPackage) => !vPackage.isSoldOut);
  }
  get visiblePackages(): IPackageClass[] {
    return this.destinationPackages?.content?.slice(
      0,
      this.post.quantityOfVisiblePackages
    ) ?? [];
  }

  dropIndexToScroll() {
    this.indexToScroll = null;
  }

  scrollToCard(value) {
    if (typeof this.indexToScroll === "object" || value <= this.indexToScroll) {
      this.indexToScroll = value;
    }
  }

  scrollToTop() {
    this.$refs.card[0]?.$el.scrollIntoView(false)
  }

  hideCityName({ currentPackage }: { currentPackage: IPackageClass }) {
    // const location = this.packageQuery.locations[this.destinationIndex]
    // return location.cities.length === 1 || location?.city?.code === currentPackage.hotel.city.code
    return false;
  }

  onShowFlightChangeModal({ currentPackage }: { currentPackage: IPackageClass;
  }): void {
    this.$emit("show-flight-change-modal", { currentPackage });
  }

  changeFavoriteStatus({
    currentPackage
  }: {
    currentPackage: IPackageClass;
  }): void {
    this.$emit("change-favorite-status", { currentPackage });
  }

  changeComparisonStatus({
    currentPackage
  }: {
    currentPackage: IPackageClass;
  }): void {
    this.$emit("change-comparison-status", { currentPackage });
  }

  async loadMorePackages(state: any) {
    const quantityOfHiddenPackages: number =
      this.destinationPackages?.content?.length >
      this.post.quantityOfVisiblePackages
        ? this.destinationPackages?.content?.length -
          this.post.quantityOfVisiblePackages
        : 0;

    if (quantityOfHiddenPackages > 0) {
      await timeout(500);

      this.$emit("load-more-products", {
        quantityOfVisiblePackages: this.post.quantityOfVisiblePackages
      });
      this.$emit("change-quantity-of-visible-packages", {
        count:
          this.post.quantityOfVisiblePackages +
          (quantityOfHiddenPackages >= 5 ? 5 : quantityOfHiddenPackages)
      });

      state.loaded();
    } else {
      state.complete();
    }
  }
}
