
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ProductProvider from 'ui-modules/cards/hotel/v2/product-provider.vue'

import {
  ComparisonItems,
  IPackageClass,
  IPackageCompareResultClass,
  IPackageCompareResultState
} from 'applications/desktop/package-compare-app/components/compare-products/types/compare-products.types'

@Component({
  components: {
    ProductProvider
  }
})
export default class DealDetails extends Vue {
  @Prop(Object) currentPackage!: IPackageClass
  @Prop(String)
  comparisonItem!: ComparisonItems

  comparisonItems = ComparisonItems

}
