
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  IProductDetailsPaxComplectClass,
  IGroupedComplect,
  IPackageClass
} from 'applications/desktop/package-app/components/order-product/types/order-product.types'

import PaxComplectsRoomFilter from './pax-complects.room-filter.vue'
import PaxComplectsListItem from './order-product.pax-complects-list-item.vue'

@Component({
  components: {
    PaxComplectsRoomFilter,
    PaxComplectsListItem
  }
})
export default class PaxComplects extends Vue {
  @Prop(Object)
  filter!: {
    fareBasisList: string[]
    fareBasis: string[]
    fastOk: boolean
    carIncluded: boolean
  }
  @Prop(Array)
  filteredComplects!: IGroupedComplect[]
  @Prop(Array)
  groupedComplects!: IGroupedComplect[]
  @Prop(Object)
  selectedPaxComplects!: {
    [productId: string]: IProductDetailsPaxComplectClass[]
  }
  @Prop(Object)
  currentPackage!: IPackageClass

  get fastOkFiltering(): boolean {
    return (
      this.groupedComplects.some(complect => complect.fastOk) &&
      this.groupedComplects.some(complect => !complect.fastOk)
    )
  }

  get carFiltering(): boolean {
    return (
      this.groupedComplects.some(complect => complect.carIncluded) &&
      this.groupedComplects.some(complect => !complect.carIncluded)
    )
  }

  get filterPost(): {
    fastOkFiltering: boolean
    carFiltering: boolean
  } {
    return {
      fastOkFiltering: this.fastOkFiltering,
      carFiltering: this.carFiltering
    }
  }

  isItemDisabled(complectObject: IGroupedComplect) {
    const selectedProducts: string[] = Object.keys(this.selectedPaxComplects)

    return (
      selectedProducts &&
      selectedProducts.length > 0 &&
      !selectedProducts.includes(complectObject.productId)
    )
  }

  onUpdatePaxComplects(complectObject: IGroupedComplect): void {
    const selectedPaxComplects = Object.assign({}, this.selectedPaxComplects)
    const productId: string = complectObject.productId

    if (selectedPaxComplects[productId]) {
      const existComplectsCount: number = selectedPaxComplects[
        complectObject.productId
      ].filter(c => c.content.id === complectObject.complect.content.id).length
      if (existComplectsCount > 0) {
        for (let i = 0; i < existComplectsCount; i++) {
          const index = selectedPaxComplects[
            complectObject.productId
          ].findIndex(c => c.content.id === complectObject.complect.content.id)
          selectedPaxComplects[complectObject.productId].splice(index, 1)
        }
      } else {
        selectedPaxComplects[productId].push(complectObject.complect)
      }
    } else {
      selectedPaxComplects[productId] = []
      selectedPaxComplects[productId].push(complectObject.complect)
    }

    this.$emit('update-pax-complects', selectedPaxComplects)
  }

  getComplectsCount(complectObject: IGroupedComplect) {
    const complectId = complectObject.complect.content.id
    const selectedComplects = this.selectedPaxComplects[
      complectObject.productId
    ]

    return (
      (selectedComplects &&
        selectedComplects.length &&
        selectedComplects.filter(complect => complect.content.id === complectId)
          .length) ||
      0
    )
  }

  onAddComplect(complectObject: IGroupedComplect): void {
    const selectedPaxComplects = Object.assign({}, this.selectedPaxComplects)

    if (selectedPaxComplects[complectObject.productId]) {
      selectedPaxComplects[complectObject.productId].push(
        complectObject.complect
      )
    } else {
      selectedPaxComplects[complectObject.productId] = []
      selectedPaxComplects[complectObject.productId].push(
        complectObject.complect
      )
    }

    this.$emit('update-pax-complects', selectedPaxComplects)
  }

  onRemoveComplect(complectObject: IGroupedComplect): void {
    const selectedPaxComplects = Object.assign({}, this.selectedPaxComplects)
    const existIndex: number = selectedPaxComplects[
      complectObject.productId
    ].findIndex(c => c.content.id === complectObject.complect.content.id)

    selectedPaxComplects[complectObject.productId].splice(existIndex, 1)

    if (!selectedPaxComplects[complectObject.productId].length) {
      delete selectedPaxComplects[complectObject.productId]
    }

    this.$emit('update-pax-complects', selectedPaxComplects)
  }
}
