import { ValidationRuleset } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

import { IMedia } from 'applications/desktop/package-app/types/package-app.types'

export interface IOwnerInput {
  birthday: string
  name: string
  type: string
  email: IMedia
  mobile: IMedia
}

export const validations: ValidationRuleset<{ ownerData: IOwnerInput }> = {
  ownerData: {
    birthday: {
      value: {
        required
      }
    },
    email: {
      value: {
        email,
        required
      }
    },
    mobile: {
      value: {
        required
      }
    },
    name: {
      value: {
        required
      }
    },
    type: {
      value: {
        required
      }
    }
  }
}
