
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import FlightDetailsDiagram from "applications/desktop/package-compare-app/components/search-results/ui/flight-details-diagram.vue";
import DashTextPlaceholder from 'applications/desktop/package-compare-app/components/search-results/ui/dash-text-placeholder.vue'
import HotelInfoScrollable from 'applications/desktop/package-compare-app/components/search-results/ui/hotel-info-scrollable.vue'
import TlIcon from "ui-modules/icons/icon";

import getSegments from 'common/getSegments'

import { ISegmentGroupResource } from 'modules/product-result.v2/data/product-result.types'
import {
  IProductBlockPost
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

import { formatDateToDMYDot } from 'common/filters/formattedDates'
import { i18n_MODULE } from 'applications/desktop/package-compare-app/store/store'

@Component({
  components: {
    FlightDetailsDiagram,
    DashTextPlaceholder,
    HotelInfoScrollable,
    TlIcon
  },
})
export default class ProductBlock extends Vue implements IProductBlockPost {
  @Prop(String) dealInterval!: string
  @Prop(Number) nights!: number
  @Prop(String) fareBasis!: string
  @Prop(String) roomType!: string
  @Prop(Array) segmentGroups!: ISegmentGroupResource[]
  @Prop(Boolean) exactDates!: boolean
  @Prop(Boolean) packageWithSingleAvailableNight!: boolean;
  @Prop(Number) productsQuantity!: number;
  @Prop(Number) indexSearchResultCard: number
  @Prop(String) provider: string
  // if this component rendered, then the event for the training uer called
  created() {
    if(this.indexSearchResultCard === 1) {
      this.$emit('next-step-training')
    }
  }

  getSegments = getSegments

  get departureFlightDate() {
    return formatDateToDMYDot(this.segmentGroups[0].segments[0].departure.time)
  }
  get returnFlightDate() {
    return formatDateToDMYDot(this.segmentGroups[1].segments[0].departure.time)
  }
  get isBtnMoreAvailable() {
    return this.productsQuantity > 1;
  }
  get i18nStore() {
    return i18n_MODULE
  }
}
