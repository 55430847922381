
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { isEqual } from 'lodash'
import FilterTemplate from 'ui-modules/products-filter/filter.template.vue'
import FilterBoardings from 'ui-modules/products-filter/conditions/filter.boardings.vue'
import FilterNights from 'ui-modules/products-filter/conditions/filter.nights.vue'
import FilterPrice from 'ui-modules/products-filter/conditions/filter.price.vue'
import FilterAirlines from 'ui-modules/products-filter/conditions/filter.airlines.vue'
import FilterFacilities from 'ui-modules/products-filter/conditions/filter.facilities.vue'
import FilterDepartureTime from 'ui-modules/products-filter/conditions/filter.departure-time.vue'
import FilterHotelStars from 'ui-modules/products-filter/conditions/filter.hotel-stars.vue'
import FilterProviders from 'ui-modules/products-filter/conditions/filter.providers.vue'
import FilterHotelNameSearch from 'ui-modules/products-filter/conditions/filter.hotel-name-search.vue'
import FilterInstantConfirmation from 'ui-modules/products-filter/conditions/filter.instant-confirmation.vue'
import FilterMap from 'applications/desktop/package-compare-app/components/search-results/ui/filter-map.vue'
import Gmap from 'ui-modules/googleMaps/googleMaps.vue'
import FilterHotelsSort from 'ui-modules/products-filter/conditions/filter.hotels-sort.vue'
import FilterVacationTypes from 'ui-modules/products-filter/conditions/filter.vacation-types.vue'
import FilterSkeletonSlider from './skeletons/filter-skeleton-slider.vue'
import FilterSkeletonField from './skeletons/filter-skeleton-field.vue'
import FilterSkeletonList from './skeletons/filter-skeleton-list.vue'

import {
  IPackageCompareProductFilterClass,
  IPartOfList,
  IProductFilter,
  IRegularFilterPost,
  IRegularResultPost,
  IHotelNameSearchPost
} from 'applications/desktop/package-compare-app/components/search-results/types/search-results.types'

@Component({
  components: {
    FilterAirlines,
    FilterBoardings,
    FilterDepartureTime,
    FilterFacilities,
    FilterHotelStars,
    FilterHotelsSort,
    // FilterMap,
    FilterNights,
    FilterPrice,
    FilterProviders,
    FilterSkeletonSlider,
    FilterSkeletonField,
    FilterSkeletonList,
    FilterTemplate,
    FilterVacationTypes,
    FilterHotelNameSearch,
    FilterInstantConfirmation,
    Gmap
  }
})
export default class PackageFilter extends Vue {
  @Prop(Object)
  post!: IRegularFilterPost
  @Prop(Array) locations!: Array<{ countryCode?: string, lat: number, lng: number}>

  get showResetPanel() {
    // if (this.post.previousFilter === null && !this.post.isFilterUsed) {
    //   return false
    // } else {
    //   return !isEqual(this.post.packageFilter, this.post.previousFilter)
    // }
    return true;
  }
}
