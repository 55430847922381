
import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component({})
export default class Switcher extends Vue {
  @Prop(Boolean) value!: boolean
  @Prop(Boolean) angular: boolean

  inputValue: boolean = null

  get switcherValue() {
    this.inputValue = this.value
    return this.value
  }
}
