
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ModalWindow from 'ui-modules/modals/modal-content/modal-window/modal-window.vue'
import ActionPanel from 'ui-modules/buttons/action-panel.vue'
@Component({
  components: {
    ModalWindow,
    ActionPanel
  }
})
export default class ComparisonLimit extends Vue {
  onClickCompareButton() {
    this.$emit('close')
  }
}
