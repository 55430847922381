
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CustomTooltip extends Vue {
  @Prop({ type: String, default: null }) tooltipText!: string
  @Prop({ type: Boolean, default: false }) top!: boolean
  @Prop({ type: Boolean, default: false }) bottom: boolean
  @Prop({ type: Boolean, default: false }) right!: boolean
  @Prop({ type: String || Number, default: null }) maxWidth!: string | number
  @Prop({ type: Boolean, default: false }) value!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
}
