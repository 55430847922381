import dayjs from 'dayjs'

import formatDate from 'common/filters/formatDate'
import countTime from 'common/filters/countTime'

export const formatDateToYMD = (value: string | Date) => formatDate(value, 'YYYY-MM-DD')

export const formatTodayToYMD = () => formatDate(dayjs().toString(), 'YYYY-MM-DD')

export const formatDateToDMYDot = (value: string | Date) => formatDate(value, 'DD.MM.YYYY')

export const formatDateToDMYShortDot = (value: string | Date) => formatDate(value, 'DD.MM.YY')

export const formatDateToDMDot = (value: string | Date) => formatDate(value, 'DD.MM')

export const formatDateToHHmm = (value: string | Date) => formatDate(value, 'HH:mm')

export const formatFlightTime = (value: string) => countTime(value)

export function getThursdayDates() {
    // Находим текущую дату
    let today = dayjs();
    // Находим ближайший четверг. Если сегодня четверг, берем его, иначе ищем следующий
    let nextThursday = today.day() <= 4 ? today.day(4) : today.add(1, 'week').day(4);
    // Вычисляем следующий четверг, добавив 7 дней к найденному четвергу
    let nextNextThursday = nextThursday.add(7, 'days');

    return {
        start: formatDateToYMD(nextThursday.toDate()),
        end: formatDateToYMD(nextNextThursday.toDate())
    };
}