
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import TlIcon from 'ui-modules/icons/icon'

@Component({
  components: {
    TlIcon
  }
})

export default class SelectionFlightTip extends Vue {}
