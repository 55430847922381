
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Popper from 'vue-popperjs';

@Component({
  components: {
    'popper': Popper
  },
})
export default class CustomTooltip extends Vue {
  @Prop(String) tooltipText!: string
  @Prop(Boolean) medium!: boolean
  @Prop({ type: Boolean, required: false }) disabled!: boolean
  @Prop({ type: String, default: 'hover', required: false }) trigger: 'clickToToggle' | 'hover'
  @Prop({ type: Object, default: () => ({placement: 'bottom'}), required: false }) options: any
  @Prop({ type: Boolean, default: false, required: false })
  autoWidth!: boolean
  @Prop({type: String, default: ''}) rootClass?: string
  @Prop({type: Boolean, default: true}) appendToBody: boolean

  toggleForEvent(event, eventType) {
    return this.trigger === eventType ? this.toggle(event) : null
  }

  toggle(event: Event) {
    event.stopPropagation()
    event.preventDefault()
    this.$refs.popper.doToggle()
  }

  show() {
    this.$emit('show')
  }

  hide() {
    this.$emit('hide')
  }
}
