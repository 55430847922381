
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator"

import Accordion from "ui-modules/accordion/grid-accordion.vue"

import {
  IFilterConditionGroup
} from 'ui-modules/products-filter/types/products-filter.types'

@Component({
  components: {
    Accordion
  },
  name: "result-search-filter"
})
export default class FilterCondition extends Vue {
  @Prop(Object)
  post!: IFilterConditionGroup

  // checkBoarding(boarding: string) {
  //   const index = this.post.packageFilter.boarding.indexOf(boarding)
  //   index === -1
  //     ? this.post.packageFilter.boarding.push(boarding)
  //     : this.post.packageFilter.boarding.splice(index, 1)
  //   this.$emit('apply-filter')
  // }

  // get packageInitialFilterForSelectedResult() {
  //   return this.post?.packageInitialFilterForSelectedResult
  // }
}
