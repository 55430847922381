
import {Sine, Linear, TweenMax, TimelineMax, Power0} from 'gsap/all'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
@Component({})
export default class MarkerLoader extends Vue {
  mounted() {
    const xmlns = "http://www.w3.org/2000/svg",
        xlinkns = "http://www.w3.org/1999/xlink",
        select = function(s) {
          return document.querySelector(s);
        },
        selectAll = function(s) {
          return document.querySelectorAll(s);
        },
        pin = select('.pin'),
        track = select('.track')


    TweenMax.set('svg', {
      visibility: 'visible'
    })

    TweenMax.set(pin, {
      transformOrigin:'50% 100%'
    })

    const tl = new TimelineMax({repeat:-1});

    tl.from(pin, 1.8, { ease:
      Power0.easeNone, y: -50 })

        .to(track, 4, {
          x:'-=240',
          ease:Linear.easeNone
        },'-=1.8')

        .to('.boop', 1.2, {
          alpha:0,
          attr:{
            r:13
          },
          strokeWidth:0,
          ease:Sine.easeOut
        },'-=1.2')
        .to(pin, 1.15, {
          transformOrigin:'50% 31%',
          //rotation:360,
          scaleX:-1,
          y:-43,
          ease:'hup'
        },'-=1.15')

    tl.timeScale(2.4)
  }
}
