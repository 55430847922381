
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Action, Getter } from "vuex-class";

import { IBestDeal } from "modules/product-result.v2/data/product-result.types";

import BestDealsModal from "./ui/best-deal-modal.vue";
import i18n from "modules/i18n/i18n.name";
import googleMaps from "common/googleMaps/maps";
import PrepareSearchDataCommon from 'applications/desktop/package-compare-app/components/prepare-search-data/prepare-search-data.common'

@Component({
  components: {
    BestDealsModal
  }
})
export default class BestDeals extends mixins(PrepareSearchDataCommon) {
  showBestDealModal = false;
  mapScript: HTMLScriptElement = null;
  currentDeal: IBestDeal = null;
  currentImg: string = null;

  async mounted() {
    // await this.searchStore.getBestDeals();
  }

  get isBestDealsPending(): boolean {
    return this.searchStore.pendings.bestDeals;
  }

  // get geo(): IGoogleGeo {
  //   const hotel = this.currentDeal.hotel;
  //   return hotel.geoLocation && hotel.geoLocation.y && hotel.geoLocation.x
  //     ? {
  //         lat: hotel.geoLocation.y,
  //         lng: hotel.geoLocation.x
  //       }
  //     : null;
  // }

  get bestDealsList() {
    const mapped = this.searchStore.bestDeals.map((deal: IBestDeal) => {
      const cityCode = deal.segmentGroups[0].segments[0].arrival.cityCode;
      let images = [];
      const size = ["l", "ls", "s"];
      let index = 0;

      try {
        while (index < 3) {
          images[
            index
          ] = require(`images/cities/${cityCode}/${cityCode}_0.jpg`);
          index++;
        }
      } catch (e) {
        if (!images.length) {
          images = null;
        }
      }

      if (images) {
        return {
          productId: deal.productId,
          nights: deal.nights,
          hotel: deal.hotel,
          price: deal.price.total,
          segmentGroups: deal.segmentGroups,
          supplierAirline: deal.supplierAirline,
          dealFeatures: deal.dealFeatures,
          roomType: deal.roomType,
          fareBasis: deal.fareBasis,
          images
        };
      }
    });

    return mapped.filter((product: any) => product);
  }

  imageSizeIndex(index: number): number {
    if (index === 0 || index === 4 || index === 7 || index % 12 === 0) {
      return 2;
    } else if (
      index === 1 ||
      index === 2 ||
      index === 11 ||
      index === 10 ||
      index === 6
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  // appendScript() {
  //   if (this.mapScript) {
  //     this.$el.removeChild(this.mapScript);
  //     // @ts-ignore
  //     google = undefined;
  //   }

  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.defer = true;
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBFU8Xutwnzsh-FKpKuVlsREXNDn0e1jLY&language=${this.currentLocale}`;

  //   this.mapScript = script;
  //   this.$el.appendChild(script);
  // }

  // getMap() {
  //   setTimeout(() => {
  //     const geo = this.geo;
  //     const address = this.currentDeal.hotel.address;
  //     const mapContainer = document.querySelector(".best-deal-modal-map");

  //     googleMaps(geo, address, mapContainer);
  //   }, 100);
  // }

  // selectBestDeal(deal: IBestDeal) {
  //   this.currentDeal = deal;
  //   this.showBestDealModal = true;
  //   this.getMap();
  // }

  onClose() {
    this.showBestDealModal = false;
    this.currentDeal = null;
  }
}
