
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import Component from "vue-class-component";

@Component({})
export default class ContactSelector extends Vue{
  @Prop(Object) contacts: {
    value: string,
    contactType: string
  };
  @Prop(String) title: string;


  isShowContacts: boolean = false;

  toggleContacts() {
    this.isShowContacts = !this.isShowContacts;
  }
  hideContacts() {
    this.isShowContacts = false;
  }
}
